import React, {useState, useEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Typography from './Typography';
import {FAIL_LITE_COLOR, SUCCESS_COLOR} from '../../Constants';

StatusSnackbar.propTypes = {
   open: PropTypes.bool,                     // Indicates if the snackbar should be shown to the user.
   onClose: PropTypes.func,                  // Callback when the snackbar is closed.
   messageKey: PropTypes.string,                // Intl ID for the error message.
   message: PropTypes.string,                // Text for the message.
   values: PropTypes.object,                 // Value object for the message.
   anchor: PropTypes.object                  // Position object for the anchor.
};

/**
 * Component to show the status messages.
 *
 * Note:
 *    Message is the default property in values.
 *
 * Reviewed:
 */
export default function StatusSnackbar({
   open = true,
   autoHideDuration,
   allowClose,
   onClose,
   messageKey,
   values = {message: 'N/A'},
   message,
   total = 100,
   index,
   errorState= false,
   anchor = {vertical: 'top', horizontal: 'left'}
}) {
   const [isOpen, setIsOpen] = useState(open || autoHideDuration);

   useEffect(() => {
      setIsOpen(open || autoHideDuration);
   }, [open, autoHideDuration]);

   const handleClose = () => {
      setIsOpen(false);

      onClose && onClose();
   };

   return (
      <Snackbar
         open={isOpen}
         autoHideDuration={autoHideDuration}
         onClose={handleClose}
         anchorOrigin={anchor}
         sx={{
           '.MuiSnackbarContent-root': {
             backgroundColor: errorState ? `${FAIL_LITE_COLOR} !important` : `${SUCCESS_COLOR} !important`,
             color: `#FFF !important`,
             pr: 6
           // } : {backgroundColor: SUCCESS_COLOR, color: `${SECONDARY_TEXT} !important`, pr: 6}
           },
           // '& .SnackbarItem-variantSuccess': {
           //   backgroundColor: SUCCESS_COLOR
           // },
           // '& .SnackbarItem-variantError': {
           //   backgroundColor: ERROR_COLOR
           // },
           // '& .SnackbarItem-variantWarning': {
           //   backgroundColor: WARN_LITE_COLOR
           // },
           // '& .SnackbarItem-variantInfo': {
           //   backgroundColor: colors.secondary, //your custom color here
           // },
         }}
         message={(
            <Grid container alignItems={'center'} spacing={1} direction={'column'}
                  sx={{minHeight: '60px', minWidth: '330px'}}>
               <Grid item>
                  <Typography id={messageKey} sx={{mr: 1}} values={values} color={'inherit'}>{message}</Typography>
               </Grid>
               {index === undefined && (
                  <Grid item>
                     <LinearProgressWithLabel value={(index / total) * 100}/>
                  </Grid>
               )}
            </Grid>
         )}
         action={[
            allowClose && (
               <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  size={'small'}
                  sx={{
                     position: 'absolute',
                     right: 0,
                     top: 0,
                     ml: 'auto',
                     zIndex: 1001
                  }}
                  onClick={handleClose}
               >
                  <CloseIcon fontSize='inherit'/>
               </IconButton>
            )
         ]}
      />
   );
}
