import without from 'lodash/without';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {atom, useRecoilState} from 'recoil';

export const progressState = atom({
   key: 'progressState',
   default: {list: [], progress: false, useGlobal: true},
});

/**
 * Hook to call onClose when escape is pressed, and onSubmit when the enter key is pressed.
 */
export default function useProgress(uniqueId) {

   const [progressList, setProgressList] = useRecoilState(progressState);

   // useEffect(() => {
   //     if (useGlobal !== undefined) {
   //        setProgressList(state => ({...state, useGlobal}));
   //     }
   // }, [useGlobal]);

   const setProgress = useCallback((progress) => {
      if (progress) {
         if (progressList?.list.indexOf(uniqueId) < 0 ) {
            const list = [...progressList?.list, uniqueId];
            const progress = list.length > 0;
            setProgressList(state => ({...state, list, progress}));
         }
      } else if (progressList?.list?.length > 0) {
         const list = without(progressList?.list, uniqueId);

         if (progressList?.list?.length !== list.length) {
            const progress = list.length > 0;
            setProgressList(state => ({...state, list, progress}));
         }
      }
   }, [progressList, setProgressList, uniqueId]);

   return useMemo(() => [progressList?.progress, setProgress], [setProgress, progressList]);
}
