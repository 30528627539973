import {
   Heading,
   Image,
   Text,
   Authenticator,
   AmplifyProvider,
   Flex,
   Link,
   useAuthenticator,
   useTheme,
} from '@aws-amplify/ui-react';
import {I18n} from 'aws-amplify';
import {LOGO} from '../../Constants';
import './Authenticator.css';

const originalMessage =
   "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
const originalMessage2 =
   "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
const translatedMessage = 'username cannot have whitespace.';

I18n.putVocabulariesForLanguage('en', {
   [originalMessage]: [translatedMessage],
   [originalMessage2]: [translatedMessage],
   Username: 'Enter your username', // Username label
   Password: 'Enter your password', // Password label
});

function Header() {
   const {tokens} = useTheme();

   return (
      <Image
         alt='logo'
         src={LOGO}
         width={300}
         padding={tokens.space.medium}
         style={{alignSelf: 'center'}}
      />
   );
}
function Footer() {
   const {tokens} = useTheme();

   return (
      <Flex justifyContent='center' padding={tokens.space.medium}>
         <Text>&copy; All Rights Reserved</Text>
      </Flex>
   );
}
function SignInHeader() {
   const {tokens} = useTheme();

   return (
      <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
         Sign in to your Account
      </Heading>
   );
}

function SignInFooter() {
   const {toResetPassword} = useAuthenticator();
   const {tokens} = useTheme();

   return (
      <Flex justifyContent='center' padding={`0 0 ${tokens.space.medium}`}>
         <Link onClick={toResetPassword}>Reset your password</Link>
      </Flex>
   );
}
const testComponents = {
   Header,
   SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter,
   },
   Footer,
};

/**
 * Authenticator for the app. The children won't be displayed until the user has logged in.
 *
 * @param theme The theme for the AWS authentication.
 * @param components The components for the AWS authentication.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export default function AuthenticatorFHG({theme, components = testComponents, children}) {
   return (
     <AmplifyProvider theme={theme}>
        <Authenticator components={components}>{children}</Authenticator>
     </AmplifyProvider>
   );
}
