import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import * as dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {Grid} from '@mui/material';
import InventoryCard from '../inventory/InventoryCard';
import {lighten} from '@mui/material/styles';
import Loading from '../../fhg/components/Loading';
import orderBy from 'lodash/orderBy';
import TypographyFHG from '../../fhg/components/Typography';
import TableFHG from '../../fhg/components/table/TableFHG';
import useMutationFHG from "../../fhg/hooks/data/useMutationFHG";
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {getTransferRefetchQueries, TRANSFER_DELETE, TRANSFERS_QUERY} from '../../data/QueriesGL';
import {DATE_FORMAT_KEYBOARD, ERROR_COLOR, TABLE_HEIGHT} from '../../Constants';

export default function Transfers() {
  const [transferId, setTransferId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [deleteTransfer, setDeleteTransfer] = useState(false);
  const [transferDeleted, setTransferDeleted] = useState(false);
  const [transfersData] = useQueryFHG(TRANSFERS_QUERY, {}, 'transfers.type');
  const [transferDelete, {data: transferDeleteData, error: transferDeleteError}] = useMutationFHG(TRANSFER_DELETE);

  const styles = useMemo(() => {
    return {
      deleteColorStyle: {
        color: 'primary.contrastText',
        backgroundColor: ERROR_COLOR,
        '&:hover': {
          backgroundColor: lighten(ERROR_COLOR, 0.3),
        }
      },
    };
  }, []);

  useEffect(() => {
    if (transferDeleteData && transferDeleteError === undefined) {
      setTransferDeleted(true);
    }
  }, [transferDeleteData, transferDeleteError, setTransferDeleted]);

  useEffect(() => {
    async function deleteThisTransfer() {
      return await transferDelete({
        variables: {id: transferId},
        refetchQueries: getTransferRefetchQueries()
      });
    }
    if (deleteTransfer && !transferDeleted) {
      deleteThisTransfer().then(r => {});
    }
  }, [transferId, transferDelete, deleteTransfer, transferDeleted]);

  const transfers = useMemo(() => {
    const data = transfersData?.transfers || [];

    if (transfersData?.transfers) {
      setLoading(false);
    }

    return orderBy(data, [row => row.id], ['desc']);
  }, [transfersData]);

  const handleDeleteClick = useCallback((id) => {
    if (id) {
      setDeleteTransfer(true);
      setTransferDeleted(false);
      setTransferId(id);
    }
  }, [setDeleteTransfer, setTransferDeleted, setTransferId]);

  const columns = useMemo(() => {
    return [
      {
        id: 0,
        Header: <TypographyFHG id={'transfers.transferDate.column'}/>,
        accessor: 'transferDate',
        Cell: ({value}) => (
          <span>{value ? dayjs(value)?.format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 1,
        Header: <TypographyFHG id={'transfers.card.column'}/>,
        accessor: 'v_inventory',
        Cell: ({value}) => (
          <span style={{margin: 0}}><InventoryCard inventory={value}/></span>
        )
      },
      {
        id: 2,
        Header: <TypographyFHG id={'transfers.clinic.column'}/>,
        accessor: 'clinic.name'
      },
      {
        id: 3,
        Header: <TypographyFHG id={'transfers.doses.column'}/>,
        accessor: 'doses',
        align: 'right',
        isFormattedNumber: true,
        format: '',
        Cell: ({value}) => (
          <span style={{paddingLeft: '16px'}}>{value ? value : ''}</span>
        )
      },
      {
        id: 4,
        Header: <TypographyFHG id={'transfers.notes.column'}/>,
        accessor: 'notes',
        width: 200
      },
      {
        id: 'delete',
        Header: 'Delete',
        headerAlign: 'left',
        accessor: '',
        Cell: ({row}) => (
          <ConfirmButton
            id={'transfers.delete.button'}
            buttonLabelKey=""
            confirmButtonLabelKey="delete.button"
            buttonTypographyProps={{variant:"body1"}}
            sx={{
              backgroundColor: "inherit",
              cursor: "pointer",
              my: 0.25,
              mx: 0,
              width: "32px"
            }}
            style={{cursor: 'pointer'}}
            onConfirm={() => handleDeleteClick(row.original.id)}
            message={`Are you sure you want to delete transfer to: ${row.original.clinic.name}`}
            values={{
              type: 'transfer record',
              name: row.values?.name
            }}
            size='small'
            submitStyle={styles.deleteColorStyle}
            startIcon={<DeleteIcon color="action" style={{width: 132}} />}
          />
        )
      }
    ];
  }, [handleDeleteClick, styles]);

  return (
    <>
    <Loading isLoading={loading}/>
    <Grid container direction={'column'} wrap={'nowrap'}>
      <TypographyFHG id="transfers.title" color="textSecondary" sx={{my: 0, mr: 0, ml: 2}} variant="h5" />
      <Grid item sx={{
          height: TABLE_HEIGHT,
          maxHeight: `calc(100% - 16px)`,
          '& > *': {
            mr: 1,
          },
          overflowY: 'auto', mb: 1}}>
        <TableFHG titleKey="" columns={columns} data={transfers}
                  allowSearch allowCellSelection={false} stickyHeader
                  emptyTableMessageKey={'transfers.noRecord.message'}
        />
      </Grid>
    </Grid>
    </>
  );
}
