import React, {useCallback, useState} from 'react';
import {Card, CardContent, Chip, Link} from '@mui/material';
import * as dayjs from 'dayjs';
import EditDrawer from '../EditDrawer';
import {getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryAdjust from './InventoryAdjust';
import InventoryPatients from './InventoryPatients';
import PropTypes from 'prop-types';
import TypographyFHG from '../../fhg/components/Typography';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  CARD_WIDTH,
  DATE_FORMAT_KEYBOARD, EDIT_DRAWER_WIDTH, CARD_SM_FONT, BGD_COLOR
} from '../../Constants';

export default function InventoryCard(props) {
  const {inventory, showAvailable, showBarcodeLink} = props;
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [{isAdmin}] = useRecoilState(userStatus);

  const isExpiring = useCallback(() => {
    const {expiring} = props;
    return expiring ? 'inventory.card.expiring.label' : 'inventory.card.available.label';
  }, [props]);

  const getCvx1 = useCallback(() => {
    return inventory?.cvxName ? `${inventory?.cvxName} (${inventory?.cvxCode})`.substring(0, 50) : '';
  }, [inventory]);

  const getCvx2 = useCallback(() => {
    return inventory?.cvxName ?
      <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">
        {`${inventory?.cvxName} (${inventory?.cvxCode})`.substring(50, 102)}
      </TypographyFHG> : null;
  }, [inventory]);

  const getCvx3 = useCallback(() => {
    return inventory?.cvxName ?
      <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">
        {`${inventory?.cvxName} (${inventory?.cvxCode})`.substring(102)}
      </TypographyFHG> : null;
  }, [inventory]);

  const handleInventoryLinkClick = useCallback(() => {
    setOpenDetailDrawer(true);
  }, [setOpenDetailDrawer]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const getClinicBarcode = useCallback(() => {
    if (isAdmin && showBarcodeLink) {
      return (
        <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={handleInventoryLinkClick}>{inventory?.clinicBarcode}</Link>
        </TypographyFHG>
      );
    } else {
      return (<TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{inventory?.clinicBarcode}</TypographyFHG>);
    }
  }, [inventory, isAdmin, handleInventoryLinkClick, showBarcodeLink]);

  const getFlyoutContent = () => {
    let action = props.drawerAction ? props.drawerAction : 'default';
    let content;

    switch (action) {
      case 'patient':
        content = (<InventoryPatients id={inventory?.id} onClose={handleDetailClose} />);
        break;
      default:
        content = (<InventoryAdjust inventory={inventory} onClose={handleDetailClose} />);
    }
    return content;
  }

  const getTypeChip = useCallback((typeId) => {
    return (<Chip size='small' label={inventory?.type}
                  style={{margin: 'auto', backgroundColor: `${getChipBgColor(inventory?.colorCode)}`,
                    color: `${hexToRgb(inventory?.colorCode)}`}} />);
  }, [inventory]);

  return (
    <>
      <Card sx={{border: 'thin solid gray', height: '174px', width: CARD_WIDTH, mb: 0, fontSize: CARD_SM_FONT}}>
        <CardContent>
          <Grid container direction="row" justifyContent="space-between">
            <TypographyFHG color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0, fontSize: CARD_SM_FONT}} variant="body1">{inventory?.mfgBrand}</TypographyFHG>
            <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body1">
              {getTypeChip(inventory?.typeId)}
            </TypographyFHG>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <TypographyFHG color="textPrimary" sx={{fontStyle: 'italic', m: 0, fontSize: CARD_SM_FONT}} variant="body1">{`(${inventory?.mfgGeneric})`}</TypographyFHG>
            {getClinicBarcode()}
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{`[Lot: ${inventory?.lot}]`}</TypographyFHG>
            </Grid>
            <Grid item container direction="row">
              <TypographyFHG id="inventory.card.exp.label" color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0, fontSize: CARD_SM_FONT}} variant="body2" />
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{dayjs(inventory?.expDate)?.format(DATE_FORMAT_KEYBOARD)}</TypographyFHG>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid container direction="row">
              <TypographyFHG id="inventory.card.mvx.label" color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0, fontSize: CARD_SM_FONT}} variant="body2" />
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{`${inventory?.manufacturerCode}`}</TypographyFHG>
            </Grid>
            {showAvailable && (
              <Grid container direction="row">
                <TypographyFHG id={isExpiring()} color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0, fontSize: CARD_SM_FONT}} variant="body2" />
                <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{inventory?.doses}</TypographyFHG>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" justifyContent="flex-start">
            <TypographyFHG id="inventory.card.mfg.label" color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0, fontSize: CARD_SM_FONT}} variant="body2" />
            <TypographyFHG color="textPrimary"  sx={{m: 0, fontSize: CARD_SM_FONT}}variant="body2">{inventory?.mfgBarcode}</TypographyFHG>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start">
            <TypographyFHG id="inventory.card.cvx.label" color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0, fontSize: CARD_SM_FONT}} variant="body2" />
            <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{getCvx1()}</TypographyFHG>
            {getCvx2()}
            {getCvx3()}
          </Grid>
        </CardContent>
      </Card>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid item container direction={'column'} overflow={'visible'} style={{width: EDIT_DRAWER_WIDTH}}>
          {getFlyoutContent()}
        </Grid>
      </EditDrawer>
    </>
  );
}

InventoryCard.propTypes = {
  drawerAction: PropTypes.string,
  inventory: PropTypes.object,
  expiring: PropTypes.bool,
  showAvailable: PropTypes.bool,
  showBarcodeLink: PropTypes.bool,
  size: PropTypes.string
}
