import React, {useState} from 'react';
import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import AuthenticatorFHG from './fhg/security/Authenticator';
import {authenticatorTheme} from './components/theme/authenticatorTheme';
import AuthenticatedUser from './fhg/security/AuthenticatedUser';
import awsconfig from './environment/aws-exports';
import awsProductionConfig from './environment/aws-production-exports';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from './components/ErrorBoundary';
import {IntlProvider} from 'react-intl';
import Root from './pages/Root';
import {styled} from '@mui/material/styles';
import {StyledEngineProvider} from '@mui/material/styles';
import theme from './components/theme';
import {ThemeProvider} from '@mui/material/styles';
import useEffect from './fhg/hooks/useEffect';
import './App.css';

const MyComponent = styled('div')({
   height: `100vh`,
});

const config = process.env.REACT_APP_POOL === 'production' ? awsProductionConfig : awsconfig;
Amplify.configure(config);

/**
 * Load the messages for the locales.
 * CAVEAT: This is required for code splitting to work.
 */
const messageLoader = {
   en: () => import('./messages/en-US')
}

const formats = {
   number: {
      USD: {
         style: 'currency',
         currency: 'USD'
      }
   }
};

/**
 * App component. Responsible for initializing AWS, GraphQL and Intl (localization). App can be displayed without
 * authorization. Main is displayed when authorized.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
export default function App() {
   const [messages, setMessages] = useState({});

   useEffect(() => {
      messageLoader.en().then((messages) => {
         setMessages(messages);
      });
   }, []);

   if (Object.keys(messages).length > 0) {
      return (
         <ErrorBoundary>
            <IntlProvider messages={messages} locale={'en'} formats={formats}>
               <StyledEngineProvider>
                  <ThemeProvider theme={theme}>
                     <AuthenticatorFHG theme={authenticatorTheme} style={{overflow: 'hidden', width: '100%', height: '100%'}}>
                        {(authState) => (
                          <AuthenticatedUser authState={authState}>
                             <MyComponent>
                                <CssBaseline/>
                                <Root />
                             </MyComponent>
                          </AuthenticatedUser>
                        )}
                     </AuthenticatorFHG>
                  </ThemeProvider>
               </StyledEngineProvider>
            </IntlProvider>
         </ErrorBoundary>
      );
   } else {
      return null;
   }
}
