import React, {useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import assign from 'lodash/assign';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {cacheAdd} from '../../fhg/utils/DataUtil';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import * as dayjs from 'dayjs';
import {getUsersCacheQueries, getUsersRefetchQueries} from '../../data/QueriesGL';
import {Grid} from '@mui/material';
import Form from '../../fhg/components/edit/Form';
import {formatMessage} from '../../fhg/utils/Utils';
import find from 'lodash/find';
import PasswordTextField from '../../fhg/components/security/PasswordTextField';
import ProgressButton from '../../fhg/components/ProgressButton';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useEffect from '../../fhg/hooks/useEffect';
import useKeyDown from '../../fhg/hooks/useKeyDown';
import useEditData from '../../fhg/components/edit/useEditData';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {ROLES_QUERY, USERS_CREATE} from '../../data/QueriesGL';
import {DATE_TIME_FORMAT, EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from '../../Constants';

export default function UserAdd(props) {
   const intl = useIntl();
   const date = dayjs().format(DATE_TIME_FORMAT);
   const variables = {};
  const [isActive, setIsActive] = useState(true);
   const [isSaving, setIsSaving] = useState(false);
   const [messageKey, setMessageKey] = useState('');
   const [openAlert, setOpenAlert] = useState(false);
   const [userCreate] = useMutationFHG(USERS_CREATE);
   const [rolesData] = useQueryFHG(ROLES_QUERY, variables, 'roles.type');
   const [{isAdmin}] = useRecoilState(userStatus);

   const editItem = useMemo(() => ({
      id: 0,
      firstName: '',
      lastName: '',
      userName: '',
      roleId: 3,
      active: "1",
      password: '',
      dateEntered: date,
      enteredBy: null,
      dateLastUpdated: date
   }), [date]);
   const [
      editValues, handleChange, {
         isChanged = false,
         setIsChanged,
         defaultValues,
         setDefaultValues,
         setEditValues,
         resetValues,
         getValue,
      }
   ] = useEditData(editItem, ['roleId']);

   const roles = useMemo(() => {
      let result = [];
      // If you are not an Admin, you can't make someone else an Admin...
      if (rolesData?.roles) {
         result = rolesData?.roles.filter(role => {
            // Currently only supporting 3 roles.
            if (isAdmin) {
               return role.id <= 3;
            } else {
               return role.id > 1 && role.id <= 3;
            }
         });
      }
      return result;
   }, [isAdmin, rolesData]);

   useEffect(() => {
      if (roles) {
         setDefaultValues(assign({}, editItem));
      }
   }, [editItem, roles, setDefaultValues]);

   const getRole = useCallback(() => {
      let result;
      if (roles?.length > 0) {
         const roleId = getValue('roleId');
         result = find(roles, role => role.id === roleId);
      }
      return result;
   }, [getValue, roles]);

   const isUserNameInUse = useCallback((userName) => {
      let result = false;
      if (props.users?.length > 0) {
         result = find(props.users, u => u.userName === userName);
      }
      return result;
   }, [props]);

   const handleClose = useCallback(() => {
      resetValues();
      setIsActive(false);
      if (props.onClose) {
         props.onClose();
      }
   }, [props, resetValues]);


   const handleRoleChange = (event, value) => {
      setEditValues(editValues => ({...editValues, roleId: value?.id}));
      setIsChanged(true);
   }

   useKeyDown(handleClose);

   /**
    * Submit the user.
    * @return {Promise<void>}
    */
   const handleSubmit = useCallback(async () => {
      if (isChanged) {
         try {
            let currentItem = {
               ...defaultValues,
               ...editValues,
            };
            currentItem.active = isActive ? "1" : "0";
            delete currentItem.role;

            if (isUserNameInUse(currentItem.userName)){
               setMessageKey('user.inuse.message');
               setOpenAlert(true);
               return;
            }

            setIsSaving(true);

            let variables = assign({}, currentItem);
            delete variables.__typename;
            variables.admin = isAdmin;

             await userCreate({
               variables: variables,
               update: cacheAdd(getUsersCacheQueries(), 'user'),
               refetchQueries: getUsersRefetchQueries()
            });

            setIsChanged(false);
            handleClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         handleClose();
      }
   }, [userCreate, defaultValues, editValues, handleClose, isAdmin, isActive, isChanged, isUserNameInUse, setIsChanged]);

   /**
    * Handle onChange events for the password. Check if the password and confirm match.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param name
    * @param reason The reason of the value change if Autocomplete
    */
   const handleChangeCallback = useCallback((event, value, reason, newValue, name) => {
      handleChange(event, value, reason, newValue, name);

      if (name === 'password') {
         const target = document.getElementById('confirm_password');
         if (target) {
            target.setCustomValidity(
               this.state.confirm !== this.state.password ?
                  formatMessage(intl, 'user.confirmMismatch.message', 'Confirm does not match the password.') : '');
         }
      }
   }, [handleChange, intl]);

  const handleCheckChange = useCallback((e)=> {
    setIsActive(e.target.checked);
    setIsChanged(true);
  },[setIsActive, setIsChanged]);

  const handleAlertClose = useCallback(() => {
      setOpenAlert(false);
   }, [setOpenAlert]);

   const getAlert = useCallback(() => {
      let result = undefined;
      if (openAlert) {
         result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
      }
      return result;
   }, [messageKey, openAlert, handleAlertClose]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'user.add.title'} color={'textSecondary'} />
        </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mx: 2, pb: 1}}>
          <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
            {getAlert()}
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                   key="firstName"
                   name="firstName"
                   autoFocus
                   labelKey="user.firstName.label"
                   onChange={handleChange}
                   value={editValues.firstName}
                   required
                />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                 key="lastName"
                 name="lastName"
                 labelKey="user.lastName.label"
                 onChange={handleChange}
                 value={editValues.lastName}
                 required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                 key="userName"
                 name="userName"
                 labelKey="user.userName.label"
                 onChange={handleChange}
                 value={editValues.userName}
                 required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <PasswordTextField
                 key="password"
                 name="password"
                 fullWidth
                 isNew={true}
                 disabled={isSaving}
                 onChange={handleChangeCallback}
                 password={editValues.password}
                 confirm={editValues.confirm}
              />
            </Grid>
            <Grid sx={{my: 0.5, mx: 1}}>
               <TypographyFHG id="user.req" color="textPrimary" variant="caption" />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <Autocomplete
                key="RoleList"
                fullWidth
                getOptionLabel={(option) => option?.title || 'n/a'}
                options={roles}
                onChange={handleRoleChange}
                value={getRole()}
                sx={{mt: 1.5, mb: 1,}}
                renderInput={(params) => (
                  <TextField{...params} label='Role' placeholder='Select role' size="small" variant="outlined" />
                )}
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <CheckboxFHG
                key="isActive"
                name="isActive"
                onChange={handleCheckChange}
                labelKey="user.active.label"
                checked={isActive}
                disabled={isSaving}
                marginTop={0}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
            borderTopColor: 'lightgray',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            bottom: 0,
            height: '60px',
            pl: 3,
            width: '100%'
          }}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

UserAdd.propTypes = {
   users: PropTypes.array,
   onClose: PropTypes.func
}
