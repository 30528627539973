import React, {useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import TypographyFHG from './Typography';

CheckboxFHG.propTypes = {
   name: PropTypes.string.isRequired,
   checked: PropTypes.bool,
   labelKey: PropTypes.string,
   defaultChecked: PropTypes.bool,
   onChange: PropTypes.func,
   label: PropTypes.string,
   fullWidth: PropTypes.bool,
   marginLeft: PropTypes.bool,
   marginTop: PropTypes.bool,
   variant: PropTypes.string
}

/**
 * The Checkbox with preset formats.
 */
export default function CheckboxFHG({name, checked, labelKey, defaultChecked, onChange, label, marginTop, marginLeft, variant, ...checkboxProps}) {
   const [isSet, setIsSet] = useState(checked !== undefined && checked !== null);

   const handleChange = (event) => {
      setIsSet(true);
      onChange && onChange(event);
   };

   return (
      <FormControlLabel
         sx={{
            ml: marginLeft !== undefined ? marginLeft : -1,
            mt: marginTop !== undefined ? marginTop : 2,
         }}
         control={
            <Checkbox
               name={name}
               checked={isSet ? checked : defaultChecked || false}
               onChange={handleChange}
               {...checkboxProps}
            />
         }
         label={<TypographyFHG id={labelKey} variant={variant}>{label}</TypographyFHG>}
      />
   );
}
