import React from 'react'
import {atom} from 'recoil';
import {findIndex} from 'lodash';
import {filter} from 'lodash';
import {Grid} from '@mui/material';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import TableContainerFHG from './TableContainerFHG';
import TableSearchToolbar from './TableSearchToolbar';
import TextFieldFHG from '../../../components/TextField';
import {useCallback} from 'react';
import {useLayoutEffect} from 'react';
import {useMemo} from 'react';
import {useGlobalFilter, useSortBy, useTable} from 'react-table';
import {useRecoilState} from 'recoil';
import useEffect from '../../hooks/useEffect';

export const editCellState = atom({
   key: 'editCellState',
   default: false,
});

export const selectedCellState = atom({
   key: 'selectedCellState',
   default: undefined,
});

const columnHasFooter = (column) => {
   if (column.Footer) {
      return true
   } else if (column.columns?.length > 0) {
      for (const columnItem of column.columns) {
         if (columnHasFooter(columnItem)) {
            return true;
         }
      }
      return false;
   } else {
      return false;
   }
};

function StaticCell({cell, color, defaultValue, onDoubleClick, updateMyData}) {
   const prefix = cell?.column?.prefix;
   const format = cell?.column?.format;
   const isFormattedNumber = cell?.column?.isFormattedNumber || prefix || format;
   const value = cell?.value || defaultValue;

   return (
      <div id={'StaticCell' + cell.getCellProps()?.key} onDoubleClick={(updateMyData && onDoubleClick) || undefined}
           style={{color, minHeight: 18}}>
         {isFormattedNumber ? numberFormatter(prefix + format, value) : value}
      </div>
   );
}

StaticCell.propTypes = {
   cellProps: PropTypes.any,
   onDoubleClick: PropTypes.func,
   color: PropTypes.any,
   prefix: PropTypes.any,
   format: PropTypes.any,
   value: PropTypes.any,
   defaultValue: PropTypes.any
};
// Create an editable cell renderer
export const EditableCell = ({
   cell,
   row,
   column,
   updateMyData, // This is a custom function that we supplied to our table instance
   color,
   defaultValue,
   tableName,
}) => {
   const {index} = row;
   const {id, isEditable} = column;
   // We need to keep and update the state of the cell normally
   const [value, setValue] = React.useState(cell?.value || defaultValue);
   const [showEdit, setShowEdit] = useRecoilState(editCellState);
   const [cellSelected, setCellSelected] = useRecoilState(selectedCellState);

   const isSelected = cellSelected?.tableName === tableName && cellSelected?.rowIndex === row?.index &&
      cellSelected?.columnIndex === column?.___index;
   const prefix = cell?.column?.prefix;
   const format = cell?.column?.format;
   const isFormattedNumber = cell?.column?.isFormattedNumber || prefix || format;

   const handleKey = useCallback((event) => {
      let rowIndex;
      let columnIndex;

      if (!event.defaultPrevented && event.target.name !== 'notes') {
         const offset = (event.shiftKey ? -1 : 1);

         if (event.key === 'Escape') {
            event.preventDefault();
            setCellSelected({});
            // Enter and down arrow
         } else if (event.key === 'Enter') {
            if (cellSelected?.rowIndex >= 0) {
               rowIndex = cellSelected?.rowIndex + offset;
            }
            // Down arrow
         } else if (event.keyCode === 40) {
            if (event.target.tagName !== 'INPUT' && cellSelected?.rowIndex >= 0) {
               rowIndex = cellSelected?.rowIndex + offset;
            }
            // Up Arrow
         } else if (event.keyCode === 38) {
            if (event.target.tagName !== 'INPUT' && cellSelected?.rowIndex >= 0) {
               rowIndex = cellSelected?.rowIndex - offset;
            }
            //Tab
         } else if (event.key === 'Tab') {
            if (cellSelected?.columnIndex >= 0) {
               columnIndex = cellSelected.columnIndex + offset;
            }
            //Right arrow
         } else if (event.keyCode === 39) {
            if (event.target.tagName !== 'INPUT' && cellSelected?.columnIndex >= 0) {
               columnIndex = cellSelected.columnIndex + offset;
            }
            // Left Arrow
         } else if (event.keyCode === 37) {
            if (event.target.tagName !== 'INPUT' && cellSelected?.columnIndex >= 0) {
               columnIndex = cellSelected.columnIndex - offset;
            }
         } else {
            if (isSelected && ((event.keyCode >= 48 && event.keyCode <= 57) ||
               (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode === 189)) {
               setShowEdit(true);
            }
         }

         if (rowIndex !== undefined || columnIndex !== undefined) {
            if ((!rowIndex || (rowIndex >= 0)) &&
               (!columnIndex || (columnIndex >= 0 && columnIndex < row?.cells?.length))) {
               event?.preventDefault();
               event?.stopPropagation();
               setCellSelected(cellSelected => ({
                  ...cellSelected,
                  rowIndex: rowIndex !== undefined ? rowIndex : cellSelected.rowIndex,
                  columnIndex: columnIndex !== undefined ? columnIndex : cellSelected.columnIndex
               }));
            }
         }
      }
   }, [isSelected, row?.cells?.length, cellSelected, setCellSelected, setShowEdit]);

   const onChange = e => {
      setValue(e.target.value)
   };

   const handleDoubleClick = () => {
      let useIsEditable;

      if (typeof isEditable === 'boolean') {
         useIsEditable = isEditable;
      } else if (typeof isEditable === 'function') {
         useIsEditable = isEditable(cell);
      }
      setShowEdit(useIsEditable);
   };

   const handleEditingKeyClick = (event) => {
      const offset = (event.shiftKey ? -1 : 1);

      if (event.key === 'Escape') {
         event.preventDefault();
         event?.stopPropagation();
         setValue(cell.value);
         setShowEdit(false);
      } else if (event.key === 'Enter') {
         event?.preventDefault();
         event?.stopPropagation();
         onBlur();
         if (cellSelected?.rowIndex >= 0) {
            setCellSelected(cellSelected => ({...cellSelected, rowIndex: cellSelected?.rowIndex + offset}));
         }
      } else if (event.key === 'Tab') {
         event?.preventDefault();
         event?.stopPropagation();
         onBlur();
         if (cellSelected?.columnIndex) {
            const columnIndex = cellSelected.columnIndex + offset;
            setCellSelected(cellSelected => ({...cellSelected, columnIndex}));
         }
      }
   };

   const handleFocus = (event) => {
      if (event?.target) {
         event.target.focus();
         event.target.select();
         if (event.target?.scrollIntoViewIfNeeded) {
            event.target.scrollIntoViewIfNeeded(true);
         } else {
            event.target?.scrollIntoView(true);
         }
      }
   };

   /**
    * Update the external data when the input is blurred.
    */
   const onBlur = () => {
      if (updateMyData) {
         updateMyData(index, id, value, row.original, cell);
      } else {
         console.log('Column is not editable - ', id)
      }
      setShowEdit(false);
   };

   /**
    * If the cell?.value is changed external, sync it up with our state.
    */
   useEffect(() => {
      setValue(cell?.value);
      setShowEdit(false);
   }, [cell?.value, setShowEdit]);

   useEffect(() => {
      if (isSelected) {
         document.addEventListener('keydown', handleKey, false);
      }
   }, [isSelected, cellSelected, setCellSelected, setShowEdit, handleKey]);

   /**
    * Cleanup the listener when this component is removed. This is needed because of a bug in react. Should be able to
    * do this from UseEffect.
    */
   useLayoutEffect(() => {
      if (!isSelected) {
         document.removeEventListener('keydown', handleKey, false);
      }
      return () => {
         document.removeEventListener('keydown', handleKey, false);
      }
   }, [isSelected, cellSelected, setCellSelected, setShowEdit, handleKey]);

   if (showEdit && isSelected) {
      let inputProps;
      if (isFormattedNumber) {
         inputProps =
            {prefix, 'data-index': index, 'data-type': 'number', pattern: '^[0-9,]+$', title: 'Enter a valid number.'};
      }

      return (
         <TextFieldFHG
            sx={{root: {'& input': {p: 4}}}}
            isFormattedNumber={isFormattedNumber}
            inputProps={inputProps ? inputProps : undefined}
            withLabel={false}
            value={value}
            onChange={onChange}
            onFocusCapture={handleFocus}
            onBlurCapture={onBlur}
            autoFocus
            margin={'none'}
            size={'small'}
            onKeyDown={handleEditingKeyClick}
         />
      )
   } else {
      return (
         <StaticCell onDoubleClick={handleDoubleClick} color={color} cell={cell} defaultValue={defaultValue}
                     updateMyData={updateMyData}/>
      )
   }
};

EditableCell.propTypes = {
   cell: PropTypes.shape({
      value: PropTypes.any,
   }),
   row: PropTypes.shape({
      index: PropTypes.number.isRequired,
   }),
   column: PropTypes.shape({
      id: PropTypes.string.isRequired,
   }),
   updateMyData: PropTypes.func,
   tableName: PropTypes.string,
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
   minWidth: 10,
   maxWidth: 200,
   Cell: EditableCell
};

/**
 * The table component that handles searching (filtering) and selection.
 *
 * Reviewed: 4/14/20
 *
 * @param titleKey The message key for the title.
 * @param columns The columns for the table.
 * @param data The data for the table.
 * @param updateMyData  Callback when a cell is edited.
 * @param skipPageReset Indicates that the page reset should be skipped.
 * @param onSelect Callback when an item is selected.
 * @param selectId The current selection item ID.
 * @param allowCellSelection Indicates if cells can be selected.
 * @param searchFilter The current search filter for external search.
 * @param allowSearch Indicates if the search component should be shown.
 * @param displaySearch Indicates if search is displayed on toolbar.
 * @param stickyHeader Indicates if the header of the table is sticky.
 * @param emptyTableMessageKey Message Key for message displayed when the table is empty
 * @param hasShadow Indicates if the table has a shadow
 * @param totalPath Path in the row.values to the total value to display.
 * @param onChangeNotes Callback when the notes change.
 * @param name Name of the table.
 * @param children The children components.
 * @return {*}
 * @constructor
 */
export default function TableFHG({
   titleKey,
   columns,
   data,
   updateMyData,
   skipPageReset,
   onSelect,
   selectId,
   allowCellSelection = false,
   searchFilter,
   allowSearch,
   displaySearch = true,  // to display search on toolbar
   stickyHeader = true,
   emptyTableMessageKey,
   hasShadow = true,
   totalPath,
   name,
   children
}) {
   let currentDefaultColumn;

   currentDefaultColumn = {...defaultColumn, Cell: updateMyData ? EditableCell : StaticCell};

   // Use the state and functions returned from useTable to build your UI
   const {
      getTableProps,
      headerGroups,
      footerGroups,
      prepareRow,
      rows,
      preGlobalFilteredRows,
      setGlobalFilter,
      setHiddenColumns,
      state: {globalFilter},
   } = useTable(
      {
         columns,
         data: data || [{}],
         defaultColumn: currentDefaultColumn,
         autoResetPage: !skipPageReset,
         // updateMyData isn't part of the API, but
         // anything we put into these options will
         // automatically be available on the instance.
         // That way we can call this function from our
         // cell renderer!
         updateMyData,
         tableName: name,
      },
      useGlobalFilter,
      useSortBy,
   );

   const hasFooter = useMemo(() => {
      return findIndex(columns, columnHasFooter) >= 0;
   }, [columns]);

   React.useEffect(() => {
      const hiddenColumns = filter(columns, column => column.show === false).map(column => column.id);
      setHiddenColumns(hiddenColumns);
   }, [columns, setHiddenColumns]);

   /**
    * Set the global filter from the search filter when the search filter changes.
    */
   useEffect(() => {
      if (searchFilter !== undefined) {
         setGlobalFilter(searchFilter);
      }
   }, [searchFilter, setGlobalFilter]);

   return (
      <Grid name={'TableFHG Root Grid'} container direction={'column'} wrap={'nowrap'}>
         {allowSearch && (
           <Grid container direction={'row'} >
            <TableSearchToolbar
               titleKey={titleKey}
               preGlobalFilteredRows={preGlobalFilteredRows}
               setGlobalFilter={setGlobalFilter}
               globalFilter={globalFilter}
               displaySearch={displaySearch}
            >
               {children}
            </TableSearchToolbar>
           </Grid>
         )}
         <TableContainerFHG name={name} headerGroups={headerGroups} footerGroups={footerGroups} prepareRow={prepareRow}
                            getTableProps={getTableProps} rows={rows} onSelect={onSelect} selectId={selectId}
                            allowCellSelection={allowCellSelection} stickyHeader={stickyHeader}
                            emptyTableMessageKey={emptyTableMessageKey} hasFooter={hasFooter} hasShadow={hasShadow}
         />
      </Grid>
   )
}
