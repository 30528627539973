import React, {useCallback, useMemo, useState} from 'react';
import {Grid} from '@mui/material';
import DashboardTable from './DashboardTable';
import EditDrawer from '../EditDrawer';
import ExpiringTable from './ExpiringTable';
import {formatMessage} from '../../fhg/utils/Utils';
import {Link} from '@mui/material';
import LowInventory from './LowInventory';
import StatusIndicator from '../report/StatusIndicator';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {DASHBOARD_LOW_INVENTORY_QUERY} from '../../data/QueriesGL';
import {BGD_COLOR, EDIT_DRAWER_WIDTH} from '../../Constants';

export default function Dashboard() {
  const intl = useIntl();
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [vacData] = useQueryFHG(DASHBOARD_LOW_INVENTORY_QUERY, {});
  const mfgVaccines = useMemo(() => vacData?.results || [], [vacData]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleLowLink = useCallback(() => {
    setOpenDetailDrawer(true);
  }, []);

  const getLowInventoryMessage = useCallback(() => {
    let result = null;
    if (mfgVaccines && mfgVaccines.length > 0) {
      if (mfgVaccines.length === 1) {
        result = (
          <TypographyFHG sx={{fontSize: '1rem'}} variant="body1" color="textSecondary">
            <Link sx={{cursor: 'pointer'}} underline="none" variant="inherit" onClick={handleLowLink}>
              {formatMessage(intl, 'mfgVaccine.reorderThreshold.msg', '', [mfgVaccines[0].name])}
            </Link>
          </TypographyFHG>);
      } else {
        result = (
          <TypographyFHG sx={{fontSize: '0.875rem'}} variant="body1" color="textSecondary">
            <Link sx={{cursor: 'pointer'}} underline="none" variant="inherit" onClick={handleLowLink}>
              {formatMessage(intl, 'mfgVaccine.reorderThreshold.multiple.msg', '', [])}
            </Link>
          </TypographyFHG>);
      }
    }
    return result;
  }, [handleLowLink, intl, mfgVaccines]);

  return (
    <Grid container direction="column" sx={{display: 'flex', height: '100%'}}>
      <Grid container direction="row" justifyContent="space-between" sx={{height: '12px'}}>
        <TypographyFHG variant="h5" id="dashboard.title" color="textSecondary" sx={{mt: 0, mr: 1, mb: 0, ml: 2}} />
        {getLowInventoryMessage()}
        <StatusIndicator />
      </Grid>
      <Grid name='ContentGrid' sx={{pt:2.5, px: 2, pb: 0}}>
        <Grid container direction="row">
            <Grid item sm={8} xs={12}>
              <DashboardTable vaccines={mfgVaccines} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <ExpiringTable />
            </Grid>
        </Grid>
      </Grid>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid item container direction={'column'} overflow={'visible'} style={{width: EDIT_DRAWER_WIDTH}}>
          <LowInventory vaccines={mfgVaccines} onClose={handleDetailClose} />
        </Grid>
      </EditDrawer>
    </Grid>
  );
}

