import React, {useCallback, useMemo, useState} from 'react';
import {Grid} from '@mui/material';
import {Chip, Link} from '@mui/material';
import * as dayjs from 'dayjs';
import EditDrawer from '../EditDrawer';
import {getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import InventoryAdjust from '../inventory/InventoryAdjust';
import NotesIcon from '@mui/icons-material/AssignmentOutlined';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {INVENTORY_NEGATIVE_DOSES_QUERY, INVENTORY_TYPES_QUERY} from '../../data/QueriesGL';
import {
  BGD_COLOR,
  DATE_FORMAT_KEYBOARD,
  EDIT_DRAWER_WIDTH,
  TABLE_HEIGHT
} from '../../Constants';
import find from "lodash/find";

export default function NegativeInventoryReport() {
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const [negativesData] = useQueryFHG(INVENTORY_NEGATIVE_DOSES_QUERY, {}, 'negative.type');
  const inventoryTypes = useMemo(() => inventoryTypesData?.inventoryTypes || [], [inventoryTypesData]);
  const negatives = useMemo(() => negativesData?.negatives || [], [negativesData]);

  const handleEdit = useCallback((id) => {
    const row = find(negatives, x => x.id === id);
    setSelectedRow(row);
    setOpenDetailDrawer(true);
  }, [negatives, setOpenDetailDrawer]);

  const getColor = useCallback((typeId) => {
    let result = 'FFFFFF';
    if (inventoryTypes && inventoryTypes.length > 0) {
      let type = inventoryTypes.find(i => i.id === typeId);
      if (type) {
        result = type.colorCode;
      }
    }
    return result;
  }, [inventoryTypes]);

  const columns = useMemo(() => {
    return [
      {
        id: 0,
        Header: <TypographyFHG id={'inventory.addedDate.column'}/>,
        accessor: 'addedDate',
        Cell: ({value}) => (
          <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 1,
        Header: <TypographyFHG id={'inventory.mfgId.column'}/>,
        accessor: 'mfgBrand'
      },
      {
        id: 2,
        Header: <TypographyFHG id={'inventory.mfgGeneric.column'}/>,
        accessor: 'mfgGeneric'
      },
      {
        id: 3,
        Header: <TypographyFHG id={'inventory.clinicBarcode.column'}/>,
        accessor: 'clinicBarcode',
        Cell: ({row}) => {
          return (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.id)}>{row.original?.clinicBarcode}</Link>);
        }
      },
      {
        id: 4,
        Header: <TypographyFHG id={'inventory.lot.column'}/>,
        accessor: 'lot'
      },
      {
        id: 5,
        Header: <TypographyFHG id={'inventory.expDate.column'}/>,
        accessor: 'expDate',
        Cell: ({value}) => (
          <span>{dayjs(value)?.format(DATE_FORMAT_KEYBOARD)}</span>
        )
      },
      {
        id: 6,
        Header: <TypographyFHG id={'inventory.doses.column'}/>,
        accessor: 'doses',
        align: 'right',
        isFormattedNumber: true,
        format: '',
        Cell: ({value}) => (
          <span style={{paddingLeft: '16px'}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 7,
        Header: <TypographyFHG id={'inventory.type.column'}/>,
        accessor: 'type',
        Cell: ({row, value}) => {
          let color = getColor(row.original?.typeId);
          return (<Chip size='small' label={value}
                        sx={{
                          margin: 'auto',
                          flex: 'flex',
                          my: 1,
                          mx: 2,
                          backgroundColor: `${getChipBgColor(color)}`, color: `${hexToRgb(color)}`
                        }}
                  />);
        }
      },
      {
        id: 'notes',
        Header: <TypographyFHG id={'inventory.notes.column'}/>,
        accessor: 'notes',
        Cell: ({value}) => (value ? <NotesIcon /> : null)
      }
    ];
  }, [getColor, handleEdit]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  return (
    <Grid container direction="column" overflow={'visible'} wrap="nowrap">
      <TypographyFHG id="negative.inventory.title" color="textSecondary" sx={{mt: 0, mr: 0, mb: 0.5, ml: 2}} variant="h5" />
      <Grid item sx={{
        height: TABLE_HEIGHT,
        '& > *': {
          mr: 1,
        },
        overflowY: 'auto',
        mb: 1,
      }}>
        <TableFHG name="negative-inventory" columns={columns} data={negatives}
                  allowSearch displaySearch
                  emptyTableMessageKey={'negative.inventory.noRecord.message'}
        >
          <>&nbsp;</>
        </TableFHG>
      </Grid>

      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid item container direction={'column'} overflow={'visible'} style={{width: EDIT_DRAWER_WIDTH}}>
          {<InventoryAdjust inventory={selectedRow} onClose={handleDetailClose} />}
        </Grid>
      </EditDrawer>
    </Grid>
  );
}
