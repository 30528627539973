import React, {useCallback, useEffect, useMemo, useState} from 'react';
import assign from 'lodash/assign';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import * as dayjs from 'dayjs';
import Form from '../../fhg/components/edit/Form';
import {Grid} from '@mui/material';
import {HexColorPicker, HexColorInput} from 'react-colorful';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getInventoryTypeRefetchQueries,
  INVENTORY_TYPE_UPDATE,
  INVENTORY_TYPE_QUERY,
} from '../../data/QueriesGL';
import {
  ACTIVE_STRING,
  DATE_DB_FORMAT, EDIT_DRAWER_ITEM_WIDTH,
  EDIT_DRAWER_WIDTH,
  INACTIVE_STRING,
  PRIMARY_DARK_COLOR
} from '../../Constants';

export default function InventoryTypeEdit(props) {
  const [{userId}] = useRecoilState(userStatus);
  const {id: inventoryTypeId, onClose} = props;
  const options = {variables: {id: props.id}};
  const [activeStatus, setActiveStatus] = useState(false);
  const [color, setColor] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [inventoryTypeData] = useQueryFHG(INVENTORY_TYPE_QUERY, options);
  const [inventoryTypeUpdate, {data, error}] = useMutationFHG(INVENTORY_TYPE_UPDATE);
  const invType = useMemo(() => inventoryTypeData?.inventoryType || {}, [inventoryTypeData]);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      resetValues
    }
  ] = useEditData({}, []);

  useMemo(() => {
    if (inventoryTypeData?.inventoryType) {
      setDefaultValues(assign({}, invType, {id: inventoryTypeId, lastUpdatedBy: userId}));
      setActiveStatus(invType.active === ACTIVE_STRING);
      setColor(invType.colorCode);
    }
  }, [invType, inventoryTypeId, inventoryTypeData, setActiveStatus, setDefaultValues, userId]);

  const handleClose = useCallback(() => {
    resetValues();
    if (onClose) {
      onClose();
    }
  }, [onClose, resetValues]);

  useEffect(() => {
    if (data && !error) {
      handleClose();
    }
  }, [data, error, handleClose]);

  useEffect(() => {
    if (error) {
      setMessageText(error.message);
      setOpenAlert(true);
    }
  }, [error]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        let currentItem = {
          ...defaultValues,
          ...editValues,
          colorCode: color,
          dateLastUpdated: dayjs().format(DATE_DB_FORMAT)
        };

        setIsSaving(true);

        currentItem.active = activeStatus ? ACTIVE_STRING : INACTIVE_STRING;
        delete currentItem.activeValue;

        let variables = assign({}, currentItem);
        currentItem['__typename'] = 'InventoryType';

        await inventoryTypeUpdate ({
          variables: variables,
          refetchQueries: getInventoryTypeRefetchQueries()
        });

        setIsChanged(false);
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [activeStatus, color, inventoryTypeUpdate, defaultValues, editValues, handleClose, isChanged, setIsChanged]);

  const handleColorChange = useCallback((value) => {
    setColor(value);
    setIsChanged(true);
  }, [setColor, setIsChanged]);

  const handleStatusChange = useCallback((e)=> {
    setActiveStatus(e.target.checked);
    setIsChanged(true);
  },[setActiveStatus, setIsChanged]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  if (defaultValues.id) {
  return (
    <Form onSubmit={handleSubmit}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
        <TypographyFHG variant={'h5'} id={'inventoryType.edit.title'} color={'textSecondary'} />
      </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
          <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
            {getAlert()}
            <TypographyFHG variant="subtitle1" color="textSecondary">
              {`Id: ${inventoryTypeId}`}
            </TypographyFHG>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key={'name1'}
                name="name"
                autoFocus
                inputProps={{style: {fontSize: '0.875rem'}}}
                labelKey="inventoryType.name.label"
                onChange={handleChange}
                value={editValues?.name || defaultValues?.name}
                required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key={'fundingSourceValue' + defaultValues.id}
                name="fundingSourceValue"
                inputProps={{style: {fontSize: '0.875rem'}}}
                labelKey="inventoryType.fundingSourceValue.label"
                onChange={handleChange}
                value={editValues?.fundingSourceValue || defaultValues?.fundingSourceValue}
                required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key={'fundingSourceDescription' + defaultValues.id}
                name="fundingSourceDescription"
                inputProps={{style: {fontSize: '0.875rem'}}}
                labelKey="inventoryType.fundingSourceDescription.label"
                onChange={handleChange}
                value={editValues?.fundingSourceDescription || defaultValues?.fundingSourceDescription}
                required
              />
            </Grid>
            <CheckboxFHG
              key={'activeValue'}
              name="activeValue"
              disabled={isSaving}
              onChange={handleStatusChange}
              color={'default'}
              labelKey="inventoryType.active.check.label"
              checked={activeStatus}
              marginTop={0}
            />
            <Grid sx={{display: "flex", flexDirection: "column", mt: 1}}>
              <HexColorPicker color={color} onChange={handleColorChange} />
              <Grid sx={{mt: 2}}>
                <HexColorInput color={color} onChange={handleColorChange}
                               style={{height: '32px', width: '120px'}}
                               placeholder="Type a color value" prefixed alpha />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
  } else {
    return null;
  }
}

InventoryTypeEdit.propTypes = {
  types: PropTypes.array,
  id: PropTypes.any,
  onClose: PropTypes.func
}
