import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

/**
 * Component to show the error messages and loading spinner.
 *
 * Note:
 *    Message is the default property in values.
 */
function Loading({isLoading=true, hasBackdrop = false, ...progressProps}) {
   if (isLoading) {
      if (hasBackdrop) {
         return <>
            <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%', zIndex: 5000}} />
            <Backdrop open={true} sx={{zIndex: 4000, color: '#fff', opacity: '0.2 !important'}} />
         </>
      } else {
         return <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%', zIndex: 5000}} {...progressProps}/>;
      }
   } else {
      return null;
   }
}

Loading.propTypes = {
   isLoading: PropTypes.bool,                // Indicates if the data is still loading.
   hasBackdrop: PropTypes.bool,              // Indicates if the backdrop should display.
}

export default Loading;
