
// Dates displayed to the user or to match the DB format.
export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'M/D/YYYY hh:mm a';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
export const INVENTORY_DATE_NUMBER = 1;
export const INVENTORY_DATE_UNIT = 'year';

//zIndex values for the different levels.
export const MIDDLE_INDEX = 2000;
export const TOP_INDEX = 3000;
export const BOTTOM_INDEX = 1000;
export const NORMAL_INDEX = 1;

export const BGD_COLOR = '#FFF';
export const BGD_DEFAULT = '#F4F5F7';
export const BGD_TABLE_HEAD = '#7882DB';
export const ERROR_COLOR = '#AA0B06';
export const WARNING_COLOR = '#F5CD19';
export const SUCCESS_COLOR = '#5C9E52';
export const SUCCESS_LITE_COLOR = '#99e3aa';
export const FAIL_LITE_COLOR = '#ff5770';
export const WARN_LITE_COLOR = '#fff4e5';
export const LOAN_COLOR = '#C8A9F1FF';
export const PRIMARY_COLOR = '#5663D2';
export const PRIMARY_DARK_COLOR = '#23319F';
export const SECONDARY_TEXT = '#5664D2';
export const SELECTED_COLOR = '#23319F';
export const LOGO_LARGE= '/images/vim-logo.png';
export const LOGO = LOGO_LARGE;

export const ENDPOINT = !process.env.REACT_APP_ENDPOINT ? '/api/graphql/' : `http://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
export const ENDPOINT_PROD = 'http://vaxtrace-test-env.eba-zk2tmhaz.us-east-2.elasticbeanstalk.com/api/graphql';
export const LOGIN_ENDPOINT = `/auth/local/login`;
export const LOGOUT_ENDPOINT = `/auth/logout`;
export const LOGOUT_PATH = '/logout';
console.log('Endpoint = ', ENDPOINT);
console.log('Version = ', process.env.REACT_APP_VERSION);
console.log('Environment = ', process.env.NODE_ENV);

export const APPBAR_HEIGHT = 70;
export const APPBAR_SMALL_HEIGHT = 60;
export const DRAWER_WIDTH = 270;
export const DATE_PICKER_WIDTH = 176;
export const CARD_WIDTH = 364;
export const CARD_WIDTH_SM = 300;
export const CARD_WIDTH_LG = 608;
export const VACCINE_COL_WIDTH = 440;
export const CARD_BOTTOM_MD = 5;
export const CARD_SM_FONT = '0.75rem';
export const EDIT_DRAWER_HEIGHT = 1000;
export const EDIT_DRAWER_WIDTH = 440;
export const EDIT_DRAWER_ITEM_WIDTH = 360;
export const TABLE_HEIGHT = 660;
export const UNDO_DURATION = 4000;

// Required for AWS
// roleNameList: ['Admin', 'Supervisor', 'User', 'ViewOnlyUser'],
export const ADMIN_ROLE_NAME = 'Admin';
export const SUPERVISOR_ROLE_NAME = 'Supervisor';

export const DEFAULT_PATH = '/';

// Admin Paths
export const ADMIN_PATH = '/admin';
export const ADMIN_DASHBOARD_PATH = '/admin/dashboard';
export const ADMIN_ADMINISTER_A_VAX_PATH = '/admin/administer';
export const ADMIN_CAPTURE_IMMUNIZE_PATH = '/admin/capture';
export const ADMIN_INVENTORYTYPES_PATH = '/admin/types';
export const ADMIN_CLINICS_PATH = '/admin/clinics';
export const ADMIN_CVX_PATH = '/admin/cvx';
export const ADMIN_INVENTORY_PATH = '/admin/inventory';
export const ADMIN_PRINT_BARCODE_PATH = '/admin/barcode';
export const ADMIN_TRANSFERS_PATH = '/admin/transfers';
export const ADMIN_BORROWS_PATH = '/admin/borrows';
export const ADMIN_MANUFACTURERS_PATH = '/admin/manufacturers';
export const ADMIN_MFGVACCINES_PATH = '/admin/vaccines';
export const ADMIN_REPORTS_PATH = '/admin/reports';
export const ADMIN_USERS_PATH = '/admin/users';
export const ADMIN_PATRECORDS_PATH = '/admin/patientRecords';

// User paths
export const USER_PATH = '/user';
export const USER_DASHBOARD_PATH = '/user/dashboard';
export const USER_ADMINISTER_A_VAX_PATH = '/user/administer';
export const USER_BORROWS_PATH = '/user/borrows';
export const USER_CAPTURE_IMMUNIZE_PATH = '/user/capture';
export const USER_PATRECORDS_PATH = '/user/patientRecords';
export const USER_INVENTORY_PATH = '/user/inventory';
export const USER_PRINT_BARCODE_PATH = '/user/barcode';
export const USER_TRANSFERS_PATH = '/user/transfers';
export const USER_REPORTS_PATH = '/user/reports';
export const USER_USERS_PATH = '/user/users';

export const USER_NEGATIVE_INV_REPORT_PATH = '/user/negative';
export const USER_EXPANDED_INVENTORY_REPORT_PATH = '/user/inventoryExpandedReport';
export const USER_DISPENSED_REPORT_PATH = '/user/dispensedReport';
export const USER_TRANSFER_REPORT_PATH = '/user/tranferredReport';
export const USER_ACTION_REPORT_PATH = '/user/actionReport';
export const USER_ENROLLMENT_REPORT_PATH = '/user/enrollmentReport';
export const USER_SEARCH_REPORT_PATH = '/user/searchReport';
export const USER_STATUS_REPORT_PATH = '/user/statusReport';
export const USER_SEND_TO_STATE_PATH = '/user/sendToState';

export const NEGATIVE_INV_REPORT_PATH = '/admin/negative';
export const DISPENSED_REPORT_PATH = '/admin/dispensedReport';
export const EXPANDED_INVENTORY_REPORT_PATH = '/admin/inventoryExpandedReport';
export const TRANSFER_REPORT_PATH = '/admin/tranferredReport';
export const ACTION_REPORT_PATH = '/admin/actionReport';
export const ENROLLMENT_REPORT_PATH = '/admin/enrollmentReport';
export const STATUS_REPORT_PATH = '/admin/statusReport';
export const SEND_TO_STATE_PATH = '/admin/sendToState';
export const SEARCH_REPORT_PATH = '/admin/searchReport';

export const ACTIVE = 1;
export const VAC_ACTIVE = 2;
export const INACTIVE = 1;
export const ACTIVE_STRING = '1';
export const INACTIVE_STRING = '0';
export const ADMIN_ROLE_ID = 1;
export const SUPERVISOR_ROLE_ID = 2;
export const USER_ROLE_ID = 3;

export const LOAN_PAID = 'Paid';
export const LOAN_CLOSED = 'Closed';

//Status
export const NOT_STARTED = '1';
export const PENDING = '2';
export const ERROR = '4';
export const REJECTED = '8';
export const SUCCESSFUL = '16';
export const ERROR_ARCH = '32';
export const REJ_ARCH = '64';
export const DISMISSED = '99';

export const NOT_FOUND = 'not found';
export const NOT_STARTED_DESC = 'Not Started';
export const PENDING_DESC = 'Pending';
export const ERROR_DESC = 'Error';
export const REJECTED_DESC = 'Rejected';
export const SUCCESSFUL_DESC = 'Sent Successfully';
export const ERROR_ARCH_DESC = 'Error Archived';
export const REJ_ARCH_DESC = 'Rejected Archived';
export const DISMISSED_DESC = 'Dismissed';
// Adjust Reasons
export const ADJUST_REASON_1 = 'expired';
export const ADJUST_REASON_2 = 'improper_storage';
export const ADJUST_REASON_3 = 'improper_handling_during_transport';
export const ADJUST_REASON_4 = 'destroyed';
export const ADJUST_REASON_5 = 'broken_vial_syringe';
export const ADJUST_REASON_6 = 'vaccine_drawn_not_administered';
export const ADJUST_REASON_7 = 'open_vial_not_all_doses_administered';
export const ADJUST_REASON_8 = 'other';

// Detail Types
export const RECEIVE = 'receive';
export const ADMINISTER = 'administer';
export const BORROW = 'borrow';
export const PAYBACK = 'payback';
export const TRANSFER = 'transfer';
export const LONG_SHORT = 'long_short';
export const WASTED = 'wasted';
export const EXPIRED = 'expired';

// VFC
export const VFC = 1;
export const PRIVATE = 2;

export const verifyEnum = {
  'DEFAULT': 0,
  'SUCCESS': 1,
  'BAD_CVX': 2,
  'INVALID': 3,
  'NO_MATCH': 4,
  'WRONG_TYPE': 5
};

export const verifyKeys = {
  0: '',
  1: 'payback.verify.message.success',
  2: 'payback.verify.message.badCvx',
  3: 'payback.verify.message.invalid',
  4: 'payback.verify.message.noMatch',
  5: 'payback.verify.message.wrong.type'
};

export const SEARCH_DEFAULT = 'unkStyle';
export const SEARCH_SUCCESS = 'successStyle';
export const SEARCH_FAIL = 'failStyle';
export const STYLES = {
  unkStyle: {backgroundColor: BGD_COLOR, border: 'none', mb: 1, ml: 2, p: 1, height: '30px', width: '180px'},
  successStyle: {backgroundColor: SUCCESS_LITE_COLOR, border: 'thin solid gray', borderRadius: '5px', fontSize: '0.75rem', py: 0.5, px: 1, height: '30px', width: '180px'},
  failStyle: {backgroundColor: FAIL_LITE_COLOR, color: '#FFF', border: 'thin solid gray', borderRadius: '5px', fontSize: '0.75rem', py: 0.5, px: 1, height: '30px', width: '180px'}
};
