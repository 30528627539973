import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Drawer} from '@mui/material';
import IconButton from '@mui/material/IconButton';

export default function EditDrawer({open = true, onClose, children, ...styleProps}) {
   return (
      <Drawer
         anchor={'right'}
         open={open}
         ModalProps={{disableEscapeKeyDown: true, BackdropProps: {sx: {mt: 0}}}}
      >
         {open && onClose && (
            <IconButton key='close' aria-label='Close' color='inherit'
                        sx={{right: 0, top: 0, ml: 'auto', mb: -6, zIndex: 1001}}
                        onClick={onClose}>
               <CloseIcon/>
            </IconButton>
         )}
         {children}
      </Drawer>
   );
}
