import React from 'react';
import {Card, CardContent} from '@mui/material';
import DispensedIcon from '@mui/icons-material/Person';
import EnrollIcon from '@mui/icons-material/AssignmentIndOutlined';
import {Grid} from '@mui/material';
import InventoryIcon from '@mui/icons-material/LibraryBooksOutlined';
import {Link} from 'react-router-dom';
import NegativeIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/SendOutlined';
import TransferIcon from '@mui/icons-material/SwapHorizOutlined';
import TypographyFHG from '../../fhg/components/Typography';
import WasteIcon from '@mui/icons-material/DeleteOutlined';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  DISPENSED_REPORT_PATH,
  EDIT_DRAWER_WIDTH,
  ENROLLMENT_REPORT_PATH,
  NEGATIVE_INV_REPORT_PATH,
  SEARCH_REPORT_PATH,
  STATUS_REPORT_PATH,
  TRANSFER_REPORT_PATH,
  ACTION_REPORT_PATH,
  EXPANDED_INVENTORY_REPORT_PATH,
  USER_STATUS_REPORT_PATH,
  USER_EXPANDED_INVENTORY_REPORT_PATH,
  USER_TRANSFER_REPORT_PATH,
  USER_ACTION_REPORT_PATH,
  USER_DISPENSED_REPORT_PATH,
  USER_ENROLLMENT_REPORT_PATH,
  USER_NEGATIVE_INV_REPORT_PATH,
  USER_SEARCH_REPORT_PATH
} from '../../Constants';

export default function ReportsLanding() {
  const [{isAdmin}] = useRecoilState(userStatus);

  return (
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <TypographyFHG id="reports.landing.title" color="textSecondary" variant="h5"
                     sx={{mt: 0, mr: 0, mb: 1, ml: 2}} gutterBottom/>
      <Grid container direction="row" sx={{py: 3, px: 10}}>
        <Card sx={{p: 2, width: EDIT_DRAWER_WIDTH}}>
          <CardContent>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <WasteIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? ACTION_REPORT_PATH : USER_ACTION_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.action.title" color="textPrimary" />
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <DispensedIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? DISPENSED_REPORT_PATH : USER_DISPENSED_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.dispensed.title"
                               color="textPrimary" />
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <EnrollIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? ENROLLMENT_REPORT_PATH : USER_ENROLLMENT_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.enrollment.title"
                               color="textPrimary" />
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <SearchIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? SEARCH_REPORT_PATH : USER_SEARCH_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.search.title"
                               color="textPrimary" />
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <InventoryIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? EXPANDED_INVENTORY_REPORT_PATH : USER_EXPANDED_INVENTORY_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.inventory.expanded.title"
                               color="textPrimary" />
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <NegativeIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? NEGATIVE_INV_REPORT_PATH : USER_NEGATIVE_INV_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.negative.inventory.title"
                               color="textPrimary"/>
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <SendIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? STATUS_REPORT_PATH : USER_STATUS_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.status.title"
                               color="textPrimary"/>
              </Link>
            </Grid>
            <Grid container direction="row" sx={{my: 2.5, mx: 1}}>
              <TransferIcon sx={{mr: 3, ml: 1}} />
              <Link sx={{my: 2, mx: 0}} to={isAdmin ? TRANSFER_REPORT_PATH : USER_TRANSFER_REPORT_PATH}>
                <TypographyFHG variant="subtitle1" id="reports.tranReport.title"
                               color="textPrimary"/>
              </Link>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
