import React from 'react';
import CustomInputTextField from './CustomInputTextField';
import get from 'lodash/get';
import NumberFormatCustom from '../fhg/components/NumberFormat';
import TextField from '@mui/material/TextField';
import useMessage from '../fhg/hooks/useMessage';

/**
 * The TextField with preset formats.

 * @param className
 * @param name
 * @param labelKey
 * @param placeholderKey
 * @param helpKey
 * @param helperText
 * @param maxRows
 * @param defaultValue
 * @param value
 * @param onChange
 * @param margin
 * @param InputLabelProps
 * @param InputProps
 * @param label
 * @param fullWidth
 * @param isFormattedNumber
 * @param variant
 * @param size
 * @param labelTemplate
 * @param editData
 * @param classesProp
 * @param internalKey
 * @param textFieldProps
 * @return {JSX.Element}
 * @constructor
 */
export default function TextFieldCustom({
   className,
   name,
   labelKey,
   placeholderKey,
   helpKey,
   helperText,
   maxRows,
   defaultValue,
   value,
   onChange,
   margin = 'normal',
   InputLabelProps,
   InputProps,
   label,
   fullWidth = true,
   isFormattedNumber,
   variant = 'outlined',
   size = 'small',
   labelTemplate,
   editData,
   classes: classesProp,
   internalKey,
   ...textFieldProps
}) {
   const classes = {...classesProp};
   const useLabelKey = labelTemplate?.format({name}) || labelKey;
   const currentLabel = useMessage(useLabelKey, label);
   const currentPlaceholder = useMessage(placeholderKey) || undefined;
   const helpText = useMessage(helpKey, helperText) || helperText;

   const useInputProps = {
      ...InputProps,
   };

   if (isFormattedNumber) {
      if (get(InputProps, 'inputComponent') || get(InputProps, 'inputProps.component')) {
         console.log('isFormattedNumber cannot have a different input component.', InputProps);
      }
      useInputProps.inputComponent = CustomInputTextField;
      useInputProps.inputProps =
         {...get(InputProps, 'inputProps', {}), ...(textFieldProps.inputProps || {}), component: NumberFormatCustom};
   }

   return (
      <TextField
         {...textFieldProps}
         key={'internal' + internalKey}
         name={name}
         className={`${classes.textFieldStyle} ${className}`}
         label={currentLabel}
         placeholder={currentPlaceholder}
         helperText={helpText}
         maxRows={maxRows}
         defaultValue={defaultValue}
         value={value}
         onChange={onChange}
         InputProps={useInputProps}
         InputLabelProps={{
            ...InputLabelProps,
            // shrink: (isFormattedNumber && value !== undefined) ? true : undefined,
         }}
         variant={variant}
         size={size}
         margin={margin}
         fullWidth={fullWidth}
      />
   );
}
