import gql from 'graphql-tag';
import * as dayjs from 'dayjs';
import {DELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {CREATE_UPDATE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {
  ADJUST_FRAGMENT,
  ADJUSTED_INVENTORY_FRAGMENT,
  CLINIC_BARCODE_FRAGMENT,
  CLINICS_FRAGMENT,
  CVX_FRAGMENT,
  CVX_STATUS_FRAGMENT,
  DASHBOARD_FRAGMENT,
  DASHBOARD_EXPIRING_FRAGMENT,
  DASHBOARD_LOW_INVENTORY_FRAGMENT,
  DASHBOARD_STATUS_FRAGMENT,
  DISPENSED_DETAIL_FRAGMENT,
  ENTY_PERSON_FRAGMENT,
  INSURANCE_FRAGMENT,
  INVENTORY_FRAGMENT, V_INVENTORY_FRAGMENT,
  INVENTORY_TYPE_FRAGMENT,
  LOANS_FRAGMENT, LOAN_REASON_FRAGMENT, V_LOAN_FRAGMENT,
  MANUFACTURER_FRAGMENT,
  MFGVACCINE_FRAGMENT,
  PATIENT_RECORD_FRAGMENT,
  PATIENTRECORD_FRAGMENT,
  PAYBACKS_FRAGMENT,
  V_PATIENT_RECORD_FRAGMENT,
  VIEW_PATIENT_FRAGMENT,
  ROLES_FRAGMENT,
  TRANSFERS_FRAGMENT,
  USERS_FRAGMENT,
  TRANSFERRED_REPORT_FRAGMENT,
  WASTED_REPORT_FRAGMENT,
  PI_REPORT_FRAGMENT,
  ENROLL_REPORT_FRAGMENT,
  STATE_STATUS_FRAGMENT,
  SEND_FRAGMENT,
  V_CHART_IMMUNIZATION_FRAGMENT,
  EXPANDED_MONTHLY_FRAGMENT,
  MONTHLY_DETAIL_FRAGMENT,
  MONTHLY_DETAIL_A_FRAGMENT,
  MONTHLY_DETAIL_B_FRAGMENT
} from './FragmentsGL';
import {DATE_DB_FORMAT, INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT} from '../Constants';

// Not in use
// export const BARCODE_MAX_QUERY = gql`
//   query getBarcode_Max {
//     barcode: reservedBarcodes_Max {
//         ...barcodeInfo
//     }
//   }
//   ${CLINIC_BARCODE_FRAGMENT}
// `;
//
// export const CLINIC_BARCODE_CREATE = {
//   mutation: gql`
//     mutation clinicBarcodeCreate($clinicBarcode: String!)
//     {
//       barcode: reservedBarcodes_Create(reservedBarcodes: {clinicBarcode: $clinicBarcode}) {
//         ...barcodeInfo
//     }
//   }
//   ${CLINIC_BARCODE_FRAGMENT}
//    `,
//   typeKey: 'clinicBarcode.type',
//   actionKey: CREATE_UPDATE_ACTION
// };

export const INVENTORY_BARCODE_MAX_QUERY = gql`
  query getInventoryBarcode_Max {
    barcode: inventoryBarcodes_Max {
        ...barcodeInfo
    }
  }
  ${CLINIC_BARCODE_FRAGMENT}
`;

export const BARCODE_SEARCH_QUERY = gql`
  query getBarcodeSearch($clinicBarcode: [String]) {
    barcodes: inventoryBarcodes_Search(reservedBarcodesSearch: {clinicBarcode: $clinicBarcode}) {
        ...barcodeInfo
    }
  }
  ${CLINIC_BARCODE_FRAGMENT}
`;

// Borrows
export const BORROWS_QUERY = gql`
   query getLoansAllWhere($lfiCvxCode: [String], $paid: [String]) {
      borrows: v_loan_AllWhere(loanSearch: {lfiCvxCode: $lfiCvxCode, paid: $paid}) {
         ...vLoanInfo
      }
   }
   ${V_LOAN_FRAGMENT}
`;

export const BORROW_CREATE = {
  mutation: gql`
      mutation loanCreate($loanDate: String, $doses: Float, $notes: String, $paid: String, $inventoryId: Int, $loanToInventoryId: Int, $reasonId: Int, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String) {
         loans: loans_Create(loans: {loanDate: $loanDate, doses: $doses, notes: $notes, paid: $paid, inventoryId: $inventoryId, loanToInventoryId: $loanToInventoryId, reasonId: $reasonId, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
            ...loansInfo
         }
      }
      ${LOANS_FRAGMENT}
   `,
  typeKey: 'loan.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const BORROW_DELETE = {
  mutation: gql`
    mutation loanDelete($id: Int!) {
      borrow: loans_Delete(loansId: $id)
    }
  `,
  typeKey: 'borrows.type',
  actionKey: DELETE_ACTION
};

export const BORROW_MULTIPLE_CREATE = {
  mutation: gql`
      mutation loansMultipleCreate($loanDate: String, $fromInvId: Int, $toTypeId: Int, $borrowNotes: String, $reasonId: Int, $enteredBy: Int, $dateEntered: String) {
          loans: loans_Multiple_Create(loans: {loanDate: $loanDate, fromInvId: $fromInvId, toTypeId: $toTypeId, borrowNotes: $borrowNotes, reasonId: $reasonId, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
              ...loansInfo
          }
      }
      ${LOANS_FRAGMENT}
  `,
  typeKey: 'loan.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const getBorrowsRefetchQueries = () => {
  return [
    {query: BORROWS_QUERY, variables: {}, queryPath: 'borrows'}
  ];
};

// Clinics
export const CLINICS_QUERY = gql`
   query getClinicsAll {
      clinics: clinics_All {
         ...clinicInfo
      }
   }
   ${CLINICS_FRAGMENT}
`;

export const CLINIC_QUERY = gql`
    query getClinicById($id: Int!) {
        clinic: clinics_ById(clinicsId: $id) {
            ...clinicInfo
        }
    }
    ${CLINICS_FRAGMENT}
`;

export const CLINIC_CREATE = {
  mutation: gql`
    mutation clinicCreate($clinicID: Int!, $name: String!, $phone: String!, $enteredBy: Int, $dateEntered: String!, $lastUpdatedBy: Int, $dateLastUpdated: String)
    {
      clinic: clinics_Create(clinics: {clinicID: $clinicID, name: $name, phone: $phone, enteredBy: $enteredBy, dateEntered: $dateEntered, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated}) {
        ...clinicInfo
      }
    }
    ${CLINICS_FRAGMENT}
  `,
  typeKey: 'clinic.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const CLINIC_UPDATE = {
  mutation: gql`
    mutation clinicUpdate($id: Int!, $clinicId: Int, $name: String, $phone: String, $lastUpdatedBy: Int, $dateLastUpdated: String)
    {
      clinic: clinics_Update(clinicsId: $id, clinics: {clinicID: $clinicId, name: $name, phone: $phone, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated}) {
        ...clinicInfo
      }
    }
    ${CLINICS_FRAGMENT}
  `,
  typeKey: 'clinic.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const CLINIC_DELETE = {
  mutation: gql`
    mutation clinicDelete($id: Int!) {
      clinic: clinics_Delete(clinicsId: $id)
    }
  `,
  typeKey: 'clinic.type',
  actionKey: DELETE_ACTION
};

export const getClinicRefetchQueries = () => {
  return [{query: CLINICS_QUERY, variables: {}, queryPath: 'clinics'},];
};

// Cvx
export const CVXS_QUERY = gql`
   query getCvxAll {
      cvxs: cvx_All {
         ...cvxInfo
      }
   }
   ${CVX_FRAGMENT}
`;

export const CVX_QUERY = gql`
    query getCvxById($id: Int!) {
        cvx: cvx_ById(cvxId: $id) {
            ...cvxInfo
        }
    }
    ${CVX_FRAGMENT}
`;

export const CVX_CREATE = {
  mutation: gql`
    mutation cvxCreate($cvxId: Int!, $cvxCode: String, $shortDescription: String, $description: String, $vaccineStatus: Int, $notes: String, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String, $dateCvxLastUpdated: String)
    {
      cvx: cvx_Create(cvx: {cvxId: $cvxId, cvxCode: $cvxCode, shortDescription: $shortDescription, description: $description, vaccineStatus: $vaccineStatus, notes: $notes, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered, dateCvxLastUpdated: $dateCvxLastUpdated, nonVaccine: false}) {
        ...cvxInfo
      }
    }
    ${CVX_FRAGMENT}
  `,
  typeKey: 'cvx.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const CVX_UPDATE = {
  mutation: gql`
    mutation cvxUpdate($id: Int!, $cvxId: Int, $cvxCode: String, $shortDescription: String, $description: String, $vaccineStatus: Int, $notes: String, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String, $dateCvxLastUpdated: String)
    {
      cvx: cvx_Update(cvxId: $id, cvx: {cvxId: $cvxId, cvxCode: $cvxCode, shortDescription: $shortDescription, description: $description, vaccineStatus: $vaccineStatus, notes: $notes, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered, dateCvxLastUpdated: $dateCvxLastUpdated, nonVaccine: false}) {
        ...cvxInfo
      }
    }
    ${CVX_FRAGMENT}
  `,
  typeKey: 'cvx.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const getCvxCacheQueries = () => {
  return [{query: CVXS_QUERY, variables: {}, queryPath: 'cvxs'}];
};

export const getCvxRefetchQueries = () => {
  return [{query: CVXS_QUERY, variables: {}, queryPath: 'cvxs'},];
};

export const CVX_STATUS_QUERY = gql`
  query getCvxStatusAll($limit: Int, $offset: Int) {
    cvxStatus: cvxStatus_All(limit: $limit, offset: $offset) {
      ...cvxStatusInfo
    }
  }
  ${CVX_STATUS_FRAGMENT}
`;

// Dashboard
export const DASHBOARD_QUERY = gql`
  query getDashboard {
    records: callDashboard {
      ...dashboardInfo
    }
  }
  ${DASHBOARD_FRAGMENT}
`;

export const DASHBOARD_LOW_INVENTORY_QUERY = gql`
  query getDashboardLowInventory {
    results: callDashboardLowInventory {
      ...dashboardLowInventoryInfo
    }
  }
  ${DASHBOARD_LOW_INVENTORY_FRAGMENT}
`;

export const DASHBOARD_STATUS_QUERY = gql`
  query getDashboardStatus {
    results: callDashboardStatus {
      ...dashboardStatusInfo
    }
  }
  ${DASHBOARD_STATUS_FRAGMENT}
`;

export const DASHBOARD_EXPIRING_QUERY = gql`
  query getDashboardExpiring {
      expireRecords: callDashboardExpiring {
      ...dashboardExpiringInfo
    }
  }
  ${DASHBOARD_EXPIRING_FRAGMENT}
`;

export const DISPENSED_DETAIL_QUERY = gql`
  query getDispensedDetail($typeId: [Int], $startDate: [String], $endDate: [String]) {
    records: dispensedDetail_AllWhere(dispensedDetailSearch: {typeId: $typeId, startDate: $startDate, endDate: $endDate}) {
      ...dispensedDetailInfo
    }
  }
  ${DISPENSED_DETAIL_FRAGMENT}
`;

export const ENTY_PERSON_SEARCH_QUERY = gql`
  query searchEntyPersons($lastName: [String], $firstName: [String]) {
    persons: enty_person_AllLikeWhere (enty_personSearch: {person_LastName: $lastName, person_FirstName: $firstName}) {
      ...entyPersonInfo
    }
  }
  ${ENTY_PERSON_FRAGMENT}
`;

export const ADJUSTED_INVENTORY_CREATE = {
  mutation: gql`
      mutation adjustedInventoryCreate($inventoryId: Int!, $adjustType: String, $adjustDate: String, $doses: Float, $reason: String, $notes: String, $vaccineID: Int, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String)
      {
         adjustedInventory: inventory_UpdateWithAdjust(
            inventoryId: $inventoryId,
            inventory: {vaccineID: $vaccineID, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated},
            adjustCreateInput: {adjustType: $adjustType, adjustDate: $adjustDate, doses: $doses, reason: $reason, notes: $notes, inventoryId: $inventoryId, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}
         ) {
            ...adjustInfo
         }
     }
     ${ADJUST_FRAGMENT}
   `,
  typeKey: 'adjustedInventory.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const ADJUSTED_INVENTORY_UPDATE = {
  mutation: gql`
    mutation adjustedInventoryUpdate($adjustId: Int!, $adjustedInventoryId: Int!, $adjustDate: String, $reason: String, $notes: String, $lastUpdatedBy: Int, $dateLastUpdated: String)
    {
      adjustedInventory: adjustedInventory_Update(
        adjustedInventoryId: $adjustedInventoryId,
        adjustedInventory: {adjustID: $adjustId, adjustDate: $adjustDate, reason: $reason, notes: $notes, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated}
      ) {
        ...adjustmentInfo
      }
    }
    ${ADJUSTED_INVENTORY_FRAGMENT}
  `,
  typeKey: 'adjustedInventory.type',
  actionKey: CREATE_UPDATE_ACTION
}

export const INSURANCE_CATEGORIES_QUERY = gql`
  query getInsuranceCategories {
    insurance: insuranceCategories_All {
      ...insuranceCategoriesInfo
    }
  }
   ${INSURANCE_FRAGMENT}
`;

export const V_INVENTORY_LIST_QUERY = gql`
   query getV_Inventory_ByDate($startDate: [String], $endDate: [String]) {
      inventories: v_inventory_ByDate(v_byDateSearch: {startDate: $startDate, endDate: $endDate}) {
         ...v_inventoryInfo
      }
   }
   ${V_INVENTORY_FRAGMENT}
`;

export const HISTORICAL_INVENTORY_SEARCH_QUERY = gql`
   query getHistorical_Search($clinicBarcode: [String], $mfgBrand: [String]) {
      records: v_inventory_AllWhere(v_inventorySearch: {clinicBarcode: $clinicBarcode, mfgBrand: $mfgBrand}, limit: 200) {
         ...v_inventoryInfo
      }
   }
   ${V_INVENTORY_FRAGMENT}
`;

export const INVENTORY_QUERY = gql`
   query getInventoryById($id: Int!) {
      inventory: v_inventory_ById(v_inventoryId: $id) {
         ...v_inventoryInfo
      }
   }
   ${V_INVENTORY_FRAGMENT}
`;

export const MFG_SEARCH_QUERY = gql`
   query getInventorySearch($mfgBarcode: [String]) {
      mfgVaccines: mfgVaccine_AllWhere(mfgVaccineSearch: {mfgBarcode: $mfgBarcode}) {
         ...mfgVaccineInfo
      }
   }
   ${MFGVACCINE_FRAGMENT}
`;

export const INVENTORY_SEARCH_CLINIC_BARCODE_QUERY = gql`
   query getClinicVaccineSearch($clinicBarcode: [String]) {
      clinicVaccine: v_inventory_AllWhere(v_inventorySearch: {clinicBarcode: $clinicBarcode}) {
         ...v_inventoryInfo
      }
   }
   ${V_INVENTORY_FRAGMENT}
`;

export const INVENTORY_NEGATIVE_DOSES_QUERY = gql`
    query getNegativeInventory {
        negatives: v_inventory_NegativeDoses {
            ...v_inventoryInfo
        }
    }
    ${V_INVENTORY_FRAGMENT}
`;

export const INVENTORY_CREATE = {
  mutation: gql`
      mutation inventoryCreate($lot: String, $addedDate: String, $doses: Float, $clinicBarcode: String, $expDate: String, $notes: String, $typeId: Int, $mfgId: Int, $originalDoses: Float, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String)
      {
         inventory: inventory_Create(inventory: {lot: $lot, addedDate: $addedDate, doses: $doses, clinicBarcode: $clinicBarcode, expDate: $expDate, notes: $notes, typeId: $typeId, mfgId: $mfgId, originalDoses: $originalDoses, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
            ...inventoryInfo
         }
      }
      ${INVENTORY_FRAGMENT}
   `,
  typeKey: 'inventory.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const INVENTORY_UPDATE = {
  mutation: gql`
    mutation inventoryUpdate($inventoryId: Int!, $clinicBarcode: String, $lot: String, $expDate: String, $notes: String, $typeId: Int, $mfgId: Int, $addedDate: String, $doses: Float, $originalDoses: Float) {
      inventory: inventory_Update(
        inventoryId: $inventoryId,
        inventory: {vaccineID: $inventoryId, clinicBarcode: $clinicBarcode, lot: $lot, expDate: $expDate, notes: $notes, typeId: $typeId, mfgId: $mfgId, addedDate: $addedDate, doses: $doses, originalDoses: $originalDoses}
      ) 
      {
        ...inventoryInfo
      }
    }
      ${INVENTORY_FRAGMENT}
  `,
  typeKey: 'inventory.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const INVENTORY_DELETE = {
  mutation: gql`
    mutation inventoryDelete($inventoryId: Int!) {
      numberDeleted: inventory_Delete(inventoryId: $inventoryId)
    }
  `,
  typeKey: 'inventory.type',
  actionKey: DELETE_ACTION
};

export const getInventoryListCacheQueries = (startDate, endDate) => {
  return [{query: V_INVENTORY_LIST_QUERY, variables: {startDate, endDate}, queryPath: 'inventories'}];
};

export const getInventoryListRefetchQueries = (startDate, endDate) => {
  return [
    {query: V_INVENTORY_LIST_QUERY, variables: {startDate, endDate}},
    {query: DASHBOARD_EXPIRING_QUERY, variables: {}},
    {query: INVENTORY_NEGATIVE_DOSES_QUERY, variables: {}}
  ];
};

// Inventory Type
export const INVENTORY_TYPES_ALL_QUERY = gql`
  query getInventoryTypeAllWhere {
    inventoryTypes: inventoryType_All {
      ...inventoryTypeInfo
    }
  }
  ${INVENTORY_TYPE_FRAGMENT}
`;

export const INVENTORY_TYPES_QUERY = gql`
   query getInventoryTypeAllWhere {
      inventoryTypes: inventoryType_AllWhere(inventoryTypeSearch: {active: "1"}) {
         ...inventoryTypeInfo
      }
   }
   ${INVENTORY_TYPE_FRAGMENT}
`;

export const INVENTORY_TYPE_QUERY = gql`
    query getInventoryTypeById($id: Int!) {
        inventoryType: inventoryType_ById(inventoryTypeId: $id) {
            ...inventoryTypeInfo
        }
    }
    ${INVENTORY_TYPE_FRAGMENT}
`;

export const INVENTORY_TYPE_CREATE = {
  mutation: gql`
      mutation inventoryTypeCreate($inventoryTypeID: Int!, $name: String, $colorCode: String, $active: String, $fundingSourceValue: String!, $fundingSourceDescription: String!, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String)
      {
          inventoryType: inventoryType_Create(inventoryType: {inventoryTypeID: $inventoryTypeID, name: $name, colorCode: $colorCode, active: $active, fundingSourceValue: $fundingSourceValue, fundingSourceDescription: $fundingSourceDescription, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
              ...inventoryTypeInfo
          }
      }
      ${INVENTORY_TYPE_FRAGMENT}
  `,
  typeKey: 'inventoryType.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const INVENTORY_TYPE_UPDATE = {
  mutation: gql`
      mutation inventoryTypeUpdate($inventoryTypeID: Int!, $name: String, $colorCode: String, $active: String, $fundingSourceValue: String!, $fundingSourceDescription: String!, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String)
      {
          inventoryType: inventoryType_Update(
            inventoryTypeId: $inventoryTypeID, 
            inventoryType: {inventoryTypeID: $inventoryTypeID, name: $name, colorCode: $colorCode, active: $active, fundingSourceValue: $fundingSourceValue, fundingSourceDescription: $fundingSourceDescription, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered
          }) {
            ...inventoryTypeInfo
          }
      }
      ${INVENTORY_TYPE_FRAGMENT}
  `,
  typeKey: 'inventoryType.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const getInventoryTypeCacheQueries = () => {
  return [
    {query: INVENTORY_TYPES_ALL_QUERY, variables: {}, queryPath: 'inventoryTypes'}
  ];
};

export const getInventoryTypeRefetchQueries = () => {
  return [
    {query: INVENTORY_TYPES_QUERY, variables: {}, queryPath: 'inventoryTypes'},
    {query: DASHBOARD_QUERY, variables: {}, queryPath: 'records'},
    {query: DASHBOARD_STATUS_QUERY, variables: {}, queryPath: 'results'},
  ];
};

export const LOAN_UPDATE = {
  mutation: gql`
    mutation loansUpdate($id: Int!, $loanId: Int, $paid: String, $notes: String,  $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String) {
      loans: loans_Update(loansId: $id, loans:{loanID: $loanId, paid: $paid, notes: $notes, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
        ...loansInfo
      }
    }
    ${LOANS_FRAGMENT}
  `,
  typeKey: 'loan.type',
  actionKey: CREATE_UPDATE_ACTION
};

// Paybacks
export const PAYBACKS_QUERY = gql`
  query getPaybacksAllWhere($loanId: [Int]) {
    paybacks: payBacks_AllWhere(payBacksSearch: {loanId: $loanId}) {
      ...paybacksInfo
    }
  }
 ${PAYBACKS_FRAGMENT}
`;

export const PAYBACK_CREATE = {
  mutation: gql`
      mutation paybacksCreate($loanId: Int, $paybackDate: String, $doses: Float, $notes: String, $paybackFromInvId: Int, $paybackToInvId: Int, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String) {
         paybacks: payBacks_Create(payBacks: {payBackId: 0, loanId: $loanId, paybackDate: $paybackDate, paybackDoses: $doses, paybackNotes: $notes, paybackFromInvId: $paybackFromInvId, paybackToInvId: $paybackToInvId, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
            ...paybacksInfo
         }
      }
      ${PAYBACKS_FRAGMENT}
   `,
  typeKey: 'paybacks.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const PAYBACK_DELETE = {
  mutation: gql`
      mutation payBacks_Delete($id: Int!) {
          payback: payBacks_Delete(payBacksId: $id)
      }
  `,
  typeKey: 'paybacks.type',
  actionKey: DELETE_ACTION
};

export const getPaybacksRefetchQueries = (startDate, endDate, loanId) => {
  return [
    {query: PAYBACKS_QUERY, variables: {loanId: loanId}, queryPath: 'paybacks'},
    {query: BORROWS_QUERY, variables: {}, queryPath: 'borrows'},
    {query: V_INVENTORY_LIST_QUERY, variables: {startDate, endDate}},
  ];
};

// Loan Reason
export const LOAN_REASONS_QUERY = gql`
   query getLoanReasonsAllWhere {
      loanReasons: loanReason_AllWhere(loanReasonSearch: {reasonActive: "1"}) {
         ...loanReasonInfo
      }
   }
   ${LOAN_REASON_FRAGMENT}
`;

// Manufacturer
export const MANUFACTURERS_QUERY = gql`
   query getManufacturerAll {
      manufacturers: manufacturer_All {
         ...manufacturerInfo
      }
   }
   ${MANUFACTURER_FRAGMENT}
`;

export const MANUFACTURER_QUERY = gql`
   query getManufacturerById($id: Int!) {
      manufacturer: manufacturer_ById(manufacturerId: $id) {
         ...manufacturerInfo
      }
   }
   ${MANUFACTURER_FRAGMENT}
`;

export const MANUFACTURER_CREATE = {
  mutation: gql`
      mutation manufacturerCreate($name: String!, $code: String!, $dateEntered: String!, $enteredBy: Int, $status: Int!, $notes: String)
      {
         manufacturer: manufacturer_Create(manufacturer: {name: $name, code: $code, dateEntered: $dateEntered, enteredBy: $enteredBy, status: $status, notes: $notes}) {
            ...manufacturerInfo
         }
      }
      ${MANUFACTURER_FRAGMENT}
   `,
  typeKey: 'manufacturer.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const MANUFACTURER_UPDATE = {
  mutation: gql`
      mutation manufacturerUpdate($id: Int!, $manufacturerId: Int, $name: String, $code: String, $status: Int, $notes: String, $dateLastUpdated: String, $lastUpdatedBy: Int)
      {
         manufacturer: manufacturer_Update(manufacturerId: $id, manufacturer: {manufacturerId: $manufacturerId, name: $name, code: $code, status: $status, notes: $notes, dateLastUpdated: $dateLastUpdated, lastUpdatedBy: $lastUpdatedBy}) {
            ...manufacturerInfo
         }
      }
      ${MANUFACTURER_FRAGMENT}
   `,
  typeKey: 'manufacturer.type',
  actionKey: CREATE_UPDATE_ACTION,
};

export const getManufacturersRefetchQueries = () => {
  return [{query: MANUFACTURERS_QUERY, variables: {}, queryPath: 'manufacturers'}];
};

// MfgVaccine
export const MFGVACCINES_QUERY = gql`
   query getMfgVaccineAll {
      mfgVaccines: mfgVaccine_All {
         ...mfgVaccineInfo
      }
   }
   ${MFGVACCINE_FRAGMENT}
`;

export const MFGVACCINES_SEARCH_QUERY = gql`
   query getMfgVaccineSearch($mfgBarcode: [String]) {
      mfgVaccines: mfgVaccine_AllWhere(mfgVaccineSearch: {mfgBarcode: $mfgBarcode}) {
         ...mfgVaccineInfo
      }
   }
   ${MFGVACCINE_FRAGMENT}
`;

export const MFGVACCINE_QUERY = gql`
   query getMfgVaccineById($id: Int!) {
      mfgVaccine: mfgVaccine_ById(mfgVaccineId: $id) {
         ...mfgVaccineInfo
      }
   }
   ${MFGVACCINE_FRAGMENT}
`;

export const MFGVACCINE_CREATE = {
  mutation: gql`
      mutation mfgVaccineCreate($mfgID: Int, $dateEntered: String!, $mfgBrand: String!, $mfgBarcode: String!, $mfgGeneric: String!, $isHide: Boolean!, $isNexMonthHide: Boolean!, $manufacturerId: Int!, $cvxId: Int!, $isStandardDosage: Boolean, $standardDose: Float, $enteredBy: Int, $lastUpdatedBy: Int, $dateLastUpdated: String, $reorderThreshold: Int)
      {
         mfgVaccine: mfgVaccine_Create(mfgVaccine: {mfgID: $mfgID, dateEntered: $dateEntered, mfgBrand: $mfgBrand, mfgBarcode: $mfgBarcode, mfgGeneric: $mfgGeneric, isHide: $isHide, isNexMonthHide: $isNexMonthHide, manufacturerId: $manufacturerId, cvxId: $cvxId, isStandardDosage: $isStandardDosage, standardDose: $standardDose, enteredBy: $enteredBy, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, reorderThreshold: $reorderThreshold}) {
            ...mfgVaccineInfo
         }
      }
      ${MFGVACCINE_FRAGMENT}
   `,
  typeKey: 'mfgVaccine.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const MFGVACCINE_UPDATE = {
  mutation: gql`
    mutation mfgVaccineUpdate($id: Int!, $mfgID: Int, $dateEntered: String, $mfgBrand: String, $mfgBarcode: String, $mfgGeneric: String, $isHide: Boolean, $isNexMonthHide: Boolean, $manufacturerId: Int, $cvxId: Int, $isStandardDosage: Boolean, $standardDose: Float, $enteredBy: Int, $lastUpdatedBy: Int, $dateLastUpdated: String, $reorderThreshold: Int)
    {
      mfgVaccine: mfgVaccine_Update(mfgVaccineId: $id, mfgVaccine: {mfgID: $mfgID, dateEntered: $dateEntered, mfgBrand: $mfgBrand, mfgBarcode: $mfgBarcode, mfgGeneric: $mfgGeneric, isHide: $isHide, isNexMonthHide: $isNexMonthHide, manufacturerId: $manufacturerId, cvxId: $cvxId, isStandardDosage: $isStandardDosage, standardDose: $standardDose, enteredBy: $enteredBy, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, reorderThreshold: $reorderThreshold}) {
        ...mfgVaccineInfo
      }
    }
    ${MFGVACCINE_FRAGMENT}
  `,
  typeKey: 'mfgVaccine.type',
  actionKey: CREATE_UPDATE_ACTION,
};

export const getMfgVaccinesCacheQueries = () => {
  return [{query: MFGVACCINES_QUERY, variables: {}, queryPath: 'mfgVaccines', mutationPath: 'mfgVaccines'}];
};

export const getMfgVaccinesRefetchQueries = () => {
  return [{query: MFGVACCINES_QUERY, variables: {}, queryPath: 'mfgVaccines'}];
};

// Patients
export const VIEW_PATIENTS_QUERY = gql`
  query getViewPatients($lastName: [String], $firstName: [String]) {
    viewPatients: view_Patient_AllLikeWhere(view_PatientSearch: {person_LastName: $lastName, person_FirstName: $firstName}) {
      ...viewPatientsInfo
    }
  }
  ${VIEW_PATIENT_FRAGMENT}
`;

export const VIEW_PATIENTS_SEARCH_QUERY = gql`
  query getViewPatients($patientId: [String]) {
    viewPatients: view_Patient_AllWhere(view_PatientSearch: {patientId: $patientId}) {
      ...viewPatientsInfo
    }
  }
  ${VIEW_PATIENT_FRAGMENT}
`;

export const PATIENTS_FOR_INV_QUERY = gql`
  query getPatientRecordsForInv($inventoryId: [Int]) {
    patientRecords: v_patientRecord_AllWhere(v_patientRecordSearch: {inventoryId: $inventoryId}) {
      ...v_patientRecordInfo
    }
  }
  ${V_PATIENT_RECORD_FRAGMENT}
`;

// Patient Record - added limit here because query is timing out.
export const PATIENT_RECORDS_QUERY = gql`
  query getPatientRecordsAll($startDate: [String], $endDate: [String], $typeId: [String], $patientNameLast: [String], $patientNameFirst: [String]) {
    patients: v_patientRecord_AllWhere(limit: 200, v_patientRecordSearch: {startDate: $startDate, endDate: $endDate, typeId: $typeId, patientNameLast: $patientNameLast, patientNameFirst: $patientNameFirst }) {
      ...patientRecordFullInfo
    }
  } 
  ${PATIENTRECORD_FRAGMENT}
`;

export const PATIENT_RECORD_QUERY = gql`
  query getPatientRecord($id: [Int]) {
    patient: v_patientRecord_AllWhere(v_patientRecordSearch: {administerID: $id}) {
      ...patientRecordFullInfo
    }
  }
  ${PATIENTRECORD_FRAGMENT}
`;

export const PATIENT_RECORDS_CREATE = {
  mutation: gql`
      mutation patientRecordsCreate($id: Int!, $dateGiven: String, $dosesGiven: Float, $inventoryId: Int, $insuranceId: Int, $lkup_patientId: String, $patientName: String, $age: String)
      {
         patientRecords: patientRecords_Create(patientRecords: {administerID: $id, dateGiven: $dateGiven, dosesGiven: $dosesGiven, inventoryId: $inventoryId, insuranceId: $insuranceId, lkup_patientId: $lkup_patientId, patientName: $patientName, age: $age}) {
            ...patientRecordInfo
         }
      }
      ${PATIENT_RECORD_FRAGMENT}
   `,
  typeKey: 'patient.create.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const PATIENT_RECORD_DELETE = {
  mutation: gql`
      mutation patientRecords_Delete($id: Int!)
      {
         patientRecords_Delete(patientRecordsId: $id)
      }
   `,
  typeKey: 'patient.delete.type',
  actionKey: DELETE_ACTION
};

export const PATIENT_RECORDS_CREATE_ADM_VAX = {
  mutation: gql`
      mutation patientRecordsCreate($id: Int!, $dateGiven: String, $dosesGiven: Float, $inventoryId: Int, $insuranceId: Int, $patientId: Int, $patientName: String, $notes: String, $age: String)
      {
         patientRecords: patientRecords_Create(patientRecords: {administerID: $id, dateGiven: $dateGiven, dosesGiven: $dosesGiven, inventoryId: $inventoryId, insuranceId: $insuranceId, patientId: $patientId, patientName: $patientName, notes: $notes, age: $age}) {
            ...patientRecordInfo
         }
      }
      ${PATIENT_RECORD_FRAGMENT}
   `,
  typeKey: 'patient.create.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const getPatientRecordsCacheQueries = (startDate, endDate) => {
  return [{
    query: PATIENT_RECORDS_QUERY,
    variables: {startDate, endDate},
    queryPath: 'patients',
    mutationPath: 'patientRecords'
  }];
};

export const getPatientRecordsRefetchQueries = (startDate, endDate, inventoryId) => {
  return [
    {query: PATIENT_RECORDS_QUERY, variables: {startDate, endDate}, queryPath: 'patients'},
    {query: V_INVENTORY_LIST_QUERY, variables: {startDate, endDate}, queryPath: 'inventories'},
    {query: PATIENTS_FOR_INV_QUERY, variables: {inventoryId}, queryPath: 'patientRecords'},
    {query: BORROWS_QUERY, variables: {}, queryPath: 'borrows'}
  ];
};

export const getCaptureRefetchQueries = (startDate, endDate, inventoryId, cvxCode) => {
  // const variables = {lfiCvxCode: cvxCode, paid: 'false', dts: dayjs().format("MMDDYYYYhhmmsszzz")};
  const queries = getInventoryListRefetchQueries(startDate, endDate);
  queries.push({query: PATIENT_RECORDS_QUERY, variables: {startDate, endDate}, queryPath: 'patients'},);
  queries.push({query: PATIENTS_FOR_INV_QUERY, variables: {inventoryId}, queryPath: 'patientRecords'});
  queries.push({query: BORROWS_QUERY, variables: {}, queryPath: 'borrows'});
  return queries;
};

export const PATIENT_RECORDS_UPDATE = {
  mutation: gql`
      mutation patientRecordsUpdate($id: Int!, $dateGiven: String, $dosesGiven: Float, $insuranceId: Int, $inventoryId: Int, $notes: String)
      {
          patients: patientRecords_Update(patientRecordsId: $id, patientRecords: {administerID: $id, dateGiven: $dateGiven, dosesGiven: $dosesGiven, insuranceId: $insuranceId, inventoryId: $inventoryId, notes: $notes}) {
            ...patientRecordInfo
         }
      }
      ${PATIENT_RECORD_FRAGMENT}
   `,
  typeKey: 'patient.update.type',
  actionKey: CREATE_UPDATE_ACTION
};

// Roles
export const ROLES_QUERY = gql`
   query getRolesAll($limit: Int, $offset: Int) {
      roles: roles_All(limit: $limit, offset: $offset) {
         ...roleInfo
      }
   }
   ${ROLES_FRAGMENT}
`;

// Transfers
export const TRANSFERS_QUERY = gql`
   query getTransfersAll {
      transfers: transfers_All {
         ...transfersInfo
      }
   }
   ${TRANSFERS_FRAGMENT}
`;

export const TRANSFER_INVENTORY_CREATE = {
  mutation: gql`
      mutation transferCreate($transferDate: String, $doses: Float, $notes: String, $inventoryId: Int, $clinicId: Int, $lastUpdatedBy: Int, $dateLastUpdated: String, $enteredBy: Int, $dateEntered: String) {
         transfers: transfers_Create(transfers: {transferDate: $transferDate, doses: $doses, notes: $notes, inventoryId: $inventoryId, clinicId: $clinicId, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, enteredBy: $enteredBy, dateEntered: $dateEntered}) {
            ...transfersInfo
         }
      }
      ${TRANSFERS_FRAGMENT}
   `,
  typeKey: 'transfer.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const TRANSFER_DELETE = {
  mutation: gql`
    mutation transferDelete($id: Int!) {
      transfer: transfers_Delete(transfersId: $id)
    }
  `,
  typeKey: 'transfer.type',
  actionKey: DELETE_ACTION
};

export const getTransferCacheQueries = () => {
  return [{query: TRANSFERS_QUERY, variables: {}, queryPath: 'transfers'}];
};
export const getTransferRefetchQueries = (startDate, endDate) => {
  const queries = getInventoryListRefetchQueries(startDate, endDate);
  queries.push({query: TRANSFERS_QUERY, variables: {}, queryPath: 'transfers'});
  return queries;
};

// User
export const USER_QUERY = gql`
  query getUserById ($id: Int!) {
    user: users_ById(usersId: $id) {
      ...usersInfo
    }
  }
   ${USERS_FRAGMENT}
`;

export const USERS_QUERY = gql`
   query getUsersAll($limit: Int, $offset: Int) {
      users: users_All(limit: $limit, offset: $offset) {
        ...usersInfo
      }
   }
   ${USERS_FRAGMENT}
`;

export const USER_BY_COGNITO_SUB_QUERY = gql`
   query getUserByCognitoSub($cognitoSub: [String!]) {
       users: users_AllWhere(usersSearch: {cognitoSub: $cognitoSub}) {
           ...usersInfo
       }
   }
   ${USERS_FRAGMENT}
`;

export const USERS_CREATE = {
  mutation: gql`
      mutation usersCreate($dateEntered: String, $userName: String!, $password: String!, $firstName: String!, $lastName: String!, $active: String, $lastUpdatedBy: Int, $dateLastUpdated: String, $roleId: Int)
      {
         user: users_Create(users: {dateEntered: $dateEntered, userName: $userName, password: $password, firstName: $firstName, lastName: $lastName, active: $active, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated, roleId: $roleId}) {
            ...usersInfo
         }
      }
      ${USERS_FRAGMENT}
   `,
  typeKey: 'user.type',
  actionKey: CREATE_UPDATE_ACTION,
};

export const USERS_UPDATE = {
  mutation: gql`
      mutation usersUpdate($id: Int!, $dateEntered: String, $userName: String, $password: String, $firstName: String!, $lastName: String!, $roleId: Int, $active: String, $lastUpdatedBy: Int, $dateLastUpdated: String)
      {
         user: users_Update(usersId: $id, users: {userID: $id, dateEntered: $dateEntered, userName: $userName, password: $password, firstName: $firstName, lastName: $lastName, roleId: $roleId, active: $active, lastUpdatedBy: $lastUpdatedBy, dateLastUpdated: $dateLastUpdated}) {
            ...usersInfo
         }
      }
      ${USERS_FRAGMENT}
   `,
  typeKey: 'user.type',
  actionKey: CREATE_UPDATE_ACTION,
};

// Delete the user on the server.
export const USER_DELETE = {
  mutation: gql`
      mutation UserDelete($id: Int!) {
         users_Delete(usersId: $id)
      }
   `,
  typeKey: 'users.type',
  actionKey: DELETE_ACTION,
};

export const getUsersCacheQueries = () => {
  return [{query: USERS_QUERY, variables: {}, queryPath: 'users'}];
}

export const getUsersRefetchQueries = () => {
  return [{query: USERS_QUERY, variables: {}, queryPath: 'users'}];
}

// Monthly Inventory
export const EXPANDED_MONTHLY_QUERY = gql`
  query getExpandedMonthlyReport($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int]) {
    monthlies: callMonthlyReport(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId}) {
      ...expandedMonthlyInfo
    }
  }
  ${EXPANDED_MONTHLY_FRAGMENT}
`;

export const MONTHLY_DETAIL_RECEIVED_QUERY = gql`
  query getMonthlyReportDetailReceived($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
    details: monthlyReportDetailReceived(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
      ...monthlyDetailInfo
    }
  }
  ${MONTHLY_DETAIL_FRAGMENT}
`;

export const MONTHLY_DETAIL_ADMINSTERED_QUERY = gql`
  query getMonthlyReportDetailAdministered($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
    details: monthlyReportDetailAdministered(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
      ...monthlyDetailInfo
    }
  }
  ${MONTHLY_DETAIL_FRAGMENT}
`;

export const MONTHLY_DETAIL_BORROWED_FROM_QUERY = gql`
    query getMonthlyReportDetailBorrowedFrom($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
        details: monthlyReportDetailBorrowedFrom(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
            ...monthlyDetailBInfo
        }
    }
    ${MONTHLY_DETAIL_B_FRAGMENT}
`;

export const MONTHLY_DETAIL_BORROWED_TO_QUERY = gql`
    query getMonthlyReportDetailBorrowedTo($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
        details: monthlyReportDetailBorrowedTo(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
            ...monthlyDetailBInfo
        }
    }
    ${MONTHLY_DETAIL_B_FRAGMENT}
`;

export const MONTHLY_DETAIL_PAIDBACK_FROM_QUERY = gql`
    query getMonthlyReportDetailPaidbackFrom($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
        details: monthlyReportDetailPaidbackFrom(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
            ...monthlyDetailBInfo
        }
    }
    ${MONTHLY_DETAIL_B_FRAGMENT}
`;

export const MONTHLY_DETAIL_PAIDBACK_TO_QUERY = gql`
    query getMonthlyReportDetailPaidbackTo($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
        details: monthlyReportDetailPaidbackTo(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
            ...monthlyDetailBInfo
        }
    }
    ${MONTHLY_DETAIL_B_FRAGMENT}
`;

export const MONTHLY_DETAIL_TRANSFERRED_QUERY = gql`
  query getMonthlyReportDetailTransferred($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
    details: monthlyReportDetailTransferred(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
      ...monthlyDetailInfo
    }
  }
  ${MONTHLY_DETAIL_FRAGMENT}
`;

export const MONTHLY_DETAIL_ADJUSTED_QUERY = gql`
  query getMonthlyReportDetailAdjusted($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int], $adjustType: [String]) {
    details: monthlyReportDetailAdjusted(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId, adjustType: $adjustType}) {
      ...monthlyDetailAInfo
    }
  }
  ${MONTHLY_DETAIL_A_FRAGMENT}
`;

export const MONTHLY_DETAIL_LONG_SHORT_QUERY = gql`
  query getMonthlyReportDetailLongShort($verifyDate: [String], $lastVerificationDate: [String], $typeId: [Int], $mfgId: [Int]) {
    details: monthlyReportDetailLongShort(monthly_reportSearch: {verifyDate: $verifyDate, lastVerificationDate: $lastVerificationDate, typeId: $typeId, mfgId: $mfgId}) {
      ...monthlyDetailAInfo
    }
  }
  ${MONTHLY_DETAIL_A_FRAGMENT}
`;

// Transferred Report
export const TRANSFERS_REPORT_QUERY = gql`
  query getTransferredReport($dateEnteredStart: [String], $dateEnteredEnd: [String]) {
    transfers: allTransfersReport(transfer_reportSearch: {dateEnteredStart: $dateEnteredStart, dateEnteredEnd: $dateEnteredEnd}) {
      ...transferredInfo
    }
  }
   ${TRANSFERRED_REPORT_FRAGMENT}
`;

// Wasted Report
export const WASTED_REPORT_QUERY = gql`
  query getWastedReport($dateAdjustedStart: [String], $dateAdjustedEnd: [String], $typeId: [Int]) {
    waste: allWastedReport(wasted_reportSearch: {dateAdjustedStart: $dateAdjustedStart, dateAdjustedEnd: $dateAdjustedEnd, typeId: $typeId}) {
      ...wastedInfo
    }
  }
   ${WASTED_REPORT_FRAGMENT}
`;

export const WASTED_REPORT_DELETE = {
  mutation: gql`
    mutation deleteWastedRecord($adjustedInventoryId: Int!)
    {
      adjustedInventory_Delete(adjustedInventoryId: $adjustedInventoryId)
    }
  `,
  typeKey: 'wasted.adjustment.delete.type',
  actionKey: DELETE_ACTION
};

export const getWastedReportCacheQueries = (dateAdjustedStart, dateAdjustedEnd, typeId) => {
  return [
    {query: WASTED_REPORT_QUERY, variables: {dateAdjustedStart, dateAdjustedEnd, typeId}, queryPath: 'waste'}
  ];
};

export const getAdjustmentRefetchQueries = (dateAdjustedStart, dateAdjustedEnd, typeId) => {
  let invEndDate = dayjs().format(DATE_DB_FORMAT);
  let invStartDate = dayjs(invEndDate).subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
  let result = getInventoryListRefetchQueries(invStartDate, invEndDate);
  result.push(
    {
      query: WASTED_REPORT_QUERY, variables: {
        dateAdjustedStart: dateAdjustedStart,
        dateAdjustedEnd: dateAdjustedEnd,
        typeId: typeId
      }
    }
  );
  return result;
};

export const getPatientRefetchQueries = (startDate, endDate, typeId, nameSearchLast, nameSearchFirst) => {
  let invEndDate = dayjs().format(DATE_DB_FORMAT);
  let invStartDate = dayjs(invEndDate).subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
  let result = getInventoryListRefetchQueries(invStartDate, invEndDate);
  result.push({query: PATIENT_RECORDS_QUERY, variables: {startDate, endDate}, queryPath: 'patients'},);
  result.push(
    {
      query: PATIENT_RECORDS_QUERY, variables: {
        startDate: startDate,
        endDate: endDate,
        typeId: typeId,
        patientNameLast: nameSearchLast,
        nameSearchFirst: nameSearchFirst,
      },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true
    }
  );
  return result;
};

// Enroll Report
export const ENROLL_REPORT_QUERY = gql`
  query getEnrollReport($dateStart: [String], $dateEnd: [String]) {
    enrollments: allEnrollmentReport(enrollment_reportSearch: {dateStart: $dateStart, dateEnd: $dateEnd}) {
      ...enrollmentInfo
    }
  }
   ${ENROLL_REPORT_FRAGMENT}
`;

// Practice Info
export const PI_REPORT_QUERY = gql`
  query getPiReport {
    piInfos: allPracticeInfoReport {
      ...piInfo
    }
  }
   ${PI_REPORT_FRAGMENT}
`;

//State status
export const STATE_STATUS_QUERY = gql`query getImmunizationList($dateGivenStart: [String], $dateGivenEnd: [String], $includeProcess: [String]) {
   exchanges: v_immunizationexchange_list_AllWhere(v_immunizationexchange_listSearch:{
      dateGivenStart:$dateGivenStart, dateGivenEnd:$dateGivenEnd, includeProcess: $includeProcess
   }) {
      ...v_stateStatus
   }
}
${STATE_STATUS_FRAGMENT}`;

export const STATE_SEND_MUT = {
  mutation: gql`mutation sendToState($processList: [Int]) {
      exchanges: immunizationExchange_Send(immunizationExchange: {
         administerId: $processList
      }) {
         ...stateStatus
      }
   }
   ${SEND_FRAGMENT}`,
  typeKey: 'sendToState.type',
  actionKey: "SEND",
};

export const SEND_TO_STATE_STATUS_UPDATE = {
  mutation: gql`mutation updateImmunizationExchange($immunizationExchangeId: Int!, $administerId: Int, $processingStatus: Int) {
      exchange: immunizationExchange_Update(immunizationExchangeId: $immunizationExchangeId, immunizationExchange: {
         immunizationExchangeId: $immunizationExchangeId, administerId: $administerId, processingStatus: $processingStatus}) {
         ...stateStatus
      }
   }
   ${SEND_FRAGMENT}`,
  typeKey: 'sendToState.type',
  actionKey: CREATE_UPDATE_ACTION
};

export const getSendToStateRefetchQueries = (variables) => {
  return [{query: STATE_STATUS_QUERY, variables: variables, queryPath: 'exchanges'}];
};

export const V_CHART_IMMUNIZATION_QUERY = gql`
  query searchCharts($personId: [String], $iDay: [Int], $iMonth: [Int], $iYear: [Int]) {
    immunizations: v_chrt_immunization_AllWhere (v_chrt_immunizationSearch: {person_ID: $personId, immunization_DateDay: $iDay, immunization_DateMonth: $iMonth, immunization_DateYear: $iYear}) {
      ...chartInfo
    }
  }
  ${V_CHART_IMMUNIZATION_FRAGMENT}
`;

export const V_CHART_IMMUNIZATION_REFRESH_QUERY = gql`
  query refreshCharts($personId: [String], $iDay: [Int], $iMonth: [Int], $iYear: [Int]) {
    immunizations: v_chrt_immunization_Where (v_chrt_immunizationRefresh: {person_ID: $personId, immunization_DateDay: $iDay, immunization_DateMonth: $iMonth, immunization_DateYear: $iYear}) {
      ...chartInfo
    }
  }
  ${V_CHART_IMMUNIZATION_FRAGMENT}
`;
