import {createTheme} from '@mui/material/styles';
import {ERROR_COLOR, WARNING_COLOR, SUCCESS_COLOR, PRIMARY_COLOR, SECONDARY_TEXT} from '../Constants';

const theme = createTheme({
   overrides: {
      // MuiAlert: {
      //    styleOverrides: {
      //       standardSuccess: {
      //          backgroundColor: '#edf7ed',
      //          color: '#000'
      //       },
      //       standardError: {
      //          backgroundColor: '#fdeded',
      //          color: '#000'
      //       },
      //       standardWarning: {
      //          backgroundColor: '#fff4e5',
      //          color: '#000'
      //       },
      //       standardInfo: {
      //          backgroundColor: '#e5f6fd',
      //          color: '#000'
      //       }
      //    }
      // },
      MuiButton: {
         textSizeLarge: {
            fontSize: '1rem',
         },
      },
      MuiTableCell: {
         root: {
            padding: '4px'
         }
      },
   },
   palette: {
      primary: {
         light: '#8997FF',
         main: PRIMARY_COLOR,
         dark: '#23319F',
         contrastText: '#fff',
      },
      secondary: {
         main: '#000000',
         light: SECONDARY_TEXT
      },
      action: {
         selected: '#ccf9e8'
      },
      background: {
         main: '#FFFFFF',
         light: '#FFFFFF',
         default: '#F4F5F7', //'#f8fafc',
      },
      text: {
         primary: '#172B4D',
         secondary: SECONDARY_TEXT,
         accent: '#98dea7'
      },
      table: {
         header: {
            primary: '#FFFFFF',
            secondary: '#E9FFFF',
         },
      },
      error: {
         main: ERROR_COLOR,
      },
      warning: {
         main: WARNING_COLOR,
      },
      success: {
         main: SUCCESS_COLOR,
      }
   },
   typography: {
      button: {
         textTransform: 'none',
      }
   },
});

export default theme;
