import React, {useCallback, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import * as dayjs from 'dayjs';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {toNumber} from '../../fhg/utils/Utils';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import ProgressButton from '../../fhg/components/ProgressButton';
import sortBy from 'lodash/sortBy';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getMfgVaccinesRefetchQueries,
  CVXS_QUERY,
  MANUFACTURERS_QUERY,
  MFGVACCINE_CREATE,
} from '../../data/QueriesGL';
import {DATE_DB_FORMAT, PRIMARY_DARK_COLOR} from '../../Constants';

export default function MfgVaccineAdd(props) {
  const {onClose, vaccineList} = props;
  const [{userId}] = useRecoilState(userStatus);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const options = {variables: {}};
  const [cvxId, setCvxId] = useState(null);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [dateEntered, setDateEntered] = useState(null);
  const [mfgBrand, setMfgBrand] = useState('');
  const [mfgBarcode, setMfgBarcode] = useState('');
  const [mfgGeneric, setMfgGeneric] = useState('');
  const [reorderThreshold, setReorderThreshold] = useState(5);
  const [vaccineCreate] = useMutationFHG(MFGVACCINE_CREATE);
  const [cvxsData] = useQueryFHG(CVXS_QUERY, {}, 'cvx.type');
  const [manufacturersData] = useQueryFHG(MANUFACTURERS_QUERY, options, 'manufacturer.type');
  const cvxs = useMemo(() => sortBy(cvxsData?.cvxs, (obj) => parseInt(obj.cvxCode, 10)), [cvxsData]);
  const manufacturers = useMemo(() => orderBy(manufacturersData?.manufacturers, [row => row.name.toLowerCase()], ['asc']), [manufacturersData]);

  const resetValues = useCallback(() => {
    setDateEntered(null);
    setIsHide(false)
    setMfgBrand('');
    setMfgBarcode('');
    setMfgGeneric('');
    setReorderThreshold(5);
    setIsChanged(false);
  }, [setIsChanged]);

  const getCvx = useCallback(() => {
    let result;
    if (cvxs?.length > 0) {
      result = find(cvxs, cvx => cvx.id === cvxId);
    }
    return result;
  }, [cvxId, cvxs]);

  const getManufacture = useCallback(() => {
    let result;
    if (manufacturers?.length > 0) {
      result = find(manufacturers, manufacturer => manufacturer.id === manufacturerId);
    }
    return result;
  }, [manufacturerId, manufacturers]);

  const getShortDescription = useCallback(() => {
    let result;
    if (cvxs?.length > 0) {
      const cvx = find(cvxs, cvx => cvx.id === cvxId);
      result = cvx?.shortDescription || '';
    }
    return result;
  }, [cvxId, cvxs]);

  const handleClose = useCallback(() => {
    resetValues();
    if (onClose) {
      onClose();
    }
  }, [onClose, resetValues]);

  const isNdcUnique = useCallback((ndc) => {
    let result = true;
    if (vaccineList?.length > 0) {
      let found = find(vaccineList, v => v.mfgBarcode === ndc && v.manufacturerId === manufacturerId);
      if (found) {
        result = false;
      }
    }
    return result;
  }, [manufacturerId, vaccineList]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);

        const currentItem = {
          id: 0,
          mfgID: 0,
          mfgBrand: mfgBrand,
          mfgBarcode: mfgBarcode,
          mfgGeneric: mfgGeneric,
          cvxId: cvxId,
          manufacturerId: manufacturerId,
          isStandardDosage: true,
          standardDose: 1,
          reorderThreshold: toNumber(reorderThreshold),
          isHide: isHide,
          isNexMonthHide: false,
          dateEntered: dateEntered ? dayjs(dateEntered).format(DATE_DB_FORMAT) : dayjs().format(DATE_DB_FORMAT),
          enteredBy: userId,
        };

        if (!isNdcUnique(currentItem.mfgBarcode)) {
          setMessageKey('mfgVaccine.duplicate.message');
          setOpenAlert(true);
          return;
        }

         await vaccineCreate({
          variables: currentItem,
          refetchQueries: getMfgVaccinesRefetchQueries()
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [cvxId, dateEntered, handleClose, isChanged, isHide, isNdcUnique, manufacturerId, mfgBrand, mfgBarcode, mfgGeneric, reorderThreshold, setIsChanged, userId, vaccineCreate]);

  const isQuantityError = () => {
    return reorderThreshold !== "" && (reorderThreshold < 0);
  };

  const handleCvxChange = (event, value) => {
    setCvxId(value?.id);
    setIsChanged(true);
  }

  const handleManufacturerChange = (event, value) => {
    setManufacturerId(value?.id);
    setIsChanged(true);
  }

  const handleDateChange = (date) => {
    setDateEntered(date);
    setIsChanged(true);
  };

  const handleIsHideChange = useCallback((e)=> {
    setIsHide(e.target.checked);
    setIsChanged(true);
  },[setIsHide, setIsChanged]);

  const handleMfgBrandChange = useCallback((e)=> {
    setMfgBrand(e.target.value)
    setIsChanged(true);
  },[setMfgBrand, setIsChanged]);

  const handleMfgBarcodeChange = useCallback((e)=> {
    setMfgBarcode(e.target.value)
    setIsChanged(true);
  },[setMfgBarcode, setIsChanged]);

  const handleMfgGenericChange = useCallback((e)=> {
    setMfgGeneric(e.target.value)
    setIsChanged(true);
  },[setMfgGeneric, setIsChanged]);

  const handleReorderThresholdChange = useCallback((e)=> {
    setReorderThreshold(e.target.value)
    setIsChanged(true);
  },[setReorderThreshold, setIsChanged]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey}/></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  return (
    <Form onSubmit={handleSubmit} sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: `calc(100% - 17px)`}}>
      <Grid sx={{height: '52px', mt: 3, ml: 2, top: 0}}>
        <TypographyFHG variant={'h5'} id="mfgVaccine.add.title" color={'textSecondary'} gutterBottom />
      </Grid>
      {getAlert()}
      <Grid sx={{display: 'flex', flexDirection: 'column', minHeight: 0, mx: '16px', paddingBottom: '8px', width: `calc(100% - 40px)`}}>
        <Grid sx={{overflowY: 'auto', mb: 5}}>
          <TextFieldCustom
            key="mfgBrand"
            name="mfgBrand"
            autoFocus
            labelKey="mfgVaccine.mfgBrand.label"
            onChange={handleMfgBrandChange}
            value={mfgBrand}
            required
          />
          <TextFieldCustom
            key="mfgGeneric"
            name="mfgGeneric"
            labelKey="mfgVaccine.mfgGeneric.label"
            onChange={handleMfgGenericChange}
            value={mfgGeneric}
            required
          />
          <Grid container direction="row">
            <Autocomplete
              key="CvxList"
              getOptionLabel={(option) => option?.cvxCode || ''}
              options={cvxs}
              onChange={handleCvxChange}
              renderInput={(params) => (
                <TextField{...params} label='CVX code' placeholder='Select CVX' variant="outlined" />
              )}
              size="small"
              sx={{my: 1, width: '320px'}}
              value={getCvx()}
            />
            <Grid container direction="column" sx={{mb: 1, ml: 2, width: '312px'}}>
              <TypographyFHG id={'cvx.shortDescription'} color="textSecondary" variant="subtitle2"/>
              <TypographyFHG color="textPrimary" variant="body2">
                {getShortDescription()}
              </TypographyFHG>
            </Grid>
          </Grid>
          <Autocomplete
            key="ManufacturersList"
            fullWidth
            options={manufacturers}
            onChange={handleManufacturerChange}
            getOptionLabel={(option) => option?.name || 'n/a'}
            size="small"
            value={getManufacture()}
            sx={{my: 1}}
            renderInput={(params) => (
              <TextField{...params} label='Manufacturer Name' placeholder='Select manufacturer' variant="outlined" />
            )}
          />
          <TextFieldCustom
            key="mfgBarcode"
            name="mfgBarcode"
            labelKey="mfgVaccine.mfgBarcode.label"
            onChange={handleMfgBarcodeChange}
            value={mfgBarcode}
            required
          />
          <TextFieldCustom
            key="reorderThreshold"
            name="reorderThreshold"
            error={isQuantityError()}
            labelKey="mfgVaccine.reorderThreshold.label"
            onChange={handleReorderThresholdChange}
            InputProps={{inputProps: {min: 0}}}
            required
            sx={{mt: 1}}
            value={reorderThreshold}
            type="number"
          />
          <Grid container direction="column" sx={{mt: 2, mb: 1, pt: 0.5}}>
            <KeyboardDatePickerFHG
              key="dateEntered"
              name="dateEntered"
              labelKey="mfgVaccine.dateEntered.label"
              onChange={handleDateChange}
              value={dateEntered}
            />
          </Grid>
          <CheckboxFHG
            key="isHide"
            name="isHide"
            onChange={handleIsHideChange}
            color={'default'}
            labelKey="mfgVaccine.isHide.label"
            checked={isHide}
            disabled={isSaving}
            marginTop={0}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box sx={{
        borderTopColor: 'lightgray',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        bottom: 0,
        height: '60px',
        pl: 3,
        width: '100%'
      }}>
        <Grid container direction="row" sx={{mt: 0.5}}>
          <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                          type={'submit'}
                          size='small' labelKey='save.label' disabled={isSaving}
                          sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                     disabled={isSaving} onClick={() => handleClose()}
                     sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
        </Grid>
     </Box>
  </Form>
  );
}

MfgVaccineAdd.propTypes = {
  onClose: PropTypes.func,
  vaccineList: PropTypes.array,
}
