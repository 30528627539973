import React, {useCallback, useEffect, useMemo, useState} from 'react';
import AdjustedEdit from './AdjustedEdit';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip, Dialog, InputLabel, Link} from '@mui/material';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import * as dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/ArrowDownwardOutlined';
import EditDrawer from '../EditDrawer';
import {formatMessage, getChipBgColor, hexToRgb, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {lighten} from '@mui/material/styles';
import Loading from '../../fhg/components/Loading';
import MenuItem from '@mui/material/MenuItem';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useIntl} from 'react-intl';
import {
  INVENTORY_TYPES_QUERY,
  PI_REPORT_QUERY,
  WASTED_REPORT_QUERY,
  WASTED_REPORT_DELETE,
  getAdjustmentRefetchQueries
} from '../../data/QueriesGL';
import {
  ADJUST_REASON_1, ADJUST_REASON_2, ADJUST_REASON_3, ADJUST_REASON_4,
  ADJUST_REASON_5, ADJUST_REASON_6, ADJUST_REASON_7, ADJUST_REASON_8,
  BGD_COLOR,
  CARD_SM_FONT, DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD,
  EDIT_DRAWER_WIDTH,
  ERROR_COLOR,
  PRIMARY_DARK_COLOR
} from '../../Constants';

export default function InventoryActionReport() {
  const intl = useIntl();
  const today = dayjs();
  const y = today.year();
  const m = today.month();
  const d = today.daysInMonth();
  const start = new Date(y, m, 1);
  const end = new Date(y, m, d);
  const [startDate, setStartDate] = useState(dayjs(start));
  const [endDate, setEndDate] = useState(dayjs(end));
  const [loading, setLoading] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [typeId, setTypeId] = useState('0');  // Null does not work as a menu option so use 0 and convert to null when submitting query variables.
  const [wastedData, {data: wastedList}] = useLazyQueryFHG(WASTED_REPORT_QUERY, undefined, 'waste.report.type', true);
  const [wastedDelete, {data: deleteData, error}] = useMutationFHG(WASTED_REPORT_DELETE);
  const [piInfoData] = useQueryFHG(PI_REPORT_QUERY, undefined, 'piInfo.type');
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);

  const styles = useMemo(() => {
    return {
      deleteColorStyle: {
        backgroundColor: lighten(ERROR_COLOR, 0.15),
        '&:hover': {
          backgroundColor: lighten(ERROR_COLOR, 0.25),
        }
      }
    };
  }, []);

  useEffect(() => {
    if (wastedList && wastedList?.waste) {
      setLoading(false);
    }
  }, [wastedList])

  const getColor = useCallback((typeId) => {
    let result = 'FFFFFF';
    if (inventoryTypes && inventoryTypes.length > 0) {
      let type = inventoryTypes.find(i => i.id === typeId);
      if (type) {
        result = type.colorCode;
      }
    }
    return result;
  }, [inventoryTypes]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (deleteData && openAlert) {
      if (deleteData.adjustedInventory_Delete === 1) {
        result = <Dialog open={true} onClose={handleAlertClose}><Alert severity="success" onClose={handleAlertClose}>
          Successfully deleted wasted/expired record.</Alert></Dialog>;
      } else {
        result = <Alert severity="error" onClose={handleAlertClose}>{`An error occurred: ${error}`}</Alert>;
      }
    }
    return result;
  }, [deleteData, error, openAlert, handleAlertClose]);

  const handleDelete = useCallback((id) => {
    let tId = typeId === '0' ? null : toNumber(typeId);

    // Delete the wasted record.
    if (id) {
      wastedDelete({
        variables: {adjustedInventoryId: id},
        refetchQueries: getAdjustmentRefetchQueries(startDate, endDate, tId)
      });
      setOpenAlert(true);
    }
  }, [wastedDelete, startDate, endDate, typeId]);

  const handleEdit = useCallback((row) => {
    setSelectedRow(row);
    setOpenDetailDrawer(true);
  }, []);

  const getReasonKey = useCallback((reason) => {
    switch (reason) {
      case ADJUST_REASON_1:
        return "inventory.adjustment.reason.option1";
      case ADJUST_REASON_2:
        return "inventory.adjustment.reason.option2";
      case ADJUST_REASON_3:
        return "inventory.adjustment.reason.option3";
      case ADJUST_REASON_4:
        return "inventory.adjustment.reason.option4";
      case ADJUST_REASON_5:
        return "inventory.adjustment.reason.option5";
      case ADJUST_REASON_6:
        return "inventory.adjustment.reason.option6";
      case ADJUST_REASON_7:
        return "inventory.adjustment.reason.option7";
      case ADJUST_REASON_8:
        return "inventory.adjustment.reason.option8";
      default:
        return null;
    }
  }, []);

  const getReasonText = useCallback((row) => {
    let result, reasonKey;
    if (row.values && row.values.reason) {
      reasonKey = getReasonKey(row.values.reason);
    }

    if (row.original && row.original.notes) {
      let notes = row.original.notes;
      result = reasonKey ? <><TypographyFHG id={reasonKey} /> <TypographyFHG>{notes}</TypographyFHG></> : <TypographyFHG>{notes}</TypographyFHG>;
    }

    return result;
  }, [getReasonKey]);

  const columns = useMemo(() => {
    return [
      {
        id: 'mfgBrand',
        Header: <TypographyFHG id={'wasted.mfgBrand.column'}/>,
        accessor: 'mfgBrand',
        weighting: 1,
        width: 100
      },
      {
        id: 'lot',
        Header: <TypographyFHG id={'wasted.lot.column'}/>,
        accessor: 'lot',
        weighting: 1,
        width: 90,
        Cell: ({value}) => (<>{value ? value : '0'}</>)
      },
      {
        id: 'clinicBarcode',
        Header: <TypographyFHG id={'wasted.clinicBarcode.column'}/>,
        accessor: 'clinicBarcode',
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original)}><span style={{display: "block", textAlign: "center"}}>{row.values?.clinicBarcode}</span></Link>),
        width: 60
      },
      {
        id: 'doses',
        Header: <TypographyFHG id={'wasted.doses.column'}/>,
        accessor: 'doses',
        weighting: 1,
        width: 60,
        Cell: ({value}) => (<span style={{display: "block", paddingRight: '12px', textAlign: "right"}}>{value ? value : '0'}</span>)
      },
      {
        id: 'typeId',
        Header: <TypographyFHG id={'wasted.type.column'}/>,
        accessor: 'typeId',
        weighting: 1,
        width: 100,
        Cell: ({row}) => {
          let color = getColor(row.original?.typeId);
          return (<Chip size='small' label={row.original.typeName}
                        style={{
                          fontSize: CARD_SM_FONT,
                          margin: 'auto', backgroundColor: `${getChipBgColor(color)}`, color: `${hexToRgb(color)}`,
                          width: '130px'
                        }} />);
        }
      },
      {
        id: 'expDate',
        Header: <TypographyFHG id={'wasted.expDate.column'}/>,
        accessor: 'expDate',
        weighting: 1,
        width: 80,
        Cell: ({value}) => (<span style={{display: "block", textAlign: "center"}}>{dayjs(value).format(DATE_FORMAT_KEYBOARD)}</span>)
      },
      {
        id: 'adjustDate',
        Header: <TypographyFHG id={'wasted.adjustDate.column'}/>,
        accessor: 'adjustDate',
        weighting: 1,
        width: 80,
        Cell: ({row}) => (<span style={{whiteSpace: 'normal'}}><span style={{display: "block", textAlign: "center"}}>{dayjs(row.values?.adjustDate).format(DATE_FORMAT_KEYBOARD)}</span></span>)
      },
      {
        id: 'adjustType',
        Header: <TypographyFHG id={'wasted.adjustType.column'}/>,
        accessor: 'adjustType',
        weighting: 1,
        width: 100,
      },
      {
        id: 'reason',
        Header: <TypographyFHG id={'wasted.reason.column'}/>,
        accessor: 'reason',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (<span style={{display: 'inline', whiteSpace: 'normal'}}>{getReasonText(row)}</span>
        )
      },
      {
        id: 'id',
        accessor: 'id',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          <ConfirmButton
            id={row?.original.id}
            buttonLabelKey="wasted.adjustment.delete.button"
            buttonTypographyProps={{variant:"body1"}}
            sx={{height: '40px', my: 0, mr: 1.5, ml: 0,
              '&:hover': {
                color: PRIMARY_DARK_COLOR,
              },
              fontSize: '1rem'
            }}
            onConfirm={() => handleDelete(row?.original.id)}
            values={{
              type: 'wasted/expired record',
              name: ''
            }}
            size='small'
            submitStyle={styles.deleteColorStyle}
            startIcon={<DeleteIcon />}
          />
        )
      }
    ]
  }, [getColor, getReasonText, handleDelete, handleEdit, styles]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleInventoryTypeChange = (value) => {
    setTypeId(value);
  }

  const handleSearch = useCallback(() => {
    if (!startDate) {
      return;
    }
    if (!endDate) {
      return;
    }
    if (!typeId) {
      return;
    }

    setLoading(true);
    const options = {
      notifyOnNetworkStatusChange: true,
      variables: {
        dateAdjustedStart: startDate,
        dateAdjustedEnd: endDate,
        typeId: typeId === '0' ? null : toNumber(typeId),
        dts: dayjs().format("MMDDYYYYhhmmsszzz")
      }
    };

    wastedData(options);
  }, [startDate, endDate, typeId, wastedData]);

  const getReasonTextPdf = useCallback((row) => {
    let result;
    if (row && row.reason) {
      result = formatMessage(intl, getReasonKey(row.reason));
    }

    if (row && row.notes) {
      let notes = row.notes;
      result = result ? result + ' - ' + notes : notes;
    }

    return result;

  }, [getReasonKey, intl]);

  const downloadData = async () => {
    if (wastedList && wastedList.waste && piInfoData && piInfoData.piInfos) {
      var tagsPI = piInfoData.piInfos;
      var totalRecordsPI = tagsPI.length;

      var ContactPhone = "";
      var InventoryNo = "";
      var ProviderName = "";
      var EmailAddress = "";

      for (var cntPI = 0; cntPI < totalRecordsPI; cntPI++) {
        if (tagsPI[cntPI].contactName !== "" || tagsPI[cntPI].phone !== "") {
          ContactPhone += ", " + tagsPI[cntPI].contactName + "/" + tagsPI[cntPI].phone;
        }
        if (tagsPI[cntPI].inventoryNo !== "") {
          InventoryNo += ", " + tagsPI[cntPI].inventoryNo;
        }
        if (tagsPI[cntPI].providerName !== "") {
          ProviderName += ", " + tagsPI[cntPI].providerName;
        }
        if (tagsPI[cntPI].emailAddress !== "") {
          EmailAddress += ", " + tagsPI[cntPI].emailAddress;
        }
      }

      if (ContactPhone !== "")
        ContactPhone = ContactPhone.substring(1);
      if (InventoryNo !== "")
        InventoryNo = InventoryNo.substring(1);
      if (ProviderName !== "")
        ProviderName = ProviderName.substring(1);
      if (EmailAddress !== "")
        EmailAddress = EmailAddress.substring(1);
    }

    var tags = wastedList.waste;
    var totalRecords = tags.length;
    var tableData = [];
    tableData.push(
      [ {text: 'Brand Name', style: 'tableHeader'},
        {text: 'Lot', style: 'tableHeader'},
        {text: 'Clinic\nBarcode', style: 'tableHeader'},
        {text: 'Doses', style: 'tableHeader'},
        {text: 'Inventory\nType', style: 'tableHeader'},
        {text: 'Exp Date', style: 'tableHeader'},
        {text: 'Action\nDate', style: 'tableHeader'},
        {text: `Action*`, style: 'tableHeader'},
        {text: 'Reason/Notes', style: 'tableHeader'}]
    )
    for (var cnt = 0; cnt < totalRecords; cnt++) {
      tableData.push([
        {text: tags[cnt].mfgBrand, style: 'tableData1'},
        {text: tags[cnt].lot, style: 'tableData1'},
        {text: tags[cnt].clinicBarcode, style: 'tableData'},
        {text: tags[cnt].doses, style: 'tableData'},
        {text: tags[cnt].typeName, style: 'tableData1'},
        {text: dayjs(tags[cnt].expDate).format(DATE_FORMAT_KEYBOARD), style: 'tableData'},
        {text: dayjs(tags[cnt].adjustDate).format(DATE_FORMAT_KEYBOARD), style: 'tableData'},
        {text: tags[cnt].adjustType, style: 'tableData1'},
        {text: getReasonTextPdf(tags[cnt]), style: 'tableData1'}
      ]);
    }

    var loDateRanges = dayjs(startDate).format(DATE_FORMAT_KEYBOARD) + " to " + dayjs(endDate).format(DATE_FORMAT_KEYBOARD);
    var loDateRan = dayjs().format(DATE_FORMAT_KEYBOARD);

    var docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [ 30, 40, 30, 40 ],
      content: [
        {text: 'Inventory Action Report', style: 'header'},
        {text: `Date Range: ${loDateRanges}`, style: 'subheader'},
        {text: `Date Ran: ${loDateRan}`, style: 'subheader'},
        {
          style: 'table',
          table: {
            widths: [100, 70, 50, 40, 50, 50, 50, 60, 200],
            body: tableData
          }
        },
        {text: '* Action = Wasted, Expired, Long, Short', style: 'footnote'}
      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          alignment: 'center'
        },
        headerFilter: {
          fontSize: 12,
          bold: true,
          margin: [0, 2, 0, 0]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 5, 0, 0],
          alignment: 'center'
        },
        footnote: {
          bold: true,
          fontSize: 10,
          margin: [10, 5, 0, 0]
        },
        note: {
          fontSize: 10,
          margin: [0, 10, 0, 10],
          italics: true
        },
        table: {
          margin: 10,
          fontSize: 9,
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black',
          alignment: 'center',
          padding: 4
        },
        tableHeader1: {
          bold: true,
          fontSize: 10,
          color: 'black',
          alignment: 'center',
          margin: [0, 12, 0, 0],
          padding: 4
        },
        tableData: {
          margin: [0, 5, 0, 0],
          alignment: 'center'
        },
        tableData1: {
          margin: [0, 5, 0, 0]
        }
      }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).download('InventoryActionReport.pdf');
  }

  return (
    <>
    <Loading isLoading={loading}/>
    <Grid container direction="column">
      <Grid container direction="row">
        <TypographyFHG id="action.title" color="textSecondary" sx={{ml: 2}} variant="h5"/>
        <Grid><TypographyFHG id="wasted.footer" color="textPrimary" sx={{ml: 3}} variant="caption"/></Grid>
      </Grid>
      {getAlert()}
      <Grid sx={{mt: 2}} >
        <TableFHG name={"actionReportTable"} columns={columns} data={wastedList ? wastedList.waste : []}
                  allowSearch emptyTableMessageKey={'wasted.noRecord.message'} fullWidth stickyHeader
        >
          <Grid container direction="row" justifyContent={'space-between'}>
            <Grid container direction="row">
              <Grid sx={{py: 0, pr: 0, pl: 1, width: '160px'}}>
                <KeyboardDatePickerFHG
                  key={'currentMonth1'}
                  name={'startDate'}
                  label={'Start Date'}
                  onChange={handleStartDateChange}
                  value={startDate}
                />
              </Grid>
              <Grid sx={{py: 0, pr: 0, pl: 1, width: '160px'}}>
                <KeyboardDatePickerFHG
                  key={'endDate1'}
                  name={'endDate'}
                  label={'End Date'}
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </Grid>
              <Grid sx={{mt: -2.5, mr: 1, mb: 0, ml: 2, width: '174px'}}>
                <InputLabel id="typeDropDown" sx={{float: 'left', mt: 0, mx: 1}}>Inventory Type</InputLabel>
                <Select
                  value={typeId}
                  onChange={e => {
                    handleInventoryTypeChange(e.target.value || undefined)
                  }}
                  labelId={'typeDropDown'}
                  size="small"
                >
                  <MenuItem key={0} value={'0'}>
                    <Chip size='small' label={'ALL'}
                          style={{
                            backgroundColor: `blue`,
                            color: '#FFF',
                            fontSize: CARD_SM_FONT,
                            margin: 'auto',
                            width: '130px'
                          }} />
                  </MenuItem>
                  {inventoryTypes.map((option, i) => (
                    <MenuItem key={i} value={`${option.inventoryTypeID}`}>
                      <Chip size='small' label={option.name} style={{
                        backgroundColor: `${getChipBgColor(option.colorCode)}`,
                        color: hexToRgb(option.colorCode),
                        fontSize: CARD_SM_FONT,
                        margin: 'auto',
                        width: '130px'
                      }}/>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid sx={{mt: 0.25, mr: 0, mb: 0, ml: 1}}>
                <ButtonFHG id="search.button" labelKey={'search.button'} color="primary" size="large"
                           sx={{height: '40px', my: 0, mr: 1.5, ml: 0,
                             '&:hover': {
                               color: PRIMARY_DARK_COLOR,
                             },
                             fontSize: '1rem'
                           }}
                           startIcon={(<SearchIcon />)} onClick={handleSearch} variant="outlined" />
                <ButtonFHG variant="outlined" color="primary" size="large" labelKey="pdf.button"
                           sx={{height: '40px', my: 0, mr: 1.5, ml: 0,
                             '&:hover': {
                               color: PRIMARY_DARK_COLOR,
                             },
                             fontSize: '1rem'
                           }}
                           startIcon={(<DownloadIcon />)} onClick={downloadData} />
              </Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          {<AdjustedEdit adjustRow={selectedRow} onClose={handleDetailClose}
                         dateAdjustedStart={dayjs(startDate).format(DATE_DB_FORMAT)}
                         dateAdjustedEnd={dayjs(endDate).format(DATE_DB_FORMAT)}
                         typeId={typeId}
          />}
        </Grid>
      </EditDrawer>
    </Grid>
    </>
  );
}
