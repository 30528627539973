import React, {useEffect, useMemo, useState} from 'react';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import * as dayjs from 'dayjs';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import Loading from '../../fhg/components/Loading';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import { TRANSFERS_REPORT_QUERY } from '../../data/QueriesGL';
import {DATE_DB_FORMAT, DATE_FORMAT_KEYBOARD, PRIMARY_DARK_COLOR} from '../../Constants';

export default function TransferredReport() {
  const today = dayjs();
  const y = today.year();
  const m = today.month();
  const d = today.daysInMonth();
  const start = new Date(y, m, 1);
  const end = new Date(y, m, d);
  const [startDate, setStartDate] = useState(dayjs(start));
  const [endDate, setEndDate] = useState(dayjs(end));
  const [options, setOptions] = useState({
    variables: {
      dateEnteredStart: startDate.format(DATE_DB_FORMAT),
      dateEnteredEnd: endDate.format(DATE_DB_FORMAT)
    }
  }, [startDate, endDate]);
  const [loading, setLoading] = useState(true);

  const [transfersData] = useQueryFHG(TRANSFERS_REPORT_QUERY, options, 'transReport.type');

  useEffect(() => {
    if (transfersData?.transfers) {
      setLoading(false);
    }
  }, [transfersData]);

  const columns = useMemo(() => {
    return [
      {
        id: 'clinicName',
        Header: <TypographyFHG id={'tranReport.clinicName.column'}/>,
        accessor: 'clinicName',
        weighting: 1,
        width: 100
      },
      {
        id: 'typeName',
        Header: <TypographyFHG id={'tranReport.type.column'}/>,
        accessor: 'typeName',
        weighting: 1,
        width: 100
      },
      {
        id: 'transferDate',
        Header: <TypographyFHG id={'tranReport.transferdate.column'}/>,
        accessor: 'transferDate',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          <>{dayjs(row.values?.transferDate).format(DATE_FORMAT_KEYBOARD)}</>
        )
      },
      {
        id: 'mfgBrand',
        Header: <TypographyFHG id={'tranReport.product.column'}/>,
        accessor: 'mfgBrand',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          <>{row.original?.mfgBrand} ({row.original?.mfgGeneric})</>
        )
      },
      {
        id: 'lot',
        Header: <TypographyFHG id={'tranReport.lotNumber.column'}/>,
        accessor: 'lot',
        weighting: 1,
        width: 100,
        Cell: ({value}) => (
          <>{value ? value : '0'}</>
        )
      },
      {
        id: 'expDate',
        Header: <TypographyFHG id={'tranReport.expdate.column'}/>,
        accessor: 'expDate',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          <>{dayjs(row.values?.expDate).format(DATE_FORMAT_KEYBOARD)}</>
        )
      },
      {
        id: 'clinicBarcode',
        Header: <TypographyFHG id={'tranReport.bcode.column'}/>,
        accessor: 'clinicBarcode',
        weighting: 1,
        width: 100,
        Cell: ({value}) => (
          <>{value ? value : '0'}</>
        )
      },
      {
        id: 'doses',
        Header: <TypographyFHG id={'tranReport.doses.column'}/>,
        accessor: 'doses',
        weighting: 1,
        width: 100,
        Cell: ({value}) => (
          <>{value ? value : '0'}</>
        )
      }
    ]
  }, []);

  const handleStartDateChange = (date) => {
    if (date) {
      setLoading(true);
      setStartDate(date);

      const options = {
        variables: {
          dateEnteredStart: date.format(DATE_DB_FORMAT),
          dateEnteredEnd: endDate.format(DATE_DB_FORMAT)
        }
      }

      setOptions(options);
    }
  }

  const handleEndDateChange = (date) => {
    if (date) {
      setLoading(true);
      setEndDate(date);

      const options = {
        variables: {
          dateEnteredStart: startDate.format(DATE_DB_FORMAT),
          dateEnteredEnd: date.format(DATE_DB_FORMAT)
        }
      }

      setOptions(options);
    }
  }

  const downloadData = async () => {
    if (transfersData && transfersData.transfers) {

      // var tags = JSON.parse(transfersData.transfers);
      const tags = transfersData.transfers;
      const totalRecords = tags.length;
      let tableData = [];

      tableData.push(
        [{text: 'Transferred\nTo', style: 'tableHeader'}, {
          text: 'Transferred\nFrom',
          style: 'tableHeader'
        }, {text: 'Date', style: 'tableHeader'},
          {text: 'Product\nName', style: 'tableHeader'}, {text: 'Lot No', style: 'tableHeader'},
          {text: 'Exp Date', style: 'tableHeader'}, {text: 'Clinic Barcode', style: 'tableHeader'}, {
          text: 'Doses',
          style: 'tableHeader'
        }]
      )

      for (var cnt = 0; cnt < totalRecords; cnt++) {
        tableData.push([
          {text: tags[cnt].clinicName, style: 'tableData'},
          {text: tags[cnt].typeName, style: 'tableData'},
          {text: dayjs(tags[cnt].transferDate).format(DATE_FORMAT_KEYBOARD), style: 'tableData'},
          {text: `${tags[cnt].mfgBrand}(${tags[cnt].mfgGeneric})`, style: 'tableData'},
          {text: tags[cnt].lot, style: 'tableData'},
          {text: dayjs(tags[cnt].expDate).format(DATE_FORMAT_KEYBOARD), style: 'tableData'},
          {text: tags[cnt].clinicBarcode, style: 'tableData'},
          {text: tags[cnt].doses, style: 'tableData'}]);
      }

      var loDateRanges = dayjs(startDate).format("L") + " TO " + dayjs(endDate).format("L");
      var loDateRan = dayjs().format("L");
      var docDefinition =
        {
          content: [
            {text: 'TRANSFER REPORT', style: 'header'},
            {text: 'Date Ranges: ' + loDateRanges, style: 'headerFilter'},
            // { text: 'Inventories: ' + loInventories, style: 'headerFilter' },
            {text: 'Date Ran: ' + loDateRan, style: 'headerFilter'},
            {
              style: 'table',
              table: {
                widths: [60, 80, 60, 50, 50, 50, 50, 50],
                body: tableData
              }
            }],
          styles: {
            header: {
              fontSize: 22,
              bold: true,
              margin: [0, 5, 0, 0],
              alignment: 'center'
            },
            headerFilter: {
              fontSize: 13,
              bold: true,
              margin: [0, 2, 0, 0],
              alignment: 'center'
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0],
              alignment: 'center'
            },
            table: {
              margin: [0, 30, 0, 0],
              fontSize: 9,
            },
            tableHeader: {
              bold: true,
              fontSize: 10,
              color: 'black',
              alignment: 'center',
              margin: [0, 0, 0, 0]
            },
            tableData: {
              bold: false,
              width: 100,
              margin: [0, 5, 0, 0],
              alignment: 'center'
            }
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(docDefinition).download('TransferReport.pdf');
    }
  }

  return (
    <>
    <Loading isLoading={loading}/>
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <TypographyFHG id="tranReport.title" color="textSecondary" sx={{mt: 1, mr: 0, mb: 0, ml: 1}} variant="h5" gutterBottom/>
      <Grid item>
        <TableFHG name={"transferredTable"} columns={columns} data={transfersData ? transfersData.transfers : []}
                  allowSearch emptyTableMessageKey={'tranReport.noRecord.message'} fullWidth stickyHeader
        >
          <Grid container direction="row" justifyContent="space-between">
            <Grid container direction="row" fullWidth={false}>
              <Grid item sx={{mr: 1, width: '192px'}}>
                <KeyboardDatePickerFHG
                  key={'currentMonth1'}
                  name={'startDate'}
                  label={'Start Date'}
                  onChange={handleStartDateChange}
                  value={startDate}
                />
              </Grid>
              <Grid item sx={{mr: 1, width: '192px'}}>
                <KeyboardDatePickerFHG
                  key={'endDate1'}
                  name={'endDate'}
                  label={'End Date'}
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </Grid>
              <Grid sx={{mt: -0.25}}>
                <ButtonFHG variant="outlined" color="primary"
                           sx={{m: 1, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}
                           labelKey="pdf.button" onClick={downloadData}/>
              </Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>
    </Grid>
    </>
  );
}

