import React, {useCallback, useMemo, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import EditDrawer from '../EditDrawer';
import {Grid} from '@mui/material';
import filter from 'lodash/filter';
import {formatMessage, getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import InventoryTypeAdd from './InventoryTypeAdd';
import InventoryTypeEdit from './InventoryTypeEdit';
import {Link, Switch} from "@mui/material";
import orderBy from 'lodash/orderBy';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {INVENTORY_TYPES_ALL_QUERY} from '../../data/QueriesGL';
import {
  ACTIVE_STRING,
  BGD_COLOR, DATE_FORMAT_KEYBOARD,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR
} from '../../Constants';
import * as dayjs from "dayjs";

export default function InventoryTypes() {
  const intl = useIntl();
  const [displayInactive, setDisplayInactive] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_ALL_QUERY, {}, 'inventoryType.type');

  const inventoryTypes = useMemo(() => {
    let data = inventoryTypesData?.inventoryTypes || [];
    let rows = displayInactive ? data : filter(data, {active: ACTIVE_STRING});
    let filteredRows = [];
    if (rows && rows.length > 0) {
      filteredRows = rows.map(obj => ({...obj, activeDisplay: obj.active === ACTIVE_STRING ? 'Active' : 'Inactive'}));
    }
    return orderBy(filteredRows, [row => row.inventoryTypeID], ['asc']);
  }, [displayInactive, inventoryTypesData]);

  const handleAdd = useCallback(() => {
    setSelectedId('new');
    setOpenDetailDrawer(true);
  }, []);

  const handleEdit = useCallback((id) => {
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        id: 'id',
        Header: <TypographyFHG id={'inventoryType.id.column'} sx={{pl: 2}} />,
        accessor: 'id',
        width: 40
      },
      {
        id: 'name',
        Header: <TypographyFHG id={'inventoryType.name.column'}/>,
        accessor: 'name',
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.inventoryTypeID)}>{row.values?.name}</Link>)
      },
      {
        id: 'fundingSourceValue',
        Header: <TypographyFHG id={'inventoryType.fundingSource.column'}/>,
        accessor: 'fundingSourceValue',
        width: 80
      },
      {
        id: 'fundingSourceDescription',
        Header: <TypographyFHG id={'inventoryType.fundingSourceDescription.column'}/>,
        accessor: 'fundingSourceDescription'
      },
      {
        id: 'colorCode',
        Header: <TypographyFHG id={'inventoryType.colorCode.column'}/>,
        accessor: 'colorCode',
        Cell: ({row}) => (<span style={{'backgroundColor': `${getChipBgColor(row.values?.colorCode)}`,
          color: `${hexToRgb(row.values?.colorCode)}`, padding: "4px"}}>{`${getChipBgColor(row.values?.colorCode)}`}</span>)
      },
      {
        id: 'dateEntered',
        Header: <TypographyFHG id={'inventoryType.dateEntered.column'}/>,
        accessor: 'dateEntered',
        Cell: ({value}) => (<span style={{display: "block"}}>{dayjs(value).format(DATE_FORMAT_KEYBOARD)}</span>)
      },
      {
        id: 'active',
        Header: <TypographyFHG id={'inventoryType.active.column'}/>,
        accessor: 'activeDisplay',
        width: 70,
      }
    ];
  }, [handleEdit]);

  const handleActiveToggle = () => {
    setDisplayInactive(value => !value);
  }

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return !displayInactive ? undefined : {fontWeight: "bold"};
    } else {
      return !displayInactive ? {fontWeight: "bold"} : undefined;
    }
  }, [displayInactive]);

  return (
    <Grid container direction="column" overflow={'visible'} wrap="nowrap">
      <TypographyFHG id="inventoryType.title" color="textSecondary" sx={{my: 0, mr: 0, ml: 2}} variant="h5" />
      <Grid>
        <TableFHG name="Inventory Types" columns={columns} data={inventoryTypes}
                  allowSearch displaySearch={false} stickyHeader
                  emptyTableMessageKey={'inventoryType.noRecord.message'}
        >
          <Grid container direction="row" justifyContent="flex-start">
              <ButtonFHG id="inventoryType.add.button" labelKey={'inventoryType.add.button'} startIcon={(<AddIcon />)} onClick={handleAdd}
                         sx={{m: 0, fontSize: '0.875rem', '&:hover': {color: PRIMARY_DARK_COLOR}}}
                         variant="outlined" />
            <Grid container direction="row" sx={{my: 0, mr: 0, ml: 2, pt: 0.75, width: '320px'}}>
              <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
              <Switch color="primary" checked={!displayInactive} onChange={handleActiveToggle} sx={{my: -1, mx: 0, width: '62px'}} />
              <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'slider.active.label', 'Display Active Only', null)}</Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid item container direction={'column'} overflow={'visible'} style={{width: EDIT_DRAWER_WIDTH}}>
          {selectedId === 'new' ? <InventoryTypeAdd types={inventoryTypesData?.inventoryTypes} onClose={handleDetailClose} /> : <InventoryTypeEdit types={inventoryTypes} id={selectedId} onClose={handleDetailClose} />}
        </Grid>
      </EditDrawer>
    </Grid>
  );
}
