import React, {Fragment, useState} from 'react';
import ButtonCustom from '../../components/ButtonCustom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ModalDialog from './dialog/ModalDialog';
import ProgressButton from './ProgressButton';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Typography from './Typography';
import {BGD_DEFAULT, SECONDARY_TEXT, UNDO_DURATION} from '../../Constants';

/**
 * Button Component to show confirmation.
 *
 * Reviewed:
 */
ConfirmButton.propTypes = {
   titleKey: PropTypes.string,                  // Localization key for the title.
   message: PropTypes.string,                   // Use either message or messageKey.
   messageKey: PropTypes.string,                // Localization key for the messages.
   buttonLabelKey: PropTypes.string,            // Localization key for the button label.
   confirmButtonLabelKey: PropTypes.string,     // Localization key for the confirm button label.
   onConfirm: PropTypes.func.isRequired,        // The callback when the action is confirmed.
   onCancel: PropTypes.func,                    // The callback when the action is canceled.
   component: PropTypes.any,                    // The Button component. Defaults to Button.
   isProgress: PropTypes.bool,                  // Indicates if the confirmed action is in progress.
   buttonProperties: PropTypes.any              // The properties for the button component.
};

export default function ConfirmButton({
   titleKey = 'confirmRemove.title',
   message,
   messageKey = 'confirmRemoveValue.message',
   buttonLabelKey = 'delete.button',
   confirmButtonLabelKey = 'delete.button',
   onConfirm,
   onCancel,
   confirmProps,
   children,
   color,
   component,
   values,
   titleValues,
   isProgress = false,
   submitStyle,
   onUndo = false,
   buttonTypographyProps,
   ...buttonProperties
}) {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [showUndelete, setShowUndelete] = useState(false);

   const useTitleValues = titleValues || values;

   const handleClick = event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      if (buttonProperties.type !== 'submit') {
         setIsModalOpen(true);
      }

      buttonProperties?.onClick?.(event);
   };

   const handleConfirm = (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setIsModalOpen(false);

      if (onUndo) {
         setShowUndelete(true);
      }
      onConfirm && onConfirm();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      onCancel && onCancel();
   };

   const handleUndoClose = () => {
      setShowUndelete(false);
   };

   const handleUndo = () => {
      onUndo?.();
      handleUndoClose();
   };

   return (
      <Fragment>
         {isModalOpen && (
            <ModalDialog submitKey={confirmButtonLabelKey} {...confirmProps} titleKey={titleKey}
                         message={message} messageKey={messageKey}
                         onSubmit={handleConfirm}
                         onClose={handleCancel} hideBackdrop submitColor={color} cancelColor={'inherit'} messageValues={values}
                         titleValues={useTitleValues}
                         submitColorStyle={submitStyle}
            />
         )}
         {showUndelete && (
            <Snackbar
               open={true}
               autoHideDuration={UNDO_DURATION}
               onClose={handleUndoClose}
               ContentProps={{classes: {root: {
                        backgroundColor: `${BGD_DEFAULT} !important`,
                        color: `${SECONDARY_TEXT} !important`,
                        pr: 6,
                     }}}}
               message={(
                  <Typography id={'confirmRemoveValue.Undo.message'} variant={'subtitle1'} sx={{mr: 1}}
                              values={values} color={'inherit'}
                  >
                     <ButtonCustom labelKey={'undo.label'} onClick={handleUndo}/>
                  </Typography>
               )}
               action={[
                  <IconButton
                     key='close'
                     aria-label='Close'
                     color='inherit'
                     size={'small'}
                     sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        ml: 'auto',
                        zIndex: 1001,
                     }}
                     onClick={handleUndoClose}
                  >
                     <CloseIcon fontSize='inherit'/>
                  </IconButton>,
               ]}
            />
         )}
         <ProgressButton
           isProgress={isProgress}
           onClick={handleClick}
           color={color}
           {...buttonProperties}
         >
            {!component && buttonLabelKey && (
              <Typography id={buttonLabelKey} {...buttonTypographyProps} sx={{fontSize: '0.75rem !important'}} />
            )}
            {children}
         </ProgressButton>
      </Fragment>
   );
}

