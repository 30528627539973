import React, {useCallback, useMemo} from 'react';
import {Chip, Grid} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import find from 'lodash/find';
import {getColorFromTypeId, hexToRgb} from '../../fhg/utils/Utils';
import PropTypes from 'prop-types';
import TypographyFHG from "../../fhg/components/Typography";
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {DASHBOARD_QUERY, INVENTORY_TYPES_QUERY} from '../../data/QueriesGL';
import {BGD_COLOR, BGD_TABLE_HEAD, TABLE_HEIGHT, VACCINE_COL_WIDTH} from '../../Constants';

export default function DashboardTable({vaccines}) {
  const options = {
    variables: {},
    fetchPolicy: "cache-and-network"
  };
  const [dashboardData] = useQueryFHG(DASHBOARD_QUERY, options, 'dashboard.type');
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, {variables: {}}, 'inventoryType.type');
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);

  const getVacColHeader = useCallback(() => {
    return (<TypographyFHG id={'dashboard.vaccine.column'} sx={{fontSize: '1.125rem', fontWeight: 'bold'}} />);
  }, []);

  const getHeader = useCallback((inventoryTypeID, name) => {
    let color = getColorFromTypeId(inventoryTypeID, inventoryTypes);
    return (<Chip label={name} style={{backgroundColor: `${color}`, color: `${hexToRgb(color)}`}} sx={{fontSize: '0.675rem', m: 0, width: '100px'}} />);
  }, [inventoryTypes]);

  const isInventoryLow = useCallback((doses, reorder) => {
    let result = false;
    if (reorder > 0) {
      result = (doses < reorder);
    }
    return result;
  },[]);

  const columns = useMemo(() => {
    let cols = [
      {
        id: 'displayText',
        field: 'displayText',
        flex: 1,
        headerName: 'Vaccine',
        renderHeader: getVacColHeader,
        headerAlign: 'left',
        minWidth: VACCINE_COL_WIDTH
      },
    ];

    inventoryTypes.forEach(i => {
      let index = i.id;
      let found = find(inventoryTypes, t => t.inventoryTypeID === index);
      if (found && found.name) {
        cols.push({
          id: index,
          field: `type${index}`,
          align: 'center',
          editable: false,
          headerAlign: 'center',
          headerName: found.name,
          renderHeader: () => getHeader(found.inventoryTypeID, found.name),
          renderCell: (row) => {
            const val = parseInt(row?.value, 10);
            const isLow = isInventoryLow(row?.formattedValue, row?.row?.reorderThreshold);
            if (isLow) {
              return (<span style={{cursor: 'pointer', color: "#FE0000", fontWeight: "bold"}}>{val}</span>)
            } else {
              return(val)
            }
          },
          sortable: false,
          width: 140
        });
      }
    });

    return cols;
  }, [getVacColHeader, getHeader, inventoryTypes, isInventoryLow]);

  const rows = useMemo(() => {
    let results = [];
    if (dashboardData && dashboardData.records && dashboardData.records.length > 0) {
      results = dashboardData.records.map(d => {
        return {...d, displayText: `${d.mfgBrand} (${d.mfgGeneric})`}
      });
    }
    return results;
  }, [dashboardData]);

  return (
    <Grid sx={{height: TABLE_HEIGHT}}>
      <DataGrid columns={columns} rows={rows}
                columnHeaderHeight={48}
                disableColumnMenu
                disableColumnSelector
                pageSize={100}
                rowHeight={36}
                density={'compact'}
                sx={{
                  '.MuiDataGrid-columnHeader': {
                    backgroundColor: `${BGD_TABLE_HEAD} !important`,
                    color: `${BGD_COLOR} !important`,
                    fontWeight: 'bold !important',
                  },
                }}
      />
    </Grid>
  );
}
DashboardTable.propTypes = {
  vaccines: PropTypes.array,
}