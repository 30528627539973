import React from 'react';
import {useSetRecoilState} from 'recoil';
import {MenuItem, Typography} from '@mui/material';
import {
  userStatus,
  authenticationDataStatus,
  authenticationStateStatus,
} from '../../fhg/hooks/auth/useAuth';
import useToken from '../../fhg/hooks/useToken';

export default function Logout(props) {
  const {logout} = props;
  const setAuthenticationDataStatus = useSetRecoilState(authenticationDataStatus);
  const setAuthenticationStateStatus = useSetRecoilState(authenticationStateStatus);
  const setUserStatus = useSetRecoilState(userStatus);
  const {setToken} = useToken();

  const handleLogout = () => {
    setToken({});
    setAuthenticationDataStatus({});
    setAuthenticationStateStatus(undefined);
    setUserStatus({});

    localStorage.clear();
    logout();
  }

  return (
    <MenuItem value='logout' onClick={handleLogout}>
      <Typography id='path.logout'>Logout</Typography>
    </MenuItem>
  );
};
