import React, {useCallback, useMemo, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import assign from 'lodash/assign';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import * as dayjs from 'dayjs';
import EditDrawer from '../EditDrawer';
import filter from 'lodash/filter';
import {Link, Switch} from '@mui/material';
import {formatMessage} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import ManufacturerAdd from './ManufacturerAdd';
import ManufacturerEdit from './ManufacturerEdit';
import orderBy from 'lodash/orderBy';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {MANUFACTURERS_QUERY} from '../../data/QueriesGL';
import {
  ACTIVE,
  BGD_COLOR,
  DATE_DB_FORMAT,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR
} from '../../Constants';

export default function Manufacturers() {
  const intl = useIntl();
  const options = {variables: {}};
  const [manufacturersData] = useQueryFHG(MANUFACTURERS_QUERY, options, 'manufacturer.type');
  const [selectedId, setSelectedId] = useState(undefined);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [displayInactive, setDisplayInactive] = useState(false);

  const handleEdit = useCallback((id) => {
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, [setSelectedId, setOpenDetailDrawer]);

  const columns = useMemo(() => {
    return [
      {
        id: 'code',
        Header: <TypographyFHG id={'manufacturers.code.column'}/>,
        accessor: 'code',
        width: 50,
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.id)}>{row.values?.code}</Link>)
      },
      {
        id: 'name',
        Header: <TypographyFHG id={'manufacturers.name.column'}/>,
        accessor: 'name',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{row.values?.name}</span>
        )
      },
      {
        id: 'notes',
        Header: <TypographyFHG id={'manufacturers.notes.column'}/>,
        accessor: 'notes',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{row.values?.notes}</span>
        )
      },
      {
        id: 'status',
        Header: <TypographyFHG id={'manufacturers.status.column'}/>,
        accessor: 'StatusValue',
        width: 50,
      }
    ];
  }, [handleEdit]);

  const manufacturers = useMemo(() => {
    let data = manufacturersData?.manufacturers || [];
    let filteredRows = displayInactive ? data : filter(data, {status: ACTIVE});

    if (filteredRows) {
      filteredRows = filteredRows.map(x => {
        return assign({}, x, {dateEntered: dayjs(x.dateEntered).format(DATE_DB_FORMAT), StatusValue: x.status === ACTIVE ? 'Active' : 'Inactive'})
      });
    }

    return orderBy(filteredRows, [row => row.name.toLowerCase()], ['asc']);
  }, [manufacturersData, displayInactive]);

  const handleAdd = useCallback(() => {
    setSelectedId('new');
    setOpenDetailDrawer(true);
  }, [setSelectedId, setOpenDetailDrawer]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleActiveToggle = useCallback(() => {
    setDisplayInactive(value => !value);
  }, [setDisplayInactive]);

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return !displayInactive ? undefined : {fontWeight: "bold"};
    } else {
      return !displayInactive ? {fontWeight: "bold"} : undefined;
    }
  }, [displayInactive]);

  return (
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <TypographyFHG id="manufacturers.title" color="textSecondary" sx={{my: 0, mr: 0, ml: 2}} variant="h5" />
      <Grid item>
        <TableFHG name={"Manufacturers"} columns={columns} data={manufacturers} allowSearch
                  emptyTableMessageKey={'manufacturers.noRecord.message'} stickyHeader
        >
          <Grid container direction="row" justifyContent="flex-start">
            <ButtonFHG id="manufacturers.add.button" labelKey={'manufacturers.add.button'} startIcon={(<AddIcon />)} onClick={handleAdd}
                       sx={{m: 0, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}} variant="outlined" />
            <Grid container direction="row" sx={{my: 0, mr: 0, ml: 2, pt: 0.75, width: '320px'}}>
              <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
              <Switch color="primary" checked={!displayInactive} onChange={handleActiveToggle} sx={{mt: -0.75, mx: 0, width: '62px'}} />
              <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'slider.active.label', 'Display Active Only', null)}</Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          {selectedId === 'new' ? <ManufacturerAdd data={manufacturersData?.manufacturers} onClose={handleDetailClose} /> : <ManufacturerEdit id={selectedId} data={manufacturersData?.manufacturers} onClose={handleDetailClose} />}
        </Grid>
      </EditDrawer>
    </Grid>
  );
}
