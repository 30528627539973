import React, {useState, Fragment, useEffect} from 'react';
import {editChange} from '../../utils/Utils';
import {Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextFieldFHG from '../../../components/TextField';
import TypographyFHG from '../Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/**
 * The TextField with preset formats.
 */
export default function PasswordTextField({password, confirm, margin, onChange, isNew, disabled,}) {
   const [showPassword, setShowPassword] = useState(false);
   const [editValues, setEditValues] = useState({});

   useEffect(() => {
      const target = document.getElementById('confirm_password');
      if (target) {
         target.setCustomValidity(
            editValues.confirm !== editValues.password ? 'Confirm does not match the password.' : '');
      }
   }, [editValues.confirm, editValues.password, password, confirm]);

   const handleShowPasswordClick = () => {
      setShowPassword(!showPassword);
   };

   const handleChange = (event) => {
      setEditValues({...editValues, ...editChange(event)});
      onChange && onChange(event);
   };

   // pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',

   return (
      <Fragment>
         <Grid item>
            <TextFieldFHG
               name='password'
               inputProps={{
                  style: {height: '32px', paddingLeft: '16px'},
                  pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$',
                  title: 'Password must contain at least 8 characters with one or more uppercase, lowercase, and number.'
               }}
               labelKey={isNew ? 'user.password.label' : 'user.changePassword.label'}
               fullWidth
               required={isNew}
               disabled={disabled}
               type={showPassword ? 'text' : 'password'}
               autoComplete='current-password'
               onChange={handleChange}
               value={password}
               margin={margin}
               // eslint-disable-next-line
               InputProps={{
                  'aria-label': 'Password',
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='Toggle password visibility'
                           onMouseDown={handleShowPasswordClick}
                           disabled={disabled}
                        >
                           {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                     </InputAdornment>
                  )
               }}
            />
         </Grid>
         <Grid item>
            {!showPassword && (
               <Grid item>
                  <TextFieldFHG
                     name='confirm'
                     label={<TypographyFHG variant='inherit' id={'user.confirm.label'}/>}
                     inputProps={{id: 'confirm_password', style: {height: '32px'}}}
                     type={'password'}
                     required={isNew}
                     onChange={handleChange}
                     value={confirm}
                     autoComplete='current-password'
                     fullWidth
                     disabled={disabled}
                  />
               </Grid>
            )}
         </Grid>
      </Fragment>
   );
}
