import React from 'react';
import PropTypes from 'prop-types';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import AdministerIcon from '@mui/icons-material/Colorize';
import BarcodeIcon from '@mui/icons-material/CalendarViewDay';
import BorrowIcon from '@mui/icons-material/SwapHorizOutlined';
import Button from '@mui/material/Button';
import CaptureIcon from '@mui/icons-material/GetAppOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Divider} from '@mui/material';
import {Grid} from '@mui/material';
import InventoryIcon from '@mui/icons-material/LibraryBooksOutlined';
import {Link, useMatch} from 'react-router-dom';
import PatientIcon from '@mui/icons-material/AccessibilityNew';
import ReportIcon from '@mui/icons-material/AssessmentOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import TransferIcon from '@mui/icons-material/SwapHorizOutlined';
import TypographyFHG from '../../fhg/components/Typography';
import UserIcon from '@mui/icons-material/SupervisorAccount';
import {useRecoilState} from 'recoil';
import {
  USER_DASHBOARD_PATH,
  USER_ADMINISTER_A_VAX_PATH,
  USER_BORROWS_PATH,
  USER_CAPTURE_IMMUNIZE_PATH,
  USER_INVENTORY_PATH,
  USER_PRINT_BARCODE_PATH,
  USER_TRANSFERS_PATH,
  USER_USERS_PATH,
  USER_PATRECORDS_PATH,
  USER_REPORTS_PATH,
  USER_SEND_TO_STATE_PATH
} from '../../Constants';

export default function UserDrawer(props) {
  const dashboardMatch = useMatch({path: USER_DASHBOARD_PATH, strict: true, sensitive: true});
  const inventoryMatch = useMatch({path: USER_INVENTORY_PATH, strict: true, sensitive: true});
  const transfersMatch = useMatch({path: USER_TRANSFERS_PATH, strict: true, sensitive: true});
  const administerMatch = useMatch({path: USER_ADMINISTER_A_VAX_PATH, strict: true, sensitive: true});
  const borrowMatch = useMatch({path: USER_BORROWS_PATH, strict: true, sensitive: true});
  const captureMatch = useMatch({path: USER_CAPTURE_IMMUNIZE_PATH, strict: true, sensitive: true});
  const barcodeMatch = useMatch({path: USER_PRINT_BARCODE_PATH, strict: true, sensitive: true});
  const reportsMatch = useMatch({path: USER_REPORTS_PATH, strict: true, sensitive: true});
  const usersMatch = useMatch({path: USER_USERS_PATH, strict: true, sensitive: true});
  const prRecordsMatch = useMatch({path: USER_PATRECORDS_PATH, strict: true, sensitive: true});
  const toStateMatch = useMatch({path: USER_SEND_TO_STATE_PATH, strict: true, sensitive: true});

  const [{isSupervisor}] = useRecoilState(userStatus);
  const {handleDrawerClose} = props;

  return (
    <Grid container direction={'column'} isScrollable sx={{py: 4, px: 2}}>
      <Grid container direction={'column'} sx={{display: 'flex', alignItems: 'flex-start', pl: 3}}>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_DASHBOARD_PATH} onClick={handleDrawerClose}
                disabled={dashboardMatch?.isExact}
                startIcon={(<DashboardIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.dashboard.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_ADMINISTER_A_VAX_PATH} onClick={handleDrawerClose}
                disabled={administerMatch?.isExact}
                startIcon={(<AdministerIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.administer.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_CAPTURE_IMMUNIZE_PATH} onClick={handleDrawerClose}
                disabled={captureMatch?.isExact}
                startIcon={(<CaptureIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.capture.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_PATRECORDS_PATH} onClick={handleDrawerClose}
                disabled={prRecordsMatch?.isExact}
                startIcon={(<PatientIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.patrecords.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_INVENTORY_PATH} onClick={handleDrawerClose}
                disabled={inventoryMatch?.isExact}
                startIcon={(<InventoryIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.inventory.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        <Grid item sx={{my: 0.75, mx: 0, pl: 1.5, width: 32}}>
          <Divider />
        </Grid>
        <br/>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: '5px !important', mx: 0, minWidth: 28, whiteSpace: 'nowrap'}}>
          <Button component={Link} to={USER_BORROWS_PATH} onClick={handleDrawerClose}
                  disabled={borrowMatch?.isExact}
                  startIcon={(<BorrowIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.borrows.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_TRANSFERS_PATH} onClick={handleDrawerClose}
                disabled={transfersMatch?.isExact}
                startIcon={(<TransferIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.transfers.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_PRINT_BARCODE_PATH} onClick={handleDrawerClose}
                disabled={barcodeMatch?.isExact}
                startIcon={(<BarcodeIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.barcode.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
        {isSupervisor && (
          <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={USER_USERS_PATH} onClick={handleDrawerClose}
                  disabled={usersMatch?.isExact}
                  startIcon={(<UserIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.users.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
          <br/>
          </Grid>
        )}
        {isSupervisor && (
          <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={USER_REPORTS_PATH} onClick={handleDrawerClose}
                  disabled={reportsMatch?.isExact}
                  startIcon={(<ReportIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.reports.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
          </Grid>
        )}
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
        <Button component={Link} to={USER_SEND_TO_STATE_PATH} onClick={handleDrawerClose}
                disabled={toStateMatch?.isExact}
                startIcon={(<SendIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG id='appbar.tostate.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
        </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

UserDrawer.propTypes = {
  handleDrawerClose: PropTypes.func
};
