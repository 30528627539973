import React, {useCallback, useMemo} from 'react';
import ButtonFHG from "../../fhg/components/ButtonFHG";
import * as dayjs from 'dayjs';
import {formatMessage, getPatientAgeString} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryCardBasic from './InventoryCardBasic';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {INVENTORY_QUERY, PATIENTS_FOR_INV_QUERY} from '../../data/QueriesGL';
import {DATE_FORMAT_KEYBOARD, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from '../../Constants';

export default function InventoryPatients(props) {
  const intl = useIntl();
  const inventoryId = props.id;
  const [inventoryData] = useQueryFHG(INVENTORY_QUERY, {variables: {id: inventoryId}});
  const inventory = useMemo(() => inventoryData?.inventory || {}, [inventoryData]);
  const patientOptions = {
    variables: {
      inventoryId: inventoryId
    },
    skip: !inventoryId,
    fetchPolicy: "no-cache"
  };
  const [patientData] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions, 'patient.inventory.type');
  const patients = useMemo(() => orderBy(patientData?.patientRecords, [row => row.patientName.toLowerCase()], ['asc']), [patientData]);

  const columns = useMemo(() => {
    return [
      {
        id: 0,
        Header: <TypographyFHG id={'inventory.patients.patientName.column'}/>,
        accessor: 'patientName'
      },
      {
        id: 1,
        Header: <TypographyFHG id={'inventory.patients.age.column'}/>,
        accessor: 'age',
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{getPatientAgeString(row.original?.patientDob, row.original?.dateGiven)}</span>
        )
      },
      {
        id: 3,
        Header: <TypographyFHG id={'inventory.patients.dateGiven.column'}/>,
        accessor: 'dateGiven',
        Cell: ({value}) => (
          <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 2,
        Header: <TypographyFHG id={'inventory.patients.dosesGiven.column'}/>,
        accessor: 'dosesGiven',
        align: 'right',
        isFormattedNumber: true,
        format: '',
        Cell: ({value}) => (
          <span style={{paddingLeft: '4px'}}>{value ? value : ''}</span>
        )
      },
    ];
  }, []);

  const handleClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);

  return inventory ? (
    <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
      <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
        <TypographyFHG variant={'h5'} id={'inventory.patients.title'} color={'textSecondary'} />
      </Grid>
      <Grid sx={{display: 'flex', flexDirection: 'column', minHeight: 0, mt: 2, pl: 3, width: `calc(100% - 40px)`}}>
        <Grid sx={{overflowY: 'auto', mb: 5}}>
          <Grid container direction="column" >
            <TypographyFHG sx={{mr: 2}} variant={'subtitle1'} color={'textSecondary'} gutterBottom>
              {`Clinic Barcode: ${inventory?.clinicBarcode}`}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.original', '', inventory && inventory.originalDoses ? [inventory.originalDoses] : [0])}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.long', '', inventory && inventory.longDoses ? [inventory.longDoses] : [0])}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.expired', '', inventory && inventory.expiredDoses ? [inventory.expiredDoses] : [0])}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.wasted', '', inventory && inventory.wastedDoses ? [inventory.wastedDoses] : [0])}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.short', '', inventory && inventory.shortDoses ? [inventory.shortDoses] : [0])}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.borrowed', '', inventory && inventory.borrowDoses ? [inventory.borrowDoses] : [0])}
            </TypographyFHG>
            <TypographyFHG variant="subtitle2" color="textSecondary">
              {formatMessage(intl, 'import.message.payback', '', inventory && inventory.paybackDoses ? [inventory.paybackDoses] : [0])}
            </TypographyFHG>
          </Grid>
          <Grid sx={{mt: 2}}>
            <InventoryCardBasic inventory={inventory} />
          </Grid>
          <Grid sx={{height: 320, mt: 2,  ml: -0.25}}>
            <TableFHG titleKey="" columns={columns} data={patients} stickyHeader
                      allowSearch={false} emptyTableMessageKey={'inventory.patients.noRecord.message'}
            >
            </TableFHG>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{
        borderTopColor: 'lightgray',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        bottom: 0,
        height: '60px',
        pl: 3,
        width: '100%'
      }}>
        <Grid container direction="row" sx={{mt: 0.5}}>
          <ButtonFHG variant='outlined' size={'small'} labelKey={'close.button'}
                     sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} onClick={() => handleClose()}/>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

InventoryPatients.propTypes = {
  clinicBarcode: PropTypes.string,
  id: PropTypes.any,
  onClose: PropTypes.func.isRequired
}
