import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import {Button, Card, CardContent, Dialog, TextField} from '@mui/material';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import * as dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {formatMessage, getAge} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {lighten} from '@mui/material/styles';
import orderBy from 'lodash/orderBy';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import PatientCard from './PatientCard';
import VaccineCard from './VaccineCard';
import {
  getPatientRecordsRefetchQueries,
  INSURANCE_CATEGORIES_QUERY,
  INVENTORY_SEARCH_CLINIC_BARCODE_QUERY,
  PATIENTS_FOR_INV_QUERY,
  PATIENT_RECORDS_CREATE_ADM_VAX,
  VIEW_PATIENTS_QUERY,
} from '../../data/QueriesGL';
import {
  ADMIN_DASHBOARD_PATH,
  ADMIN_PATRECORDS_PATH,
  DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD, DATE_PICKER_WIDTH,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT,
  PRIMARY_COLOR, PRIMARY_DARK_COLOR, STYLES,
  USER_DASHBOARD_PATH,
  USER_PATRECORDS_PATH
} from '../../Constants';

export default function AdministerVax() {
  const clinicBarcodeRef = useRef(null);
  const navigate = useNavigate();
  const intl = useIntl();
  const options = {variables: {clinicBarcode: ''}};
  const [another, setAnother] = useState(false);
  const [allowMultipleDoseOverride, setAllowMultipleDoseOverride] = useState(false);
  const [multipleDosesDisplayed, setMultipleDosesDisplayed] = useState(false);
  const [allowNegativeDoseOverride, setAllowNegativeDoseOverride] = useState(false);
  const [negativeDoseDisplayed, setNegativeDoseDisplayed] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inventoryId, setInventoryId] = useState(null);
  const [inventoryState, setInventoryState] = useState({});
  const [hasError, setHasError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [persons, setPersons] = useState([]);
  const [searchBarcode, setSearchBarcode] = useState(false);
  const [alertMessageKey, setAlertMessageKey] = useState('');
  const patientOptions = useMemo(() => {
    return {
      variables: {inventoryId},
      skip: !inventoryId,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId]);
  const [insuranceData] = useQueryFHG(INSURANCE_CATEGORIES_QUERY, undefined, 'insurance.type');
  const [inventoryData, {data}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, options, 'inventory.type', true);
  const [patientData] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions, 'patient.inventory.type');
  const [personData, {data: searchData}] = useLazyQueryFHG(VIEW_PATIENTS_QUERY, {}, 'view.patient.type', true);
  const [patientRecordsCreate, {data: createData, error}] = useMutationFHG(PATIENT_RECORDS_CREATE_ADM_VAX);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      getValue,
      setEditValues,
      resetValues,
      setDefaultValues
    }
  ] = useEditData({}, ['insuranceId, patientId']);
  const patients = useMemo(() => patientData?.patientRecords,[patientData]);
  const insurance = useMemo(() => orderBy(insuranceData?.insurance, [row => row.name.toLowerCase()], ['asc']), [insuranceData]);
  const [{isAdmin}] = useRecoilState(userStatus);

  useEffect(() => {
    const invData = data ? data.clinicVaccine: [];

    if (searchBarcode && invData && editValues.clinicBarcode && editValues.clinicBarcode !== '' && Object.keys(invData).length > 0) {
      setInventoryState({doses: invData[0]?.doses, messageKey: 'import.message.search', success: true});
      setInventoryId(invData[0]?.id);
      setSearchBarcode(false);
    } else if (searchBarcode && invData && editValues.clinicBarcode && editValues.clinicBarcode !== '' && Object.keys(invData).length === 0) {
      setInventoryState({doses: invData[0]?.doses, messageKey: 'import.message.noMatch', success: false});
      setInventoryId(null);
    } else if (editValues?.clinicBarcode === '') {
      setInventoryState({doses: undefined, messageKey: 'import.message.noMatch', success: undefined});
      setInventoryId(null);
    }
  }, [data, editValues, searchBarcode, setInventoryState]);

  useEffect(() => {
    const returnedPersons = searchData && searchData.viewPatients ?
      orderBy(searchData?.viewPatients, [row => row?.person_LastName?.toLowerCase(), row => row?.person_FirstName?.toLowerCase()], ['asc', 'asc'])
      : [];
    setPersons(returnedPersons);
  }, [searchData, setPersons]);

  useEffect(() => {
    if (createData) {
      // Error from backend once create button is clicked.
      if (error) {
        setHasError(true);
        setAlertMessageKey('administer.message.fail');
      } else {
        setAlertMessageKey('administer.message.success');
      }
      setOpenAlert(true);
    }
  }, [createData, error]);

  const getPatient = useCallback(() => {
    let result;
    if (persons?.length > 0) {
      result = {
        person_FirstName: getValue('person_FirstName'),
        person_LastName: getValue('person_LastName'),
        person_DateOfBirth: getValue('person_DateOfBirth'),
        person_Age: getValue('person_Age')
      }
    }
    return result;
  }, [getValue, persons]);

  const getInsurance = useCallback(() => {
    let result;
    if (insurance?.length > 0) {
      const insuranceId = getValue('insuranceId');
      result = find(insurance, i => i.id === insuranceId);
    }
    return result;
  }, [getValue, insurance]);

  const getPersonOptionLabel = useCallback((person) => {
    let result = '';

    if (person && person.person_LastName !== 'undefined') {
      result = `${person.person_LastName}, ${person.person_FirstName} (${dayjs(person.person_DateOfBirth).format(DATE_FORMAT_KEYBOARD)})`
    }

    return result;
  }, []);

  const getStyle = useCallback(() => {
    let returnStyle;
    if (inventoryState.success === undefined) {
      returnStyle = STYLES.unkStyle;
    } else {
      returnStyle = inventoryState.success ? STYLES.successStyle : STYLES.failStyle;
    }
    return returnStyle;
  }, [inventoryState]);

  const getSuccessMessage = useCallback(() => {
    let result;
    // Display results of barcode search.
    if (inventoryState.success === undefined || !inventoryState.messageKey) {
      result = '';
    } else {
      if (inventoryState.messageKey === 'import.message.search') {
        result = formatMessage(intl, 'import.message.search', '', [inventoryState.doses]);
      } else {
        result = formatMessage(intl, inventoryState.messageKey, '', undefined);
      }
    }
    return result;
  }, [intl, inventoryState]);

  const handleClose = useCallback(() => {
    const path = isAdmin ? ADMIN_DASHBOARD_PATH : USER_DASHBOARD_PATH
    setAlertMessageKey('');
    setHasError(false);
    setOpenAlert(false);
    resetValues();
    navigate({pathname: path});
  }, [isAdmin, navigate, resetValues]);

  const handleSubmit = useCallback(() => {
    if (isChanged && data) {
      try {
        const inv = {
          ...defaultValues,
          ...editValues
        };
        const invId = data?.clinicVaccine?.[0]?.id;

        // Verify that there is a patient.
        if (!inv.patientId) {
          setAlertMessageKey('import.message.patient.required');
          setHasError(true);
          setOpenAlert(true);
          return;
        }
        // Verify that there is an insuranceId.
        if (!inv.insuranceId) {
          setAlertMessageKey('import.message.insurance.required');
          setHasError(true);
          setOpenAlert(true);
          return;
        }

        // Check for negative doses, allow user to cancel.
        let stop = false;
        let isNegative = inventoryState && inventoryState.doses <= 0;
        if (!negativeDoseDisplayed && isNegative) {
          setAlertMessageKey('import.message.negative.doses');
          setHasError(false);
          setOpenAlert(true);
          stop = true;
        }
        if (isNegative && !allowNegativeDoseOverride) {
          // If not allowed, stop and exit. We want to keep the vaccines in an all or none group.
          stop = true;
        }
        if (stop) {
          return;
        }

        // Check for already given dose from this vial.
        stop = false;
        if (patients && patients.length > 0) {
          let found = find(patients, x => x.patientId === inv?.patientId.toString());
          if (found && !multipleDosesDisplayed) {
            let msg = isAdmin ? 'import.message.multiple.doses.override' : 'import.message.no.multiple.doses';
            setAlertMessageKey(msg);
            setHasError(false);
            setOpenAlert(true);
            stop = true;
          }
          if (found && !allowMultipleDoseOverride) {
            // If multiple doses is not allowed, stop and exit. We want to keep the vaccines in an all or none group.
            stop = true;
          }
          if (stop) {
            return;
          }
        }

        const dateGiven = dayjs(inv.dateGiven).format(DATE_DB_FORMAT);
        const name = inv.person_FirstName + ' ' + inv.person_LastName
        const currentItem = {
          id: 0,
          dateGiven: dateGiven,
          dosesGiven: 1.00,
          age: inv.person_Age,
          notes: inv.notes,
          inventoryId: invId,
          insuranceId: inv.insuranceId,
          patientId: inv.patientId,
          patientName: name
        };

        setIsSaving(true);
        const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
        const endDate = dayjs().format(DATE_DB_FORMAT);

        patientRecordsCreate({
          variables: currentItem,
          refetchQueries: getPatientRecordsRefetchQueries(startDate, endDate, invId)
        });

        setIsChanged(false);
      } catch (e) {
        console.log(e?.message);
      } finally {
        setIsSaving(false);
      }
    }
  }, [patientRecordsCreate, data, isAdmin, isChanged, inventoryState, defaultValues, editValues,
    allowMultipleDoseOverride, multipleDosesDisplayed, allowNegativeDoseOverride, negativeDoseDisplayed,
    patients, setIsSaving, setIsChanged]);

  const handleMultipleYes = useCallback(() => {
    setMultipleDosesDisplayed(true);
    setAllowMultipleDoseOverride(true); // Only Admin gets button to overrride
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAllowMultipleDoseOverride, setMultipleDosesDisplayed]);

  const handleMultipleNo = useCallback(() => {
    setMultipleDosesDisplayed(false);
    setAllowMultipleDoseOverride(false);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAllowMultipleDoseOverride, setMultipleDosesDisplayed]);

  const handleNegativeYes = useCallback(() => {
    setNegativeDoseDisplayed(true);
    setAllowNegativeDoseOverride(true);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAllowNegativeDoseOverride, setNegativeDoseDisplayed]);

  const handleNegativeNo = useCallback(() => {
    setNegativeDoseDisplayed(false);
    setAllowNegativeDoseOverride(false);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAllowNegativeDoseOverride, setNegativeDoseDisplayed]);

  const handleAlertClose = useCallback(() => {
    setAlertMessageKey('');
    setOpenAlert(false);

    if (hasError) {
      setHasError(false);
    } else {
      if (another) {
        setAnother(false);
        setDefaultValues(defaultValues => ({...defaultValues, clinicBarcode: ''}));
        setEditValues(editValues => ({...editValues, clinicBarcode: ''}));
      } else {
        const path = isAdmin ? ADMIN_PATRECORDS_PATH : USER_PATRECORDS_PATH
        resetValues();
        navigate({pathname: path});
      }
    }
  }, [another, setAnother, hasError, isAdmin, navigate, resetValues, setDefaultValues, setEditValues]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      if (hasError) {
        if (alertMessageKey === 'import.message.patient.required' || alertMessageKey === 'import.message.insurance.required') {
          const errorStr = formatMessage(intl, alertMessageKey, 'An unknown error has occurred.', undefined);
          result = <Alert severity="error" onClose={handleAlertClose}>{`An error occurred: ${errorStr}`}</Alert>;
        } else {
          result = <Alert severity="error" onClose={handleAlertClose}>{`An error occurred: ${error}`}</Alert>;
        }
      } else {
        if (alertMessageKey === 'import.message.multiple.doses.override') {
          result = <Dialog open={true} onClose={handleMultipleNo}>
            <Alert severity="warning" onClose={handleMultipleNo} action={
              <><Button color="inherit" size="small" onClick={handleMultipleYes}
                        sx={{mr: 1}} variant="outlined">Yes</Button>
                <Button onClick={handleMultipleNo} color="inherit" size="small" variant="outlined">No</Button></>
            }>
              <TypographyFHG id={alertMessageKey}/>
            </Alert>
          </Dialog>;
        } else if (alertMessageKey === 'import.message.no.multiple.doses') {
          result = <Dialog open={true} onClose={handleMultipleNo}>
            <Alert severity="warning" onClose={handleMultipleNo} action={
              <Button onClick={handleMultipleNo} color="inherit" size="small" variant="outlined">Close</Button>
            }>
              <TypographyFHG id={alertMessageKey}/>
            </Alert>
          </Dialog>;
        } else if (alertMessageKey === 'import.message.negative.doses') {
          result = <Dialog open={true} onClose={handleNegativeNo}>
            <Alert severity="warning" onClose={handleNegativeNo} action={
              <><Button sx={{mr: 1}} onClick={handleNegativeYes} color="inherit" size="small"
                        variant="outlined">Yes</Button>
                <Button onClick={handleNegativeNo} color="inherit" size="small" variant="outlined">No</Button></>
            }>
              <TypographyFHG id={alertMessageKey}/>
            </Alert>
          </Dialog>;
        } else {
          result = <Dialog open={true} onClose={handleAlertClose}>
            <Alert severity="success" onClose={handleAlertClose}>
              <TypographyFHG id={alertMessageKey} />
            </Alert>
          </Dialog>;
        }
      }
    }

    return result;
  }, [error, hasError, intl, alertMessageKey, openAlert, handleAlertClose, handleMultipleYes, handleMultipleNo, handleNegativeYes, handleNegativeNo]);

  useEffect(() => {
    if (multipleDosesDisplayed || negativeDoseDisplayed) {
      setTimeout(() => handleSubmit(), 10);
    }
  }, [multipleDosesDisplayed, negativeDoseDisplayed, handleSubmit])

  const handleInsuranceChange = (event, value) => {
    setEditValues(editValues => ({...editValues, insuranceId: value?.id}));
    setIsChanged(true);
  };

  const handlePersonSelection = useCallback((event, value) => {
    if (value?.id) {
      // If dateGiven is null then use the default date which is today.
      let dateGiven = editValues.dateGiven ? editValues.dateGiven : defaultValues.dateGiven;
      const given = dateGiven ? dayjs(dateGiven) : dayjs();
      const dob = dayjs(value?.person_DateOfBirth);
      const age = getAge(dob, given);

      setIsChanged(true);
      setEditValues(editValues => ({...editValues, patientId: value?.id}));
      setEditValues(editValues => ({...editValues, person_LastName: value?.person_LastName}));
      setEditValues(editValues => ({...editValues, person_FirstName: value?.person_FirstName}));
      setEditValues(editValues => ({...editValues, person_DateOfBirth: value?.person_DateOfBirth}));
      setEditValues(editValues => ({...editValues, person_Age: age}));
      setEditValues(editValues => ({...editValues, dateGiven: given}));
    }
  }, [defaultValues, editValues, setIsChanged, setEditValues]);

  const handlePersonSearch = useCallback((name) => {
    let arr, lastName, firstName;

    if (name && name.length > 2) {
      if (name.indexOf(', ') > -1) {
        arr = name.split(', ');
        lastName = arr ? arr[0] : '';
        firstName = arr ? arr[1] : '';
      } else if (name.indexOf(',') > -1) {
        arr = name.split(',');
        lastName = arr ? arr[0] : '';
        firstName = arr ? arr[1] : '';
      } else {
        lastName = name;
      }
    }

    if (lastName) {
      let param = {fetchPolicy: 'network-only', variables: {lastName, firstName}};
      personData(param);
    }
  }, [personData]);

  const handlePersonSearchDebounced = useRef(debounce(handlePersonSearch, 1000)).current;

  const handlePersonInputChange = useCallback((e) => {
    const {target: {value}} = e;
    setEditValues(editValues => ({...editValues, searchInput: value}));

    if (value && value.length > 2) {
      handlePersonSearchDebounced(value);
    }
    setIsChanged(true);
  }, [handlePersonSearchDebounced, setEditValues, setIsChanged]);

  const handleClinicBarcodeChange = (e) => {
    handleSearchDebounced(e.target.value);
    setEditValues(editValues => ({...editValues, clinicBarcode: e.target.value}));
    setIsChanged(true);
  };

  const handleAddAnother = useCallback(() => {
    handleSubmit();
    clinicBarcodeRef.current.focus();
    setAnother(true);
  }, [clinicBarcodeRef, handleSubmit, setAnother]);

  const handleSearch = useCallback((barCode) => {
    if (barCode) {
      inventoryData({variables: {clinicBarcode: barCode}});
      setSearchBarcode(true);
    }
  }, [inventoryData]);

  const handleSearchDebounced = useRef(debounce(handleSearch, 1000)).current;

  const handleDateChange = (date) => {
    setEditValues(prevState => ({...prevState, dateGiven: date}));
    setIsChanged(true);
  };

  return (
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <TypographyFHG variant="h5" id="administer.title.label" color="textSecondary" gutterBottom sx={{mt: 0, mr: 0, mb: 1, ml: 2}} />
      {getAlert()}
      <Form onSubmit={handleSubmit} sx={{maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
        <Grid name={'Administer Root'} item sx={{maxHeight: `calc(100% - 16px)`, '& > *': {marginRight: 1}, 'overflow-y': 'scroll', mb: 1}}>
          <Grid name={'Administer Inner'} container item sx={{py: 0, px: 2}}>
            <Grid container direction="row">
              <Card sx={{border: `thin solid ${PRIMARY_COLOR}`, m: 1, maxWidth: '420px', pt: 1, px: 1, pb: 0}}>
                <CardContent>
                  <Grid>
                    <TypographyFHG variant={'h6'} id={'administer.card.patient.label'} color={'textSecondary'} />
                  </Grid>
                  <Grid>
                    <Grid container direction="column" sx={{mt: 2, mx: 0, mb: 1, pt: 0, width: DATE_PICKER_WIDTH}}>
                      <KeyboardDatePickerFHG
                        key={'dateGiven1'}
                        name={'dateGiven'}
                        labelKey={'administer.dateGiven.label'}
                        onChange={handleDateChange}
                        value={editValues?.dateGiven || dayjs(defaultValues?.dateGiven)}
                      />
                    </Grid>
                    <Autocomplete
                      key="person-search"
                      autoSelect
                      getOptionLabel={(option) => getPersonOptionLabel(option)}
                      noOptionsText=""
                      sx={{
                        '.MuiTextField-root': {width: '366px'}
                      }}
                      onChange={handlePersonSelection}
                      onInputChange={handlePersonInputChange}
                      options={persons}
                      filterOptions={(options, _ref) => {
                        let getOptionLabel = _ref.getOptionLabel;

                        return options.filter(function (option) {
                          let candidate = getOptionLabel(option);

                          let entries = candidate.split(',');
                          let beforeComma = entries[0];
                          let afterComma = entries[1];

                          if (!afterComma) {
                            beforeComma = candidate;
                            return candidate.indexOf(beforeComma) > -1
                          }

                          return candidate.indexOf(beforeComma) > -1 || candidate.indexOf(afterComma) > -1;
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{mb: 1, mt: 1, width: 46}}
                          label="Search patients"
                          fullWidth={false}
                          margin="normal"
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: 'search'
                          }}
                        />
                      )}
                    />
                    <PatientCard patient={getPatient()} compareDate={editValues.dateGiven ? editValues.dateGiven : defaultValues.dateGiven} />
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{border: `thin solid ${PRIMARY_COLOR}`, m: 1, maxWidth: '360px', pt: 1, px: 1, pb: 0}}>
                <CardContent>
                  <Grid>
                    <TypographyFHG variant={'h6'} id={'administer.card.vaccine.label'} color={'textSecondary'} />
                  </Grid>
                  <Grid>
                    <Grid sx={getStyle()}>{getSuccessMessage()}</Grid>
                    <TextFieldCustom
                      key={'clinicBarcode' + defaultValues.id}
                      name={'clinicBarcode'}
                      labelTemplate={'inventory.{name}.label'}
                      InputLabelProps={{style: {color: lighten(PRIMARY_COLOR, .5)}}}
                      inputRef={clinicBarcodeRef}
                      onBlur={handleSearchDebounced.flush}
                      onChange={handleClinicBarcodeChange}
                      value={editValues.clinicBarcode}
                      required
                    />
                    <VaccineCard inventory={data?.clinicVaccine?.[0]} />
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{border: `thin solid ${PRIMARY_COLOR}`, m: 1, width: '360px', pt: 1, px: 1, pb: 0}}>
                <CardContent>
                  <Grid>
                    <TypographyFHG variant={'h6'} id={'administer.card.insurance.label'} color={'textSecondary'} />
                  </Grid>
                  <Grid>
                    <Autocomplete
                      key={'insurance' + editValues.id}
                      sx={{mt: 1, mb: 1}}
                      fullWidth
                      getOptionLabel={(option) => option?.name || 'n/a'}
                      options={insurance}
                      onChange={handleInsuranceChange}
                      value={getInsurance()}
                      renderInput={(params) => (
                        <TextField{...params}  required label={formatMessage(intl, 'administer.insurance.label')} placeholder='Select insurance' variant="outlined" />
                      )}
                    />
                    <TextFieldCustom
                      key={'notes' + defaultValues.id}
                      name={'notes'}
                      labelTemplate={'administer.{name}.label'}
                      multiline
                      rows="6"
                      size="small"
                      onChange={handleChange}
                      value={editValues.notes}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} sx={{m: 2, borderTop: 'solid 1px lightgray'}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            sx={{mt: 1, mr: 0, mb: 0, ml: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
                            type={'submit'} size='large' labelKey='create.button' disabled={isSaving}/>
            <ButtonFHG variant='outlined' size='large' labelKey='create.another.label'
                       sx={{mt: 1, mr: 0, mb: 0, ml: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
                       color="primary" disabled={isSaving} onClick={handleAddAnother}/>
            <ButtonFHG variant='outlined' size={'large'} labelKey={'close.button'}
                       sx={{mt: 1, mr: 0, mb: 0, ml: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
                       disabled={isSaving} onClick={() => handleClose()}/>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
}

AdministerVax.propTypes = {
  onClose: PropTypes.func
}
