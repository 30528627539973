import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import {Button, Card, CardContent, Dialog, IconButton, TextField} from '@mui/material';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckIcon from '@mui/icons-material/Done';
import * as dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {formatMessage, getAge, getCvxCode, getVCodeConversionList, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import ImportVaccineCard from './ImportVaccineCard';
import isEqual from 'lodash/isEqual';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {lighten} from '@mui/material/styles';
import {Navigate, useNavigate} from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import PatientCard from './PatientCard';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import StatusSnackbar from '../../fhg/components/StatusSnackbar';
import TextFieldCustom from '../TextFieldCustom';
import times from 'lodash/times';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import usePromptFHG from '../../fhg/hooks/usePromptFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getCaptureRefetchQueries,
  BORROWS_QUERY,
  ENTY_PERSON_SEARCH_QUERY,
  INSURANCE_CATEGORIES_QUERY,
  INVENTORY_SEARCH_CLINIC_BARCODE_QUERY,
  PATIENT_RECORDS_CREATE,
  PATIENTS_FOR_INV_QUERY,
  V_CHART_IMMUNIZATION_QUERY,
  V_CHART_IMMUNIZATION_REFRESH_QUERY,
  VIEW_PATIENTS_SEARCH_QUERY
} from '../../data/QueriesGL';
import {
  ADMIN_DASHBOARD_PATH,
  CARD_WIDTH,
  CARD_WIDTH_LG,
  DATE_DB_FORMAT,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT,
  NOT_FOUND,
  USER_DASHBOARD_PATH,
  ADMIN_PATRECORDS_PATH,
  USER_PATRECORDS_PATH,
  PRIMARY_DARK_COLOR,
  PRIMARY_COLOR,
  STYLES
} from '../../Constants';

export default function CaptureImmunizations() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [anchor, setAnchor] = useState({vertical: 'top', horizontal: 'center'});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allowMultipleDoseOverride, setAllowMultipleDoseOverride] = useState(false);
  const [allowNegativeDoseOverride, setAllowNegativeDoseOverride] = useState(false);
  const [allowPaybackOverride, setAllowPaybackOverride] = useState(false);
  const [defaultDate] = useState(dayjs());
  const [givenDate, setGivenDate] = useState(defaultDate);
  const [multipleDosesDisplayed, setMultipleDosesDisplayed] = useState(false);
  const [negativeDoseDisplayed, setNegativeDoseDisplayed] = useState(false);
  const [paybackReminderDisplayed, setPaybackReminderDisplayed] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(true);
  const [dobDisplay, setDobDisplay] = useState('');
  const [editValues, setEditValues] = useState({});
  const [hasError, setHasError] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [{isAdmin}] = useRecoilState(userStatus);
  const [numVaccineContainers, setNumVaccineContainers] = useState(3);
  const [persons, setPersons] = useState([]);
  const [snackbarMessageKey, setSnackbarMessageKey] = useState('');
  const [alertMessageKey, setAlertMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [searchInventory, setSearchInventory] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [inventoryId0, setInventoryId0] = useState('');
  const [inventoryId1, setInventoryId1] = useState('');
  const [inventoryId2, setInventoryId2] = useState('');
  const [inventoryId3, setInventoryId3] = useState('');
  const [inventoryId4, setInventoryId4] = useState('');
  const [inventoryId5, setInventoryId5] = useState('');
  const [inventoryId6, setInventoryId6] = useState('');
  const [inventoryId7, setInventoryId7] = useState('');
  const [inventoryState, setInventoryState] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading0, setIsLoading0] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [isLoading6, setIsLoading6] = useState(false);
  const [isLoading7, setIsLoading7] = useState(false);
  const [isPatientLoading0, setIsPatientLoading0] = useState(false);
  const [isPatientLoading1, setIsPatientLoading1] = useState(false);
  const [isPatientLoading2, setIsPatientLoading2] = useState(false);
  const [isPatientLoading3, setIsPatientLoading3] = useState(false);
  const [isPatientLoading4, setIsPatientLoading4] = useState(false);
  const [isPatientLoading5, setIsPatientLoading5] = useState(false);
  const [isPatientLoading6, setIsPatientLoading6] = useState(false);
  const [isPatientLoading7, setIsPatientLoading7] = useState(false);
  const [inventorySearch0, {loading: loading0, data: inventoryData0}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch1, {loading: loading1, data: inventoryData1}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch2, {loading: loading2, data: inventoryData2}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch3, {loading: loading3, data: inventoryData3}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch4, {loading: loading4, data: inventoryData4}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch5, {loading: loading5, data: inventoryData5}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch6, {loading: loading6, data: inventoryData6}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [inventorySearch7, {loading: loading7, data: inventoryData7}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type');
  const [insuranceData] = useQueryFHG(INSURANCE_CATEGORIES_QUERY, undefined, 'insurance.type');
  const [immunizationData, {data: chartData}] = useLazyQueryFHG(V_CHART_IMMUNIZATION_QUERY, {}, 'chart.type');
  const [immunizationRefreshData, {data: refreshData}] = useLazyQueryFHG(V_CHART_IMMUNIZATION_REFRESH_QUERY, {}, 'chart.type');
  const [borrowSearch0, {loading: borrowLoading0, data: borrowData0}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch1, {loading: borrowLoading1, data: borrowData1}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch2, {loading: borrowLoading2, data: borrowData2}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch3, {loading: borrowLoading3, data: borrowData3}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch4, {loading: borrowLoading4, data: borrowData4}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch5, {loading: borrowLoading5, data: borrowData5}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch6, {loading: borrowLoading6, data: borrowData6}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const [borrowSearch7, {loading: borrowLoading7, data: borrowData7}] = useLazyQueryFHG(BORROWS_QUERY, {}, 'borrows.type');
  const dashboardPath = isAdmin ? ADMIN_DASHBOARD_PATH : USER_DASHBOARD_PATH
  const recordsPath = isAdmin ? ADMIN_PATRECORDS_PATH : USER_PATRECORDS_PATH

  const patientOptions0 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId0},
      skip: !inventoryId0,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId0]);
  const patientOptions1 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId1},
      skip: !inventoryId1,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId1]);
  const patientOptions2 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId2},
      skip: !inventoryId2,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId2]);
  const patientOptions3 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId3},
      skip: !inventoryId3,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId3]);
  const patientOptions4 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId4},
      skip: !inventoryId4,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId4]);
  const patientOptions5 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId5},
      skip: !inventoryId5,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId5]);
  const patientOptions6 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId6},
      skip: !inventoryId6,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId6]);
  const patientOptions7 = useMemo(() => {
    return {
      variables: {inventoryId: inventoryId7},
      skip: !inventoryId7,
      fetchPolicy: "no-cache"
    }
  }, [inventoryId7]);
  const [patientData0] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions0, 'patient.inventory.type');
  const [patientData1] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions1, 'patient.inventory.type');
  const [patientData2] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions2, 'patient.inventory.type');
  const [patientData3] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions3, 'patient.inventory.type');
  const [patientData4] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions4, 'patient.inventory.type');
  const [patientData5] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions5, 'patient.inventory.type');
  const [patientData6] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions6, 'patient.inventory.type');
  const [patientData7] = useQueryFHG(PATIENTS_FOR_INV_QUERY, patientOptions7, 'patient.inventory.type');
  const [searchEmds, {data: searchData}] = useLazyQueryFHG(ENTY_PERSON_SEARCH_QUERY, {}, 'person.type', true);
  const [patientRecordCreate] = useMutationFHG(PATIENT_RECORDS_CREATE);

  const resetValues = useCallback(() => {
    setEditValues({});
    setIsChanged(false);
  }, [setEditValues, setIsChanged]);

  const personLookupOptions = useMemo(() => {
    return {
      variables: {patientId: editValues.patientId},
      skip: !editValues.patientId,
      fetchPolicy: "no-cache"
    }
  }, [editValues]);
  const [personLookupData] = useQueryFHG(VIEW_PATIENTS_SEARCH_QUERY, personLookupOptions, 'view.patient.type');
  // const patients = useMemo(() => patientData?.patientRecords,[patientData]);

  const getChartData = useCallback(() => {
    if (refreshed) {
      return refreshData ? refreshData.immunizations : [];
    } else {
      return chartData ? chartData.immunizations : [];
    }
  }, [refreshed, chartData, refreshData]);
  const insuranceList = useMemo(() => orderBy(insuranceData?.insurance, [row => row.name?.toLowerCase()], ['asc']), [insuranceData]);
  const insurance = useMemo(() => {
    const immuneData = getChartData();
    const code = immuneData?.[0]?.vFCEligibility_Code || '';
    let result = '';

    if (code) {
      const value = find(insuranceList, item => item.financialClass === code) || '';
      setEditValues((prevState) => ({...prevState, insuranceId: value?.id}));
      result = value.name;
    }
    return result;
  }, [getChartData, insuranceList, setEditValues]);
  const vCode = useMemo(() => {
    const immuneData = getChartData();
    return immuneData?.[0]?.vFCEligibility_Code || '';
  }, [getChartData]);

  useEffect(() => {
    const returnedPersons = searchData ? searchData.persons : [];
    setPersons(returnedPersons);
  }, [searchData, setPersons]);

  useEffect(() => {
    if (editValues?.patientId) {
      if (chartData && chartData.immunizations.length > 0) {
        setNumVaccineContainers(chartData.immunizations.length);
        setHasError(false);
        setDisplaySearch(false);
      } else if (chartData && chartData.immunizations.length === 0) {
        setSnackbarMessageKey('import.message.notFound');
        setAnchor({vertical: 'top', horizontal: 'center'});
        setHasError(true);
        setOpenMessage(true);
        setDisplaySearch(true);
      } else {
        setDisplaySearch(true);
      }
    }
  }, [chartData, editValues, setDisplaySearch, setNumVaccineContainers, setSnackbarMessageKey]);

  useEffect(() => {
    if (!loading0 && inventoryData0 && inventoryData0.clinicVaccine) {
      if (inventoryData0.clinicVaccine.length > 0) {
        setInventoryId0(inventoryData0.clinicVaccine[0].vaccineID);
      }
      setIsLoading0(false);
    }
    if (!loading1 && inventoryData1 && inventoryData1.clinicVaccine) {
      if (inventoryData1.clinicVaccine.length > 0) {
        setInventoryId1(inventoryData1.clinicVaccine[0].vaccineID);
      }
      setIsLoading1(false);
    }
    if (!loading2 && inventoryData2 && inventoryData2.clinicVaccine) {
      if (inventoryData2.clinicVaccine.length > 0) {
        setInventoryId2(inventoryData2.clinicVaccine[0].vaccineID);
      }
      setIsLoading2(false);
    }
    if (!loading3 && inventoryData3 && inventoryData3.clinicVaccine) {
      if (inventoryData3.clinicVaccine.length > 0) {
        setInventoryId3(inventoryData3.clinicVaccine[0].vaccineID);
      }
      setIsLoading3(false);
    }
    if (!loading4 && inventoryData4 && inventoryData4.clinicVaccine) {
      if (inventoryData4.clinicVaccine.length > 0) {
        setInventoryId4(inventoryData4.clinicVaccine[0].vaccineID);
      }
      setIsLoading4(false);
    }
    if (!loading5 && inventoryData5 && inventoryData5.clinicVaccine) {
      if (inventoryData5.clinicVaccine.length > 0) {
        setInventoryId5(inventoryData5.clinicVaccine[0].vaccineID);
      }
      setIsLoading5(false);
    }
    if (!loading6 && inventoryData6 && inventoryData6.clinicVaccine) {
      if (inventoryData6.clinicVaccine.length > 0) {
        setInventoryId6(inventoryData6.clinicVaccine[0].vaccineID);
      }
      setIsLoading6(false);
    }
    if (!loading7 && inventoryData7 && inventoryData7.clinicVaccine) {
      if (inventoryData7.clinicVaccine.length > 0) {
        setInventoryId7(inventoryData7.clinicVaccine[0].vaccineID);
      }
      setIsLoading7(false);
    }
  }, [
    inventoryData0, inventoryData1, inventoryData2, inventoryData3, inventoryData4, inventoryData5, inventoryData6, inventoryData7,
    loading0, loading1, loading2, loading3, loading4, loading5, loading6, loading7, setInventoryId0, setInventoryId1, setInventoryId2,
    setInventoryId3, setInventoryId4, setInventoryId5, setInventoryId6, setInventoryId7
  ]);

  useEffect(() => {
    if (!isPatientLoading0 && patientData0 && patientData0.patientRecords) {
      setIsPatientLoading0(false);
    }
    if (!isPatientLoading1 && patientData1 && patientData1.patientRecords) {
      setIsPatientLoading1(false);
    }
    if (!isPatientLoading2 && patientData2 && patientData2.patientRecords) {
      setIsPatientLoading2(false);
    }
    if (!isPatientLoading3 && patientData3 && patientData3.patientRecords) {
      setIsPatientLoading3(false);
    }
    if (!isPatientLoading4 && patientData4 && patientData4.patientRecords) {
      setIsPatientLoading4(false);
    }
    if (!isPatientLoading5 && patientData5 && patientData5.patientRecords) {
      setIsPatientLoading5(false);
    }
    if (!isPatientLoading6 && patientData6 && patientData6.patientRecords) {
      setIsPatientLoading6(false);
    }
    if (!isPatientLoading7 && patientData7 && patientData7.patientRecords) {
      setIsPatientLoading7(false);
    }
  }, [
    patientData0, patientData1, patientData2, patientData3, patientData4, patientData5, patientData6, patientData7,
    isPatientLoading0, isPatientLoading1, isPatientLoading2, isPatientLoading3, isPatientLoading4, isPatientLoading5, isPatientLoading6, isPatientLoading7
  ]);

  const handleMultipleYes = useCallback(() => {
    setMultipleDosesDisplayed(true);
    setAllowMultipleDoseOverride(true); // Only Admin gets button to overrride
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert, setAllowMultipleDoseOverride, setMultipleDosesDisplayed]);

  const handleMultipleNo = useCallback(() => {
    setMultipleDosesDisplayed(false);
    setAllowMultipleDoseOverride(false);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert, setAllowMultipleDoseOverride, setMultipleDosesDisplayed]);

  const handleNegativeYes = useCallback(() => {
    setNegativeDoseDisplayed(true);
    setAllowNegativeDoseOverride(true);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert, setAllowNegativeDoseOverride, setNegativeDoseDisplayed]);

  const handleNegativeNo = useCallback(() => {
    setNegativeDoseDisplayed(false);
    setAllowNegativeDoseOverride(false);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert, setAllowNegativeDoseOverride, setNegativeDoseDisplayed]);

  const handlePaybackYes = useCallback(() => {
    setPaybackReminderDisplayed(true);
    setAllowPaybackOverride(true);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert,setAllowPaybackOverride,  setPaybackReminderDisplayed]);

  const handlePaybackNo = useCallback(() => {
    setPaybackReminderDisplayed(true);
    setAllowPaybackOverride(false);
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert, setAllowPaybackOverride, setPaybackReminderDisplayed]);

  const handleAlertClose = useCallback(() => {
    setAlertMessageKey('');
    setOpenAlert(false);
  }, [setAlertMessageKey, setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      if (alertMessageKey === 'import.message.multiple.doses.override') {
        result = <Dialog open={true} onClose={handleMultipleNo}>
          <Alert severity="warning" onClose={handleMultipleNo} action={
            <><Button onClick={handleMultipleYes} color="inherit" size="small" sx={{mr: 1}}
                      variant="outlined">Yes</Button>
              <Button onClick={handleMultipleNo} color="inherit" size="small" variant="outlined">No</Button></>
          }>
            <TypographyFHG id={alertMessageKey}/>
          </Alert>
        </Dialog>;
      } else if (alertMessageKey === 'import.message.no.multiple.doses') {
        result = <Dialog open={true} onClose={handleMultipleNo}>
          <Alert severity="warning" onClose={handleMultipleNo} action={
            <Button onClick={handleMultipleNo} color="inherit" size="small" variant="outlined">Close</Button>
          }>
            <TypographyFHG id={alertMessageKey}/>
          </Alert>
        </Dialog>;
      } else if (alertMessageKey === 'import.message.negative.doses') {
          result = <Dialog open={true} onClose={handleNegativeNo}>
            <Alert severity="warning" onClose={handleNegativeNo} action={
              <><Button color="inherit" size="small" onClick={handleNegativeYes}
                        sx={{mr: 1}} variant="outlined">Yes</Button>
                <Button onClick={handleNegativeNo} color="inherit" size="small" variant="outlined">No</Button></>
            }>
              <TypographyFHG id={alertMessageKey}/>
            </Alert>
          </Dialog>;
      } else if (alertMessageKey === 'import.message.possible.payback') {
        result = <Dialog open={true} onClose={handlePaybackNo}>
          <Alert severity="warning" onClose={handlePaybackNo} action={
            <><Button color="inherit" size="small" onClick={handlePaybackYes}
                      sx={{mr: 1}} variant="outlined">Yes</Button>
              <Button onClick={handlePaybackNo} color="inherit" size="small" variant="outlined">No</Button></>
          }>
            <TypographyFHG id={alertMessageKey}/>
          </Alert>
        </Dialog>;
      } else {
        result = <Dialog open={true} onClose={handleAlertClose}>
          <Alert severity="warning" onClose={handleAlertClose}
                 action={
                   <>
                     <Button color="inherit" size="small" onClick={handleAlertClose}
                             sx={{mr: 1}} variant="outlined">Close</Button>
                   </>
                 }
          >
            <TypographyFHG id={alertMessageKey}/>
          </Alert>
        </Dialog>;
      }
    }

    return result;
  }, [alertMessageKey, handleAlertClose, handleMultipleNo, handleMultipleYes, handleNegativeNo, handleNegativeYes, handlePaybackYes, handlePaybackNo, openAlert]);

  const getIndexedData = useCallback((index) => {
    let result = undefined;
    switch (index){
      case 0:
        if (!loading0 && inventoryData0 && inventoryData0.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading0 && inventoryData0 ? inventoryData0.clinicVaccine?.[0] : [];
        }
        break;
      case 1:
        if (!loading1 && inventoryData1 && inventoryData1.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading1 && inventoryData1 ? inventoryData1.clinicVaccine?.[0] : [];
        }
        break;
      case 2:
        if (!loading2 && inventoryData2 && inventoryData2.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading2 && inventoryData2 ? inventoryData2.clinicVaccine?.[0] : [];
        }
        break;
      case 3:
        if (!loading3 && inventoryData3 && inventoryData3.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading3 && inventoryData3 ? inventoryData3.clinicVaccine?.[0] : [];
        }
        break;
      case 4:
        if (!loading4 && inventoryData4 && inventoryData4.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading4 && inventoryData4 ? inventoryData4.clinicVaccine?.[0] : [];
        }
        break;
      case 5:
        if (!loading5 && inventoryData5 && inventoryData5.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading5 && inventoryData5 ? inventoryData5.clinicVaccine?.[0] : [];
        }
        break;
      case 6:
        if (!loading6 && inventoryData6 && inventoryData6.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading6 && inventoryData6 ? inventoryData6.clinicVaccine?.[0] : [];
        }
        break;
      case 7:
        if (!loading7 && inventoryData7 && inventoryData7.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading7 && inventoryData7 ? inventoryData7.clinicVaccine?.[0] : [];
        }
        break;
      default:
        if (!loading0 && inventoryData0 && inventoryData0.clinicVaccine.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !loading0 && inventoryData0 ? inventoryData0.clinicVaccine?.[0] : [];
        }
    }

    return result;
  }, [inventoryData0, inventoryData1, inventoryData2, inventoryData3, inventoryData4, inventoryData5, inventoryData6, inventoryData7,
    loading0, loading1, loading2, loading3, loading4, loading5, loading6, loading7]);

  const getLoading = useCallback((index) => {
    switch (index){
      case 1:
        return isLoading1;
      case 2:
        return isLoading2;
      case 3:
        return isLoading3;
      case 4:
        return isLoading4;
      case 5:
        return isLoading5;
      case 6:
        return isLoading6;
      case 7:
        return isLoading7;
      default:
        return isLoading0;
    }
  }, [isLoading0, isLoading1, isLoading2, isLoading3, isLoading4, isLoading5, isLoading6, isLoading7]);

  const getIndexedBorrowData = useCallback((index) => {
    let result = undefined;
    switch (index){
      case 0:
        if (!borrowLoading0 && borrowData0 && borrowData0.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading0 && borrowData0 ? borrowData0.borrows : [];
        }
        break;
      case 1:
        if (!borrowLoading1 && borrowData1 && borrowData1.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading1 && borrowData1 ? borrowData1.borrows : [];
        }
        break;
      case 2:
        if (!borrowLoading2 && borrowData2 && borrowData2.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading2 && borrowData2 ? borrowData2.borrows : [];
        }
        break;
      case 3:
        if (!borrowLoading3 && borrowData3 && borrowData3.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading3 && borrowData3 ? borrowData3.borrows : [];
        }
        break;
      case 4:
        if (!borrowLoading4 && borrowData4 && borrowData4.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading4 && borrowData4 ? borrowData4.borrows : [];
        }
        break;
      case 5:
        if (!borrowLoading5 && borrowData5 && borrowData5.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading5 && borrowData5 ? borrowData5.borrows : [];
        }
        break;
      case 6:
        if (!borrowLoading6 && borrowData6 && borrowData6.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading6 && borrowData6 ? borrowData6.borrows : [];
        }
        break;
      case 7:
        if (!borrowLoading7 && borrowData7 && borrowData7.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading7 && borrowData7 ? borrowData7.borrows : [];
        }
        break;
      default:
        if (!borrowLoading0 && borrowData0 && borrowData0.borrows.length === 0) {
          result = NOT_FOUND;
        } else {
          result = !borrowLoading0 && borrowData0 ? borrowData0.borrows : [];
        }
    }
    return result;
  }, [borrowData0, borrowData1, borrowData2, borrowData3, borrowData4, borrowData5, borrowData6, borrowData7,
    borrowLoading0, borrowLoading1, borrowLoading2, borrowLoading3, borrowLoading4, borrowLoading5, borrowLoading6, borrowLoading7]);

  const inventorySearch = useCallback((index, param) => {
    switch (index){
      case 1:
        setIsLoading1(true);
        return inventorySearch1(param);
      case 2:
        setIsLoading2(true);
        return inventorySearch2(param);
      case 3:
        setIsLoading3(true);
        return inventorySearch3(param);
      case 4:
        setIsLoading4(true);
        return inventorySearch4(param);
      case 5:
        setIsLoading5(true);
        return inventorySearch5(param);
      case 6:
        setIsLoading6(true);
        return inventorySearch6(param);
      case 7:
        setIsLoading7(true);
        return inventorySearch7(param);
      default:
        setIsLoading0(true);
        return inventorySearch0(param);
    }
  }, [inventorySearch0, inventorySearch1, inventorySearch2, inventorySearch3, inventorySearch4,
    inventorySearch5, inventorySearch6, inventorySearch7]);

  const borrowSearch = useCallback((index, param) => {
    switch (index){
      case 1:
        borrowSearch1(param);
        break;
      case 2:
        borrowSearch2(param);
        break;
      case 3:
        borrowSearch3(param);
        break;
      case 4:
        borrowSearch4(param);
        break;
      case 5:
        borrowSearch5(param);
        break;
      case 6:
        borrowSearch6(param);
        break;
      case 7:
        borrowSearch7(param);
        break;
      default:
        borrowSearch0(param);
        break;
    }
  }, [borrowSearch0, borrowSearch1, borrowSearch2, borrowSearch3, borrowSearch4,
    borrowSearch5, borrowSearch6, borrowSearch7]);

  const getIndexedPatientData = useCallback((index) => {
    let results = [];
    switch (index){
      case 1:
        if (!isPatientLoading1 && patientData1 && patientData1.patientRecords) {
          results = patientData1.patientRecords;
        }
        break;
      case 2:
        if (!isPatientLoading2 && patientData2 && patientData2.patientRecords) {
          results = patientData2.patientRecords;
        }
        break;
      case 3:
        if (!isPatientLoading3 && patientData3 && patientData3.patientRecords) {
          results = patientData3.patientRecords;
        }
        break;
      case 4:
        if (!isPatientLoading4 && patientData4 && patientData4.patientRecords) {
          results = patientData4.patientRecords;
        }
        break;
      case 5:
        if (!isPatientLoading5 && patientData5 && patientData5.patientRecords) {
          results = patientData5.patientRecords;
        }
        break;
      case 6:
        if (!isPatientLoading6 && patientData6 && patientData6.patientRecords) {
          results = patientData6.patientRecords;
        }
        break;
      case 7:
        if (!isPatientLoading7 && patientData7 && patientData7.patientRecords) {
          results = patientData7.patientRecords;
        }
        break;
      default:
        if (!isPatientLoading0 && patientData0 && patientData0.patientRecords) {
          results = patientData0.patientRecords;
        }
        break;
    }
    return results;
  }, [patientData0, patientData1, patientData2, patientData3, patientData4,
    patientData5, patientData6, patientData7,
    isPatientLoading0, isPatientLoading1, isPatientLoading2, isPatientLoading3, isPatientLoading4,
    isPatientLoading5, isPatientLoading6, isPatientLoading7]);

  const getChart = useCallback((index) => {
    const immuneData = getChartData();
    const chart = immuneData ? immuneData[index] : {}
    let result = {};

    if (chart){
      result = {
        mfgBrand: chart.immuneType_Description,
        cvxCode: getCvxCode(chart.immuneType_CVX),
        expDate: dayjs(chart.lotNumber_DateExpireYear, chart.lotNumber_DateExpireMonth, chart.lotNumber_DateExpireDay) || '',
        lot: chart.lotNumber,
        manufacturerName: chart.organization_Name,
        vFCEligibility_Code: chart.vFCEligibility_Code
      };
    }
    return result;
  }, [getChartData]);

  const getInventory = useCallback((index) => {
    let result = {};
    if (inventoryState && inventoryState.length > 0) {
      result = inventoryState[index];
    }
    return result;
  }, [inventoryState]);

  // This searches all loans for the same cvx to return a list of possible loans to  payback.
  const getFilteredLoanList = useCallback((index, inventoryTypeId) => {
    const unpaidBorrowList = getIndexedBorrowData(index);
    let result = [];

    if (unpaidBorrowList && unpaidBorrowList !== NOT_FOUND) {
      // Does the inv type of borrow to match the barcode inv type?
      unpaidBorrowList.forEach(b => {
        if (b.ltiTypeId === inventoryTypeId) {
          result.push(b);
        }
      });
    }
    return result;
  }, [getIndexedBorrowData]);

  const isInvTypeConflict = useCallback((index, messageKey) => {
    if (messageKey === 'import.message.noMatch.search') {
      return false;
    }

    const chart = getChart(index);
    const invData = getIndexedData(index);
    let result = false;

    if (chart && invData) {
      const vCodeList = getVCodeConversionList();
      const invTypes = filter(vCodeList, item => item.code === chart.vFCEligibility_Code) || [];
      const invTypeActual = invData?.typeId;
      if (invTypes && invTypes.length > 0 && invTypeActual) {
        result = !invTypes.some(x => x.type === invTypeActual);
      }
    }
    return result;
  }, [getChart, getIndexedData]);

  const isPaybackPossible = useCallback((index) => {
    let result = false;
    const myInventory = getInventory(index);

    if (myInventory && myInventory.inventory && myInventory.inventory.clinicBarcode) {
      // By convention, barcodes ending in -Bn or -Pn are ineligible to use for a payback.
      const dashPos = myInventory.inventory.clinicBarcode.indexOf('-');
      const ineligible = dashPos > 0;

      // The filtered loan list is a look up based on the CVX of the borrow to barcode.
      const filtered = getFilteredLoanList(index, myInventory.inventory.typeId);
      const areLoansAvailable = filtered && filtered.length > 0;
      result = !ineligible && areLoansAvailable;
    }
    return result;
  }, [getFilteredLoanList, getInventory]);

  const updateInventoryState = useCallback((index, inventory, messageKey, success, conflict) => {
    let currentValue = {
      index: index,
      doses: inventory.doses,
      success,
      inventory,
      conflict,
      messageKey
    };

    // Setting state is causing recursion, so verify the values actually changed.
    let stateArray = [...inventoryState] || [];
    if (stateArray && stateArray.length > 0) {
      if (!isEqual(stateArray[index], currentValue)) {
        stateArray[index] = currentValue;
        setInventoryState(stateArray);
      }
    } else {
      stateArray[index] = currentValue;
      setInventoryState(stateArray);
    }

  }, [inventoryState, setInventoryState]);

  useEffect(() => {
    const immuneData = getChartData();
    let messageKey;

    if (immuneData && immuneData.length > 0) {
      const isLoading = getLoading(currentIndex);

      if (searchInventory && !isLoading) {
        const invData = getIndexedData(currentIndex);
        let inventory = invData;
        let search = true;

        if (invData === NOT_FOUND) {
          messageKey = 'import.message.noMatch';
          inventory = {};
          search = false;
        } else if (invData) {
          const chart = immuneData ? immuneData[currentIndex] : {};
          if (toNumber(invData.cvxCode) !== toNumber(chart.immuneType_CVX)) {
            messageKey = 'import.message.noMatch.search';
            inventory = {};
            search = false;
          }

          // Verify the lot.
          if (search) {
            if (invData.lot.toLowerCase() !== chart.lotNumber.toLowerCase()) {
              messageKey = 'import.message.noMatch.lot';
              search = false;
            }
          }

          // Verify the expiration date.
          if (search) {
            const chartExpireDate = chart.lotNumber_DateExpireYear + '-' +  ('0' + chart.lotNumber_DateExpireMonth).slice(-2) +
              '-' + ('0' + chart.lotNumber_DateExpireDay).slice(-2);
            const invExpireDate = dayjs(invData.expDate).format(DATE_DB_FORMAT);
            if (chartExpireDate !== invExpireDate) {
              messageKey = 'import.message.noMatch.date';
              search = false;
            }
          }
        }

        if (invData) {
          let conflict = isInvTypeConflict(currentIndex, messageKey);
          updateInventoryState(currentIndex, inventory, messageKey, search, conflict);
          setSearchInventory(false);
        }
      }
    }
  }, [getChartData, currentIndex, getChart, getIndexedData,
    getLoading, inventoryState, isInvTypeConflict, isPaybackPossible,
    setInventoryState, searchInventory, setSearchInventory, updateInventoryState]);

  const getConflictMessageKey = useCallback((index) => {
    const inv = inventoryState[index];
    const payback = isPaybackPossible(index);
    const bc = inv && inv.inventory?.clinicBarcode;
    let result = undefined;
    if (payback && bc) {
      result = 'inventory.payback.conflict.message';
    } else if (inv && inv.conflict && bc) {
      result = 'inventory.borrow.conflict.message';
    }
    return result;
  }, [inventoryState, isPaybackPossible]);

  const getConflictMessageDisplay = useCallback((index) => {
    let result = undefined;
    const conflictMsgKey = getConflictMessageKey(index);
    if (conflictMsgKey) {
      result = (
        <Grid item sx={{color: PRIMARY_COLOR, fontWeight: 'bold', my: 0, mr: 0, ml: 2, pt: 0.5, pr: 0, pb: 0, pl: 0.25}}>
          <TypographyFHG variant={'subtitle2'} id={conflictMsgKey} style={{'color': 'red'}} />
        </Grid>
      );
    }
    return result;
  }, [getConflictMessageKey]);

  const getSnackbarMessageKey = useCallback(() => {
    let result = '';

    if (snackbarMessageKey === 'success') {
      result = numVaccineContainers === 1 ? 'import.message.success' : 'import.messages.success';
    } else {
      result = snackbarMessageKey;
    }

    return result;
  }, [numVaccineContainers, snackbarMessageKey]);

  const getPersonOptionLabel = useCallback((person) => {
    let result = 'Unknown';

    if (person && person.person_LastName !== 'undefined') {
      let y = person.person_DateOfBirth && person.person_DateOfBirth.slice(0,4);
      let m = person.person_DateOfBirth && person.person_DateOfBirth.slice(5,7);
      let d = person.person_DateOfBirth && person.person_DateOfBirth.slice(8,10);
      let dob = `${m}-${d}-${y}`;
      setDobDisplay(dob);
      result = `${person.person_LastName}, ${person.person_FirstName} (${dob})`
    }

    return result;
  }, []);

  const getSearchSuccessMessage = useCallback((index) => {
    let result;
    if (inventoryState[index] === undefined) {
      result = '';
    } else {
      if (inventoryState[index].messageKey) {
        result = formatMessage(intl, inventoryState[index].messageKey);
      } else {
        result = formatMessage(intl, 'import.message.search', '', [inventoryState[index].doses]);
      }
    }
    return result;
  }, [intl, inventoryState]);

  const getStyle = useCallback((index) => {
    let returnStyle;
    if (inventoryState[index] === undefined) {
      returnStyle = STYLES.unkStyle;
    } else {
      returnStyle = inventoryState[index].success ? STYLES.successStyle : STYLES.failStyle;
    }
    return returnStyle;
  }, [inventoryState]);

  const isSubmitEnabled = useCallback(() => {
    let numResult = 0;

    times(numVaccineContainers, (index) => {
      const inv = getInventory(index);
      const conflictMsgKey = getConflictMessageKey(index);
      if ((inv && inv.success && !inv.conflict &&
        conflictMsgKey !== 'inventory.borrow.conflict.message' &&
        conflictMsgKey !== 'inventory.payback.conflict.message') ||
        (inv && inv.success && !inv.conflict && conflictMsgKey === 'inventory.payback.conflict.message')) {
        numResult++;
      }
    });

    return isEqual(numVaccineContainers, numResult);
  }, [getConflictMessageKey, getInventory, numVaccineContainers]);

  const handleClose = useCallback(() => {
    resetValues();
    setAllowMultipleDoseOverride(false);
    setAllowNegativeDoseOverride(false);
    setAllowPaybackOverride(false);
    setMultipleDosesDisplayed(false);
    setNegativeDoseDisplayed(false);
    setPaybackReminderDisplayed(false);
    navigate({pathname: dashboardPath});
  }, [dashboardPath, navigate, resetValues]);

  const handleRefreshVaccines = useCallback(() => {
    if (editValues && editValues.patientId) {
      let iDay = dayjs(givenDate).date();
      let iMonth = dayjs(givenDate).month()+1;
      let iYear = dayjs(givenDate).year();
      // Search charts for this person to get updated EMD data.
      immunizationRefreshData({
        fetchPolicy: "no-cache",
        variables: {
          personId: editValues.patientId, iDay, iMonth, iYear,
          dts: dayjs().format("MMDDYYYYhhmmsszzz")
        }
      });

      setRefreshed(true);
    }
  }, [editValues, givenDate, immunizationRefreshData]);

  const handleMessageClose = useCallback(() => {
    setOpenMessage(false);
    setSnackbarMessageKey('');
    setHasError(false);
  }, [setSnackbarMessageKey, setHasError, setOpenMessage]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);

        const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
        const endDate = dayjs().format(DATE_DB_FORMAT);
        const currentValues = {
          ...editValues,
          dateGiven: givenDate
        };

        // Verify that there is an insuranceId.
        if (!currentValues.insuranceId) {
          setOpenMessage(true);
          setSnackbarMessageKey('import.message.insurance.required');
          setHasError(true);
          setIsSaving(false);
          return;
        }

        // Check for negative doses, allow user to cancel.
        let stop = false;
        times(numVaccineContainers, (index) => {
          const currentData = getIndexedData(index);
          let isNegative = currentData && currentData.doses <= 0;
          if (!negativeDoseDisplayed && isNegative) {
            setAlertMessageKey('import.message.negative.doses');
            setHasError(false);
            setOpenAlert(true);
            stop = true;
          }
          if (isNegative && !allowNegativeDoseOverride) {
            // If not allowed, stop and exit. We want to keep the vaccines in an all or none group.
            stop = true;
          }
        });
        if (stop) {
          return;
        }

        // Check for already given dose from this vial.
        stop = false;
        let found = false;
        if (personLookupData && personLookupData.viewPatients && personLookupData.viewPatients.length > 0) {
          let thisPersonId = personLookupData && personLookupData.viewPatients ? personLookupData.viewPatients[0].person_ID : {}
          times(numVaccineContainers, (index) => {
            const patients = getIndexedPatientData(index);
            found = find(patients, x => x.patientId === thisPersonId.toString());
            if (found && !multipleDosesDisplayed) {
              let msg = isAdmin ? 'import.message.multiple.doses.override' : 'import.message.no.multiple.doses';
              setAlertMessageKey(msg);
              setHasError(false);
              setOpenAlert(true);
              stop = true;
            }
            if (found && !allowMultipleDoseOverride) {
              // If multiple doses is not allowed, stop and exit. We want to keep the vaccines in an all or none group.
              stop = true;
            }
          });
          if (stop) {
            return;
          }
        }

        // Check for possible payback
        stop = false;
        times(numVaccineContainers, (index) => {
          let isPayback = isPaybackPossible(index);
          if (!paybackReminderDisplayed && isPayback) {
            setAlertMessageKey('import.message.possible.payback');
            setHasError(false);
            setOpenAlert(true);
            stop = true;
          }
          if (isPayback && !allowPaybackOverride) {
            stop = true;
          }
        });
        if (stop) {
          return;
        }

        const dateGiven = dayjs(currentValues.dateGiven)?.format(DATE_DB_FORMAT);
        const name = currentValues.person_FirstName + ' ' + currentValues.person_LastName
        times(numVaccineContainers, (index) => {
          const chart = getChart(index);
          const currentData = getInventory(index);
          const currentItem = {
            id: 0,
            dateGiven: dateGiven,
            dosesGiven: 1.00,
            inventoryId: currentData?.inventory.id || 0,
            insuranceId: currentValues.insuranceId,
            lkup_patientId: currentValues.patientId,
            patientName: name,
            age: currentValues.person_Age
          };

          if (currentItem) {
            patientRecordCreate({
              variables: currentItem,
              optimisticResponse: {
                __typename: 'Mutation',
                patientRecord: {
                  ...currentItem,
                  __typename: 'PatientRecords'
                }
              },
              refetchQueries: getCaptureRefetchQueries(startDate, endDate, currentItem.inventoryId, chart.cvxCode)
            }).then((result) => {
              if (result.data.patientRecords.id === null) {
                setAnchor({vertical: 'top', horizontal: 'center'});
                setSnackbarMessageKey('import.messages.duplicate');
                setHasError(true);
                setOpenMessage(true);
              } else {
                setRedirect(true);
              }
            });
          }
        });
        setIsChanged(false);
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    }
  }, [allowMultipleDoseOverride, allowNegativeDoseOverride, allowPaybackOverride, editValues,
    getChart, getIndexedPatientData, getIndexedData, getInventory, givenDate, isAdmin, isChanged, isPaybackPossible,
    multipleDosesDisplayed, negativeDoseDisplayed, numVaccineContainers, personLookupData,
    patientRecordCreate, paybackReminderDisplayed,
    setIsSaving, setSnackbarMessageKey, setHasError, setIsChanged, setOpenMessage]);

  useEffect(() => {
    if (multipleDosesDisplayed || negativeDoseDisplayed || paybackReminderDisplayed) {
      setTimeout(() => handleSubmit(), 10);
    }
  }, [multipleDosesDisplayed, negativeDoseDisplayed, paybackReminderDisplayed, handleSubmit])

  const handlePersonSelection = useCallback((event, value) => {
    if (value?.person_ID && value?.person_ID.length > 0) {
      let iDay = dayjs(givenDate).date();
      let iMonth = dayjs(givenDate).month()+1;
      let iYear = dayjs(givenDate).year();
      const given = givenDate ? dayjs(givenDate) : dayjs();
      const dob = dayjs(value?.person_DateOfBirth);
      const age = getAge(dob, given);
      // Search charts for this person and this date in EMD.
      immunizationData({
        fetchPolicy: "no-cache",
        variables: {personId: value.person_ID, iDay, iMonth, iYear}
      });
      setEditValues(prevState => ({...prevState, patientId: value.person_ID}));
      setEditValues(prevState => ({...prevState, person_LastName: value?.person_LastName}));
      setEditValues(prevState => ({...prevState, person_FirstName: value?.person_FirstName}));
      setEditValues(prevState => ({...prevState, person_DateOfBirth: value?.person_DateOfBirth}));
      setEditValues(prevState => ({...prevState, person_Age: age}));
    }
  }, [givenDate, setEditValues, immunizationData]);

  const handlePersonSearch = useCallback((name) => {
    let arr, lastName, firstName;

    if (name && name.length > 2) {
      if (name.indexOf(', ') > -1) {
        arr = name.split(', ');
        lastName = arr ? arr[0] : '';
        firstName = arr ? arr[1] : '';
      } else if (name.indexOf(',') > -1) {
        arr = name.split(',');
        lastName = arr ? arr[0] : '';
        firstName = arr ? arr[1] : '';
      } else {
        lastName = name;
      }
    }

    if (lastName) {
      let param = {fetchPolicy: 'network-only', variables: {lastName, firstName}};
      searchEmds(param);
    }
  }, [searchEmds]);

  const handlePersonSearchDebounced = useRef(debounce(handlePersonSearch, 1000)).current;

  const handlePersonInputChange = useCallback((e) => {
    const {target: {value}} = e;
    setEditValues(prevState => ({...prevState, searchInput: value}));

    if (value && value.length > 2) {
      handlePersonSearchDebounced(value);
    }
  }, [handlePersonSearchDebounced, setEditValues]);

  const handleClinicBarcodeChange = useCallback((e) => {
    const { target: { name, value } } = e;
    setEditValues(prevState => ({...prevState, [name]: value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleSearch = useCallback((id) => {
    setCurrentIndex(id);

    const chart = getChart(id);
    if (chart) {
      let options = {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: {lfiCvxCode: getCvxCode(chart.cvxCode), paid: 'false', dts: dayjs().format("MMDDYYYYhhmmsszzz")}
      };
      borrowSearch(id, options);
    }

    let param = {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {clinicBarcode: editValues[`clinicBarcode${id}`], dts: dayjs().format("MMDDYYYYhhmmsszzz")}
    };
    setSearchInventory(true);
    inventorySearch(id, param);
  }, [borrowSearch, getChart, inventorySearch, editValues, setSearchInventory]);

  const getContainer = useCallback((index) => {
    const chart = getChart(index);
    return (
        <Grid key={`inventory-card-wrapper-${index}`} container direction="row"
              sx={{border: 'thin solid gray', display: 'flex', flexDirection: 'row', mt: 1, mx: 0, mb: 0, p: 1}}>
          <Grid item container direction="row">
            <Grid item><ImportVaccineCard key={`inventory-card-${index}`} inventory={chart} small /></Grid>
            <Grid item container direction="column" sx={{display: 'flex', width: '320px'}}>
              <Grid item container direction="row">
                <Grid item container direction="column" sx={{display: 'inline-block', width: '208px'}}>
                  <Grid sx={{mb: 1.5, ml: 2}}>
                    <Grid sx={getStyle(index)}>{getSearchSuccessMessage(index)}</Grid>
                  </Grid>
                  <TextFieldCustom
                      key={`clinicBarcode${index}`}
                      name={`clinicBarcode${index}`}
                      sx={{mt: 0, mr: 0, mb: 0, ml: 2, width: '146px'}}
                      inputProps={{ style: {fontSize: '0.875rem'}}}
                      InputLabelProps={{style: {color: lighten(PRIMARY_COLOR, .5)}}}
                      labelKey="import.card.clinicBarcode.label"
                      onChange={handleClinicBarcodeChange}
                      onKeyUp={(ev) => {
                        if (ev.keyCode === 13) {
                          handleSearch(index);
                        }
                      }}
                      value={editValues[`clinicBarcode${index}`]}
                      fullWidth={false}
                      required
                  />
                  <IconButton aria-label="select"
                              key={`barcode-select-${index}`} size="small" onClick={() => handleSearch(index)}>
                    <Grid key={`barcode-check-${index}`} sx={{
                      backgroundColor: lighten(PRIMARY_DARK_COLOR, 0.25),
                      color: '#FFF',
                      mt: -0.25, mx: 0, mb: 0, pt: 0.25, height: '30px', width: '30px'}}>
                      <CheckIcon />
                    </Grid>
                  </IconButton>
                </Grid>
              </Grid>
              {getConflictMessageDisplay(index)}
            </Grid>
          </Grid>
        </Grid>
      );
  }, [editValues, getChart, getConflictMessageDisplay, getSearchSuccessMessage, getStyle,
    handleClinicBarcodeChange, handleSearch]);

  const handleDateChange = (date) => {
    setGivenDate(date);
  };

  usePromptFHG({when: isChanged, messageKey: 'leaveCapture'});
  return (
    <>
    {redirect && (givenDate === defaultDate) && (<Navigate to={recordsPath} push={true} />)}
    {redirect && (givenDate !== defaultDate) && (<Navigate to={dashboardPath} push={true} />)}
    {!redirect && (
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <TypographyFHG id="import.title.label" color="textSecondary" variant="h5" gutterBottom sx={{mt: 0, mr: 0, mb: 1, ml: 2}} />
      <TypographyFHG id="import.title.help" color="textSecondary" variant="subtitle2" gutterBottom sx={{mt: 0, mr: 0, mb: 1, ml: 2}} />
      <StatusSnackbar autoHideDuration={null} allowClose open={openMessage} messageKey={getSnackbarMessageKey()}
                      onClose={handleMessageClose} index={currentIndex} total={numVaccineContainers}
                      errorState={hasError} anchor={anchor} />
      {displaySearch && (
        <Grid container direction="column" sx={{mt: 2, mr: 0, mb: 1, ml: 2}}>
          <Grid container direction="column" sx={{pt: 1, width: '164px'}}>
            <KeyboardDatePickerFHG
              key={'dateGiven'}
              name={'dateGiven'}
              labelKey={'import.dateGiven.label'}
              onChange={handleDateChange}
              value={givenDate}
            />
          </Grid>
          <Autocomplete
            key="person-search"
            autoSelect
            getOptionLabel={(option) => getPersonOptionLabel(option)}
            noOptionsText=""
            sx={{
              mt: 1,
              '.MuiTextField-root': {width: '366px'}
            }}
            onChange={handlePersonSelection}
            onInputChange={handlePersonInputChange}
            options={persons}
            filterOptions={(options, _ref) => {
              let getOptionLabel = _ref.getOptionLabel;

              return options.filter(function (option) {
                let candidate = getOptionLabel(option);

                let entries = candidate.split(',');
                let beforeComma = entries[0];
                let afterComma = entries[1];

                if (!afterComma) {
                  beforeComma = candidate;
                  return candidate.indexOf(beforeComma) > -1
                }

                return candidate.indexOf(beforeComma) > -1 || candidate.indexOf(afterComma) > -1;
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{mt: 1, mb: 3, width: 50}}
                label="Search patients"
                fullWidth={false}
                margin="normal"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                  endAdornment: (
                    <>
                      <SearchIcon size="small" />
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}
      {!displaySearch && (
      <Form onSubmit={handleSubmit} sx={{maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
        <Grid name={'capture-root'} item sx={{
          maxHeight: `calc(100% - 16px)`,
          '& > *': {
            mr: 1,
          },
          overflowY: 'auto',
          mb: 1,
        }}>
          <Grid name={'capture-inner'} container item sx={{py: 0, px: 2}}>
            {getAlert()}
            <Grid container direction="row" >
              <Card sx={{m: 0.5, maxWidth: CARD_WIDTH, pt: 1, pr: 1, pb: 0, pl: 1}}>
                <CardContent>
                  <Grid>
                    <TypographyFHG variant={'subtitle2'} id={'import.card.patient.label'} color={'textSecondary'} />
                  </Grid>
                  <Grid>
                    <PatientCard patient={editValues} dob={dobDisplay} compareDate={givenDate} small />
                    <Grid container direction="row" sx={{mt: 2}}>
                      <TypographyFHG id={'import.insurance.label'} variant={'subtitle2'} color={'textSecondary'}
                                     sx={{my: 0, mr: 1, ml: 0}} />
                      <TypographyFHG variant={'body1'} sx={{fontWeight: 'bold'}}>{insurance}</TypographyFHG>
                    </Grid>
                    <Grid container direction="row" sx={{mt: 2}}>
                      <TypographyFHG id={'import.vcode.label'} color={'textSecondary'} variant={'subtitle2'}
                                     sx={{my: 0, mr: 1, ml: 0}} />
                      <TypographyFHG variant={'body1'} sx={{fontWeight: 'bold'}}>{vCode}</TypographyFHG>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{m: 0.5, pt: 1, pr: 1, pb: 0, pl: 1, overflowY: 'auto', width: CARD_WIDTH_LG + 100}}>
                <CardContent>
                  <Grid container item direction="row" justifyContent="space-between">
                    <TypographyFHG variant={'subtitle2'} id={'import.card.vaccine.label'} color={'textSecondary'} />
                  </Grid>
                  <Grid container item direction="column">
                    {times(numVaccineContainers, (index) => {
                      return getContainer(index)
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container item direction={'row'} justifyContent={'space-between'}
                sx={{
                  mt: 2, mr: 0, mb: 1, ml: -8,
                  borderTop: `solid 1px darkgray`,
                  pt: 1, pr: 2, pb: 0,
                  '& > *': {
                    mr: 1,
                  },
                }}
                overflow={'visible'} alignItems={'center'}>
            <Grid item sx={{ml: 9}}>
              <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                              sx={{mt: 1, mx: 1, mb: 0,
                                '&:hover': {
                                  color: PRIMARY_DARK_COLOR,
                                }
                              }}
                              type={'submit'} size='large' labelKey='capture.button' disabled={!isSubmitEnabled()} />
              <ButtonFHG variant='outlined' size={'large'} labelKey={'refresh.button'}
                         sx={{mt: 1, mx: 1, mb: 0,
                           '&:hover': {
                             color: PRIMARY_DARK_COLOR,
                           }
                         }}
                         disabled={refreshed} onClick={() => handleRefreshVaccines()}/>
              <ButtonFHG variant='outlined' size={'large'} labelKey={'close.button'}
                         sx={{mt: 1, mx: 1, mb: 0,
                           '&:hover': {
                             color: PRIMARY_DARK_COLOR,
                           }
                         }}
                         disabled={isSaving} onClick={() => handleClose()}/>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    )}
    </Grid>)
    }
    </>
  );
}

CaptureImmunizations.propTypes = {
  onClose: PropTypes.func
}
