export const authenticatorTheme = {
   name: 'my-theme',
   tokens: {
      colors: {
         font: {
            primary: {value: '#0072ce'},
         },
      },
      components: {
         button: {
            // this will affect the font weight of all button variants
            fontWeight: {value: '{fontWeights.black.value}'},
            // style the primary variation
            primary: {
               backgroundColor: {value: '#0072ce'},
               _hover: {
                  backgroundColor: {value: '#0059B5'},
               },
            },
         },
      }
   }
};
