import React, {useState, useCallback, useMemo} from 'react';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {formatMessage} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import PasswordTextField from '../../fhg/components/security/PasswordTextField';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import useEffect from '../../fhg/hooks/useEffect';
import {useIntl} from 'react-intl';
import useKeyDown from '../../fhg/hooks/useKeyDown';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
   getUsersRefetchQueries,
   ROLES_QUERY,
   USERS_UPDATE
} from '../../data/QueriesGL';
import {ACTIVE_STRING, EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from '../../Constants';

export default function UserEdit(props) {
   const {id: userID, users} = props;
   const intl = useIntl();
   const variables = {};
   const [isActive, setIsActive] = useState(true);
   const [isSaving, setIsSaving] = useState(false);
   const [messageText, setMessageText] = useState('');
   const [openAlert, setOpenAlert] = useState(false);
   const [userUpdate, {data, error}] = useMutationFHG(USERS_UPDATE);
   const [rolesData] = useQueryFHG(ROLES_QUERY, variables, 'roles.type');
   const [{isAdmin}] = useRecoilState(userStatus);
   const [
      editValues, handleChange, {
         isChanged = false,
         setIsChanged,
         defaultValues,
         setDefaultValues,
         setEditValues,
         resetValues,
         getValue,
      }
   ] = useEditData({}, ['roleId']);

   useEffect(() => {
      if (users && users.length > 0) {
         let curUser = find(users, x => x.id === userID);
         setIsActive(curUser?.active === ACTIVE_STRING)
         setDefaultValues(assign({}, curUser, {id: userID, userID: userID}));
      }
   }, [setDefaultValues, userID, users]);

   const roles = useMemo(() => {
      let result = [];
      // If you are not an Admin, you can't make someone else an Admin...
      if (rolesData?.roles) {
         result = rolesData?.roles.filter(role => {
            // Currently only supporting 3 roles.
            if (isAdmin) {
               return role.id <= 3;
            } else {
               return role.id > 1 && role.id <= 3;
            }
         });
      }
      return result;
   }, [isAdmin, rolesData]);

   const getRole = useCallback(() => {
      let result;
      if (roles?.length > 0) {
         const roleId = getValue('roleId');
         result = find(roles, role => role.id === roleId);
      }
      return result;
   }, [getValue, roles]);

   const handleClose = useCallback(() => {
      resetValues();
      if (props.onClose) {
         props.onClose();
      }
   }, [props, resetValues]);

   useEffect(() => {
      if (data && !error) {
         handleClose();
      }
   }, [data, error, handleClose]);

   useEffect(() => {
      if (error) {
         setMessageText(error.message);
         setOpenAlert(true);
      }
   }, [error]);

   const handleRoleChange = (event, value) => {
      setEditValues(editValues => ({...editValues, roleId: value?.id}));
      setIsChanged(true);
   }

   useKeyDown(handleClose);

   /**
    * Submit the user.
    * @return {Promise<void>}
    */
   const handleSubmit = useCallback(async () => {
      if (isChanged) {
         try {
            let currentItem = {
               ...defaultValues,
               ...editValues,
            };
            setIsSaving(true);

            currentItem.active = isActive ? "1" : "0";
            delete currentItem.role;
            delete currentItem['__typename'];
            let variables = assign({}, currentItem);
            delete variables['__typename'];
            variables.admin = isAdmin;

            await userUpdate({
               variables: variables,
               refetchQueries: getUsersRefetchQueries()
            });

            setIsChanged(false);
            handleClose();
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         handleClose();
      }
   }, [userUpdate, handleClose, isAdmin, isActive, isChanged, defaultValues, editValues, setIsChanged]);

   /**
    * Handle onChange events for the password. Check if the password and confirm match.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param name
    * @param reason The reason of the value change if Autocomplete
    */
   const handleChangeCallback = useCallback((event, value, reason, newValue, name) => {
      handleChange(event, value, reason, newValue, name);

      if (name === 'password') {
         const target = document.getElementById('confirm_password');
         if (target) {
            target.setCustomValidity(
               this.state.confirm !== this.state.password ?
                  formatMessage(intl, 'user.confirmMismatch.message', 'Confirm does not match the password.') : '');
         }
      }
   }, [handleChange, intl]);

   const handleCheckChange = useCallback((e)=> {
      setIsActive(e.target.checked);
      setIsChanged(true);
   },[setIsActive, setIsChanged]);

   const handleAlertClose = useCallback(() => {
      setOpenAlert(false);
   }, [setOpenAlert]);

   const getAlert = useCallback(() => {
      let result = undefined;
      if (openAlert) {
         result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
      }
      return result;
   }, [messageText, openAlert, handleAlertClose]);

  if (defaultValues.id) {
    return (
      <Form onSubmit={handleSubmit}>
         <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
           <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
             <TypographyFHG variant={'h5'} id={'user.edit.title'} color={'textSecondary'} />
           </Grid>
            <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
              <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
                {getAlert()}
                <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                  <TextFieldCustom
                    key="firstName"
                    name="firstName"
                    autoFocus
                    labelKey="user.firstName.label"
                    onChange={handleChange}
                    value={editValues?.firstName || defaultValues?.firstName}
                    required
                  />
                </Grid>
                <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                  <TextFieldCustom
                    key="lastName"
                    name="lastName"
                    labelKey="user.lastName.label"
                    onChange={handleChange}
                    value={editValues?.lastName || defaultValues?.lastName}
                    required
                  />
                </Grid>
                <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                  <TextFieldCustom
                    key="userName"
                    name="userName"
                    labelKey="user.userName.label"
                    onChange={handleChange}
                    value={editValues?.userName || defaultValues?.userName}
                    disabled={true}
                    helperText={'Username cannot be changed'}
                    required
                  />
                </Grid>
                 <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                  <PasswordTextField
                    key="password"
                    name="password"
                    isNew={false}
                    disabled={isSaving}
                    onChange={handleChangeCallback}
                    password={editValues.password}
                    confirm={editValues.confirm}
                    sx={{height: '64px'}}
                  />
                </Grid>
                <Grid sx={{my: 0.5, mx: 1}}>
                  <TypographyFHG id="user.req" color="textPrimary" variant="caption" />
                </Grid>
                <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                  <Autocomplete
                    key={'RoleList' + editValues.id}
                    fullWidth
                    getOptionLabel={(option) => option?.title || ''}
                    options={roles}
                    onChange={handleRoleChange}
                    value={getRole()}
                    size="small"
                    sx={{mt: 1.5, mb: 1,}}
                    renderInput={(params) => (
                      <TextField{...params} label='Role' placeholder='Select role' size="small" variant="outlined" />
                    )}
                  />
                </Grid>
            <CheckboxFHG
              key={'isActive'}
              name={'isActive'}
              onChange={handleCheckChange}
              labelKey={'user.active.label'}
              checked={isActive}
              disabled={isSaving}
              marginTop={0}
              fullWidth
            />
          </Grid>
          </Grid>
           <Grid sx={{
              borderTopColor: 'lightgray',
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              bottom: 0,
              height: '60px',
              pl: 3,
              width: '100%'
          }}>
            <Grid container direction="row" sx={{mt: 0.5}}>
              <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
              <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
   } else {
      return null;
   }
}

UserEdit.propTypes = {
   id: PropTypes.any,
   users: PropTypes.array,
   onClose: PropTypes.func
}
