import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip, InputLabel} from '@mui/material';
import * as dayjs from 'dayjs';
import {formatMessage, getChipBgColor, hexToRgb, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from "../../fhg/components/KeyboardDatePickerFHG";
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {DISPENSED_DETAIL_QUERY, INVENTORY_TYPES_QUERY} from '../../data/QueriesGL';
import {CARD_SM_FONT, DATE_DB_FORMAT, DATE_FORMAT_KEYBOARD, PRIMARY_DARK_COLOR} from '../../Constants';

export default function DispensedReport(props) {
  const intl = useIntl();
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [typeId, setTypeId] = useState();
  const [rows, setRows] = useState([]);
  const options = {variables: {typeId: toNumber(typeId), startDate, endDate}, skip: !typeId};
  const [searchDispensed, {data: detailData}] = useLazyQueryFHG(DISPENSED_DETAIL_QUERY, options, 'dispensed.report.type');
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);

  useEffect(() => {
    if (detailData && detailData.records && detailData.records.length > 0) {
      setRows(detailData.records);
    }
  }, [detailData]);

  const columns = useMemo(() => {
    return [
      {
        id: '0',
        Header: <TypographyFHG id={'dispensed.report.name.column'}/>,
        accessor: 'name',
        weighting: 1,
        width: 130
      },
      {
        id: '1',
        Header: <TypographyFHG id={'dispensed.report.mfgBrand.column'}/>,
        accessor: 'mfgBrand',
        weighting: 1,
        width: 150
      },
      {
        id: '3',
        Header: <TypographyFHG id={'dispensed.report.mfgBarcode.column'}/>,
        accessor: 'mfgBarcode',
        width: 90
      },
      {
        id: '7',
        Header: <TypographyFHG id={'dispensed.report.lot.column'}/>,
        accessor: 'lot',
        weighting: 1,
        width: 90,
        Cell: ({value}) => (<>{value ? value : '0'}</>)
      },
      {
        id: '4',
        Header: <TypographyFHG id={'dispensed.report.dateGiven.column'}/>,
        accessor: 'dateGiven',
        weighting: 1,
        width: 85,
        Cell: ({value}) => (
          <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: '5',
        Header: <TypographyFHG id={'dispensed.report.dosesGiven.column'}/>,
        accessor: 'dosesGiven',
        weighting: 1,
        width: 50,
        Cell: ({value}) => (<span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{value ? value : '0'}</span>)
      },
      {
        id: '6',
        Header: <TypographyFHG id={'dispensed.report.cvxCode.column'}/>,
        accessor: 'cvxCode',
        weighting: 1,
        width: 50,
        Cell: ({value}) => (<span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{value ? value : '0'}</span>)
      },
      {
        id: '8',
        Header: <TypographyFHG id={'dispensed.report.clinicBarcode.column'}/>,
        accessor: 'clinicBarcode',
        width: 70
      }
    ];
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleInventoryTypeChange = useCallback((e) => {
    let id = e.target.value;
    if (id) {
      setTypeId(`${id}`);
    }
  }, []);

  const handleSearch = useCallback(() => {
    if (!startDate || !endDate) {
      return;
    }
    if (!typeId){
      setMessageKey('reports.dispensed.required.message');
      setOpenAlert(true);
      return;
    }

    const options = {
      variables: {
        startDate: dayjs(startDate).format(DATE_DB_FORMAT),
        endDate: dayjs(endDate).format(DATE_DB_FORMAT),
        typeId: toNumber(typeId),
        dts: dayjs().format("MMDDYYYYhhmmsszzz")
      }
    };

    searchDispensed(options);
  }, [startDate, endDate, typeId, searchDispensed]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="warning" onClose={handleAlertClose}>
        {formatMessage(intl, messageKey, 'Inventory type is required.', [])}
      </Alert>;
    }
    return result;
  }, [intl, handleAlertClose, messageKey, openAlert]);

  return (
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <Grid container direction="row" justifyContent={'space-between'}>
        <TypographyFHG id="reports.dispensed.title" color="textSecondary" sx={{my: 0, mr: 0, ml: 1}} variant="h5" />
      </Grid>
      {getAlert()}
      <Grid sx={{mt: 1, mx: 0, mb: 0}}>
        <TableFHG name="DispensedReport" columns={columns} data={rows}
                  allowSearch displaySearch={false} fullWidth stickyHeader
                  emptyTableMessageKey={'dispensed.report.noRecord.message'}
        >
          <Grid container direction="row" justifyContent={'space-between'}>
            <Grid container direction="row">
              <Grid sx={{py: 0, pr: 0, pl: 1, width: '160px'}}>
                <KeyboardDatePickerFHG
                  key={'startDate'}
                  name={'startDate'}
                  label={'Start Date'}
                  onChange={handleStartDateChange}
                  value={startDate}
                />
              </Grid>
              <Grid sx={{py: 0, pr: 0, pl: 1, width: '160px'}}>
                <KeyboardDatePickerFHG
                  key={'endDate'}
                  name={'endDate'}
                  label={'End Date'}
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </Grid>
              <Grid container direction="column" sx={{mt: -2.5, mr: 1, mb: 0, ml: 2, width: '174px'}}>
                <InputLabel id="typeDropDown" sx={{float: 'left', mt: 0, mx: 1}}>Inventory Type</InputLabel>
                <Select
                  value={typeId}
                  onChange={handleInventoryTypeChange}
                  labelId={'typeDropDown'}
                  size="small"
                >
                  {inventoryTypes.map((option, i) => (
                    <MenuItem key={i} value={`${option.inventoryTypeID}`}>
                      <Chip size='small' label={option.name} style={{
                        fontSize: CARD_SM_FONT,
                        margin: 'auto',
                        backgroundColor: `${getChipBgColor(option.colorCode)}`,
                        color: `${hexToRgb(option.colorCode)}`,
                        width: '130px'
                      }}/>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid sx={{mt: 0}}>
                <ButtonFHG id="search.button" labelKey={'search.button'} color="primary"
                           sx={{m: 1, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '1rem'}}
                           size="small" startIcon={(<SearchIcon />)} onClick={handleSearch} variant="outlined" />
              </Grid>

            </Grid>
          </Grid>
        </TableFHG>
      </Grid>
    </Grid>);
}
