import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import assign from 'lodash/assign';
import orderBy from 'lodash/orderBy';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Grid} from '@mui/material';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import ProgressButton from '../../fhg/components/ProgressButton';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import {CVX_STATUS_QUERY, CVX_UPDATE, getCvxRefetchQueries} from '../../data/QueriesGL';
import Loading from "../../fhg/components/Loading";
import {EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from "../../Constants";

export default function CvxEdit(props) {
  const {id: cvxId, cvx, onClose} = props;
  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [cvxStatusData] = useQueryFHG(CVX_STATUS_QUERY, {variables: {}}, 'cvxStatus.type');
  const [cvxUpdate, {data, error}] = useMutationFHG(CVX_UPDATE);
  const statuses = useMemo(() => orderBy(cvxStatusData?.cvxStatus, row => row?.title?.toLowerCase()), [cvxStatusData]);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData({}, ['cvxId', 'vaccineStatus']);

  useEffect(() => {
    if (cvx) {
      setDefaultValues(assign({}, cvx, {id: cvxId}));
    }
  }, [cvx, cvxId, setDefaultValues]);

  const handleClose = useCallback(() => {
    resetValues();
    setInputValue('');
    if (onClose) {
      onClose();
    }
  }, [onClose, resetValues]);

  useEffect(() => {
    if (data && !error) {
      handleClose();
    }
  }, [data, error, handleClose]);

  useEffect(() => {
    if (error) {
      setMessageText(error.message);
      setOpenAlert(true);
    }
  }, [error]);

  const getCvxStatus = useCallback(() => {
    let result = null;
    if (statuses?.length > 0) {
      if (editValues?.vaccineStatus) {
        result = find(statuses, status => status.id === editValues.vaccineStatus);
      } else {
        result = find(statuses, status => status.id === cvx?.vaccineStatus);
      }
    }
    return result;
  }, [cvx, editValues, statuses]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        let currentItem = {
          ...defaultValues,
          ...editValues
        };

        setIsSaving(true);

        let variables = assign({}, currentItem);
        currentItem['__typename'] = 'Cvx';

        await cvxUpdate ({
          variables: variables,
          refetchQueries: getCvxRefetchQueries()
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [cvxUpdate, defaultValues, editValues, handleClose, isChanged, setIsChanged]);

  const handleCvxStatusChange = (event, value) => {
    setEditValues(editValues => ({...editValues, vaccineStatus: value?.id}));
    setIsChanged(true);
  }

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  if (statuses && statuses.length > 0 && cvx && Object.keys(cvx).length > 0) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'cvx.edit.title'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
            <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
              {getAlert()}
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="cvxCode"
                  name="cvxCode"
                  labelKey="cvx.cvxCode"
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={editValues.cvxCode ? editValues.cvxCode : defaultValues.cvxCode}
                  required
                />
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="shortDescription"
                  name="shortDescription"
                  labelKey="cvx.shortDescription"
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={editValues.shortDescription ? editValues.shortDescription : defaultValues.shortDescription}
                  multiline
                  rows="2"
                  required
                />
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key={'description' + defaultValues.id}
                  name="description"
                  labelKey="cvx.full.name"
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={editValues.description ? editValues.description : defaultValues.description}
                  multiline
                  rows="3"
                />
              </Grid>
              <Grid sx={{my: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <Autocomplete
                  key="cvxStatus"
                  fullWidth
                  getOptionLabel={(option) => option?.title}
                  options={statuses}
                  onChange={handleCvxStatusChange}
                  onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  isOptionEqualToValue={(option, value) => option?.title === value?.title}
                  inputValue={inputValue || ''}
                  value={getCvxStatus()}
                  renderInput={(params) => (
                    <TextField{...params} label='Status' placeholder='Select status' size="small" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="notes"
                  name="notes"
                  labelKey="cvx.notes"
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={editValues.notes ? editValues.notes : defaultValues.notes}
                  multiline
                  rows="4"
                />
              </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}} >
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'} disabled={isSaving}
                       onClick={handleClose} sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
  } else {
    return (<Loading />);
  }
}

CvxEdit.propTypes = {
  id: PropTypes.any,
  cvx: PropTypes.object,
  onClose: PropTypes.func
}
