import React from 'react'
import PropTypes from 'prop-types'
import SearchFilter from './SearchFilter';
import Toolbar from '@mui/material/Toolbar'
import Typography from '../Typography';

/**
 * Component to show the search (filter) for the table.
 *
 * Reviewed: 4/14/20
 *
 * @param (optional) titleKey The message key for the table.
 * @param setGlobalFilter The global filter callback to change the filtered rows in the table.
 * @param globalFilter The current global filter.
 * @param (optional)children The children to be placed after the search.
 * @return {*}
 * @constructor
 */
export default function TableSearchToolbar ({titleKey, setGlobalFilter, globalFilter, children, displaySearch}) {
   return (
      <Toolbar sx={{py: 1, width: '100%'}}>
         {titleKey && (
            <Typography id={titleKey} variant="h5" />
         )}
         {children}
         {displaySearch && (<SearchFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter}/>)}
      </Toolbar>
   )
};

TableSearchToolbar.propTypes = {
   displaySearch: PropTypes.bool,
   setGlobalFilter: PropTypes.func.isRequired,
   globalFilter: PropTypes.string,
   titleKey: PropTypes.string
};
