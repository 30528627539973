import React, {useState, useRef, Fragment} from 'react'
import {Grid} from '@mui/material';
import {lighten} from '@mui/material/styles';
import {Popover} from '@mui/material';
import {selectedCellState} from './TableFHG';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TypographyFHG from '../Typography';
import {useRecoilState} from 'recoil';
import useTheme from '@mui/material/styles/useTheme';
import {BGD_COLOR, PRIMARY_COLOR, PRIMARY_DARK_COLOR, TABLE_HEIGHT} from '../../../Constants';

const MyTableHead = styled(TableHead)({
   '&.MuiTableSortLabel-root': {
      color: 'white',
   },
   '&.MuiTableSortLabel-root:hover': {
      color: 'white',
   },
   '&.Mui-active': {
      color: 'white',
   },
   '& .MuiTableSortLabel-icon': {
      color: 'white !important',
   },
   '& th': {
      backgroundColor: `${PRIMARY_COLOR} !important`,
      color: `${BGD_COLOR} !important`,
      padding: '4px 0 4px 8px !important'
   },
});

/**
 * The table component that handles searching (filtering) and selection.
 *
 * Reviewed:
 *
 * @param titleKey The message key for the title.
 * @param onSelect Callback when an item is selected.
 * @param selectId The current selection item ID.
 * @param stickyHeader Indicates if the header of the table is sticky.
 * @return {*}
 * @constructor
 */
export default function TableContainerFHG({
   name, rows, prepareRow, headerGroups, footerGroups, getTableProps, onSelect, selectId, allowCellSelection = false,
   stickyHeader = true, classes: classesProp, emptyTableMessageKey, hasFooter, hasShadow
}) {
   const theme = useTheme();
   const [isNoteOpen, setIsNoteOpen] = useState(false);
   const [note, setNote] = useState('');
   const [selectedIndex, setSelectedIndex] = useState(-1);
   const [cellSelected, setCellSelected] = useRecoilState(selectedCellState);
   const selectRef = useRef();
   const selectCellRef = useRef();

   const styles = {
      headerStyle: {
         backgroundColor: `${PRIMARY_COLOR} !important`,
         color: `${BGD_COLOR} !important`,
      },
      rowStyle: {
         '& tr:nthOfType(odd)': {
            backgroundColor: lighten(PRIMARY_DARK_COLOR, 0.6),
         },
      },
      tableHeadStyle: {
         margin: 0
      }
   };

   /**
    * Select the row on click.
    * @param row The row clicked to be selected.
    * @return {function(...[*]=)}
    */
   const handleRowClick = (row) => () => {
      if (!allowCellSelection) {
         setSelectedIndex(row.index);
         onSelect && onSelect(row.original);
      }
   };

   const handleSelectCell = (cellKey, rowIndex, columnIndex, cell) => () => {
      if (allowCellSelection) {
         setCellSelected({tableName: name, rowIndex, columnIndex});

         onSelect?.(undefined, cellKey, rowIndex, columnIndex, cell);
      }
   };
   const handleNoteClose = () => {
      setIsNoteOpen(isNoteOpen => !isNoteOpen);
   };

   const handleNoteClick = (value) => {
      setNote(value);
      setIsNoteOpen(isNoteOpen => !isNoteOpen);
   };

   return (
     <>
       {isNoteOpen && (
          <Popover open={isNoteOpen}
            onClose={handleNoteClose}
            anchorOrigin={{vertical: 'center', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center',}}
            PaperProps={{style: {padding: '6px 8px', width: '400px'}}}
          >
             <TypographyFHG id="inventory.notes.title" sx={{borderBottom: '1px solid gray', pt: 1, pr: 1, pb: 0, pl: 1}} variant="h6" />
             <TypographyFHG sx={{py: 1, px: 2}} variant="body1">{note}</TypographyFHG>
          </Popover>
       )}
       <TableContainer style={{
         width: !hasShadow ? 'calc(100% + 1px)' : 'calc(100% - 3px)',
         boxShadow: hasShadow ? theme.shadows[2] : undefined,
         marginLeft: hasShadow ? 3 : undefined,
         backgroundColor: theme.palette.background.paper,
       }}
         sx={{height: TABLE_HEIGHT, overflowY: 'auto'}}>
         <Table {...getTableProps()} stickyHeader={stickyHeader}>
            <MyTableHead>
               {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()} >
                     {headerGroup.headers.map((column, index) => (
                        <TableCell
                           {...column.getHeaderProps(column.getSortByToggleProps())}
                           size='small'
                           style={{
                              cursor: 'pointer',
                              borderLeft: column.id === 'payback' ? 'solid thick gray' : undefined,
                              borderRight: hasShadow && (!column.depth || (index % 2) === 0) ?
                                 `2px solid ${theme.palette.divider}` : `1px solid ${theme.palette.divider}`,
                           }}
                        >
                           <Grid container direction="row" justifyContent="flex-start" wrap="nowrap">
                              {column.render('Header')}
                              {<TableSortLabel
                                 active={column.isSorted}
                                 // react-table has a unsorted state which is not treated here
                                 direction={column.isSortedDesc ? 'desc' : 'asc'}
                                 sx={{color: '#000', ml: 1}}
                              />}
                           </Grid>
                        </TableCell>
                     ))}
                  </TableRow>
               ))}
            </MyTableHead>
            <TableBody>
               {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                     <TableRow {...row.getRowProps()} onClick={handleRowClick(row)}
                               style={styles.rowStyle}
                               hover={!allowCellSelection}
                               selected={!allowCellSelection && (i === selectedIndex)}
                               ref={i === selectedIndex ? selectRef : undefined}>
                        {row.cells.map((cell, index) => {
                           const cellProps = cell.getCellProps();
                           const isSelected = cellSelected?.tableName === name && cellSelected?.rowIndex === i && cellSelected?.columnIndex === cell.column?.___index;
                           const cellComp = (cell.column.id === 'notes' || cell.column.id === 'paybackNotes') ? <div style={{cursor: 'pointer'}} onClick={() => handleNoteClick(cell.value)}>{cell.render('Cell')}</div> : cell.render('Cell');
                           return (<TableCell {...cellProps} {...cell.column.tableCellProps}
                                         ref={isSelected ? selectCellRef : undefined}
                                         style={{
                                            // backgroundColor: `${isSelected}` ? SELECTED_COLOR : "unset",
                                            borderLeft: cell.column.id === 'payback' ? 'solid thick gray' : undefined,
                                            borderRight: hasShadow && (!cell.column.depth || (index % 2) === 0) ?
                                               `2px solid darkgray` :
                                               `1px solid darkgray`,
                                            fontWeight: cell.column.bold ? 'bold' : undefined,
                                            fontSize: 13,
                                            minWidth: cell.column.minWidth || undefined,
                                            padding: '8px 8px 4px',
                                         }}
                                         onClick={handleSelectCell(cellProps.key, i, cell.column?.___index, cell)}
                             >
                               {cellComp}
                             </TableCell>)
                        })}
                     </TableRow>
                  )
              })}
            </TableBody>

            {hasFooter && (
               <TableFooter>
                  {footerGroups?.map((group, index) => (
                     <Fragment key={'frag ' + index}>
                        <TableRow {...group.getFooterGroupProps()} key={'footer row ' + index + ' ' + group.getFooterGroupProps()?.key}>
                           {group.headers.map(column => {
                              if (column.Footer) {
                                 return (
                                    <TableCell {...column.getFooterProps()}  {...column.tableCellProps}
                                      style={{
                                         whiteSpace: 'nowrap',
                                         padding: '8px 8px 4px',
                                         fontSize: 14,
                                         borderRight: '2px solid rgba(0, 0, 0, 0.12)',
                                         fontWeight: column.bold ? 'bold' : undefined
                                      }}
                                    >
                                       {column.render('Footer')}
                                    </TableCell>
                                 )
                              } else {
                                 return null;
                              }
                           })}
                        </TableRow>
                        <TableRow {...group.getFooterGroupProps()}>
                           {group.headers.map(column => {
                              if (column.Footer2) {
                                 return (
                                    <TableCell {...column.getFooterProps()}  {...column.tableCellProps}
                                               style={{borderRight: '2px solid rgba(0, 0, 0, 0.12)'}}>
                                       {column.render('Footer2')}
                                    </TableCell>
                                 )
                              } else {
                                 return null;
                              }
                           })}
                        </TableRow>
                     </Fragment>
                  ))}
               </TableFooter>
            )}
         </Table>
         {rows?.length <= 0 && (
            <Grid container justifyContent={'center'} style={{margin: theme.spacing(2)}}>
               <TypographyFHG id={emptyTableMessageKey}/>
            </Grid>
         )}
       </TableContainer>
     </>
   )
}
