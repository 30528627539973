import React from 'react';
import ReactDOM from 'react-dom/client';
import {Auth} from 'aws-amplify';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot, } from 'recoil';
import { InMemoryCache } from '@apollo/client';
import { HttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { ApolloClient } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import QueueLink from 'apollo-link-queue';
import reportWebVitals from './reportWebVitals';
import App from './App';
import Loading from './fhg/components/Loading';
import SuspenseLx from './fhg/components/SuspenseLx';
import {ENDPOINT} from './Constants';
import './index.css';

/*
Auth: {
         // REQUIRED - Amazon Cognito Identity Pool ID
         identityPoolId: 'us-east-2:fc2ef06e-cf67-4b73-9da8-090e59828afe',
         // REQUIRED - Amazon Cognito Region
         region: 'us-east-2',
         // OPTIONAL - Amazon Cognito User Pool ID
         userPoolId: 'us-east-2_juU9d1lC5',
         // OPTIONAL - Amazon Cognito Web Client ID
         userPoolWebClientId: '730rts1ddvhja0k0nv09hvthm2',
},
 */

// const RecoilRoot = lazy(() => import('./packageExports/RecoilRoot'));
// const BrowserRouter = lazy(() => import('./packageExports/BrowserRouter'));

const getAccessToken = () => {
   return Auth.currentSession()
     .then((session) => {
        return session.accessToken.jwtToken;
     })
     .catch((error) => {
        console.log(error);
        throw error;
     });
};

/**
 * Header for all graphql calls to add the access token.
 */
const authLink = setContext(async (_, {headers}) => {
   let token = await getAccessToken();

   return {
      headers: {
         ...headers,
         // eslint-disable-next-line
         authorization: `Bearer ${token}` || '',
      },
   };
});

const queueLink = new QueueLink();
window.addEventListener('offline', () => queueLink.close());
window.addEventListener('online', () => queueLink.open());

const client = new ApolloClient({
   link: ApolloLink.from([
      new RetryLink(), queueLink, authLink, new HttpLink({uri: ENDPOINT})]),
   cache: new InMemoryCache(),
});

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
// eslint-disable-next-line
   String.prototype.format = function (values) {
      return this.replace(/{(\w+)}/g, function (match, key) {
         return typeof values[key] !== 'undefined' ? values[key] : match;
      });
   };
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <SuspenseLx fallback={<Loading isLoading />}>
     <RecoilRoot>
        <ApolloProvider client={client}>
           <BrowserRouter>
             <App />
           </BrowserRouter>
        </ApolloProvider>
     </RecoilRoot>
  </SuspenseLx>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.onunload = () => {
   // Clear the local storage
   localStorage.clear();
   sessionStorage.clear();
}
