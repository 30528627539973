import {useLazyQuery} from '@apollo/client';
import uniqueId from 'lodash/uniqueId';
import {useRef} from 'react';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../utils/Utils';
import useEffect from '../useEffect';
import useMessage from '../useMessage';
import useProgress from '../useProgress';

export default function useLazyQueryFHG(query, options, typeKey, showLoading) {
   const theUniqueId = useRef(uniqueId()).current;
   const [/*Unused*/, setProgress] = useProgress(theUniqueId);
   const type = useMessage(typeKey, 'Unknown');

   const setErrorState = useSetRecoilState(errorState);

   const [queryFunction, {loading, error, data}] = useLazyQuery(query, options);

   useEffect(() => {
      return () => {
         setProgress(false);
      }
   }, [setProgress]);

   useEffect(() => {
      if (showLoading) {
         setProgress(loading);
      }
   }, [loading, setProgress, showLoading]);

   useEffect(() => {
      if (error) {
         console.log('Error type', typeKey);
         console.log(error, error.stackTrace);
         setErrorState({error, errorKey: 'load.error', values: {type, message: error.message}});
      }
   }, [error, setErrorState, typeKey, type]);

   return [queryFunction, {loading, error, data}];
}
