import React, {useCallback} from 'react';
import {Card, CardContent, Chip} from '@mui/material';
import * as dayjs from 'dayjs';
import {getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import TypographyFHG from '../../fhg/components/Typography';
import {
  CARD_WIDTH, CARD_SM_FONT,
  DATE_FORMAT_KEYBOARD
} from '../../Constants';

export default function InventoryCardBasic(props) {
  const {inventory} = props;

  const getCvx1 = useCallback(() => {
    return inventory?.cvxName ? `${inventory?.cvxName} (${inventory?.cvxCode})`.substring(0, 50) : '';
  }, [inventory]);

  const getCvx2 = useCallback(() => {
    return inventory?.cvxName ?
      <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">
        {`${inventory?.cvxName} (${inventory?.cvxCode})`.substring(50, 102)}
      </TypographyFHG> : null;
  }, [inventory]);

  const getCvx3 = useCallback(() => {
    return inventory?.cvxName ?
      <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">
        {`${inventory?.cvxName} (${inventory?.cvxCode})`.substring(102)}
      </TypographyFHG> : null;
  }, [inventory]);

  const getTypeChip = useCallback((typeId) => {
    return (<Chip size='small' label={inventory?.type}
                  style={{margin: 'auto', backgroundColor: `${getChipBgColor(inventory?.colorCode)}`,
                    color: `${hexToRgb(inventory?.colorCode)}`}} />);
  }, [inventory]);

  return (
    <>
      <Card sx={{border: 'thin solid gray', mb: 1, fontSize: CARD_SM_FONT, width: CARD_WIDTH}}>
        <CardContent sx={{pb: 1.5}}>
          <Grid container direction="row" justifyContent="space-between">
            <TypographyFHG color="textPrimary" sx={{fontWeight: 'bold', fontSize: CARD_SM_FONT, my: 0, mr: 1, ml: 0}} variant="body1">{inventory?.mfgBrand}</TypographyFHG>
            <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body1">
              {getTypeChip(inventory?.typeId)}
            </TypographyFHG>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <TypographyFHG color="textPrimary" sx={{fontStyle: 'italic', m: 0, fontSize: CARD_SM_FONT}} variant="body1">{`(${inventory?.mfgGeneric})`}</TypographyFHG>
            <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{inventory?.clinicBarcode}</TypographyFHG>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{`[Lot: ${inventory?.lot}]`}</TypographyFHG>
            </Grid>
            <Grid item container direction="row">
              <TypographyFHG id="inventory.card.exp.label" color="textPrimary" sx={{fontWeight: 'bold', fontSize: CARD_SM_FONT, my: 0, mr: 1, ml: 0}} variant="body2" />
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{dayjs(inventory?.expDate)?.format(DATE_FORMAT_KEYBOARD)}</TypographyFHG>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid container direction="row">
              <TypographyFHG id="inventory.card.mvx.label" color="textPrimary" sx={{fontWeight: 'bold', fontSize: CARD_SM_FONT, my: 0, mr: 1, ml: 0}} variant="body2" />
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{`${inventory?.manufacturerCode}`}</TypographyFHG>
            </Grid>
            <Grid container direction="row">
              <TypographyFHG id="inventory.card.available.label" color="textPrimary" sx={{fontWeight: 'bold', fontSize: CARD_SM_FONT, my: 0, mr: 1, ml: 0}} variant="body2" />
              <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{inventory?.doses}</TypographyFHG>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start">
            <TypographyFHG id="inventory.card.mfg.label" color="textPrimary" sx={{fontWeight: 'bold', fontSize: CARD_SM_FONT, my: 0, mr: 1, ml: 0}} variant="body2" />
            <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{inventory?.mfgBarcode}</TypographyFHG>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start">
            <TypographyFHG id="inventory.card.cvx.label" color="textPrimary" sx={{fontWeight: 'bold', fontSize: CARD_SM_FONT, my: 0, mr: 1, ml: 0}} variant="body2" />
            <TypographyFHG color="textPrimary" sx={{m: 0, fontSize: CARD_SM_FONT}} variant="body2">{getCvx1()}</TypographyFHG>
            {getCvx2()}
            {getCvx3()}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

InventoryCardBasic.propTypes = {
  inventory: PropTypes.object
}
