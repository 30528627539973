import React, {useCallback, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import {Chip} from '@mui/material';
import * as dayjs from 'dayjs';
import EditDrawer from '../EditDrawer';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import find from 'lodash/find';
import {getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryAdd from './InventoryAdd';
import InventoryAdjust from './InventoryAdjust';
import InventoryEdit from './InventoryEdit';
import InventoryPatients from './InventoryPatients';
import InventoryTransfer from './InventoryTransfer';
import Loading from '../../fhg/components/Loading';
import NotesIcon from '@mui/icons-material/AssignmentOutlined';
import orderBy from 'lodash/orderBy';
import TypographyFHG from '../../fhg/components/Typography';
import {useNavigate} from 'react-router-dom';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getInventoryListRefetchQueries,
  INVENTORY_DELETE,
  INVENTORY_QUERY,
  V_INVENTORY_LIST_QUERY
} from '../../data/QueriesGL';
import {
  ADMIN_CLINICS_PATH,
  BGD_COLOR,
  DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD,
  EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT
} from '../../Constants';

export default function Inventory() {
  const navigate = useNavigate();
  const [drawerAction, setDrawerAction] = useState('');
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [addAnother, setAddAnother] = useState(false);
  const [staticValues, setStaticValues] = useState({});
  const [loading, setLoading] = useState(true);
  let endDate = dayjs().format(DATE_DB_FORMAT);
  let options = {
    variables: {
      startDate: dayjs(endDate).subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT),
      endDate: endDate
    }
  };
  const [inventoryList] = useQueryFHG(V_INVENTORY_LIST_QUERY, options, 'inventory.type', loading);
  const [inventoryDelete, {data, error}] = useMutationFHG(INVENTORY_DELETE);
  const [{isAdmin}] = useRecoilState(userStatus);

  useMemo(() => {
    const inventory = inventoryList?.inventories || [];
    if (inventory.length > 0) {
      const sorted = orderBy(inventory, [row => row.id], ['desc']);
      setInventoryData(sorted);
    }

    if (inventoryList?.inventories) {
      setLoading(false);
    }

  }, [inventoryList]);

  const columns = useMemo(() => {
    return [
      {
        id: 0,
        Header: <TypographyFHG id={'inventory.addedDate.column'}/>,
        accessor: 'addedDate',
        Cell: ({value}) => (
          <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 1,
        Header: <TypographyFHG id={'inventory.mfgId.column'}/>,
        accessor: 'mfgBrand'
      },
      {
        id: 2,
        Header: <TypographyFHG id={'inventory.mfgGeneric.column'}/>,
        accessor: 'mfgGeneric',
        width: 100
      },
      {
        id: 3,
        Header: <TypographyFHG id={'inventory.clinicBarcode.column'}/>,
        accessor: 'clinicBarcode',
        width: 75
      },
      {
        id: 4,
        Header: <TypographyFHG id={'inventory.lot.column'}/>,
        accessor: 'lot'
      },
      {
        id: 5,
        Header: <TypographyFHG id={'inventory.expDate.column'}/>,
        accessor: 'expDate',
        Cell: ({value}) => (
          <span>{dayjs(value ? value : '').format(DATE_FORMAT_KEYBOARD)}</span>
        )
      },
      {
        id: 6,
        Header: <TypographyFHG id={'inventory.doses.column'}/>,
        accessor: 'doses',
        Cell: ({value}) => (
            <span style={{display: "block", paddingRight: 6, textAlign: "right", width: '120px'}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 7,
        Header: <TypographyFHG id={'inventory.type.column'}/>,
        accessor: 'type',
        Cell: ({row, value}) => {
          return (<Chip size='small' label={value} style={{
            flex: 'flex',
            margin: 'auto',
            backgroundColor: `${getChipBgColor(row.original?.colorCode)}`,
            color: `${hexToRgb(row.original?.colorCode)}`}} />);
        }
      },
      {
        id: 8,
        Header: <TypographyFHG id={'inventory.notes.column'}/>,
        accessor: 'notes',
        Cell: ({value}) => (value ? <NotesIcon /> : null)
      }
    ];
  }, []);

  useMemo(() => {
    if (addAnother) {
      setAddAnother(false);
      setSelectedId('new');
      setDrawerAction('add');
      setTimeout(() => setOpenDetailDrawer(true), 300);
    }
  }, [addAnother]);

  const getFlyoutContent = () => {
    let content;
    let row = {};

    switch (drawerAction) {
      case 'edit':
        row = find(inventoryList?.inventories, x => x.id === selectedId);
        content = (<InventoryEdit inventory={row} onClose={handleDetailClose} />);
        break;
      case 'adjust':
        row = find(inventoryList?.inventories, x => x.id === selectedId);
        content = (<InventoryAdjust inventory={row} onClose={handleDetailClose} />);
        break;
      case 'barcode':
        content = (<InventoryPatients id={selectedId} onClose={handleDetailClose} />);
        break;
      case 'transfer':
        content = (<InventoryTransfer id={selectedId} onClose={handleDetailClose} />);
        break;
      default:
        content = (<InventoryAdd inventories={inventoryList?.inventories ? inventoryList.inventories : []} onClose={handleDetailClose} onAddAnother={handleAddAnother} staticValues={staticValues} />);
    }
    return content;
  };

  const handleEdit = useCallback(() => {
    setDrawerAction('edit');
    setOpenDetailDrawer(true);
  }, []);

  const handleAdd = useCallback(() => {
    setDrawerAction('add');
    setOpenDetailDrawer(true)
  }, []);

  const handleAddAnother = useCallback((priorValues) => {
    setOpenDetailDrawer(false);
    setStaticValues(priorValues);
    setAddAnother(true);
  }, []);

  const handleAdjust = useCallback(() => {
    setDrawerAction('adjust');
    setOpenDetailDrawer(true);
  }, []);

  const handleBarcodeLink = () => {
    setDrawerAction('barcode');
    setOpenDetailDrawer(true);
  };

  const handleDelete = useCallback(() => {
    const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
    const endDate = dayjs().format(DATE_DB_FORMAT);

    // Delete the inventory record.
    setOpenAlert(true);
    inventoryDelete({
      variables: {inventoryId: selectedId},
      update: cacheDelete([
        {query: V_INVENTORY_LIST_QUERY, variables: {startDate, endDate}, queryPath: 'inventories'},
        {query: INVENTORY_QUERY, variables: {id: selectedId}, queryPath: 'inventory'}
      ], selectedId, 'inventory'),
      refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
    });

  }, [inventoryDelete, selectedId]);

  const handleTransfer = () => {
    setDrawerAction('transfer');
    setOpenDetailDrawer(true);
  };

  const handleTransferLocations = () => {
    const location = {
      pathname: ADMIN_CLINICS_PATH
    };
    navigate(location);
  };

  const handleRowSelect = (row) => {
    const rowData = row ? row.original : row;
    setSelectedId(rowData.id ? Number(rowData.id) : null);
  };

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, []);

  const handleSearch = (typeId) => {
    const inventory = inventoryList?.inventories || [];
    if (inventory.length > 0) {
      const sorted = orderBy(inventory, [row => row.id], ['desc']);
      let filterData = sorted;
      if (typeId !== '0') {
        filterData = sorted.filter(x => x.typeId === typeId);
      }
      setInventoryData(filterData);
    }
  };

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setInventoryData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const getAlert = useCallback(() => {
    let result = undefined;
    if (data && openAlert) {
      if (data.numberDeleted === 1) {
        result = <Alert severity="success"  onClose={handleAlertClose}>
          Successfully deleted inventory record.</Alert>;
      } else {
        result = <Alert severity="error">{`An error occurred: ${error}`}</Alert>;
      }
    }
    return result;
  }, [data, error, openAlert, handleAlertClose]);

  return (
    <>
    <Loading isLoading={loading}/>
    <Grid container direction={'column'}>
      <TypographyFHG id="inventory.title" color="textSecondary"  sx={{mt: 0, mr: 0, mb: 1, ml: 2}} variant="h5" />
      <Grid>
        {getAlert()}
        <Grid container direction="column" sx={{
          height: 'calc(100% - 60)', mb: 1, overflowY: 'auto',
          width: 'calc(100% - 17)'
        }}>
          <Grid container direction="column">
            <EnhancedTable
              columns={columns}
              data={inventoryData}
              setData={setInventoryData}
              scrollbarSize={17}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              onAdd={handleAdd}
              onAdjust={handleAdjust}
              onBarcodeClick={handleBarcodeLink}
              onDelete={handleDelete}
              onTransfer={handleTransfer}
              onTransferLocations={handleTransferLocations}
              onRowSelect={handleRowSelect}
              onSearch={handleSearch}
              onEdit={handleEdit}
              addButtonKey="inventory.add.button"
              adjustButtonKey="inventory.adjust.button"
              transferButtonKey="inventory.transfer.button"
              transferLocationsButtonKey="inventory.transfer.locations.button"
              editButtonKey="inventory.edit.button"
              deleteButtonKey="inventory.delete.button"
              barcodeColumnIndex={3}
              notesColumnIndex={8}
              notesTitleKey="inventory.notes.title"
              isAdmin={isAdmin}
              displayInventoryTypes={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          {getFlyoutContent()}
        </Grid>
      </EditDrawer>
    </Grid>
    </>
  );
}
