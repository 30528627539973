import React, {useCallback, useEffect, useState} from 'react';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import * as dayjs from 'dayjs';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import usePromptFHG from '../../fhg/hooks/usePromptFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getManufacturersRefetchQueries,
  MANUFACTURER_UPDATE,
} from '../../data/QueriesGL';
import {
  ACTIVE,
  DATE_DB_FORMAT,
  DATE_PICKER_WIDTH,
  EDIT_DRAWER_ITEM_WIDTH,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR
} from '../../Constants';

ManufacturerEdit.propTypes = {
  id: PropTypes.any,
  data: PropTypes.array,
  onClose: PropTypes.func
}

export default function ManufacturerEdit(props) {
  const {data: manufacturers, id: manufacturerId} = props;
  const [{userId}] = useRecoilState(userStatus);
  const [dateLastUpdated, setDateLastUpdated] = useState(null);
  const [dateEntered, setDateEntered] = useState(null);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [notes, setNotes] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [manufacturerUpdate] = useMutationFHG(MANUFACTURER_UPDATE);

  useEffect(() => {
    if (manufacturers && manufacturers.length > 0) {
      let defaultMan = find(manufacturers, x => x.id === manufacturerId);
      setIsActive(defaultMan?.status === ACTIVE)
      setName(defaultMan?.name);
      setCode(defaultMan?.code);
      setNotes(defaultMan?.notes);
      setDateLastUpdated(defaultMan?.dateLastUpdated);
      setDateEntered(defaultMan?.dateEntered);
    }
  }, [manufacturerId, manufacturers]);

  const resetValues = useCallback(() => {
    setName('');
    setCode('');
    setNotes('');
    setIsActive(false);
    setIsChanged(false);
    setDateLastUpdated(null);
  }, [setIsChanged]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        const date = dateLastUpdated ? dayjs(dateLastUpdated).format(DATE_DB_FORMAT) : dayjs().format(DATE_DB_FORMAT);
        let currentItem = {
          id: manufacturerId,
          manufacturerId: manufacturerId,
          name: name,
          code: code,
          status: isActive ? 1 : 2,
          notes: notes,
          lastUpdatedBy: userId,
          dateLastUpdated: date,
          __typename: 'Manufacturer'
        };

        setIsSaving(true);

        await manufacturerUpdate({
          variables: currentItem,
          refetchQueries: getManufacturersRefetchQueries()
        });
        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [code, dateLastUpdated, handleClose, isActive, isChanged, manufacturerId, manufacturerUpdate, name, notes, setIsChanged, userId]);

  const handleDateChange = (date) => {
    setDateLastUpdated(date);
    setIsChanged(true);
  };

  const handleCheckChange = useCallback((e)=> {
    setIsActive(e.target.checked);
    setIsChanged(true);
  },[setIsActive, setIsChanged]);

  const handleCodeChange = useCallback((e)=> {
    setCode(e.target.value)
    setIsChanged(true);
  },[setIsChanged]);

  const handleNameChange = useCallback((e)=> {
    setName(e.target.value)
    setIsChanged(true);
  },[setIsChanged]);

  const handleNotesChange = useCallback((e)=> {
    setNotes(e.target.value)
    setIsChanged(true);
  },[setIsChanged]);

  usePromptFHG({when: isChanged});

   return (
     <Form onSubmit={handleSubmit}>
       <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
         <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG id="manufacturer.edit.title" color="textSecondary" variant="h5" gutterBottom />
         </Grid>
         <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
           <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
             <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
               <TextFieldCustom
                  key="name"
                  name="name"
                  autoFocus
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  labelKey="manufacturer.name.label"
                  onChange={handleNameChange}
                  value={name}
                  required
               />
             </Grid>
             <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
               <TextFieldCustom
                  key="code"
                  name="code"
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  labelKey="manufacturer.code.label"
                  onChange={handleCodeChange}
                  value={code}
                  required
               />
             </Grid>
             <Grid sx={{width: `${DATE_PICKER_WIDTH}px`}}>
               <TextFieldCustom
                  key="dateEntered"
                  name="dateEntered"
                  labelKey="manufacturer.dateEntered.label"
                  value={dayjs(dateEntered).format("L")}
                  disabled
               />
             </Grid>
             <Grid container direction="column" sx={{pt: 0.75, width: DATE_PICKER_WIDTH}}>
              <KeyboardDatePickerFHG
                key={'dateLastUpdated1'}
                name={'dateLastUpdated'}
                labelKey={'manufacturer.dateLastUpdated.label'}
                onChange={handleDateChange}
                value={dateLastUpdated}
              />
             </Grid>
             <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="notes"
                name="notes"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                labelKey="manufacturer.notes.label"
                multiline
                maxRows={4}
                onChange={handleNotesChange}
                value={notes}
              />
             </Grid>
             <CheckboxFHG
              key="isActive"
              name="isActive"
              onChange={handleCheckChange}
              labelKey="manufacturer.status.label"
              checked={isActive}
              disabled={isSaving}
              marginTop={0}
              fullWidth
            />
         </Grid>
       </Grid>
       <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'}}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
         </Grid>
       </Grid>
     </Form>
  );
}
