import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip} from '@mui/material';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import * as dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {Dialog, InputLabel, Link, TextField} from '@mui/material';
import EditDrawer from '../EditDrawer';
import {getPatientAgeString, getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryCard from '../inventory/InventoryCard';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {lighten} from '@mui/material/styles';
import Loading from '../../fhg/components/Loading';
import MenuItem from '@mui/material/MenuItem';
import PatientRecordEdit from './PatientRecordEdit';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import {
  INVENTORY_TYPES_QUERY,
  PATIENT_RECORDS_QUERY,
  PATIENT_RECORD_DELETE,
  getPatientRefetchQueries
} from '../../data/QueriesGL';
import {
  BGD_COLOR,
  CARD_SM_FONT, DATE_DB_FORMAT,
  EDIT_DRAWER_WIDTH, ERROR_COLOR,
  PRIMARY_DARK_COLOR,
  SUCCESSFUL, TABLE_HEIGHT
} from '../../Constants';

export default function PatientRecords() {
  let defaultDate = new Date();
  const start = localStorage.getItem("PR_START_DATE")
    ? dayjs(localStorage.getItem("PR_START_DATE")) : dayjs(defaultDate);
  const end = localStorage.getItem("PR_END_DATE")
    ? dayjs(localStorage.getItem("PR_END_DATE")) : dayjs(defaultDate);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [typeId, setTypeId] = useState('0');
  const [openAlert, setOpenAlert] = useState(false);
  const [patientDelete, {data, error}] = useMutationFHG(PATIENT_RECORD_DELETE);
  const [nameSearchLast, setNameSearchLast] = useState(undefined);
  const [nameSearchFirst, setNameSearchFirst] = useState(undefined);
  const [nameSearch, setNameSearch] = useState(undefined);

  const [options, setOptions] = useState({
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: dayjs(startDate).format(DATE_DB_FORMAT),
      endDate: dayjs(endDate).format(DATE_DB_FORMAT),
      typeId: typeId,
      patientNameLast: nameSearchLast,
      patientNameFirst: nameSearchFirst,
      dts: dayjs().format("MMDDYYYYhhmmsszzz")
    },
    fetchPolicy: "cache-and-network"
  }, [startDate, endDate, typeId, nameSearchLast, nameSearchFirst]);

  const [patientRecordsData] = useQueryFHG(PATIENT_RECORDS_QUERY, options, 'patient.records.type', loading);
  const [selectedId, setSelectedId] = useState(undefined);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [patientRecords, setPatientRecords] = useState([]);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);

  useEffect(() => {
    if (patientRecordsData && patientRecordsData?.patients && loading) {
      setPatientRecords(patientRecordsData?.patients || []);
      setLoading(false);
    }
  }, [loading, patientRecordsData, setPatientRecords]);

  const handleSearch = useCallback(() => {
    setLoading(true);
    setSelectedId(undefined);
    const searchOptions = {
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: dayjs(startDate).format(DATE_DB_FORMAT),
        endDate: dayjs(endDate).format(DATE_DB_FORMAT),
        typeId: typeId,
        patientNameLast: nameSearchLast,
        patientNameFirst: nameSearchFirst,
        dts: dayjs().format("MMDDYYYYhhmmsszzz")
      },
      fetchPolicy: "cache-and-network"
    }

    setOptions(searchOptions);
  }, [startDate, endDate, nameSearchLast, nameSearchFirst, typeId]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
    setLoading(false);
    handleSearch();
  }, [setOpenAlert, handleSearch]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (data && openAlert) {
      if (data.patientRecords_Delete === 1) {
        result = <Dialog open={true} onClose={handleAlertClose}><Alert severity="success" onClose={handleAlertClose}>
          Successfully deleted patient record.</Alert></Dialog>;
      } else {
        result = <Alert severity="error" onClose={handleAlertClose}>{`An error occurred: ${error}`}</Alert>;
      }
    }
    return result;
  }, [data, error, openAlert, handleAlertClose]);

  const handleDelete = useCallback((id) => {
    // Delete the patient inventory record.
    if (id) {
      patientDelete({
        variables: {id: parseInt(id)},
        refetchQueries: getPatientRefetchQueries(startDate, endDate, typeId, nameSearchLast, nameSearchFirst)
      });
      setOpenAlert(true);
      setSelectedId(undefined);
    }
  }, [startDate, endDate, patientDelete, typeId, nameSearchLast, nameSearchFirst]);

  const handlePatientLinkClick = useCallback((id) => {
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, [setOpenDetailDrawer, setSelectedId]);

  const updateNameSearch = (e) => {
    let arr, lastName, firstName;
    let name = e.currentTarget.value;

    if (name && name.length > 2) {
      if (name.indexOf(', ') > -1) {
        arr = name.split(', ');
        lastName = arr ? arr[0] : '';
        firstName = arr ? arr[1] : '';
      } else if (name.indexOf(',') > -1) {
        arr = name.split(',');
        lastName = arr ? arr[0] : '';
        firstName = arr ? arr[1] : '';
      } else {
        lastName = name;
      }
    }

    setNameSearch(name);
    if (lastName) {
      setNameSearchLast(lastName);
    }
    if (firstName) {
      setNameSearchFirst(firstName);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        id: 'administerID',
        accessor: 'administerID',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          row.original.processingStatus !== parseInt(SUCCESSFUL) ?
          <ConfirmButton
                id={row?.original.id}
                buttonLabelKey="patient.delete.button"
                buttonTypographyProps={{variant:"body1"}}
                sx={{height: '40px', m: 0, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}
                onConfirm={() => handleDelete(row?.original.id)}
                values={{
                  type: 'patient record',
                  name: ''
                }}
                size='large'
                submitStyle={{
                  backgroundColor: lighten(ERROR_COLOR, 0.15),
                  '&:hover': {
                    backgroundColor: lighten(ERROR_COLOR, 0.2),
                  }
                }}
                startIcon={<DeleteIcon />}
              />
            : <></>
        )
      },
      {
        id: 'dateGiven',
        Header: <TypographyFHG id={'patientRecords.dateGiven.column'}/>,
        accessor: 'dateGiven',
        width: 50,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{row.values?.dateGiven ? dayjs(row.values?.dateGiven).format("L") : ''}</span>
        )
      },
      {
        id: 'vax',
        Header: <TypographyFHG id={'patientRecords.vax.column'}/>,
        accessor: 'clinicBarcode',
        Cell: ({row}) => (<InventoryCard inventory={row.original} showAvailable={false} drawerAction="patient" />)
      },
      {
        id: 'dosesGiven',
        Header: <TypographyFHG id={'patientRecords.dosesGiven.column'} />,
        accessor: 'dosesGiven',
        minWidth: 50,
        maxWidth: 50,
        width: 50,
        Cell: ({row}) => (<span style={{paddingLeft: 24}}>{row.values?.dosesGiven}</span>)
      },
      {
        id: 'patientName',
        Header: <TypographyFHG id={'patientRecords.name.column'} />,
        accessor: 'patientName',
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        Cell: ({row}) => (
          <Link underline="always" variant="inherit" sx={{cursor: 'pointer'}}
                onClick={() => handlePatientLinkClick(row?.original.id)}>
            {row.values?.patientName}
          </Link>)
      },
      {
        id: 'previousName',
        Header: <TypographyFHG id={'patientRecords.previousName.column'} />,
        accessor: 'previousName',
        minWidth: 200,
        maxWidth: 200,
        width: 200
      },
      {
        id: 'topsPatientID',
        Header: <TypographyFHG id={'patientRecords.patientId.column'}/>,
        accessor: 'topsPatientID',
        width: 50
      },
      {
        id: 'patientDob',
        Header: <TypographyFHG id={'patientRecords.patientDob.column'}/>,
        accessor: 'patientDob',
        width: 50,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{dayjs(row.values?.patientDob).format("L")}</span>
        )
      },
      {
        id: 'age',
        Header: <TypographyFHG id={'patientRecords.age.column'}/>,
        accessor: 'age',
        width: 50,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{getPatientAgeString(row.values?.patientDob, dayjs(row.values?.dateGiven))}</span>
        )
      },
      {
        id: 'notes',
        Header: <TypographyFHG id={'patientRecords.notes.column'}/>,
        accessor: 'notes',
        width: 50
      },
      {
        id: 'insurance',
        Header: <TypographyFHG id={'patientRecords.insurance.column'}/>,
        accessor: 'insurance',
        width: 50
      }
    ];
  }, [handleDelete, handlePatientLinkClick]);

  const handleStartDateChange = (date) =>  {
    if (date) {
      setStartDate(date);
      localStorage.setItem("PR_START_DATE", date);
    }
  }

  const handleEndDateChange = (date) =>  {
    if (date) {
      setEndDate(date);
      localStorage.setItem("PR_END_DATE", date);
    }
  };

  const handleDetailClose = useCallback(() => {
    handleSearch();
    setOpenDetailDrawer(false);
  }, [handleSearch, setOpenDetailDrawer]);

  const handleInventoryTypeChange = (value) => {
    setTypeId(`${value}`);
  }

  return (
    <>
    <Loading isLoading={loading}/>
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <TypographyFHG id="patientRecords.title" color="textSecondary" sx={{my: 0, mr: 0, ml: 2}} variant="h5" />
      {getAlert()}
      <Grid item sx={{height: TABLE_HEIGHT, overflowY: 'auto', mb: 1}}>
        <TableFHG name={"PatientRecords"} columns={columns} data={patientRecords}
                  fullWidth allowSearch displaySearch={false} stickyHeader
                  emptyTableMessageKey={'patientRecords.noRecord.message'}
        >
          <Grid container item direction={'row'} justifyContent={'space-between'}>
            <Grid container direction={'row'} sx={{height: '50px'}}>
              <Grid item sx={{mt: 0.75, mr: 0, mb: 0, ml: 0, p: 0, width: '160px'}}>
                <KeyboardDatePickerFHG
                    key={'startDate'}
                    name={'startDate'}
                    label={'Start Date'}
                    onChange={handleStartDateChange}
                    value={startDate}
                />
              </Grid>
              <Grid item sx={{mt: 0.75, mr: 0, mb: 0, ml: 1, p: 0, width: '160px'}}>
                <KeyboardDatePickerFHG
                    key={'endDate'}
                    name={'endDate'}
                    label={'End Date'}
                    onChange={handleEndDateChange}
                    value={endDate}
                />
              </Grid>
              <Grid item sx={{mt: -2, mr: 1, ml: 1, pt: 0.5, width: '160px'}}>
                <InputLabel id="typeDropDown" sx={{fontSize: '0.875rem', my: 0, ml: 2}}>Inventory Type</InputLabel>
                <Select
                  value={typeId}
                  onChange={e => {
                    handleInventoryTypeChange(e.target.value || undefined)
                  }}
                  labelId="typeDropDown"
                  size="small"
                >
                  <MenuItem key={0} value={'0'}>
                  <Chip size='small' label={'ALL'} sx={{
                    fontSize: CARD_SM_FONT,
                    margin: 'auto',
                    backgroundColor: `blue`,
                    color: '#FFF',
                    width: '130px'
                  }}/>
                  </MenuItem>
                  {inventoryTypes.map((option, i) => (
                    <MenuItem key={i} value={option.inventoryTypeID}>
                      <Chip size='small' label={option.name} sx={{
                        fontSize: CARD_SM_FONT,
                        margin: 'auto',
                        backgroundColor: `${getChipBgColor(option.colorCode)}`,
                        color: `${hexToRgb(option.colorCode)}`,
                        width: '130px'
                      }}/>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item sx={{mt: -2, mr: 1.5, ml: 1, pt: 0.5}}>
                <InputLabel id="nameSearchLabel" sx={{fontSize: '0.875rem', my: 0, ml: 2}}>Name Search</InputLabel>
                <TextField
                  value={nameSearch}
                  onChange={updateNameSearch}
                  labelId={"nameSearchLabel"}
                  size="small"
                  sx={{ml: "12px", mr: 0}}
                />
              </Grid>
              <Grid sx={{mt: 1}}>
                <ButtonFHG id="patientRecords.search.button" labelKey="patientRecords.search.button"
                           sx={{height: '40px', m: 0,
                             '&:hover': {
                               color: PRIMARY_DARK_COLOR,
                             },
                             fontSize: '1rem'}}
                         startIcon={(<SearchIcon />)} onClick={handleSearch} variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>

      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
       >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          <PatientRecordEdit id={selectedId} onClose={handleDetailClose} startDate={startDate} endDate={endDate} />
        </Grid>
      </EditDrawer>
    </Grid>
    </>
  );
}
