import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import {Chip} from '@mui/material';
import * as dayjs from 'dayjs';
import EditDrawer from '../EditDrawer';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import find from 'lodash/find';
import {getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryAdjust from '../inventory/InventoryAdjust';
import InventoryEdit from '../inventory/InventoryEdit';
import InventoryPatients from '../inventory/InventoryPatients';
import NotesIcon from '@mui/icons-material/AssignmentOutlined';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getInventoryListRefetchQueries,
  HISTORICAL_INVENTORY_SEARCH_QUERY, INVENTORY_DELETE,
  INVENTORY_QUERY,
  V_INVENTORY_LIST_QUERY
} from '../../data/QueriesGL';
import {
  BGD_COLOR,
  CARD_SM_FONT,
  DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD,
  EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT,
  TABLE_HEIGHT
} from '../../Constants';

export default function HistoricalReport() {
  const [{isAdmin}] = useRecoilState(userStatus);
  const [inventoryData, setInventoryData] = useState([]);
  const [drawerAction, setDrawerAction] = useState('');
  const [messageKey, setMessageKey] = useState('');
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [searchInventoryData, {data: searchList, error: searchError}] = useLazyQueryFHG(HISTORICAL_INVENTORY_SEARCH_QUERY, {}, 'search.report.type', true);
  const [inventoryDelete, {data, error}] = useMutationFHG(INVENTORY_DELETE);

  useMemo(() => {
    if (searchList && searchList.records) {
      setInventoryData(searchList?.records || [])
    }
  }, [searchList, setInventoryData]);

  useEffect(() => {
    if (searchError) {
      setMessageKey('An error occurred:' + searchError?.message);
      setOpenAlert(true);
    }
  }, [searchError])

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert && data) {
      if (data.numberDeleted === 1) {
        result = <Alert severity="success"  onClose={handleAlertClose}>
          Successfully deleted inventory record.</Alert>;
      } else {
        result = <Alert severity="error">{`An error occurred: ${error}`}</Alert>;
      }
    } else if (openAlert && messageKey) {
      result = <Alert severity="error">{`An error occurred: ${messageKey}`}</Alert>;
    }
    return result;
  }, [data, error, openAlert, handleAlertClose, messageKey]);

  const getFlyoutContent = () => {
    let content;
    let row = {};

    switch (drawerAction) {
      case 'edit':
        row = find(inventoryData, x => x.id === selectedId);
        content = (<InventoryEdit inventory={row} onClose={handleDetailClose} />);
        break;
      case 'adjust':
        row = find(inventoryData, x => x.id === selectedId);
        content = (<InventoryAdjust inventory={row} onClose={handleDetailClose} />);
        break;
      case 'barcode':
        content = (<InventoryPatients id={selectedId} onClose={handleDetailClose} />);
        break;
      default:
        content = undefined;
    }
    return content;
  };

  const columns = useMemo(() => {
    return [
      {
        id: 0,
        Header: <TypographyFHG id={'inventory.addedDate.column'}/>,
        accessor: 'addedDate',
        Cell: ({value}) => (
          <span>{value ? dayjs(value)?.format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 1,
        Header: <TypographyFHG id={'inventory.mfgId.column'}/>,
        accessor: 'mfgBrand'
      },
      {
        id: 2,
        Header: <TypographyFHG id={'inventory.mfgGeneric.column'}/>,
        accessor: 'mfgGeneric'
      },
      {
        id: 3,
        Header: <TypographyFHG id={'inventory.clinicBarcode.column'}/>,
        accessor: 'clinicBarcode'
      },
      {
        id: 4,
        Header: <TypographyFHG id={'inventory.lot.column'}/>,
        accessor: 'lot'
      },
      {
        id: 5,
        Header: <TypographyFHG id={'inventory.expDate.column'}/>,
        accessor: 'expDate',
        Cell: ({value}) => (
          <span>{dayjs(value ? value : '').format(DATE_FORMAT_KEYBOARD)}</span>
        )
      },
      {
        id: 6,
        Header: <TypographyFHG id={'inventory.doses.column'}/>,
        accessor: 'doses',
        Cell: ({value}) => (
          <span style={{display: "block", paddingRight: '12px', textAlign: "right"}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 7,
        Header: <TypographyFHG id={'inventory.type.column'}/>,
        accessor: 'type',
        Cell: ({row, value}) => {
          return (<Chip size='small' label={value} style={{margin: 'auto',
            fontSize: CARD_SM_FONT, width: '130px',
            backgroundColor: `${getChipBgColor(row.original?.colorCode)}`,
            color: `${hexToRgb(row.original?.colorCode)}`}} />);
        }
      },
      {
        id: 8,
        Header: <TypographyFHG id={'inventory.notes.column'}/>,
        accessor: 'notes',
        Cell: ({value}) => (value ? <NotesIcon /> : null)
      }
    ];
  }, []);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleRowSelect = (row) => {
    const rowData = row ? row.original : row;
    setSelectedId(rowData.id ? Number(rowData.id) : null);
  };

  const handleAdjust = useCallback(() => {
    setDrawerAction('adjust');
    setOpenDetailDrawer(true);
  }, []);

  const handleBarcodeLink = () => {
    setDrawerAction('barcode');
    setOpenDetailDrawer(true);
  };

  const handleDelete = useCallback(() => {
    const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT)?.format(DATE_DB_FORMAT);
    const endDate = dayjs().format(DATE_DB_FORMAT);

    // Delete the inventory record.
    setOpenAlert(true);
    inventoryDelete({
      variables: {inventoryId: selectedId},
      update: cacheDelete([
        {query: V_INVENTORY_LIST_QUERY, variables: {startDate, endDate}, queryPath: 'inventories'},
        {query: INVENTORY_QUERY, variables: {id: selectedId}, queryPath: 'inventory'}
      ], selectedId, 'inventory'),
      refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
    });

  }, [inventoryDelete, selectedId]);


  const handleEdit = useCallback(() => {
    setDrawerAction('edit');
    setOpenDetailDrawer(true);
  }, []);

  const handleSearch = useCallback((clinicBarcode, mfgBrand) => {
    let variables;
    if (clinicBarcode) {
      variables = {
        clinicBarcode: clinicBarcode,
        dts: dayjs().format("MMDDYYYYhhmmsszzz")
      };
    } else if (mfgBrand) {
      variables = {
        mfgBrand: mfgBrand,
        dts: dayjs().format("MMDDYYYYhhmmsszzz")
      };
    } else {
      return;
    }

    const options = {
      notifyOnNetworkStatusChange: true,
      variables: variables
    };

    searchInventoryData(options);
  }, [searchInventoryData]);

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
  }

  return (
    <>
      <Grid container direction={'column'} wrap={'nowrap'}>
        <TypographyFHG id="reports.search.title" sx={{mt: 1,mr: 0,mb: 0,ml: 1}} variant="h5" color="textSecondary" />
        <Grid item sx={{cursor: 'pointer'}}>
          {getAlert()}
          <Grid item container direction="column" sx={{
            height: TABLE_HEIGHT,
            width: `calc(100% - 17)`,
            overflowY: 'auto',
            mb: 1}}>
            <EnhancedTable
              columns={columns}
              data={inventoryData}
              setData={setInventoryData}
              scrollbarSize={17}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              onAdjust={handleAdjust}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onRowSelect={handleRowSelect}
              onSearch={handleSearch}
              onBarcodeClick={handleBarcodeLink}
              adjustButtonKey="inventory.adjust.button"
              barcodeColumnIndex={3}
              editButtonKey="inventory.edit.button"
              deleteButtonKey="inventory.delete.button"
              notesColumnIndex={8}
              notesTitleKey="inventory.notes.title"
              isAdmin={isAdmin}
              historicalSearch={true}
            />
          </Grid>
        </Grid>
        <EditDrawer
          backgroundColor={BGD_COLOR}
          ModalProps={{BackdropProps: {style: {height: '100%'}}}}
          open={openDetailDrawer}
          onClose={handleDetailClose}
        >
          <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
            {getFlyoutContent()}
          </Grid>
        </EditDrawer>
      </Grid>
    </>
  );
}
