import React, {useCallback, useMemo} from 'react';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from '../../Constants';

export default function LowInventory(props) {
  const {vaccines} = props;

  const columns = useMemo(() => {
    return [
      {
        id: 0,
        Header: <TypographyFHG id={'dashboard.low.inv.name.column'}/>,
        accessor: 'name'
      },
      {
        id: 1,
        Header: <TypographyFHG id={'dashboard.low.inv.doses.column'}/>,
        accessor: 'doses',
        Cell: ({value}) => (
          <span style={{paddingLeft: '16px'}}>{value}</span>
        )
      },
      {
        id: 2,
        Header: <TypographyFHG id={'dashboard.low.inv.threshold.column'}/>,
        accessor: 'threshold',
        Cell: ({value}) => (
          <span style={{paddingLeft: '16px'}}>{value}</span>
        )
      }
    ];
  }, []);

  const handleClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);

  return vaccines && vaccines.length > 0 ? (
    <Grid>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'dashboard.low.inv.title'} color={'textSecondary'} />
        </Grid>
        <Grid sx={{mt: 2, pl: 3, minHeight: 0, overflowX: 'hidden'}}>
          <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`, my: 1, ml: 0.5}}>
              <TableFHG columns={columns} data={vaccines} stickyHeader
                        allowSearch={false} emptyTableMessageKey={'dashboard.low.inv.noRecord.message'}
              >
              </TableFHG>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}} >
            <ButtonFHG variant='outlined' size={'small'} labelKey={'close.button'} onClick={handleClose}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}

LowInventory.propTypes = {
  vaccines: PropTypes.array,
  onClose: PropTypes.func.isRequired
}
