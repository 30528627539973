import React from 'react';
import {formatMessage} from '../../utils/Utils';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '../../../components/TextField';
import {useIntl} from 'react-intl';

/**
 * The search header for a TableFHG.
 *
 * Reviewed: 3/26/20
 *
 * @param globalFilter The current global filter for the table.
 * @param setGlobalFilter The callback when the global filter changes.
 * @param placeholder The placeholder text for the search.
 * @return {*}
 * @constructor
 */
export default function SearchFilter({globalFilter, setGlobalFilter, placeholder}) {
   const intl = useIntl();

   /**
    * Handle the changes to the search TextField.
    * @param e The change event.
    */
   const handleChange = (e) => {
      setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
   };

   return (
     <div>
         <TextField
            name="search"
            margin={'normal'}
            sx={{mt: 0.5, mx: 0.5, mb: 0, width: '192px'}}
            onChange={handleChange}
            placeholder={placeholder || formatMessage(intl, 'search.placeholder', 'Search…', [])}
            InputProps={{
               'aria-label': 'Search',
               startAdornment: (
                  <InputAdornment position='start' style={{height: 'unset'}}>
                     <SearchIcon color="primary" size="small" />
                  </InputAdornment>
               )
            }}
            value={globalFilter || ''}
            variant="outlined"
         />
     </div>
   )
};

SearchFilter.propTypes = {
   globalFilter: PropTypes.string,
   setGlobalFilter: PropTypes.func.isRequired,
   placeholder: PropTypes.string,
};
