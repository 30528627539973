import React, {useCallback, useMemo, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {cacheUpdate} from '../../fhg/utils/DataUtil';
import * as dayjs from 'dayjs';
import Form from '../../fhg/components/edit/Form';
import {Grid} from '@mui/material';
import find from 'lodash/find';
import {formatMessage, toNumber} from '../../fhg/utils/Utils';
import InventoryCardBasic from './InventoryCardBasic';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useEditData from '../../fhg/components/edit/useEditData';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {
  getInventoryListCacheQueries,
  getInventoryListRefetchQueries,
  INVENTORY_UPDATE,
  INVENTORY_TYPES_QUERY
} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT, DATE_PICKER_WIDTH, EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT, PRIMARY_DARK_COLOR
} from '../../Constants';
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export default function InventoryEdit(props) {
  const intl = useIntl();
  const {inventory} = props;
  const [isSaving, setIsSaving] = useState(false);
  const [inventoryUpdate] = useMutationFHG(INVENTORY_UPDATE);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues,
      getValue
    }
  ] = useEditData(undefined, ['mfgId', 'typeId']);
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);

  useMemo(() => {
    if (inventory) {
      setDefaultValues(inventory);
    }
  }, [inventory, setDefaultValues]);

  const getInventoryType = useCallback(() => {
    let result;
    if (inventoryTypes?.length > 0) {
      const typeId = getValue('typeId');
      result = find(inventoryTypes, type => type.inventoryTypeID === typeId);
    }
    return result;
  }, [getValue, inventoryTypes]);

  const getDoses = useCallback(() => {
    let result = '';
    if (inventory) {
      result = inventory.doses;
    }
    return result;
  }, [inventory]);

  const handleInventoryTypeChange = (event, value) => {
    setEditValues(editValues => ({...editValues, typeId: value?.inventoryTypeID}));
    setIsChanged(true);
  }

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);

        let currentItem = {
          ...defaultValues,
          ...editValues,
        };

        const invItem = {
          id: inventory?.id,
          inventoryId: inventory?.id,
          clinicBarcode: currentItem.clinicBarcode,
          lot: currentItem.lot,
          addedDate: currentItem.addedDate,
          expDate: currentItem.expDate ? currentItem.expDate.format(DATE_DB_FORMAT) : '',
          doses: toNumber(currentItem.doses),
          originalDoses: toNumber(currentItem.originalDoses),
          notes: currentItem.notes,
          typeId: currentItem.typeId,
          mfgId: currentItem.mfgId // Vaccine id
        };

        const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
        const endDate = dayjs().format(DATE_DB_FORMAT);

        await inventoryUpdate({
          variables: invItem,
          optimisticResponse: {
            __typename: 'Mutation',
            inventory: {
              ...invItem,
              __typename: 'Inventory'
            }
          },
          update: cacheUpdate(getInventoryListCacheQueries(startDate, endDate), invItem?.id, 'inventory'),
          refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [inventory, inventoryUpdate, handleClose, isChanged, defaultValues, editValues, setIsChanged]);

  const handleAddedDateChange = (date) => {
    setEditValues(prevState => ({...prevState, addedDate: date}));
    setIsChanged(true);
  };

  const handleExpDateChange = (date) => {
    setEditValues(prevState => ({...prevState, expDate: date}));
    setIsChanged(true);
  };

  if (inventory?.mfgBrand) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'inventory.edit.title'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
            <Grid sx={{mt: 1, minHeight: 0, overflowY: 'auto'}}>
              <InventoryCardBasic inventory={inventory} />
              <Grid sx={{mt: 1.5, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <Table size="small" sx={{border: 'thin solid gray', borderRadius: '5px', mt: 1, width: EDIT_DRAWER_ITEM_WIDTH}}>
                <TableRow>
                  <TableCell><TypographyFHG id={'inventory.mfgBarcode.label'} color="textSecondary" variant="body2" /></TableCell>
                  <TableCell colSpan={3}><TypographyFHG color="textPrimary" sx={{m: 0}} variant="body2">{inventory?.mfgBarcode}</TypographyFHG></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><TypographyFHG id={'inventory.cvx.label'} color="textSecondary" variant="body2" /></TableCell>
                  <TableCell colSpan={3}>
                    <TypographyFHG color="textPrimary" sx={{m: 0}} variant="body2">
                      {`${inventory?.cvxShortDescription} (${inventory?.cvxCode})`}
                    </TypographyFHG>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><TypographyFHG id={'inventory.manufacturer.label'} color="textSecondary" variant="body2" /></TableCell>
                  <TableCell colSpan={3}>
                    <TypographyFHG color="textPrimary" sx={{m: 0}} variant="body2">
                    {inventory?.manufacturerName}
                  </TypographyFHG>
                  </TableCell>
                </TableRow>
              </Table>
              </Grid>
              <Grid container direction="column" sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="lot"
                  name="lot"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  labelKey="inventory.lot.label"
                  onChange={handleChange}
                  value={editValues?.lot || defaultValues?.lot}
                />
              </Grid>
              <Grid container direction="row" justifyContent="flex-start" sx={{mt: 1}}>
                <Grid container direction="column" sx={{mb: 1, mr: 2, pt: 0.75, width: DATE_PICKER_WIDTH-10}}>
                  <KeyboardDatePickerFHG
                    key="addedDate"
                    name="addedDate"
                    labelKey={'inventory.addedDate.label'}
                    onChange={handleAddedDateChange}
                    value={editValues?.addedDate || dayjs(defaultValues?.addedDate)}
                  />
                </Grid>
                <Grid container direction="column" sx={{mb: 1, pt: 0.75, width: DATE_PICKER_WIDTH}}>
                  <KeyboardDatePickerFHG
                    key="expDate"
                    name="expDate"
                    labelKey={'inventory.expDate.label'}
                    onChange={handleExpDateChange}
                    value={editValues?.expDate || dayjs(defaultValues?.expDate)}
                  />
                </Grid>
              </Grid>
              <Grid sx={{mt: 1.5, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <Autocomplete
                  key="inventoryTypes"
                  fullWidth
                  getOptionLabel={(option) => option?.name || ''}
                  options={inventoryTypes}
                  onChange={handleInventoryTypeChange}
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  value={getInventoryType()}
                  renderInput={(params) => (
                    <TextField{...params} label={formatMessage(intl, 'inventory.type.label', '',[])}
                              placeholder='Select type' size="small" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid container direction="row" justifyContent="flex-start" sx={{mt: 1}}>
                <Grid sx={{width: '130px', mr: 2, mt: -0.5}}>
                  <TextFieldCustom
                    key="originalDoses"
                    name="originalDoses"
                    inputProps={{style: {fontSize: '0.875rem'}}}
                    labelKey="inventory.originalDoses.label"
                    onChange={handleChange}
                    value={editValues?.originalDoses || defaultValues?.originalDoses}
                  />
                </Grid>
                <Grid container direction="column" sx={{width: '130px'}}>
                  <fieldset style={{border: '1px solid lightgray', borderRadius: '5px', fontSize: '8px'}}>
                    <legend style={{fontSize: '.75rem'}}><TypographyFHG id={'inventory.doses.label'} color="textSecondary" /></legend>
                    <TypographyFHG color="textPrimary" sx={{m: 0}} variant="body1">
                      {getDoses()}
                    </TypographyFHG>
                  </fieldset>
                </Grid>
              </Grid>
              <Grid sx={{mt: -1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="notes"
                  name="notes"
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  labelKey="inventory.notes.label"
                  multiline
                  rows="2"
                  onChange={handleChange}
                  value={editValues?.notes || defaultValues?.notes}
                />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
        </Grid>
      </Form>
    );
  } else {
    return null;
  }
}

InventoryEdit.propTypes = {
  inventory: PropTypes.any,
  onClose: PropTypes.func.isRequired
}
