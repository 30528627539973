import React, {useState, useEffect, useCallback} from 'react';
import {Button} from '@mui/material';
import CheckIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close'
import {Link} from 'react-router-dom';
import TypographyFHG from '../../fhg/components/Typography';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from "recoil";
import {userStatus} from "../../fhg/hooks/auth/useAuth";
import {DASHBOARD_STATUS_QUERY} from '../../data/QueriesGL';
import {SEND_TO_STATE_PATH, USER_SEND_TO_STATE_PATH} from '../../Constants';

export default function StatusIndicator() {
    const [{isAdmin}] = useRecoilState(userStatus);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [options] = useState({
        variables: {},
        fetchPolicy: "no-cache"
    });

    const [sendToStateData] = useQueryFHG(DASHBOARD_STATUS_QUERY, options, 'status.type');

    useEffect(() => {
        const records = sendToStateData?.results || [];

        if (records && records.length > 0) {
            let result = records[0];

            if (result.seven_day_total > 0) {
                setError(true);
                setErrorMsg(`${result.seven_day_total} errors have occurred. Please check Send to State`);
            }
        }
    }, [sendToStateData]);

    const getButton = useCallback(() => {
      return (
        <Button sx={{
                  height: '2rem',
                  pb: 3,
                  mr: 2,
                  color: 'red',
                  '&:hover': {
                    textDecoration: "none",
                    color: "red",
                    backgroundColor: "inherit",
                    pointer: ""
                  },
                  cursor: "pointer"
                }}
                component={Link} to={isAdmin ? SEND_TO_STATE_PATH : USER_SEND_TO_STATE_PATH}
                startIcon={(<CloseIcon fontSize="large" />)}
                size={'large'}>
          <TypographyFHG >{errorMsg}</TypographyFHG>
        </Button>
      );
    }, [errorMsg, isAdmin]);

    return (
        <>
        {error && getButton()}
        {!error &&
            <Button sx={{
                      height: '2rem',
                      mr: 2,
                      pb: 2,
                      color: 'green',
                      '.MuiButton-startIcon': {
                        fontSize: '28px !important'
                      },
                      '&:hover': {
                        textDecoration: "none",
                        color: "green",
                        backgroundColor: "inherit",
                        pointer: ""
                      },
                      cursor: "default"
                    }}
                    startIcon={(<CheckIcon size="large" sx={{pb: 1}} />)}
                    size={'large'}>
            <TypographyFHG id='dashboard.tostate.nav'/>
            </Button>
        }
        </>
    );
}
