import React, {useCallback, useMemo, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import EditDrawer from '../EditDrawer';
import filter from 'lodash/filter';
import {Link, Switch} from '@mui/material';
import {formatMessage} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import MfgVaccineAdd from './MfgVaccineAdd';
import MfgVaccineEdit from './MfgVaccineEdit';
import orderBy from 'lodash/orderBy';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {MFGVACCINES_QUERY} from '../../data/QueriesGL';
import {
  BGD_COLOR,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR
} from '../../Constants';

export default function MfgVaccines() {
  const intl = useIntl();
  const options = {variables: {limit: 0, offset: 0}};
  const [mfgVaccinesData] = useQueryFHG(MFGVACCINES_QUERY, options, 'mfgVaccine.type');
  const [selectedId, setSelectedId] = useState(undefined);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [displayInactive, setDisplayInactive] = useState(false);

  const handleEdit = useCallback((id) => {
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        id: 'mfgBrand',
        Header: <TypographyFHG id={'mfgVaccines.mfgBrand.column'}/>,
        accessor: 'mfgBrand',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.id)}>{row.values?.mfgBrand}</Link>)
      },
      {
        id: 'mfgGeneric',
        Header: <TypographyFHG id={'mfgVaccines.mfgGeneric.column'}/>,
        accessor: 'mfgGeneric',
        width: 100
      },
      {
        id: 'cvxCode',
        Header: <TypographyFHG id={'mfgVaccines.cvx.column'}/>,
        accessor: 'cvx.cvxCode',
        align: 'right',
        width: 40,
        Cell: ({value}) => (
          <span style={{display: "block", paddingRight: "48px", textAlign: "right"}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 'manufacturerName',
        Header: <TypographyFHG id={'mfgVaccines.manufacturer.column'}/>,
        accessor: 'manufacturer.name',
        weighting: 2,
        width: 60
      },
      {
        id: 'mfgBarcode',
        Header: <TypographyFHG id={'mfgVaccines.mfgBarcode.column'}/>,
        accessor: 'mfgBarcode',
        width: 50
      },
      {
        id: 'reorderThreshold',
        Header: <TypographyFHG id={'mfgVaccines.reorderThreshold.column'}/>,
        accessor: 'reorderThreshold',
        width: 50,
        Cell: ({value}) => (
          <span style={{display: "block", paddingRight: "48px", textAlign: "right"}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 'isHide',
        Header: <TypographyFHG id={'mfgVaccines.isHide.column'}/>,
        accessor: 'isHideValue',
        width: 50,
      }
    ];
  }, [handleEdit]);

  const mfgVaccines = useMemo(() => {
    let data = mfgVaccinesData?.mfgVaccines || [];
    let rows = displayInactive ? data : filter(data, {isHide: false});
    let filteredRows = [];

    if (rows && rows.length > 0) {
      filteredRows = rows.map(obj => ({...obj, isHideValue: obj.isHide ? 'Hidden' : ''}))
    }
    const brandSorter = row => row.mfgBrand.toLowerCase();
    return orderBy(filteredRows, [brandSorter, 'cvxCode', 'mfgBarcode']);
  }, [mfgVaccinesData, displayInactive]);

  const handleAdd = (event) => {
    setSelectedId('new');
    setOpenDetailDrawer(true);
  };

  const handleDetailClose = () => {
    setOpenDetailDrawer(false);
  };

  const handleHiddenToggle = () => {
    setDisplayInactive(value => !value);
  };

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return !displayInactive ? undefined : {fontWeight: "bold"};
    } else {
      return !displayInactive ? {fontWeight: "bold"} : undefined;
    }
  }, [displayInactive]);

  return (
    <Grid container direction={'column'}>
       <Grid container direction="row">
        <TypographyFHG id={'mfgVaccines.title'} color="textSecondary" sx={{mt: 0, mr: 0, mb: -0.5, ml: 2}} variant="h5" />
      </Grid>
        <Grid sx={{mb: 1}} >
          <TableFHG name={"Vaccines"} columns={columns} data={mfgVaccines} allowSearch
                    emptyTableMessageKey={'mfgVaccines.noRecord.message'} stickyHeader
          >
            <Grid container direction="row" justifyContent="flex-start">
              <ButtonFHG id="mfgVaccines.add.button" labelKey={'mfgVaccines.add.button'} startIcon={(<AddIcon />)} onClick={handleAdd}
                         sx={{mr: 1, fontSize: '0.875rem', '&:hover': {color: PRIMARY_DARK_COLOR}}}
                         variant="outlined" />
              <Grid container direction="row" sx={{my: 0, mr: 0, ml: 2, pt: 0.75, width: '320px'}}>
                <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
                <Switch color="primary" checked={!displayInactive} onChange={handleHiddenToggle} sx={{my: -1, mx: 0, width: '62px'}} />
                <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'slider.active.label', 'Display Hidden Only', null)}</Grid>
              </Grid>
            </Grid>
          </TableFHG>
        </Grid>
      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          {selectedId === 'new' ? <MfgVaccineAdd onClose={handleDetailClose} vaccineList={mfgVaccinesData?.mfgVaccines || []} />
          : <MfgVaccineEdit id={selectedId} onClose={handleDetailClose} vaccineList={mfgVaccinesData?.mfgVaccines || []} />
          }
        </Grid>
      </EditDrawer>
    </Grid>
  );
}
