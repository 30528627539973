import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import assign from 'lodash/assign';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {cacheUpdate} from '../../fhg/utils/DataUtil';
import {Grid} from '@mui/material';
import Form from '../../fhg/components/edit/Form';
import ProgressButton from '../../fhg/components/ProgressButton';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import {CLINIC_QUERY, CLINIC_UPDATE, getClinicRefetchQueries} from '../../data/QueriesGL';
import {EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from "../../Constants";

export default function ClinicEdit(props) {
  const clinicId = props.id;
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [clinicData] = useQueryFHG(CLINIC_QUERY, {variables: {id: clinicId}});
  const [clinicUpdate, {data, error}] = useMutationFHG(CLINIC_UPDATE);
  const clinic = useMemo(() => clinicData?.clinic || {}, [clinicData]);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      resetValues
    }
  ] = useEditData({}, ['clinicId']);

  useEffect(() => {
    if (clinicData?.clinic) {
      setDefaultValues(assign({}, clinic, {id: clinicId}));
    }
  }, [clinic, clinicId, clinicData, setDefaultValues]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  useEffect(() => {
    if (data && !error) {
      handleClose();
    }
  }, [data, error, handleClose]);

  useEffect(() => {
    if (error) {
      setMessageText(error.message);
      setOpenAlert(true);
    }
  }, [error]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        let currentItem = {
          ...defaultValues,
          ...editValues
        };

        setIsSaving(true);

        let variables = assign({}, currentItem);
        currentItem['__typename'] = 'Clinic';

        await clinicUpdate ({
          variables: variables,
          optimisticResponse: {
            __typename: 'Mutation',
            clinicId: clinicId,
            clinic: currentItem
          },
          update: cacheUpdate(getClinicRefetchQueries(), clinicId, 'clinic'),
          refetchQueries: getClinicRefetchQueries()
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [clinicId, clinicUpdate, defaultValues, editValues, handleClose, isChanged, setIsChanged]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  if (defaultValues.id) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'clinic.edit.title'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 1, pl: 3}}>
            <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
              {getAlert()}
              <Grid sx={{mt:1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="name"
                  name="name"
                  autoFocus
                  labelKey="clinic.name"
                  inputProps={{fontSize: '0.875rem'}}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  value={editValues.name ? editValues.name : defaultValues.name}
                  required
                />
                <TextFieldCustom
                  key="phone"
                  name="phone"
                  inputProps={{fontSize: '0.875rem'}}
                  InputLabelProps={{ shrink: true }}
                  labelKey="clinic.phone"
                  onChange={handleChange}
                  value={editValues.phone ? editValues.phone : defaultValues.phone}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{
            borderTopColor: 'lightgray',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            bottom: 0,
            height: '60px',
            pl: 3,
            width: '100%'
          }}>
            <Grid container direction="row" sx={{mt: 0.5}}>
              <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                              type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                              sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
              <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                         disabled={isSaving} onClick={handleClose}
                         sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
  } else {
    return null;
  }
}

ClinicEdit.propTypes = {
  id: PropTypes.any,
  onClose: PropTypes.func
}
