import React, {forwardRef, PureComponent} from 'react';
import Barcode from 'react-barcode';
import {Grid} from '@mui/material';

export class LabelSheet extends PureComponent {
  render() {
    const {barcodes, sheets} = this.props;
    const bStyle = {margin: '12px 12px',  paddingLeft: '4px', height: '72px', width:'216px'};
    const bStyle2 = {margin: '12px 8px 12px 24px',  paddingLeft: '18px', height: '72px', width:'216px'};
    const bStyle3 = {margin: '12px 10px 12px 30px',  paddingLeft: '24px', height: '72px', width:'216px'};
    const gridStyle = {display: 'block', position: 'relative', padding: '54px 36px 60px 36px', height: '1056px', width:'816px'};

    if (barcodes && barcodes.length > 0) {
      return (
        <div>
          {sheets > 0 && (
            <div style={gridStyle}>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[0]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[10]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[20]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[1]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[11]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[21]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[2]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[12]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[22]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[3]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[13]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[23]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[4]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[14]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[24]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[5]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[15]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[25]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[6]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[16]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[26]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[7]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[17]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[27]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[8]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[18]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[28]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[9]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[19]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[29]} /></div>
              </Grid>
            </div>
          )}
          {sheets > 1 && (
            <div style={gridStyle}>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[30]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[40]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[50]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[31]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[41]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[51]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[32]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[42]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[52]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[33]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[43]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[53]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[34]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[44]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[54]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[35]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[45]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[55]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[36]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[46]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[56]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[37]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[47]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[57]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[38]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[48]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[58]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[39]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[49]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[59]} /></div>
              </Grid>
            </div>
          )}
          {sheets > 2 && (
            <div style={gridStyle}>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[60]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[70]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[80]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[61]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[71]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[81]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[62]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[72]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[82]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[63]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[73]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[83]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[64]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[74]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[84]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[65]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[75]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[85]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[66]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[76]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[86]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[67]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[77]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[87]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[68]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[78]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[88]} /></div>
              </Grid>
              <Grid container direction="row">
                <div style={bStyle}><Barcode height={25} fontSize={16} value={barcodes[69]} /></div>
                <div style={bStyle2}><Barcode height={25} fontSize={16} value={barcodes[79]} /></div>
                <div style={bStyle3}><Barcode height={25} fontSize={16} value={barcodes[89]} /></div>
              </Grid>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export const LabelsSheet = forwardRef((props, ref) => {
  return <LabelSheet ref={ref} barcodes={props.barcodes} sheets={props.sheets} />;
});
