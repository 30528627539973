import React, {useCallback, useMemo, useState} from 'react';
import assign from 'lodash/assign';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {cacheAdd} from '../../fhg/utils/DataUtil';
import {Card, CardContent, TextField} from '@mui/material';
import * as dayjs from 'dayjs';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {Grid} from '@mui/material';
import InventoryCardBasic from './InventoryCardBasic';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import orderBy from 'lodash/orderBy';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import useEffect from '../../fhg/hooks/useEffect';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  CLINICS_QUERY,
  INVENTORY_QUERY,
  TRANSFER_INVENTORY_CREATE,
  getTransferCacheQueries,
  getTransferRefetchQueries
} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT,
  DATE_TIME_FORMAT, EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT, PRIMARY_DARK_COLOR
} from '../../Constants';

export default function InventoryTransfer(props) {
  const inventoryId = props.id;
  const [{userId}] = useRecoilState(userStatus);
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [clinicsData] = useQueryFHG(CLINICS_QUERY);
  const [inventoryData] = useQueryFHG(INVENTORY_QUERY, {variables: {id: inventoryId}});
  const [inventoryTransferCreate] = useMutationFHG(TRANSFER_INVENTORY_CREATE);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      getValue,
      setDefaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData(undefined, ['loanToInventoryId, reasonId']);
  const clinics = useMemo(() => orderBy(clinicsData?.clinics, [row => row.name], ['asc']), [clinicsData]);
  let inventory = useMemo(() => inventoryData?.inventory || {}, [inventoryData]);

  useEffect(() => {
    if (inventoryData?.inventory) {
      setDefaultValues(assign({}, inventory, {id: inventoryId, inventoryId: inventoryId}));
    }
  }, [inventoryId, inventory, inventoryData, setDefaultValues]);

  const getClinic = useCallback(() => {
    let result;
    if (clinics?.length > 0) {
      const clinicId = getValue('clinicId');
      result = find(clinics, clinic => clinic.id === clinicId);
    }
    return result;
  }, [getValue, clinics]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleClinicChange = (event, value) => {
    setEditValues(editValues => ({...editValues, clinicId: value?.id}));
    setIsChanged(true);
  }

  const handleDosesChange = useCallback((event) => {
    let {value} = event.target;
    if (value > inventory.doses) {
      setMessageKey('inventory.transfer.doses.message');
      setOpenAlert(true);
    } else {
      setEditValues(editValues => ({...editValues, doses: value}));
      setOpenAlert(false);
    }
  }, [inventory, setEditValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);
        const date = dayjs().format(DATE_TIME_FORMAT);

        let currentItem = {
          ...defaultValues,
          ...editValues,
        };

        const transferItem = {
          id: inventoryId,
          transferDate: date,
          doses: Number(currentItem.doses),
          notes: currentItem.notes,
          inventoryId: inventoryId,
          clinicId: currentItem.clinicId,
          dateEntered: date,
          enteredBy: userId
        };

        const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT)?.format(DATE_DB_FORMAT);
        const endDate = dayjs().format(DATE_DB_FORMAT);

        await inventoryTransferCreate({
          variables: transferItem,
          update: cacheAdd(getTransferCacheQueries(), 'transfers'),
          refetchQueries: getTransferRefetchQueries(startDate, endDate)
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [inventoryId, inventoryTransferCreate, handleClose, isChanged, defaultValues, editValues, setIsChanged, userId]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey}/></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  const handleDateChange = (date) => {
    setEditValues(prevState => ({...prevState, transferDate: date}));
    setIsChanged(true);
  };

  if (inventory?.mfgBrand) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'inventory.transfer.title'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
            <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
              {getAlert()}
              <InventoryCardBasic inventory={inventory} />
              <Card sx={{border: 'thin solid #707070', mt: 2, pt: 1, pr: 3, pl: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <CardContent>
                  <Grid>
                    <TypographyFHG variant={'subtitle2'} id={'inventory.transfer.to.label'} color={'textSecondary'} />
                  </Grid>
                  <Grid>
                    <Grid sx={{m: 0, pt: 1}}>
                      <KeyboardDatePickerFHG
                        key={'transferDate1'}
                        name={'transferDate'}
                        labelKey={'inventory.transfer.date.label'}
                        onChange={handleDateChange}
                        value={editValues?.transferDate || dayjs(defaultValues?.transferDate)}
                      />
                    </Grid>
                    <Autocomplete
                      key="clinics"
                      fullWidth
                      getOptionLabel={(option) => option?.name || 'n/a'}
                      options={clinics}
                      onChange={handleClinicChange}
                      value={getClinic()}
                      sx={{mt: 2, mb: 0}}
                      renderInput={(params) => (
                        <TextField{...params} placeholder='Search clinics' size="small" variant="outlined" />
                      )}
                      required
                    />
                  </Grid>
                </CardContent>
              </Card>
            <Grid sx={{mt: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="doses"
                name="doses"
                labelKey="inventory.transfer.doses.label"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                onChange={handleDosesChange}
                value={editValues.doses}
                required
              />
            </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="notes"
                name="notes"
                labelKey="inventory.transfer.notes.label"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                multiline
                rows="2"
                onChange={handleChange}
                value={editValues.notes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
        </Grid>
      </Form>
    );
  } else {
    return null;
  }
}

InventoryTransfer.propTypes = {
  id: PropTypes.any,
  onClose: PropTypes.func.isRequired
}
