import gql from 'graphql-tag';

export const CLINIC_BARCODE_FRAGMENT = gql`
  fragment barcodeInfo on ReservedBarcodes {
    id
    clinicBarcode
  }
`;

export const CLINICS_FRAGMENT = gql`
  fragment clinicInfo on Clinics {
    id: clinicID
    name
    phone
    dateEntered
    enteredBy
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const CVX_FRAGMENT = gql`
  fragment cvxInfo on Cvx {
    id: cvxId
    dateEntered
    vaccineStatus
    shortDescription
    description
    cvxStatus {
      id: statusId
      statusId
      title
    }
    dateCvxLastUpdated
    notes
    cvxCode
    enteredBy
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const CVX_STATUS_FRAGMENT = gql`
  fragment cvxStatusInfo on CvxStatus  {
    id: statusId
    statusId
    title
    addedDate
  }
`;

export const ADJUST_FRAGMENT = gql`
  fragment adjustInfo on Adjust {
    id: adjustID
    adjustType
    adjustDate
    doses
    reason
    notes
    inventoryId
    enteredBy
    dateEntered
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const ADJUSTED_INVENTORY_FRAGMENT = gql`
  fragment adjustmentInfo on AdjustedInventory {
    id: adjustID
    adjustID
    adjustType
    adjustDate
    doses
    reason
    notes
    inventoryId
    enteredBy
    dateEntered
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const DASHBOARD_FRAGMENT = gql`
  fragment dashboardInfo on Dashboard_report {
    id
    mfgID
    mfgBrand
    mfgGeneric
    manufacturerCode
    mfgBarcode
    reorderThreshold
    cvxCode
    cvxName
    type1
    type2
    type3
    type4
    type5
    type6
    type7
    type8
    type9
    type10
  }
`;

export const DASHBOARD_EXPIRING_FRAGMENT = gql`
  fragment dashboardExpiringInfo on DashboardExpiring_report {
    id
    clinicBarcode
    colorCode
    cvxName
    cvxCode
    doses
    inventoryId  
    lot  
    mfgBrand
    mfgGeneric
    mfgName
    mfgBarcode
    manufacturerCode
    type  
    typeId
    expDate: expdate
  }
`;

export const DASHBOARD_LOW_INVENTORY_FRAGMENT = gql`
  fragment dashboardLowInventoryInfo on DashboardLowInventory_List {
    mfgID
    name
    doses
    threshold
  }
`;

export const DASHBOARD_STATUS_FRAGMENT = gql`
  fragment dashboardStatusInfo on DashboardStatus_report {
    id
    successful
    rejected
    last_reported_date
    seven_day_success
    seven_day_not_started
    seven_day_pending
    seven_day_other
    seven_day_failure
    seven_day_total
  }
`;

export const DISPENSED_DETAIL_FRAGMENT = gql`
  fragment dispensedDetailInfo on DispensedDetail {
    id
    name
    mfgBrand
    mfgGeneric
    mfgBarcode
    dateGiven
    dosesGiven
    cvxCode
    lot
    clinicBarcode
    inventoryType
    colorCode
  }
`;

export const ENTY_PERSON_FRAGMENT = gql`
  fragment entyPersonInfo on Enty_person {
    id: entity_ID
    person_ID
    person_LastName
    person_FirstName
    person_DateOfBirth
  }
`;

export const INSURANCE_FRAGMENT = gql`
  fragment insuranceCategoriesInfo on InsuranceCategories {
    id: insuranceCategoryID
    dateEntered
    name
    notes
    financialClass
    financialClassDescription
    lastUpdatedBy
    dateLastUpdated
    enteredBy
  }
`;

export const INVENTORY_FRAGMENT = gql`
  fragment inventoryInfo on Inventory {
    id: vaccineID
    lot
    addedDate
    doses
    clinicBarcode
    expDate
    notes
    typeId
    mfgId
    originalDoses
    lastUpdatedBy
    dateLastUpdated
    enteredBy
    dateEntered
  }
`;

export const V_INVENTORY_FRAGMENT = gql`
  fragment v_inventoryInfo on V_inventory {
    id: vaccineID
    vaccineID
    lot
    addedDate
    doses
    transferredDoses
    expiredDoses
    wastedDoses
    longDoses
    shortDoses
    borrowDoses
    paybackDoses
    clinicBarcode
    expDate
    notes
    originalDoses
    typeId
    type
    colorCode
    mfgId
    mfgBarcode
    mfgBrand
    mfgGeneric
    isStandardDosage
    standardDose
    manufacturerId
    manufacturerName
    manufacturerCode
    cvxId
    cvxCode
    cvxName
    cvxShortDescription
    enteredBy
    enteredByName
    dateEntered
    lastUpdatedBy
    lastUpdatedByName
    dateLastUpdated
  }
`;

export const INVENTORY_TYPE_FRAGMENT = gql`
  fragment inventoryTypeInfo on InventoryType {
    id: inventoryTypeID  
    inventoryTypeID
    dateEntered
    name
    colorCode
    active
    fundingSourceValue
    fundingSourceDescription
    lastUpdatedBy
    dateLastUpdated
    enteredBy
  }
`;

export const LOAN_REASON_FRAGMENT = gql`
  fragment loanReasonInfo on LoanReason {
    id: reasonID
    dateEntered
    enteredBy
    reasonName
    active: reasonActive
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const LOANS_FRAGMENT = gql`
  fragment loansInfo on Loans {
    id: loanID
    loanID
    loanDate
    doses
    notes
    paid
    reasonId
    loanReason {
      id: reasonID
      reasonName
    }
    inventoryId
    v_inventory {
      id: vaccineID
      inventoryId: vaccineID
      clinicBarcode
      cvxCode
      cvxName
      expDate
      doses
      lot
      manufacturerCode
      mfgBrand
      mfgBarcode
      mfgGeneric
      type
      typeId
    }
    loanToInventoryId
    loanToInventory {
      id: vaccineID
      inventoryId: vaccineID
      clinicBarcode
      cvxCode
      cvxName
      expDate
      doses
      lot
      manufacturerCode
      mfgBrand
      mfgBarcode
      mfgGeneric
      type
      typeId
    }
    immunization {
      patientId
      person_FirstName
      person_LastName
      person_DateOfBirth
    }
    payback {
      paybackDate
      paybackFromInvId
      paybackToInvId
    }
    dateEntered
    enteredBy
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const V_LOAN_FRAGMENT = gql`
  fragment vLoanInfo on V_Loan {
    id: loanID
    loanID
    loanDate
    doses
    notes
    paid
    reasonId
    reasonName
    paybackDate
    paybackNotes
    paybackId
    lfiInventoryId
    lfiClinicBarcode
    lfiColorCode
    lfiCvxCode
    lfiCvxName
    lfiDoses
    lfiExpDate
    lfiLot
    lfiManufacturerCode
    lfiMfgBarcode
    lfiMfgBrand
    lfiMfgGeneric
    lfiType
    lfiTypeId    

    ltiInventoryId
    ltiClinicBarcode
    ltiColorCode
    ltiCvxCode
    ltiCvxName
    ltiDoses
    ltiExpDate
    ltiLot
    ltiManufacturerCode
    ltiMfgBarcode
    ltiMfgBrand
    ltiMfgGeneric
    ltiType
    ltiTypeId    

    pfiInventoryId
    pfiClinicBarcode
    pfiColorCode
    pfiCvxCode
    pfiCvxName
    pfiDoses
    pfiExpDate
    pfiLot
    pfiManufacturerCode
    pfiMfgBarcode
    pfiMfgBrand
    pfiMfgGeneric
    pfiType
    pfiTypeId    

    ptiInventoryId
    ptiClinicBarcode
    ptiColorCode
    ptiCvxCode
    ptiCvxName
    ptiDoses
    ptiExpDate
    ptiLot
    ptiManufacturerCode
    ptiMfgBarcode
    ptiMfgBrand
    ptiMfgGeneric
    ptiType
    ptiTypeId    

    lie_patientId
    lie_person_FirstName
    lie_person_LastName
    lie_person_DateOfBirth
    lie_financial_Class
    
    pie_patientId
    pie_person_FirstName
    pie_person_LastName
    pie_person_DateOfBirth
    pie_financial_Class
  }
`;

export const PAYBACKS_FRAGMENT = gql`
  fragment paybacksInfo on PayBacks {
    id: payBackId
    paybackId: payBackId
    loanId
    paybackDate
    paybackNotes
    paybackFromInvId
    paybackToInvId
    paybackDoses
    enteredBy
    dateEntered
    lastUpdatedBy
    dateLastUpdated
    fromInventory {
      id: vaccineID
      inventoryId: vaccineID
      clinicBarcode
      cvxCode
      cvxName
      expDate
      doses
      lot
      manufacturerCode
      mfgBrand
      mfgBarcode
      mfgGeneric
      type
      typeId
    }
    toInventory {
      id: vaccineID
      inventoryId: vaccineID
      clinicBarcode
      cvxCode
      cvxName
      expDate
      doses
      lot
      manufacturerCode
      mfgBrand
      mfgBarcode
      mfgGeneric
      type
      typeId
    }
    immunization {
      patientId
      person_FirstName
      person_LastName
      person_DateOfBirth
    }
  }
`;

export const MANUFACTURER_FRAGMENT = gql`
  fragment manufacturerInfo on Manufacturer {
    id: manufacturerId
    name
    code
    dateEntered
    status
    notes
    enteredBy
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const MFGVACCINE_FRAGMENT = gql`
  fragment mfgVaccineInfo on MfgVaccine {
    id: mfgID
    mfgID
    manufacturerId
    cvxId
    dateEntered
    mfgBarcode
    mfgBrand
    mfgGeneric
    isStandardDosage
    standardDose
    isHide
    isNexMonthHide
    reorderThreshold
    enteredBy
    lastUpdatedBy
    dateLastUpdated
    cvx {
      cvxId
      cvxCode
      shortDescription
    }
    manufacturer {
      manufacturerId
      name
    }
  }
`;

export const PATIENTRECORD_FRAGMENT = gql`
  fragment patientRecordFullInfo on V_patientRecord {
    id: administerID
    dosesGiven
    dateGiven
    age
    notes
    inventoryId
    administeredBy
    insuranceId
    fluDoses
    patientId
    clinicBarcode
    expDate
    lot
    doses
    cvxName
    cvxCode
    mfgBrand: brand
    mfgGeneric: generic
    mfgBarcode
    isStandardDosage
    standardDose
    manufacturerName
    manufacturerCode
    type
    typeId
    colorCode: colorcode
    insurance
    patientName
    patientNameFirst
    patientNameLast
    previousName
    patientSex
    patientDob
    topsPatientID
    dtModifiedDate
    modifyBy
    dtCreationDate
    createdBy
    inCreatedBy
    processingStatus
    immunizationExchangeId
  }
`;

export const PATIENT_RECORD_FRAGMENT = gql`
  fragment patientRecordInfo on PatientRecords {
    id: administerID
    dateGiven
    dosesGiven
    notes
    inventoryId
    insuranceId
  }
`;

export const V_PATIENT_RECORD_FRAGMENT = gql`
  fragment v_patientRecordInfo on V_patientRecord {
    id: administerID
    dateGiven
    mfgBarcode
    expDate
    lot
    dosesGiven
    fluDoses
    patientId
    patientName
    patientDob
    age
    notes
    insuranceId
    insurance
    administeredBy
    inventoryId
    type
    inCreatedBy
    dtCreationDate
    dtModifiedDate
  }
`;

export const VIEW_PATIENT_FRAGMENT = gql`
  fragment viewPatientsInfo on View_Patient {
    id: person_ID
    person_ID
    patientId
    person_LastName
    person_FirstName
    person_DateOfBirth
    enteredBy
    dateEntered
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const ROLES_FRAGMENT = gql`
  fragment roleInfo on Roles {
    id: roleId
    title
    description
    enteredBy
    dateEntered
    lastUpdatedBy
    dateLastUpdated
  }
`;

export const TRANSFERS_FRAGMENT = gql`
  fragment transfersInfo on Transfers {
    id: transferID
    transferDate
    doses
    notes
    inventoryId
    clinicId
    clinic {
      name
    }
    v_inventory {
      clinicBarcode
      cvxCode
      cvxName
      expDate
      doses
      lot
      manufacturerCode
      mfgBrand
      mfgBarcode
      mfgGeneric
      colorCode
      type
      typeId
    }
    dateEntered
    enteredBy
    lastUpdatedBy
    dateLastUpdated
  }
`;


export const USERS_FRAGMENT = gql`
  fragment usersInfo on Users {
    id: userID
    userId: userID
    userID
    userName
    dateEntered
    firstName
    lastName
    active
    enteredBy
    lastUpdatedBy
    dateLastUpdated
    roleId
    role {
      title
    }
  }
`;

export const EXPANDED_MONTHLY_FRAGMENT = gql`
  fragment expandedMonthlyInfo on Monthly_report {
      id: inventoryId
      name
      mfgId
      mfgBarcode
      isHide
      beginning_Doses
      ending_Doses
      received_Doses
      long_Short
      administered
      transferred
      wasted
      expired
      long
      short
      borrow
      payback
  }`;

export const MONTHLY_DETAIL_FRAGMENT = gql`
  fragment monthlyDetailInfo on Monthly_report_detail {
    id
    name
    mfgId
    detailDate
    mfgBarcode
    clinicBarcode
    doses
  }`;

export const MONTHLY_DETAIL_A_FRAGMENT = gql`
  fragment monthlyDetailAInfo on Monthly_report_detail_a {
    id
    name
    mfgId
    detailDate
    mfgBarcode
    clinicBarcode
    doses
    adjustType
  }`;

export const MONTHLY_DETAIL_B_FRAGMENT = gql`
    fragment monthlyDetailBInfo on Monthly_report_detail_b {
        id
        name
        mfgId
        detailDate
        mfgBarcode
        clinicBarcode
        doses
        typeTo
        typeFrom
    }`;

export const TRANSFERRED_REPORT_FRAGMENT = gql`
  fragment transferredInfo on Transfer_report {
    id: transferId
    transferDate
    doses
    notes
    inventoryId
    clinicBarcode
    lot
    expDate
    typeId
    typeName
    colorCode
    mfgBarcode
    mfgBrand
    mfgGeneric
    isStandardDosage
    standardDose
    manufacturerName
    manufacturerCode
    cvxCode
    cvxName
    cvxShortDescription
    clinicId
    clinicName
    clinicPhone
    clinicVFC
    enteredBy
    enteredByName
    dateEntered
    lastUpdatedBy
    lastUpdatedByName
    dateLastUpdated
  }`;

  export const WASTED_REPORT_FRAGMENT = gql`
  fragment wastedInfo on Wasted_report {
    id: adjustId
    adjustType
    adjustDate
    doses
    reason
    notes
    clinicBarcode
    mfgBarcode
    mfgBrand
    mfgGeneric
    isStandardDosage
    standardDose
    inventoryId
    expDate
    lot
    typeId
    typeName
    enteredBy
    enteredByName
    dateEntered
    lastUpdatedBy
    lastUpdatedByName
    dateLastUpdated
  }`;

  export const PI_REPORT_FRAGMENT = gql`
  fragment piInfo on Practice_info_report {
        practiceInfoID
        name
        contactName
        address
        phone
        inventoryNo
        providerName
        emailAddress
  }`;

  export const ENROLL_REPORT_FRAGMENT = gql`
  fragment enrollmentInfo on Enrollment_report {
        type
        lessThanOne
        oneToSix
        sevenToEighteen
        totalByType
        typeId
   }`;

   export const STATE_STATUS_FRAGMENT = gql`
   fragment v_stateStatus on V_immunizationexchange_list {
    id: immunizationExchangeId
    administerID
    administeredDate
    vaxName
    person_LastName
    person_FirstName
    processingStatus
    lot
    responseMessage
    outgoingMessage
    clinicBarcode
    inventoryId
    immunizationExchangeId
  }`;

   export const SEND_FRAGMENT = gql`
   fragment stateStatus on ImmunizationExchange {
      id: immunizationExchangeId
      immunizationExchangeId
      patientId
      administerId
      administerDate
      outgoingMessage
      outgoingMessageType
      processingStatus
      responseMessage
      reportedDate
   }`;

export const V_CHART_IMMUNIZATION_FRAGMENT = gql`
  fragment chartInfo on V_chrt_immunization {
    patientID
    person_ID
    person_LastName
    person_FirstName
    person_DateOfBirth
    isDuplicateProduct
    lotNumber
    lotNumber_DateExpireDay
    lotNumber_DateExpireMonth
    lotNumber_DateExpireYear
    immunization_DateDay
    immunization_DateMonth
    immunization_DateYear
    organization_Name
    immuneType_CVX
    immuneType_Description
    vFCEligibility_Code
  }`;
