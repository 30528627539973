import React, {useCallback, useMemo, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import EditDrawer from '../EditDrawer';
import filter from 'lodash/filter';
import {Link, Switch} from '@mui/material';
import {formatMessage} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import orderBy from 'lodash/orderBy';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import UserAdd from "./UserAdd";
import UserEdit from './UserEdit';
import {USERS_QUERY} from '../../data/QueriesGL';
import {
  ACTIVE_STRING,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR, BGD_COLOR
} from '../../Constants';

export default function Users() {
  const intl = useIntl();
  const [displayInactive, setDisplayInactive] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [userData] = useQueryFHG(USERS_QUERY, {variables: {}}, 'user.type');

  const users = useMemo(() => {
    let data = userData?.users || [];
    let rows = displayInactive ? data : filter(data, {active: ACTIVE_STRING});
    let filteredRows = [];
    if (rows && rows.length > 0) {
      filteredRows = rows.map(obj => ({...obj, activeValue: obj.active === ACTIVE_STRING ? 'Active' : 'Inactive'}));
    }

    return orderBy(filteredRows, [row => row.lastName.toLowerCase()], ['asc']);
  }, [userData, displayInactive]);

  const handleEdit = useCallback((id) => {
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, [setSelectedId, setOpenDetailDrawer]);

  const columns = useMemo(() => {
    return [
      {
        id: 'firstName',
        Header: <TypographyFHG id={'users.firstName.column'}/>,
        accessor: 'firstName',
        width: 100
      },
      {
        id: 'lastName',
        Header: <TypographyFHG id={'users.lastName.column'}/>,
        accessor: 'lastName',
        align: 'right',
        width: 100
      },
      {
        id: 'userName',
        Header: <TypographyFHG id={'users.userName.column'}/>,
        accessor: 'userName',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.id)}>{row.values?.userName}</Link>)
      },
      {
        id: 'title',
        Header: <TypographyFHG id={'users.role.column'}/>,
        accessor: 'role.title',
        weighting: 2,
        width: 100
      },
      {
        id: 'active',
        Header: <TypographyFHG id={'users.status.column'}/>,
        accessor: 'activeValue',
        width: 50,
      }
    ];
  }, [handleEdit]);

  const handleAdd = () => {
    setSelectedId('new');
    setOpenDetailDrawer(true);
  };

  const handleDetailClose = () => {
    setOpenDetailDrawer(false);
  }

  const handleActiveToggle = () => {
    setDisplayInactive(value => !value);
  }

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return !displayInactive ? undefined : {fontWeight: "bold"};
    } else {
      return !displayInactive ? {fontWeight: "bold"} : undefined;
    }
  }, [displayInactive]);

  return (
    <Grid container direction={'column'}>
      <Grid container direction="row">
        <TypographyFHG id={'users.title'} color="textSecondary" sx={{mt: 0, mr: 0, mb: -0.5, ml: 2}} variant="h5" />
      </Grid>
      <Grid sx={{mb: 1}} >
        <TableFHG name={"Users"} columns={columns} data={users} allowSearch emptyTableMessageKey={'users.noRecord.message'} fullWidth stickyHeader>
          <Grid container direction="row" justifyContent="flex-start">
              <ButtonFHG id="users.add.button" labelKey={'users.add.button'} startIcon={(<AddIcon />)} onClick={handleAdd}
                         sx={{m: 0, fontSize: '0.875rem', '&:hover': {color: PRIMARY_DARK_COLOR}}}
                         variant="outlined" />
            <Grid container direction="row" sx={{my: 0, mr: 0, ml: 2, pt: 0.75, width: '320px'}}>
              <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
              <Switch color="primary" checked={!displayInactive} onChange={handleActiveToggle} sx={{mt: -0.75, mx: 0, width: '62px'}} />
              <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'slider.active.label', 'Display Active Only', null)}</Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>
      <EditDrawer
          backgroundColor={BGD_COLOR}
          ModalProps={{BackdropProps: {style: {height: '100%'}}}}
          open={openDetailDrawer}
          onClose={handleDetailClose}
      >
        <Grid item container direction={'column'} overflow={'visible'} style={{width: EDIT_DRAWER_WIDTH}}>
          {selectedId === 'new' ? <UserAdd users={userData?.users || []} onClose={handleDetailClose} /> : <UserEdit id={selectedId} users={userData?.users || []} onClose={handleDetailClose} />}
        </Grid>
      </EditDrawer>
    </Grid>
   );
}
