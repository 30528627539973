import React, {useCallback, useMemo, useState} from 'react';
import BackIcon from '@mui/icons-material/ArrowBack';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import DashboardIcon from '@mui/icons-material/Dashboard';
import * as dayjs from 'dayjs';
import {Grid} from '@mui/material';
import includes from 'lodash/includes';
import Loading from '../../fhg/components/Loading';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useNavigate} from 'react-router-dom';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {
  MONTHLY_DETAIL_ADMINSTERED_QUERY,
  MONTHLY_DETAIL_ADJUSTED_QUERY,
  MONTHLY_DETAIL_RECEIVED_QUERY,
  MONTHLY_DETAIL_TRANSFERRED_QUERY,
  MONTHLY_DETAIL_LONG_SHORT_QUERY,
  MONTHLY_DETAIL_BORROWED_FROM_QUERY,
  MONTHLY_DETAIL_BORROWED_TO_QUERY,
  MONTHLY_DETAIL_PAIDBACK_FROM_QUERY,
  MONTHLY_DETAIL_PAIDBACK_TO_QUERY
} from '../../data/QueriesGL';
import {
  ADMIN_DASHBOARD_PATH, ADMINISTER,
  DATE_FORMAT_KEYBOARD,
  RECEIVE, TRANSFER,
  WASTED, EXPIRED, LONG_SHORT,
  BORROW, PAYBACK
} from '../../Constants';

export default function InventoryRptDetail(props) {
  const navigate = useNavigate();
  const {
    detailType, mfgId, mfgName, onReturn,
    typeId, verifyDate, lastVerificationDate, fromTo
  } = props;
  const [loading, setLoading] = useState(true);
  const adjustment = useMemo(() => {
    return [WASTED, EXPIRED];
  }, []);
  const receiveOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== RECEIVE
    }
  }, [detailType, mfgId, typeId, verifyDate, lastVerificationDate]);
  const administerOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== ADMINISTER
    }
  }, [detailType, mfgId, typeId, verifyDate, lastVerificationDate]);
  const borrowFromOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== BORROW && fromTo !== 'from'
    }
  }, [detailType, fromTo, mfgId, typeId, verifyDate, lastVerificationDate]);
  const borrowToOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== BORROW && fromTo !== 'to'
    }
  }, [detailType, fromTo, mfgId, typeId, verifyDate, lastVerificationDate]);
  const paybackFromOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== PAYBACK && fromTo !== 'from'
    }
  }, [detailType, fromTo, mfgId, typeId, verifyDate, lastVerificationDate]);
  const paybackToOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== PAYBACK && fromTo !== 'to'
    }
  }, [detailType, fromTo, mfgId, typeId, verifyDate, lastVerificationDate]);
  const transferOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== TRANSFER
    }
  }, [detailType, mfgId, typeId, verifyDate, lastVerificationDate]);
  const longShortOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate}, skip: detailType !== LONG_SHORT
    }
  }, [detailType, mfgId, typeId, verifyDate, lastVerificationDate]);
  const adjustedOptions = useMemo(() => {
    return {
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {mfgId, typeId, verifyDate, lastVerificationDate, adjustType: detailType},
      skip: !includes(adjustment, detailType)
    }
  }, [adjustment, detailType, mfgId, typeId, verifyDate, lastVerificationDate]);
  const [receiveData] = useQueryFHG(MONTHLY_DETAIL_RECEIVED_QUERY, receiveOptions, 'inRpt.detail.type');
  const [administerData] = useQueryFHG(MONTHLY_DETAIL_ADMINSTERED_QUERY, administerOptions, 'inRpt.detail.type', loading);
  const [borrowFromData] = useQueryFHG(MONTHLY_DETAIL_BORROWED_FROM_QUERY, borrowFromOptions, 'inRpt.detail.type', loading);
  const [borrowToData] = useQueryFHG(MONTHLY_DETAIL_BORROWED_TO_QUERY, borrowToOptions, 'inRpt.detail.type', loading);
  const [paidbackFromData] = useQueryFHG(MONTHLY_DETAIL_PAIDBACK_FROM_QUERY, paybackFromOptions, 'inRpt.detail.type', loading);
  const [paidbackToData] = useQueryFHG(MONTHLY_DETAIL_PAIDBACK_TO_QUERY, paybackToOptions, 'inRpt.detail.type', loading);
  const [transferData] = useQueryFHG(MONTHLY_DETAIL_TRANSFERRED_QUERY, transferOptions, 'inRpt.detail.type', loading);
  const [longShortData] = useQueryFHG(MONTHLY_DETAIL_LONG_SHORT_QUERY, longShortOptions, 'inRpt.detail.type', loading);
  const [adjustData] = useQueryFHG(MONTHLY_DETAIL_ADJUSTED_QUERY, adjustedOptions, 'inRpt.detail.type', loading);

  const rows = useMemo(() => {
    let data = undefined;
    switch (detailType)
    {
      case RECEIVE:
        data = receiveData ? receiveData.details : undefined;
        break;
      case ADMINISTER:
        data = administerData ? administerData.details : undefined;
        break;
      case BORROW:
        data = fromTo === 'from' ? borrowFromData ? borrowFromData.details : undefined : borrowToData ? borrowToData.details : undefined;
        break;
      case PAYBACK:
        data = fromTo === 'from' ? paidbackFromData ? paidbackFromData.details : undefined : paidbackToData ? paidbackToData.details : undefined;
        break;
      case TRANSFER:
        data = transferData ? transferData.details : undefined;
        break;
      case LONG_SHORT:
        data = longShortData ? longShortData.details : undefined;
        break;
      case WASTED:
      case EXPIRED:
        data = adjustData ? adjustData.details : undefined;
        break;
      default:
        data = receiveData ? receiveData.details : undefined;
    }

    if (loading && data !== undefined) {
      setLoading(false);
    }
    data = orderBy(data, ['detailDate', 'clinicBarcode']);
    return data;
  }, [fromTo, loading, detailType, administerData, adjustData, borrowFromData, borrowToData, paidbackFromData, paidbackToData, longShortData, receiveData, transferData]);

  const handleDashboard = useCallback(() => {
    const location = {
      pathname: ADMIN_DASHBOARD_PATH
    };
    navigate(location)
  }, [navigate]);

  const handleBack = useCallback(() => {
    if (onReturn) {
      onReturn();
    }
  }, [onReturn]);

  const columns = useMemo(() => {
    let cols = [
      {
        id: 'name',
        Header: <TypographyFHG id={'inv.rpt.detail.name'}/>,
        accessor: 'name'
      },
      {
        id: 'detailDate',
        Header: <TypographyFHG id={'inv.rpt.detail.date'}/>,
        accessor: 'detailDate',
        width: 50,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{dayjs(row.values?.detailDate).format(DATE_FORMAT_KEYBOARD)}</span>
        )
      },
      {
        id: 'mfgBarcode',
        Header: <TypographyFHG id={'inv.rpt.detail.mfgBarcode'}/>,
        accessor: 'mfgBarcode',
      },
      {
        id: 'clinicBarcode',
        Header: <TypographyFHG id={'inv.rpt.detail.clinicBarcode'}/>,
        accessor: 'clinicBarcode',
      },
      {
        id: 'doses',
        Header: <TypographyFHG id={'inv.rpt.detail.doses'}/>,
        accessor: 'doses',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.doses}</span>
        )
      }
    ];

    if (detailType === LONG_SHORT) {
        cols.push({
          id: 'adjustType',
          Header: <TypographyFHG id={'inv.rpt.detail.action'}/>,
          accessor: 'adjustType',
        });
    }
    if ((detailType === BORROW) || (detailType === PAYBACK)) {
      cols.push({
        id: 'typeFrom',
        Header: <TypographyFHG id={'inv.rpt.detail.typeFrom'}/>,
        accessor: 'typeFrom',
      });
      cols.push({
        id: 'typeTo',
        Header: <TypographyFHG id={'inv.rpt.detail.typeTo'}/>,
        accessor: 'typeTo',
      });
    }
    return cols;
  }, [detailType]);

  const getDetailTypeDisplay = useCallback(() => {
    if (detailType === LONG_SHORT) {
      return 'Long/Short';
    } else {
      return detailType.charAt(0).toUpperCase() + detailType.slice(1);
    }
  }, [detailType]);

  return (
    <Grid container direction={'column'} overflow={'visible'} wrap={'nowrap'}>
      <Loading isLoading={loading}/>
      <Grid container direction="row" justifyContent={'space-between'}>
        <TypographyFHG  id="reports.inventory.detail.title" color="textSecondary" sx={{mt: 1, mr: 0, mb: 0, ml: 1}} variant="h5" />
        <TypographyFHG color="textPrimary" sx={{mt: 1, mr: 0, mb: 0, ml: 1}} variant="subtitle1">
          &nbsp;&nbsp;{mfgName}&nbsp;-&nbsp;{getDetailTypeDisplay()}
        </TypographyFHG>
        <Grid item sx={{mt: -1}}>
          <ButtonFHG id="back.button" labelKey={'back.button'}
                     color="primary" size="small"
                     startIcon={(<BackIcon />)} onClick={handleBack} variant="outlined" />
          <ButtonFHG id="dashboard.button" labelKey={'dashboard.button'}
                     color="primary" size="small"
                     startIcon={(<DashboardIcon />)} onClick={handleDashboard} variant="outlined" />
        </Grid>
      </Grid>
      <Grid item>
        <TableFHG name="MonthlyDetail" columns={columns} data={rows}
                  allowSearch={false} fullWidth stickyHeader
                  emptyTableMessageKey={'inv.rpt.detail.noRecord.message'}
        />
      </Grid>
    </Grid>);
}

InventoryRptDetail.propTypes = {
  detailType: PropTypes.string,
  mfgId: PropTypes.number,
  mfgName: PropTypes.string,
  onReturn: PropTypes.func,
  typeId: PropTypes.number,
  verifyDate: PropTypes.string,
  lastVerificationDate: PropTypes.string,
  fromTo: PropTypes.string
};