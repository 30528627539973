import React from 'react';
import ButtonFHG from '../fhg/components/ButtonFHG';
import PropTypes from "prop-types";

ButtonCustom.propTypes = {
   labelKey: PropTypes.string
};

export default function ButtonCustom({labelKey, children, ...buttonProps}) {
   return (
      <ButtonFHG labelKey={labelKey} color='primary' size='large' sx={{textDecoration: 'underline', '&:hover': {textDecoration: 'underline'}}} {...buttonProps}/>
   );
}
