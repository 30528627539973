import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {errorState} from '../utils/Utils';
import IconButton from '@mui/material/IconButton';
import {lighten} from '@mui/material/styles';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Typography from './Typography';
import {useRecoilValue} from 'recoil';
import {ERROR_COLOR} from '../../Constants';

ErrorStateSnackbar.propTypes = {
   open: PropTypes.bool,
   enableRefresh: PropTypes.bool,
   onClose: PropTypes.func,
   messageKey: PropTypes.string,
   message: PropTypes.string,
   values: PropTypes.object,
};

/**
 * Component to show the error messages.
 *
 * Note:
 *    Message is the default property in values.
 *
 * Reviewed:
 *
 * open Indicates if the Error snackbar should be shown to the user.
 * onClose Callback when the snackbar is closed.
 * messageKey Intl ID for the error message.
 * values Value object for the error message.
 * message Text for the error message.
 * enableRefresh Indicates if the Refresh action should be shown / enabled.
 * error The error that occurred.
 * errorInfo The errorInfo for the error that occurred.
 */
export default function ErrorStateSnackbar() {
   const [open, setOpen] = useState(false);

   const errorStateValue = useRecoilValue(errorState);
   const {error, errorKey, errorMessage, values, enableRefresh, errorInfo} = errorStateValue;

   useEffect(() => {
       if (errorStateValue && error) {
          setOpen(true);
       }
   }, [errorStateValue, error]);

   /**
    * Force a browser reload.
    */
   const handleRefresh = () => {
      window.location.reload();
   };

   const handleClose = (event) => {
       setOpen(false);
   };

   return (
      <Snackbar
         open={open}
         anchorOrigin={{vertical: 'top', horizontal: 'center'}}
         ContentProps={{
           'aria-describedby': 'message-id',
           sx: {backgroundColor: `${lighten(ERROR_COLOR, 0.15)} !important`}
         }}
         message={<>
            <Typography id={errorKey} values={values} color={'inherit'}>{errorMessage}</Typography>
            {(error || errorInfo) && (
               <details style={{whiteSpace: 'pre-wrap'}}>
                  {error?.toString()}
                  <br/>
                  {errorInfo?.componentStack}
               </details>
            )}
         </>}
         action={[
            enableRefresh && <Button key='undo' color='inherit' size='small' onClick={handleRefresh}>
               <Typography color='inherit' id='refresh'>Refresh</Typography>
            </Button>,
            <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  sx={{
                     position: 'absolute',
                     right: 0,
                     top: 0,
                     ml: 'auto',
                     zIndex: 1001,
                  }}
                  onClick={handleClose}
               >
                  <CloseIcon/>
               </IconButton>,
         ]}
      />
   );
};
