import React, {useCallback, useMemo, useState, useEffect} from 'react';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import Checkbox from '../../fhg/components/CheckboxFHG';
import CloseIcon from '@mui/icons-material/Close';
import * as dayjs from 'dayjs';
import {Dialog, DialogTitle, IconButton, Link, SvgIcon} from '@mui/material';
import EditDrawer from '../EditDrawer';
import find from 'lodash/find';
import FormGroup from '@mui/material/FormGroup';
import {getStatus, getStatusReverse, toNumber} from '../../fhg/utils/Utils';
import { grey } from '@mui/material/colors';
import {Grid} from '@mui/material';
import InventoryPatients from '../inventory/InventoryPatients';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import Loading from '../../fhg/components/Loading';
import NotesIcon from '@mui/icons-material/AssignmentOutlined';
import PatientRecordEdit from '../patientRecords/PatientRecordEdit';
import SearchIcon from '@mui/icons-material/Search';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getSendToStateRefetchQueries,
  STATE_STATUS_QUERY,
  STATE_SEND_MUT,
  SEND_TO_STATE_STATUS_UPDATE
} from '../../data/QueriesGL';
import {
  BGD_COLOR,
  DATE_DB_FORMAT,
  DISMISSED, EDIT_DRAWER_WIDTH,
  ERROR,
  NOT_STARTED,
  NOT_STARTED_DESC,
  PENDING, PRIMARY_DARK_COLOR,
  REJECTED,
  REJECTED_DESC,
  SUCCESSFUL_DESC,
  SUPERVISOR_ROLE_ID
} from '../../Constants';

export default function StateStatusReport() {
  const [selectAll, setSelectAll] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [include, setInclude] = useState([REJECTED]);
  const [isSaving, setIsSaving] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(dayjs());
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [note, setNote] = useState('');
  const [sendMessage, setSendMessage] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [checked, setChecked] = useState({
    SUCCESSFUL: false,
    NOT_STARTED: false,
    REJECTED: true
  });
  const [sendToStateList] = useMutationFHG(STATE_SEND_MUT, undefined, true);
  const [updateSendToState, {data: sendToStateUpdateData, error: sendToStateUpdateError}] = useMutationFHG(SEND_TO_STATE_STATUS_UPDATE, undefined, true);

  const [options, setOptions] = useState({
    variables: {
      dateGivenStart: startDate,
      dateGivenEnd: endDate,
      includeProcess: include
    },
    fetchPolicy: "no-cache"
  }, [startDate, endDate, include]);

  const [loading, setLoading] = useState(true);
  const [exchangeData] = useQueryFHG(STATE_STATUS_QUERY, options, 'status.type', loading);
  const [exData, setExData] = useState([]);
  const [selectedId, setSelectedId] = useState(undefined);
  const [drawerAction, setDrawerAction] = useState('');
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [{isAdmin, roleId}] = useRecoilState(userStatus);
  const isSupervisor = roleId === SUPERVISOR_ROLE_ID;

  const getFlyoutContent = () => {
    let content, date = '';
    switch (drawerAction) {
      case 'barcode':
        content = (<InventoryPatients id={selectedId} onClose={handleDetailClose} />);
        break;
      default:
        content = (<PatientRecordEdit id={selectedId} onClose={handleDetailClose} startDate={date} endDate={date} />);
    }
    return content;
  };

  const handleBarcodeLinkClick = useCallback((id) => {
    setDrawerAction('barcode');
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, [setOpenDetailDrawer]);

  const handleStatusLinkClick = useCallback((administerId) => {
    let val = toNumber(administerId);
    setDrawerAction('status');
    setSelectedId(val);
    setOpenDetailDrawer(true);
  }, [setOpenDetailDrawer]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleNoteClick = useCallback((value) => {
    setNote(value.currentTarget.id);
    setIsNoteOpen(isNoteOpen => !isNoteOpen);
  }, [setNote, setIsNoteOpen]);

  const handleSelect = useCallback((event) => {
    let id = parseInt(event.target.id) || event;

    if (event.target.checked) {
      processList.push(id);
    } else {
      const idx = processList.indexOf(id);
      if (idx > -1) {
        processList.splice(idx, 1);
      }
    }

    setProcessList(processList);

    const records = exchangeData?.exchanges || [];

    let recs = [];
    if (records.length > 0) {
      for (let i = 0; i < records.length; i++) {
        let msg = records[i];
        if (parseInt(msg.administerID) === id) {
          msg = Object.assign({}, records[i], {selected: event.target.checked});
        }
        recs.push(msg);
      }
    }
    setExData(recs);

  }, [exchangeData, processList, setExData]);

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
  }

  const CheckBoxBlankIcon = () => (
    <SvgIcon>
      <svg focusable="false" aria-hidden="true">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" fill="white"></path>
      </svg>
    </SvgIcon>
  );

  const CheckBoxCheckIcon = () => (
    <SvgIcon>
      <svg focusable="false" aria-hidden="true">
        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="white"></path>
      </svg>
    </SvgIcon>
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'administerID',
        Header: <Grid sx={{height: '16px', mt: -3, pl: 2, width: '15px'}}>
          <Checkbox icon={selectAll ? (<CheckBoxCheckIcon fillColor="white" />) : (<CheckBoxBlankIcon />)}
                    marginTop={1.5} size="small" onClick={handleSelectAll}  />
        </Grid>,
        accessor: 'administerID',
        weighting: 0,
        Cell: ({row}) => (
          row.original.processingStatus === REJECTED || row.original.processingStatus === NOT_STARTED
          || row.original.processingStatus === PENDING || row.original.processingStatus === ERROR ?
            row.original.selected ?
              <Grid sx={{pl: 2}}><Checkbox size="small" marginTop={-1} onClick={handleSelect} id={row.values.administerID} checked/></Grid>
            : <Grid sx={{pl: 2}}><Checkbox size="small" marginTop={-1} onClick={handleSelect} id={row.values.administerID}/></Grid>
            : <></>
        )
      },
      {
        id: 'person_LastName',
        Header: <TypographyFHG id={'stateStatus.person_LastName.column'} />,
        accessor: 'person_LastName',
        weighting: 1,
        width: 100,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{row.original.person_LastName}, {row.original.person_FirstName}</span>
        )
      },
      {
        id: 'vaxName',
        Header: <TypographyFHG id={'stateStatus.vaxName.column'}/>,
        accessor: 'vaxName',
        weighting: 1,
        width: 140,
        Cell: ({row}) => (<span style={{whiteSpace: 'normal'}}>{row.values.vaxName} - <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleBarcodeLinkClick(row?.original.inventoryId)}>{row.original.clinicBarcode}</Link></span>)
      },
      {
        id: 'administeredDate',
        Header: <TypographyFHG id={'stateStatus.administeredDate.column'}/>,
        accessor: 'administeredDate',
        weighting: 0,
        width: 100,
        Cell: ({row}) => (
          <span style={{whiteSpace: 'normal'}}>{dayjs(row.values?.administeredDate)?.format(DATE_DB_FORMAT)}</span>
        )
      },
      {
        id: 'processingStatus',
        Header: <TypographyFHG id={'stateStatus.processingStatus.column'}/>,
        accessor: 'processingStatus',
        weighting: 0,
        width: 100,
        Cell: ({row}) => (
          row.original.processingStatus === REJECTED ?
            (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleStatusLinkClick(row.values.administerID)}><span style={{whiteSpace: 'normal'}}>{getStatus(row.values.processingStatus)}</span></Link>) :
            (<span style={{whiteSpace: 'normal'}}>{getStatus(row.values.processingStatus)}</span>)
        )
      },
      {
        id: 'responseMessage',
        Header: <TypographyFHG id={'stateStatus.responseMessage.column'}/>,
        accessor: 'responseMessage',
        width: 40,
        Cell: ({row}) => (row || row.original.outgoingMessage ? <NotesIcon sx={{cursor: 'pointer'}} id={`RESPONSE:\n\n${row.original.responseMessage ? row.original.responseMessage : ''}\n\nSENT:\n\n${row.original.outgoingMessage}`} onClick={handleNoteClick}/> : null)
      }
    ]
  }, [handleBarcodeLinkClick, handleStatusLinkClick, handleSelect, handleNoteClick, selectAll]);

  useEffect(() => {
    const records = exchangeData?.exchanges || [];

    let recs = [];
    if (records.length > 0) {
      recs = records.map(x => Object.assign({}, x, {selected: selectAll}))
    }
    if (exchangeData?.exchanges) {
      setLoading(false);
    }
    setExData(recs);
    }, [exchangeData, selectAll, setExData]);

  useEffect(() => {
    const records = exchangeData?.exchanges || [];

    let recs = [];
    if (records.length > 0) {
      for (let i = 0; i < records.length; i++) {
        let msg = records[i];

        if (selectAll) {
          recs.push(parseInt(msg.administerID));
        }
      }
    }
    setProcessList(recs);

    }, [exchangeData, selectAll, setProcessList]);

  const handleStartDateChange = (date) =>  {
    setStartDate(date);
  }

  const handleEndDateChange = (date) =>  {
    setEndDate(date);
  };

  const handleChange = (event) => {
    setChecked({...checked, [event.target.name]: event.target.checked});

    const add = getStatusReverse(event.target.name);

    let localInclude = [...include];

    if (event.target.checked) {
      localInclude.push(add);
      setInclude(localInclude);
    } else {
      const idx = localInclude.indexOf(add);
      localInclude.splice(idx, 1);
      setInclude(localInclude);
    }
  };

  const handleNoteClose = () => {
    setIsNoteOpen(isNoteOpen => !isNoteOpen);
  };

  const handleSendMessageClose = () => {
    setSendMessage(sendMessage => !sendMessage);
  };


  const handleSearch = () => {
    setLoading(true);
    const options = {
      variables: {
        dateGivenStart: startDate,
        dateGivenEnd: endDate,
        includeProcess: include,
        dts: dayjs().format("MMDDYYYYhhmmsszzz")
      },
      fetchPolicy: "cache-and-network"
    }

    setOptions(options);
  };

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      let severity = (messageKey === 'stateStatus.dismissed.success.message') ? 'success' : 'error';
      result = <Alert severity={severity} onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  useEffect(() => {
    if (sendToStateUpdateData && !sendToStateUpdateError) {
      setMessageKey('stateStatus.dismissed.success.message');
      setOpenAlert(true);
    }
  }, [sendToStateUpdateData, sendToStateUpdateError]);

  const getExchangeId = useCallback((administerId) => {
    const records = exchangeData?.exchanges || [];
    let result = undefined;
    if (records.length > 0) {
      let msg = find(records, x => x.administerID === administerId?.toString());
      if (msg) {
        result = msg.immunizationExchangeId;
      }
    }
    return result;
  }, [exchangeData]);

  const dismissedExchangeRecord = useCallback(() => {
    try {
      if (processList && processList.length === 0) {
        setMessageKey('stateStatus.no.selection.message');
        setOpenAlert(true);
        return;
      } else if (processList && processList.length > 1) {
        setMessageKey('stateStatus.multiple.selection.message');
        setOpenAlert(true);
        return;
      }
      const administerId = processList[0];
      const exchangeId = getExchangeId(administerId);

      if (!exchangeId) {
        setMessageKey('stateStatus.invalid.selection.message');
        setOpenAlert(true);
        return;
      }

      let currentItem = {
        immunizationExchangeId: toNumber(exchangeId),
        administerId: administerId,
        processingStatus: toNumber(DISMISSED)
      };

      let variables = assign({}, options.variables, {dts: dayjs().format("MMDDYYYYhhmmsszzz")})
      setIsSaving(true);

      updateSendToState({
        variables: currentItem,
        refetchQueries: getSendToStateRefetchQueries(variables)
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  }, [getExchangeId, options, processList, updateSendToState]);

  const sendToState = async () => {
    setLoading(true);

    if (processList.length < 1) {
      return
    }

    await sendToStateList({
      variables: {processList: processList},
    }).then((response) => {
      if (response.data
          && response.data.exchanges 
          && response.data.exchanges.length > 0
          && response.data.exchanges[0].responseMessage) {
        setSendMessage(true);
        setMessage(response.data.exchanges[0].responseMessage);
      }
      setProcessList([]);
      setExData([]);
      setLoading(false);
    });
  };

  return (
    <>
    <Loading isLoading={loading}/>
      <Grid container direction={'column'}>
        <TypographyFHG id="stateStatus.title" color="textSecondary" variant="h5" sx={{my: 'auto', mx: 1}} gutterBottom/>
        <Grid>
          {isNoteOpen && (
            <Dialog open={isNoteOpen} onClose={handleNoteClose}
                    fullWidth maxWidth="xl">
              <DialogTitle>
                <IconButton aria-label="Close" onClick={handleNoteClose} sx={{position: 'absolute', right: 0.5, top: 0.5, color: grey[500]}}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <TypographyFHG sx={{borderBottom: '1px solid gray', pt: 1, px: 1, pb: 0}} variant="h6"/>
              <TypographyFHG sx={{py: 1, px: 2}} variant="body1"><pre style={{overflow: 'scroll'}}>{note}</pre></TypographyFHG>
            </Dialog>
          )}
          {sendMessage && (
            <Dialog open={sendMessage} onClose={handleSendMessageClose} fullWidth maxWidth="sm">
              <DialogTitle>
                <IconButton aria-label="Close" onClick={handleSendMessageClose} sx={{position: 'absolute', right: 0.5, top: 0.5, color: grey[500]}}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <TypographyFHG sx={{borderBottom: '1px solid gray', pt: 1, px: 1, pb: 0}} variant="h6"/>
              <TypographyFHG sx={{py: 1, px: 2}} variant="body1"><pre>{message}</pre></TypographyFHG>
            </Dialog>
          )}
          <Grid container direction={'row'} justifyContent={'space-between'}>
            <Grid container direction="row">
              <Grid sx={{mt: 0.5, mr: 0, mb: 0, ml: 1, pt: 1, px: 0, pb: 0, width: '160px'}}>
                <KeyboardDatePickerFHG
                  key={'currentMonth1'}
                  name={'startDate'}
                  label={'Start Date'}
                  onChange={handleStartDateChange}
                  value={startDate}
                />
              </Grid>
              <Grid sx={{mt: 0.5, mr: 0, mb: 0, ml: 1, pt: 1, px: 0, pb: 0, width: '160px'}}>
                <KeyboardDatePickerFHG
                  key={'endDate1'}
                  name={'endDate'}
                  label={'End Date'}
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </Grid>
              <Grid sx={{ml: 2, mt: 1}}>
                <FormGroup row>
                  <span style={{fontSize: '1rem', paddingTop: '8px', margin: '8px 16px 0 8px'}}>Include: </span>
                  <Checkbox marginTop={1} marginLeft={0} label={SUCCESSFUL_DESC} checked={checked.SUCCESSFUL} onChange={handleChange} name="SUCCESSFUL" />
                  <Checkbox marginTop={1} marginLeft={0} label={REJECTED_DESC} checked={checked.REJECTED} onChange={handleChange} name="REJECTED"/>
                  <Checkbox marginTop={1} marginLeft={0} label={NOT_STARTED_DESC} checked={checked.NOT_STARTED} onChange={handleChange} name="NOT_STARTED"/>
                </FormGroup>
              </Grid>
              <Grid sx={{mt: 1}}>
                <ButtonFHG id="patientRecords.search.button" labelKey={'patientRecords.search.button'}
                           sx={{m: 1, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}
                           startIcon={(<SearchIcon />)} onClick={handleSearch} variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
          {getAlert()}
          <TableFHG name={"stateStatusReport"} columns={columns}
                    data={exData || []} allowSearch stickyHeader
                    emptyTableMessageKey={'stateStatus.noRecord.message'}
          >
            <Grid container direction={'row'} justifyContent={'space-between'}>
              <Grid container direction={'row'} sx={{my: -1, mx: 0}}>
                {isAdmin || isSupervisor ? (<>
                  <ButtonFHG variant="outlined" color="primary" size="small"
                             sx={{m: 1, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}
                             labelKey="state.button" onClick={sendToState} />
                  <ButtonFHG variant="outlined" color="primary" size="small"
                             disabled={isSaving} labelKey="dismiss.button" onClick={dismissedExchangeRecord}
                             sx={{m: 1, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}/>
                  <TypographyFHG sx={{my: 'auto', mx: 1}} variant="body1" id="stateStatus.title.help" color="textSecondary" /></>) :
                  <TypographyFHG sx={{my: 'auto', mx: 1}} variant="body1" id="stateStatus.title.noAuth" color="textSecondary" />}
              </Grid>
            </Grid>
          </TableFHG>
        </Grid>
        <EditDrawer
          backgroundColor={BGD_COLOR}
          ModalProps={{BackdropProps: {style: {height: '100%'}}}}
          open={openDetailDrawer}
          onClose={handleDetailClose}
        >
          <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
            {getFlyoutContent()}
          </Grid>
        </EditDrawer>
      </Grid>
    </>
  );
}
