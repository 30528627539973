import React, {useCallback, useMemo, useState} from 'react';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip, InputLabel, Link, Switch} from '@mui/material';
import * as dayjs from 'dayjs';
import filter from 'lodash/filter';
import {formatMessage, getChipBgColor, hexToRgb, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryRptDetail from './InventoryRptDetail';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import Loading from '../../fhg/components/Loading';
import MenuItem from '@mui/material/MenuItem';
import orderBy from 'lodash/orderBy';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {INVENTORY_TYPES_QUERY, EXPANDED_MONTHLY_QUERY} from '../../data/QueriesGL';
import {
  CARD_SM_FONT,
  DATE_FORMAT_KEYBOARD,
  ADMINISTER, EXPIRED, LONG_SHORT,
  RECEIVE, TRANSFER, WASTED,
  BORROW, PAYBACK,
  DATE_DB_FORMAT, PRIMARY_DARK_COLOR
} from '../../Constants';

export default function InventoryReportExpanded() {
  const intl = useIntl();
  const [displayDetail, setDisplayDetail] = useState(false);
  const [displayInactive, setDisplayInactive] = useState(false);
  const [detailType, setDetailType] = useState('');
  const [fromTo, setFromTo] = useState("from");
  const [mfgId, setMfgId] = useState(null);
  const [mfgName, setMfgName] = useState('');
  const [typeId, setTypeId] = useState("2");  // default to Commercial
  const [loading, setLoading] = useState(false);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const inventoryTypes = useMemo(() => orderBy(inventoryTypesData?.inventoryTypes, [row => row.name.toLowerCase()], ['asc']), [inventoryTypesData]);
  const today = dayjs();
  const y = today.year();
  const m = today.month();
  const d = today.daysInMonth();
  const start = new Date(y, m, 1);
  const end = new Date(y, m, d);
  const [lastVerificationDate, setLastVerificationDate] = useState(dayjs(start));
  const [verifyDate, setVerifyDate] = useState(dayjs(end));
  const [monthlyData, {data}] = useLazyQueryFHG(EXPANDED_MONTHLY_QUERY, {}, 'monthly.type');

  const monthlyInventories = useMemo(() => {
    let myData = data?.monthlies || [];
    let rows = displayInactive ? myData : filter(myData, {isHide: false});

    // if (filteredRows) {
    //   filteredRows = filteredRows.map(x => {
    //     x.isHideValue = x.isHide ? 'Inactive' : 'Active'
    //     return x;
    //   });
    // }
    let filteredRows = [];
    if (rows && rows.length > 0) {
      filteredRows = rows.map(obj => ({...obj, activeValue: obj.isHide ? 'Inactive' : 'Active'}));
    }

    if (loading && data?.monthlies) {
      setLoading(false);
    }

    return orderBy(filteredRows, [row => row.name.toLowerCase()], ['asc']);
  }, [data, displayInactive, loading]);

  const handleDrilldown = useCallback((detail, mId, mName, fromToVal) => {
    setDetailType(detail);
    setMfgId(mId);
    setMfgName(mName);
    setFromTo(fromToVal);
    setDisplayDetail(true);

  }, []);

  const handleReturn = useCallback(() => {
    setDisplayDetail(false);
  }, []);

  const getDetail = useCallback(() => {
    return (<InventoryRptDetail detailType={detailType}
                                mfgId={mfgId}
                                mfgName={mfgName}
                                onReturn={handleReturn}
                                typeId={toNumber(typeId)}
                                verifyDate={verifyDate}
                                lastVerificationDate={lastVerificationDate}
                                fromTo={fromTo} />);
  }, [detailType, fromTo, handleReturn, mfgId, mfgName, typeId, verifyDate, lastVerificationDate]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        Header: <TypographyFHG id={'monthly.name.column'}/>,
        accessor: 'name',
        width: 80
      },
      {
        id: 'beginning_Doses',
        Header: <TypographyFHG id={'monthly.beginning_Doses.column'}/>,
        accessor: 'beginning_Doses',
        align: 'right',
        isFormattedNumber: true,
        width: 80,
        Cell: ({value}) => (
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 'received_Doses',
        Header: <TypographyFHG id={'monthly.received_Doses.column'}/>,
        accessor: 'received_Doses',
        width: 80,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(RECEIVE, row?.original.mfgId, row.values?.name, "")}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.received_Doses}</span>
          </Link>
        )
      },
      {
        id: 'bor',
        Header: <TypographyFHG id={'monthly.borrow.column'}/>,
        accessor: 'borrow',
        width: 70,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(BORROW, row?.original.mfgId, row.values?.name, row.values?.bor > 0 ? 'to' : 'from')}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.bor}</span>
          </Link>
        ),
      },
      {
        id: 'pb',
        Header: <TypographyFHG id={'monthly.payback.column'}/>,
        accessor: 'payback',
        width: 70,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(PAYBACK, row?.original.mfgId, row.values?.name, row.values?.bor > 0 ? 'from' : 'to')}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.pb}</span>
          </Link>
        ),
      },
      {
        id: 'administered',
        Header: <TypographyFHG id={'monthly.administered.column'}/>,
        accessor: 'administered',
        width: 80,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(ADMINISTER, row?.original.mfgId, row.values?.name, "")}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.administered}</span>
          </Link>)
      },
      {
        id: 'transferred',
        Header: <TypographyFHG id={'monthly.transferred.column'}/>,
        accessor: 'transferred',
        width: 80,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(TRANSFER, row?.original.mfgId, row.values?.name, "")}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.transferred}</span>
          </Link>
        ),
      },
      {
        id: 'wasted',
        Header: <TypographyFHG id={'monthly.wasted.column'}/>,
        accessor: 'wasted',
        width: 80,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(WASTED, row?.original.mfgId, row.values?.name, "")}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.wasted}</span>
          </Link>),
      },
      {
        id: 'expired',
        Header: <TypographyFHG id={'monthly.expired.column'}/>,
        accessor: 'expired',
        width: 80,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleDrilldown(EXPIRED, row?.original.mfgId, row.values?.name, "")}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.expired}</span>
          </Link>),
      },
      {
        id: 'long_Short',
        Header: <TypographyFHG id={'monthly.long_Short.column'}/>,
        accessor: 'long_Short',
        width: 80,
        Cell: ({row}) => (
          <Link sx={{cursor: 'pointer'}} underline="always" variant="inherit"
                onClick={() => handleDrilldown(LONG_SHORT, row?.original.mfgId, row.values?.name, "")}>
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{row.values?.long_Short}</span>
          </Link>)
      },
      {
        id: 'ending_Doses',
        Header: <TypographyFHG id={'monthly.ending_Doses.column'}/>,
        accessor: 'ending_Doses',
        align: 'right',
        isFormattedNumber: true,
        width: 80,
        Cell: ({value}) => (
            <span style={{display: "block", paddingRight: '16px', textAlign: "right"}}>{value ? value : '0'}</span>
        )
      },
      {
        id: 'isHide',
        Header: <TypographyFHG id={'mfgVaccines.isHide.column'}/>,
        accessor: 'activeValue',
        width: 50,
      }
    ]
  }, [handleDrilldown]);

  const handleLastVerificationDateChange = (date) =>  {
    setLastVerificationDate(date);
  }

  const handleVerifyDateChange = (date) =>  {
    setVerifyDate(date);
  };

  const handleInventoryTypeChange = useCallback((e) => {
    let id = e.target.value;
    if (id) {
      setTypeId(`${id}`);
    }
  }, []);

  const handleSearch = useCallback(() => {
    if (!lastVerificationDate) {
      return;
    }
    if (!verifyDate) {
      return;
    }
    if (!typeId) {
      return;
    }

    setLoading(true);
    const options = {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        dts: dayjs().format("MMDDYYYYhhmmsszzz"),
        verifyDate: dayjs(verifyDate).format(DATE_DB_FORMAT),
        lastVerificationDate: dayjs(lastVerificationDate).format(DATE_DB_FORMAT),
        typeId: toNumber(typeId)
      }
    };

    monthlyData(options);
  }, [monthlyData, lastVerificationDate, verifyDate, typeId]);

  const downloadData = async () => {
    if (data && monthlyInventories) {
      const tags = monthlyInventories;
      const totalRecords = tags.length;
      let tableData = [];

      tableData.push([
        {text: 'Name', style: 'tableHeader'},
        {text: 'Beginning', style: 'tableHeader'},
        {text: 'Received', style: 'tableHeader'},
        {text: 'Borrowed', style: 'tableHeader'},
        {text: 'Paidback', style: 'tableHeader'},
        {text: 'Administered', style: 'tableHeader'},
        {text: 'Transferred', style: 'tableHeader'},
        {text: 'Wasted', style: 'tableHeader'},
        {text: 'Expired', style: 'tableHeader'},
        {text: 'Long/Short', style: 'tableHeader'},
        {text: 'Ending', style: 'tableHeader'}
      ]);

      for (let cnt = 0; cnt < totalRecords; cnt++) {
        tableData.push([
          {text: tags[cnt].name.replace("<br/>", "\n"), style: 'tableData'},
          {text: tags[cnt].beginning_Doses, style: 'tableData'},
          {text: tags[cnt].received_Doses, style: 'tableData'},
          {text: tags[cnt].borrow, style: 'tableData'},
          {text: tags[cnt].payback, style: 'tableData'},
          {text: tags[cnt].administered, style: 'tableData'},
          {text: tags[cnt].transferred, style: 'tableData'},
          {text: tags[cnt].wasted, style: 'tableData'},
          {text: tags[cnt].expired, style: 'tableData'},
          {text: tags[cnt].long_Short ? tags[cnt].long_Short : 0, style: 'tableData'},
          {text: tags[cnt].ending_Doses ? tags[cnt].ending_Doses : 0, style: 'tableData'}]);
      }

      const loInventories = inventoryTypes.find(iType => iType.inventoryTypeID === parseInt(typeId));
      const loPracticeName = 'Cottonwood Pediatrics';
      const loDateRan = dayjs().format(DATE_FORMAT_KEYBOARD);

      const docDefinition =
        {
          pageOrientation: 'landscape',
          // pageMargins: [30, 97, 30, 250],
          pageMargins: [30, 30],
          content: [
            {text: 'INVENTORY REPORT BY DATE RANGE', style: 'header'},
            {text: 'Inventories: ' + loInventories.name, style: 'headerFilter'},
            {text: 'Practice Name: ' + loPracticeName, style: 'headerFilter'},
            {text: 'Date Range: ' + dayjs(lastVerificationDate).format(DATE_FORMAT_KEYBOARD) + ' - ' + dayjs(verifyDate).format(DATE_FORMAT_KEYBOARD), style: 'headerFilter'},
            {text: 'Date Ran: ' + loDateRan, style: 'headerFilter'},
            {
              style: 'table',
              table: {
                widths: [110, 50, 50, 50, 50, 65, 50, 50, 45, 45, 80, 50],
                body: tableData
              }
            }],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, -10, 0, 0],
              alignment: 'center'
            }
            ,
            headerFilter: {
              fontSize: 12,
              bold: true,
              margin: [0, 2, 0, 0],
              alignment: 'center'
            },
            subheader: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 0],
              alignment: 'center'
            },
            table: {
              margin: [0, 10, 0, 0],
              fontSize: 8,
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: 'black',
              alignment: 'center',
              margin: [0, 0, 0, 0]
            },
            tableData: {
              bold: false,
              width: 100,
              margin: [0, 4, 0, 0],
              alignment: 'center'
            }
          },
          defaultStyle: {
            // alignment: 'justify'
          }
        };
      const defaultFileName = `InventoryReport-${dayjs().format('MM-YYYY')}.pdf`
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(docDefinition).download(defaultFileName);
    }
  }

  const handleHiddenToggle = () => {
    setDisplayInactive(value => !value);
  }

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return !displayInactive ? undefined : {fontWeight: "bold"};
    } else {
      return !displayInactive ? {fontWeight: "bold"} : undefined;
    }
  }, [displayInactive]);

  if(displayDetail) {
    return (getDetail());
  } else {
    return (
      <>
        <Loading isLoading={loading}/>
        <Grid container direction="column">
          <TypographyFHG id="reports.inventory.expanded.title" color="textSecondary" sx={{mt: 1, mr: 0, mb: 1, ml: 1}} variant="h5" gutterBottom/>
          <Grid>
            <TableFHG name={"Monthly"} columns={columns} data={data ? monthlyInventories : []}
                      allowSearch emptyTableMessageKey={'monthly.noRecord.message'} fullWidth stickyHeader
            >
              <Grid container direction="row" justifyContent={'space-between'}>
                <Grid container direction="row" sx={{display: "flex", justifyContent: "flex-start"}}>
                  <Grid sx={{py: 0, pr: 0, pl: 0, width: '160px'}}>
                    <KeyboardDatePickerFHG
                      key={'lastVerificationDate1'}
                      name={'lastVerificationDate'}
                      label={'Start Date'}
                      onChange={handleLastVerificationDateChange}
                      value={lastVerificationDate}
                    />
                  </Grid>
                  <Grid sx={{py: 0, pr: 0, pl: 1, width: '160px'}}>
                    <KeyboardDatePickerFHG
                      key={'verifyDate1'}
                      name={'verifyDate'}
                      label={'End Date'}
                      onChange={handleVerifyDateChange}
                      value={verifyDate}
                    />
                  </Grid>
                  <Grid container direction="column" sx={{mt: -2.5, mr: 1, mb: 0, ml: 2, width: '174px'}}>
                    <InputLabel id="typeDropDown" sx={{float: 'left', mt: 0, mx: 1}}>Inventory Type</InputLabel>
                    <Select
                      value={typeId}
                      onChange={handleInventoryTypeChange}
                      labelId={'typeDropDown'}
                      size="small"
                    >
                      {inventoryTypes.map((option, i) => (
                        <MenuItem key={i} value={`${option.inventoryTypeID}`}>
                          <Chip size='small' label={option.name} sx={{
                            margin: 'auto',
                            backgroundColor: `${getChipBgColor(option.colorCode)}`,
                            color: `${hexToRgb(option.colorCode)}`,
                            fontSize: CARD_SM_FONT,
                            width: '130px'
                          }}/>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid sx={{mt: 0.25}}>
                    <ButtonFHG id="search.button" labelKey={'search.button'}
                               color="primary" size="large" startIcon={(<SearchIcon />)} onClick={handleSearch}
                               sx={{ml: 0.5, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}
                               variant="outlined" />
                    <ButtonFHG variant="outlined" color="primary" size="large"
                               sx={{mx: 1, '&:hover': {color: PRIMARY_DARK_COLOR}, fontSize: '0.875rem'}}
                               labelKey="pdf.button" onClick={downloadData}/>
                  </Grid>
                  <Grid container direction="row" sx={{mt: 0.75, mr: 0, ml: 2, pt: 0.75, width: '320px'}}>
                    <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
                    <Switch color="primary" checked={!displayInactive} onChange={handleHiddenToggle} sx={{my: -1, mx: 0, width: '62px'}} />
                    <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'slider.active.label', 'Display Active Only', null)}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TableFHG>
          </Grid>
        </Grid>
      </>
    );
  }
}
