import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckIcon from '@mui/icons-material/Done';
import {Chip, IconButton, MenuItem, Select, Tooltip} from '@mui/material';
import * as dayjs from 'dayjs';
import {Grid} from '@mui/material';
import Form from '../../fhg/components/edit/Form';
import FormControl from '@mui/material/FormControl';
import {formatMessage, getChipBgColor, getCvxCode, hexToRgb, toNumber} from '../../fhg/utils/Utils';
import HelpIcon from '@mui/icons-material/HelpOutline';
import InventoryCardBasic from '../inventory/InventoryCardBasic';
import isEqual from 'lodash/isEqual';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {lighten} from '@mui/material/styles';
import orderBy from 'lodash/orderBy';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  BARCODE_SEARCH_QUERY,
  getBorrowsRefetchQueries,
  getInventoryListRefetchQueries,
  getPaybacksRefetchQueries,
  INVENTORY_CREATE,
  INVENTORY_SEARCH_CLINIC_BARCODE_QUERY,
  INVENTORY_TYPES_QUERY, LOAN_UPDATE,
  PAYBACK_CREATE,
} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT, DATE_PICKER_WIDTH,
  EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT,
  PRIMARY_DARK_COLOR,
  SEARCH_DEFAULT, SEARCH_FAIL, SEARCH_SUCCESS,
  STYLES,
  verifyEnum, verifyKeys
} from '../../Constants';

export default function PaybackAdd(props) {
  const {borrow, onClose} = props;
  const intl = useIntl();
  const [{userId}] = useRecoilState(userStatus);
  const [barcode, setBarcode] = useState('');
  const [barcodeNew, setBarcodeNew] = useState('');
  const [creatingPayback, setCreatingPayback] = useState(false);
  const [createdPayback, setCreatedPayback] = useState(false);
  const [creatingInventory, setCreatingInventory] = useState(false);
  const [createdInventory, setCreatedInventory] = useState(false);
  const [defaultDate] = useState(dayjs());
  const [filteredInvTypes, setFilteredInvTypes] = useState([]);
  const [fromTypeId, setFromTypeId] = useState(undefined);
  const [fromInventory, setFromInventory] = useState({});
  const [fromInventoryId, setFromInventoryId] = useState(undefined);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [notes, setNotes] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [paybackDate, setPaybackDate] = useState(null);
  const [searchingBarcode, setSearchingBarcode] = useState(false);
  const [searchedBarcode, setSearchedBarcode] = useState(false);
  const [searchingInventory, setSearchingInventory] = useState(false);
  const [toInventoryId, setToInventoryId] = useState(undefined);
  const [toTypeId, setToTypeId] = useState(undefined);
  const [updatingBorrow, setUpdatingBorrow] = useState(false);
  const [updatedBorrow, setUpdatedBorrow] = useState(false);
  const [verifyMessageKey, setVerifyMessageKey] = useState(verifyKeys[0]);
  const [verifyStyle, setVerifyStyle] = useState(SEARCH_DEFAULT);

  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const [borrowUpdate, {data: borrowUpdateData, error: borrowUpdateError}] = useMutationFHG(LOAN_UPDATE);
  const [paybackCreate, {data: paybackCreateData, error: paybackCreateError}] = useMutationFHG(PAYBACK_CREATE);
  const [inventoryCreate, {data: invCreateData, error: invCreateError}] = useMutationFHG(INVENTORY_CREATE);
  const [searchBarcode, {data: barcodeData, error: barcodeError}] = useLazyQueryFHG(BARCODE_SEARCH_QUERY, undefined, 'clinicBarcode.type');
  const [getInventory, {data: fromInventoryData, error: fromInventoryError}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type', true);
  const inventoryTypes = useMemo(() => inventoryTypesData?.inventoryTypes || [], [inventoryTypesData]);

  const getFilteredInventoryTypes = useCallback((fromType) => {
    let filtered = [];
    if (inventoryTypes && inventoryTypes.length > 0 && fromType) {
      // remove the type we are borrowing from
      let inventoryTypesFiltered = inventoryTypes.filter(x => x.id !== fromType);
      filtered = orderBy(inventoryTypesFiltered, [row => row.name.toLowerCase()], ['asc']);
    }
    return filtered;
  }, [inventoryTypes]);

  useEffect(() => {
    const types = getFilteredInventoryTypes(fromTypeId);
    setFilteredInvTypes(types);
  }, [fromTypeId, getFilteredInventoryTypes]);

  useEffect(() => {
    if (barcode && !fromInventoryError && fromInventoryData && fromInventoryData.clinicVaccine.length > 0) {
      setFromInventoryId(fromInventoryData.clinicVaccine?.[0].id);
      setFromTypeId(fromInventoryData.clinicVaccine?.[0].typeId);
      setFromInventory(fromInventoryData.clinicVaccine[0]);
      setSearchingBarcode(true);
    }
  }, [barcode, fromInventoryData, fromInventoryError, searchingInventory, setFromTypeId, setFromInventory,
    setSearchingBarcode]);

  // execute barcode search to find all values like the typed in barcode value
  useEffect(() => {
    if (searchingBarcode) {
      let param = {
        fetchPolicy: 'no-cache',
        variables: {clinicBarcode: barcode, dts: dayjs().format("MMDDYYYYhhmmsszzz")}
      };
      searchBarcode(param);
      setSearchingBarcode(false);
      setSearchedBarcode(false);
    }
  }, [barcode, searchBarcode, searchingBarcode, setSearchingBarcode, setSearchedBarcode]);

  const getNewBarcodeValue = useCallback((bcData) => {
    // By convention a new barcode number will be generated with -Px at the end.
    // First find the list of barcodes with the same starting number since this vial could already have been borrowed against.
    const orderedOrig = orderBy(bcData, [row => row.clinicBarcode], ['desc']);
    // If this vial has already been borrowed against there will be a dash in the barcode.
    const bc = orderedOrig[0]?.clinicBarcode;
    let arr = [];
    let barcodeLen = 0;
    let barcodeStem;
    let barcodeSuffix;
    let newBc;
    let dashPos = bc.indexOf('-');
    if (dashPos >= 0 ) {
      barcodeLen = dashPos;
      barcodeStem = bc.substring(0, barcodeLen);
      orderedOrig.forEach(x => {
        // one of the items does not have a dash
        if (x?.clinicBarcode?.length > dashPos + 2) {
          barcodeSuffix = toNumber(x?.clinicBarcode?.substring(dashPos + 2)); // Number part after the P
          arr.push(barcodeSuffix);
        }
      });

      arr.sort((a,b)=>b-a);  // sort reverse
      const maxBc = arr[0] + 1;
      newBc = `${barcodeStem}-P${maxBc}`;
    } else {
      // No priors exist, this will be the first one.
      newBc = `${bc}-P1`;
    }
    return newBc;
  }, []);

  useEffect(() => {
    const data = barcodeData && barcodeData.barcodes ? barcodeData.barcodes : [];
    if (data.length > 0 && !searchedBarcode && !barcodeError) {
      const newBc = getNewBarcodeValue(data);
      setBarcodeNew(newBc);
      setSearchedBarcode(true);
    }
  }, [barcode, barcodeData, barcodeError, getNewBarcodeValue, setBarcodeNew, searchedBarcode, setSearchedBarcode]);

  useEffect(() => {
    if (!createdInventory && invCreateData && !invCreateError) {
      setToInventoryId(invCreateData.inventory.id);
      setCreatedInventory(true);
    }
  }, [createdInventory, invCreateData, invCreateError, setCreatedInventory, setToInventoryId]);
  
  useEffect(() => {
    if (!createdPayback && paybackCreateData && !paybackCreateError) {
      // Verify barcode from toInventory matches expected value.
      if (barcodeNew === invCreateData.inventory.clinicBarcode) {
        setCreatedPayback(true);
      }
    }
  }, [barcodeNew, paybackCreateData, paybackCreateError, createdPayback, invCreateData, setCreatedPayback]);

  useEffect(() => {
    if (createdPayback && paybackCreateData && !paybackCreateError) {
      setMessageKey('payback.success.message');
      setOpenAlert(true);
    }
  }, [createdPayback, paybackCreateData, paybackCreateError]);

  const isCvxValid = useCallback((cvxCode) => {
    // The payback must have the same cvx as the borrowed.
    // Codes are string and need to be zero filled if 1 digit.
    let result = false;
    if (borrow && cvxCode) {
      const cvxBorrow = getCvxCode(borrow?.ltiCvxCode);
      const cvxPb = getCvxCode(cvxCode);
      result = isEqual(cvxBorrow, cvxPb);
    }
    return result;
  }, [borrow]);

  const isInvTypeValid = useCallback((typeId) => {
    // The "payback from" must have the same inventory type as the "borrowed to".
    let result = false;
    if (borrow && typeId) {
      result = isEqual(borrow.ltiTypeId, typeId);
    }
    return result;
  }, [borrow]);

  const updateSearchStyle = useCallback((verifyCode) => {
    let result;
    switch (verifyCode) {
      case verifyEnum.BAD_CVX:
      case verifyEnum.NO_MATCH:
      case verifyEnum.INVALID:
      case verifyEnum.WRONG_TYPE:
        result = SEARCH_FAIL;
        break;
      case verifyEnum.SUCCESS:
        result = SEARCH_SUCCESS;
        break;
      default:
        result = SEARCH_DEFAULT;
        break;
    }
    setVerifyStyle(result);
    setVerifyMessageKey(verifyKeys[verifyCode]);
  }, [setVerifyMessageKey, setVerifyStyle]);

  useEffect(() => {
    const inv = fromInventoryData?.clinicVaccine?.[0];
    if (inv && Object.keys(inv).length > 0) {
      if (!isCvxValid(inv.cvxCode)) {
        updateSearchStyle(verifyEnum.BAD_CVX);
      } else if (!isInvTypeValid(inv.typeId)) {
        updateSearchStyle(verifyEnum.WRONG_TYPE);
      } else {
        updateSearchStyle(verifyEnum.SUCCESS);
      }
    }
  }, [fromInventoryData, isCvxValid, isInvTypeValid, updateSearchStyle]);

  const handleInventoryTypeChange = useCallback((e) => {
    setToTypeId(e.target.value);
    setIsChanged(true);
  }, [setToTypeId, setIsChanged]);

  const getToInventoryTypes = useCallback(() => {
    return filteredInvTypes && (filteredInvTypes.map((option, i) => {
      return(
        <MenuItem key={i} value={`${option.inventoryTypeID}`}>
          <Chip size='small' label={option.name}
                style={{fontSize: '0.675rem', margin: 'auto', width: '130px',
                        backgroundColor: `${getChipBgColor(option.colorCode)}`,
                        color: hexToRgb(option.colorCode)
          }} />
        </MenuItem>);
    }));
  }, [filteredInvTypes]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        if (!borrow) {
          setMessageKey('payback.loan.required.message');
          setOpenAlert(true);
          return;
        }

        if (!toTypeId) {
          setMessageKey('payback.type.required.message');
          setOpenAlert(true);
          return;
        }

        setIsSaving(true);
        const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
        const endDate = dayjs().format(DATE_DB_FORMAT);
        const date = paybackDate ? dayjs(paybackDate).format(DATE_DB_FORMAT) : endDate;
        let expDate = dayjs(fromInventory.expDate)?.format(DATE_DB_FORMAT);

        // Create a copy of the "from" inventory only with the new "to" inventory type.
        if (barcodeNew && fromInventory && !invCreateData && !creatingInventory && !createdInventory) {
          const currentItem = {
            id: 0,
            clinicBarcode: barcodeNew,
            doses: 1,
            originalDoses: 1,
            notes: notes,
            expDate: expDate,
            lot: fromInventory.lot,
            mfgId: fromInventory.mfgId,
            addedDate: date,
            typeId: toNumber(toTypeId),
            dateEntered: date,
            enteredBy: userId
          };

          inventoryCreate({
            variables: currentItem,
            refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
          });
          setCreatingInventory(true);
          setIsSaving(false);
          return;
        }

        // Has the new inventory been created?
        if (!toInventoryId) {
          setMessageKey('payback.barcode.error.message');
          setOpenAlert(true);
          setIsSaving(false);
          return;
        }

        // Use the new inventoryId to create a payback record.
        if (!creatingPayback && !createdPayback && createdInventory) {
          setCreatingPayback(true);

          const paybackItem = {
            loanId: borrow.id,
            paybackDate: date,
            doses: 1,
            notes: notes,
            paybackFromInvId: fromInventoryId,
            paybackToInvId: toInventoryId,
            dateEntered: date,
            dateLastUpdated: date,
            enteredBy: userId,
            lastUpdatedBy: userId
          };

          await paybackCreate({
            variables: paybackItem,
            refetchQueries: getPaybacksRefetchQueries(startDate, endDate, borrow.id)
          });

          if (!updatingBorrow && !updatedBorrow && createdInventory) {
            setUpdatingBorrow(true);
            const loanItem = {
              id: borrow.id,
              loanId: borrow.id,
              paid: 'true',
              dateEntered: date,
              dateLastUpdated: date,
              enteredBy: userId,
              lastUpdatedBy: userId
            };
            borrowUpdate({
              variables: loanItem,
              refetchQueries: getBorrowsRefetchQueries()
            });
          }

          setIsChanged(false);
        }
      } catch (e) {
        console.log(e?.message);
      }
    }
  }, [barcodeNew, borrow, borrowUpdate, paybackCreate, creatingPayback, createdPayback, creatingInventory,
    createdInventory, fromInventory, fromInventoryId, inventoryCreate, isChanged, invCreateData, notes, paybackDate,
    setCreatingPayback, setIsChanged, toInventoryId, toTypeId, updatingBorrow, updatedBorrow, setUpdatingBorrow, userId]);

  useEffect(() => {
    if (!creatingPayback && !createdPayback && createdInventory && toInventoryId) {
      setTimeout(() => handleSubmit().then(r => {}), 10);
    }
  }, [creatingPayback, createdPayback, createdInventory, handleSubmit, toInventoryId]);

  const isBarcodeInvalid = useCallback((bc) => {
    // By convention, barcodes ending in -Bn or -Pn are ineligible to use for a payback.
    let result = false;
    if (bc) {
      const dashPosB = bc.indexOf('-B');
      const dashPosP = bc.indexOf('-P');
      result = dashPosB > 0 || dashPosP > 0;
    }
    return result;
  }, []);

  const resetValues = useCallback(() => {
    updateSearchStyle(verifyEnum.DEFAULT);
    setMessageKey('');
    setOpenAlert(false);
    setFromInventory({});
    setFromTypeId(null);
    setCreatingInventory(false);
    setCreatedInventory(false);
    setCreatingPayback(false);
    setCreatedPayback(false);
    setNotes('');
    setSearchingBarcode(false);
    setSearchingInventory(false);
    setUpdatingBorrow(false);
    setUpdatedBorrow(false);
    setIsChanged(false);
  }, [setSearchingBarcode, updateSearchStyle]);

  const clearInventory = useCallback(() => {
    setTimeout(() => {
      resetValues();
    }, 10);
  }, [resetValues]);

  useEffect(() => {
    // If the barcode box is cleared, reset the search variables.
    if (!barcode) {
      clearInventory();
    }
  },[barcode, clearInventory]);

  const handleSearch = useCallback(() => {
    // Check barcode before searching inventory for ineligible values.
    setMessageKey('');
    setOpenAlert(false);
    updateSearchStyle(verifyEnum.DEFAULT);
    if (isBarcodeInvalid(barcode)) {
      setFromInventory({});
      updateSearchStyle(verifyEnum.INVALID);
      return;
    }
    let fromInvOptions = {
      fetchPolicy: 'network-only',
      variables: {clinicBarcode: barcode, dts: dayjs().format("MMDDYYYYhhmmsszzz")}
    };
    getInventory(fromInvOptions);
  }, [barcode, getInventory, isBarcodeInvalid, updateSearchStyle]);

  const handleClose = useCallback(() => {
    resetValues();
    if (onClose) {
      onClose();
    }
  }, [onClose, resetValues]);

  const handleCloseFlyout = useCallback(() => {
    setOpenAlert(false);
    handleClose()
  }, [setOpenAlert, handleClose]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      if (messageKey === 'payback.success.message') {
        result = <Alert severity="success" onClose={handleCloseFlyout}>
          {formatMessage(intl, messageKey, 'Payback record created.', [invCreateData.inventory.clinicBarcode])}
        </Alert>;
      } else if (messageKey === 'payback.barcode.not.found.message') {
        result = <Alert severity="warning" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
      } else {
        result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
      }
    }
    return result;
  }, [invCreateData, intl, messageKey, openAlert, handleAlertClose, handleCloseFlyout]);

  const handleClinicBarcodeChange = (e) => {
    setBarcode(e.target.value);
    setIsChanged(true);
  };

  const handleDateChange = (date) => {
    setPaybackDate(date);
    setIsChanged(true);
  };

  const handleNotesChange = useCallback((e) => {
    setNotes(e.target.value);
    setIsChanged(true);
  }, [setNotes, setIsChanged]);

  const getTooltipMessage = useCallback(() => {
    // Force the message to wrap to multiple lines.
    return <div style={{fontSize: '.875rem', whiteSpace: 'pre-line'}}>{formatMessage(intl, 'payback.tooltip.message')}</div>
  }, [intl]);

  const getVerifyComponent = useCallback(() => {
    let returnStyle;
    if (verifyStyle === SEARCH_SUCCESS) {
      returnStyle = STYLES.successStyle;
    } else if (verifyStyle === SEARCH_FAIL) {
      returnStyle = STYLES.failStyle;
    } else {
      returnStyle = STYLES.unkStyle;
    }
    return <Grid sx={{mt: 1.5, ml:1}}><Grid sx={returnStyle}>{<TypographyFHG id={verifyMessageKey} variant={'caption'} />}</Grid></Grid>;
  }, [verifyMessageKey, verifyStyle]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid  container direction="row" sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'payback.add.title'} color={'textSecondary'} />
          <Tooltip title={getTooltipMessage()}>
            <HelpIcon color="primary" sx={{cursor: "pointer", mt: 0.5, ml: 2}} />
          </Tooltip>
        </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
          <Grid sx={{overflowY: 'auto'}}>
            {getAlert()}
            <Grid container direction="row">
              <TypographyFHG color={'textSecondary'} sx={{ml: 1}} variant={'subtitle1'}>{formatMessage(intl, 'payback.loan.value', 'Borrow id to payback.', [borrow.id])}</TypographyFHG>
            </Grid>
            <Grid container direction={'row'} sx={{mb: 1}}>
              <TextFieldCustom
                key="clinicBarcode"
                name="clinicBarcode"
                autoFocus
                fullWidth={false}
                labelKey="payback.clinicBarcode.label"
                InputLabelProps={{style: {color: lighten(PRIMARY_DARK_COLOR, .5)}}}
                onChange={handleClinicBarcodeChange}
                required
                size="small"
                sx={{mt: 0.875, mr: 0.25, width: '136px'}}
                value={barcode}
              />
              <IconButton aria-label="select" key={`barcode-select-01`} size="small" onClick={handleSearch}>
                <Grid key={`barcode-check-01`} sx={{
                  backgroundColor: lighten(PRIMARY_DARK_COLOR, 0.25),
                  color: '#FFF',
                  mt: -0.25,
                  pt: 0.75,
                  height: '35px',
                  width: '32px'
                }}>
                  <CheckIcon />
                </Grid>
              </IconButton>
              {getVerifyComponent()}
            </Grid>
            <InventoryCardBasic inventory={fromInventoryData?.clinicVaccine?.[0]} />
            <Grid container direction="column" sx={{mb: 1}}>
              <FormControl size={'small'}>
                <TypographyFHG id="inventory.loan.typeTo.label" color="primary" variant="subtitle2" />
                <Select onChange={handleInventoryTypeChange}
                        size="small"
                        sx={{fontSize: '.75rem !important', width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}
                        value={toTypeId}
                        variant="outlined"
                >
                  {getToInventoryTypes()}
                </Select>
              </FormControl>
            </Grid>
            <Grid container direction="column" sx={{mb: 0.5, pt: 0.75, width: DATE_PICKER_WIDTH}}>
              <KeyboardDatePickerFHG
                key={'paybackDate-key'}
                name={'paybackDate'}
                labelKey={'payback.paybackDate.label'}
                onChange={handleDateChange}
                value={paybackDate || dayjs(defaultDate)}
              />
            </Grid>
            <Grid sx={{mt: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key={'notes'}
                name={'notes'}
                inputProps={{fontSize: '0.875rem'}}
                InputLabelProps={{ shrink: true }}
                labelKey={'payback.notes.label'}
                multiline
                rows="2"
                sx={{fontSize: '.875rem', mt: 1}}
                onChange={handleNotesChange}
                value={notes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}} >
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            disabled={isSaving || verifyStyle !== SEARCH_SUCCESS}
                            type={'submit'} size='small' labelKey='save.label'
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

PaybackAdd.propTypes = {
  borrow: PropTypes.object,
  onClose: PropTypes.func
}
