import {useQuery} from '@apollo/client';
import {useSetRecoilState} from 'recoil';
import {errorState} from '../../utils/Utils';
import useEffect from '../useEffect';
import useMessage from '../useMessage';

export default function useQueryFHG(query, options, typeKey, showLoading) {
   const type = useMessage(typeKey, 'Unknown');
   const setErrorState = useSetRecoilState(errorState);
   const {loading, error, data} = useQuery(query, options);

   useEffect(() => {
      if (error) {
         console.log('Error type', typeKey);
         console.log(error, error.stackTrace);
         setErrorState({error, errorKey: 'load.error', values: {type, message: error.message}});
      }
   }, [error, typeKey, type, setErrorState]);

   return [data, {loading, error}];
}
