import React, {useMemo, useState} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs';
import {formatMessage} from '../utils/Utils';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {useIntl} from 'react-intl';

/**
 * The TextField with preset formats.
 */
export default function KeyboardDatePickerFHG({name, className, label, labelKey, onChange, value, defaultValue, variant = 'inline'}) {
   const intl = useIntl();
   const [isSet, setIsSet] = useState(value !== undefined && value !== null && value !== '');

   return useMemo(() => {
      const currentLabel = label || (labelKey && formatMessage(intl, labelKey)) || undefined;
      const handleChange = (date) => {
         onChange && onChange(date);
         setIsSet(true);
      };

      return (
         <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker name={name}
                           InputLabelProps={{
                              shrink: true,
                              sx: {
                                backgroundColor: 'transparent',
                                fontSize: 14,
                                fontWeight: 500,
                                height: '16px',
                              }
                           }}
                           label={currentLabel}
                           onChange={(newValue) => handleChange(newValue)}
                           slotProps={{
                              openPickerButton: {color: 'primary'},
                              inputAdornment: {position: 'start', sx: {paddingTop: '2px'}},
                              textField: { size: 'small', sx: {paddingTop: '4px'}}
                           }}
                           sx={{
                             '& button': {
                               p: 0.5,
                             },
                             '& > div': {
                               pr: 0,
                             },
                             '& > div > div': {
                               ml: 0,
                             },
                             '& input > div': {
                               ml: 0,
                             },
                            "& .MuiOutlinedInput-root": {fontSize: '0.875rem'},
                            "& .MuiInputLabel-root": { zIndex: 2 },
                           }}
                           value={isSet ? value : dayjs(defaultValue)}
                           variant={variant}
               />
            </LocalizationProvider>
         </>
      )
   }, [name, defaultValue, intl, isSet, label, labelKey, onChange, value, variant]);
}
