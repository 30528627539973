import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import dayjs from 'dayjs';
import {Grid} from '@mui/material';
import Form from '../../fhg/components/edit/Form';
import {formatMessage} from "../../fhg/utils/Utils";
import {isEmpty} from 'lodash';
import ProgressButton from '../../fhg/components/ProgressButton';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {getBorrowsRefetchQueries, LOAN_UPDATE} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT,
  EDIT_DRAWER_ITEM_WIDTH,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR
} from '../../Constants';

export default function BorrowEdit(props) {
  const {borrow, onClose} = props;
  const intl = useIntl();
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUnpayable, setIsUnpayable] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [notes, setNotes] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [borrowUpdate, {data: borrowUpdateData, error: borrowUpdateError}] = useMutationFHG(LOAN_UPDATE);
  const [{userId}] = useRecoilState(userStatus);

  useEffect(() => {
    if (!isEmpty(borrow)) {
      setNotes(borrow.notes);
      setIsUnpayable(borrow.paid === 'unpayable');
    }
  }, [borrow]);

  const resetValues = useCallback(() => {
    setMessageText('');
    setNotes('');
    setIsUnpayable(false);
    setIsChanged(false);
  }, [setIsChanged]);

  const handleClose = useCallback(() => {
    resetValues();
    if (onClose) {
      onClose();
    }
  }, [onClose, resetValues]);

  useEffect(() => {
    if (borrowUpdateData && !borrowUpdateError) {
      handleClose();
    }
  }, [borrowUpdateData, borrowUpdateError, handleClose]);

  useEffect(() => {
    if (borrowUpdateError) {
      setMessageText(borrowUpdateError.message);
      setOpenAlert(true);
    }
  }, [borrowUpdateError]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        const date = dayjs().format(DATE_DB_FORMAT);
        const paid = borrow.paid === 'true' ? 'true' : isUnpayable ? 'unpayable' : 'false';

        let currentItem = {
          id: borrow.id,
          loanId: borrow.id,
          notes: notes,
          paid: paid,
          dateLastUpdated: date,
          lastUpdatedBy: userId,
          __typename: 'Loan'
        };

        setIsSaving(true);

        await borrowUpdate({
          variables: currentItem,
          refetchQueries: getBorrowsRefetchQueries()
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        console.log(e);
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [borrow, borrowUpdate, handleClose, isChanged, notes, setIsChanged, isUnpayable, userId]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  const handleCheckChange = useCallback((e)=> {
    setIsUnpayable(e.target.checked);
    setIsChanged(true);
  },[setIsUnpayable, setIsChanged]);

  const handleNotesChange = useCallback((e)=> {
    setNotes(e.target.value)
    setIsChanged(true);
  },[setIsChanged]);

  if (borrow.id) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'borrow.edit.title'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 1, pl: 3}}>
            <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
              {getAlert()}
              <Grid sx={{mt:1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <Grid container direction="row">
                  <TypographyFHG color={'textSecondary'} sx={{mb: 1}} variant={'subtitle1'}>{formatMessage(intl, 'payback.loan.value', 'Borrow id to payback.', [borrow.id])}</TypographyFHG>
                </Grid>
                <CheckboxFHG
                  key="isUnpayable"
                  name="isUnpayable"
                  onChange={handleCheckChange}
                  labelKey="borrow.status.label"
                  checked={isUnpayable}
                  disabled={isSaving || borrow.paid === 'true'}
                  marginTop={0}
                  fullWidth
                />
                <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                  <TextFieldCustom
                    key="notes"
                    name="notes"
                    inputProps={{style: {fontSize: '0.875rem'}}}
                    InputLabelProps={{ shrink: true }}
                    labelKey="borrow.notes.label"
                    multiline
                    maxRows={4}
                    rows={3}
                    onChange={handleNotesChange}
                    value={notes}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{
            borderTopColor: 'lightgray',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            bottom: 0,
            height: '60px',
            pl: 3,
            width: '100%'
          }}>
            <Grid container direction="row" sx={{mt: 0.5}}>
              <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                              type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                              sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
              <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                         disabled={isSaving} onClick={handleClose}
                         sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
  } else {
    return null;
  }
}

BorrowEdit.propTypes = {
  borrow: PropTypes.object,
  onClose: PropTypes.func
}
