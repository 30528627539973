import {useRecoilState, useSetRecoilState} from 'recoil';
import {atom} from 'recoil';

export const SIGN_IN_STATE = 'signedin';

export const authenticationStateStatus = atom({
   key: 'authenticationState',
   default: {}
});

export const authenticationDataStatus = atom({
   key: 'authenticationData',
   default: {},
});

export const userStatus = atom({
  key: 'userStatusKey',
  default: {username: '', groups: [], signOut: undefined, sub: undefined, isAdmin: false},
});

/**
 * Hook to handle the change in the security state.
 *
 * @return {handleAuthStateChange} callback when the security state changes.
 */
export default function useAuth() {
  const setAuthenticationState = useSetRecoilState(authenticationStateStatus);
  const [authStateData, setAuthStateData] = useRecoilState(authenticationDataStatus)

  /**
  * Handle the auth changes from the AWS security via AmplifyAuthenticator listener.
  *
  * @param state the authState
  * @param data the authData
  */
  async function handleAuthStateChange(state, data) {
    setAuthenticationState(state);

    if (state === SIGN_IN_STATE) {
      if (authStateData.userName !== data.userName) {
        setAuthStateData({userName: data.userName, roleId: data.roleId, token: data.token});
      }
    }
  }

   return handleAuthStateChange;
}
