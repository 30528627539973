import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {cacheAdd} from "../../fhg/utils/DataUtil";
import * as dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {formatMessage, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import {useIntl} from 'react-intl';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  getInventoryListCacheQueries,
  getInventoryListRefetchQueries,
  INVENTORY_CREATE,
  INVENTORY_TYPES_QUERY,
  MFGVACCINES_SEARCH_QUERY
} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT, DATE_PICKER_WIDTH,
  DATE_TIME_FORMAT, EDIT_DRAWER_ITEM_WIDTH,
  EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER,
  INVENTORY_DATE_UNIT, PRIMARY_DARK_COLOR
} from '../../Constants';

export default function InventoryAdd(props) {
  const intl = useIntl();
  const [{userId}] = useRecoilState(userStatus);
  const clinicBarcodeRef = useRef(null);
  const [barcode, setBarcode] = useState('');
  const [another, setAnother] = useState(false);
  const [mfgName, setMfgName] = useState('');
  const [invTypeId, setInvTypeId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [inventoryCreate, {error}] = useMutationFHG(INVENTORY_CREATE);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, {}, 'inventoryType.type');
  const options = {variables: {mfgBarcode: ''}};
  const [vaccineData, {data}] = useLazyQueryFHG(MFGVACCINES_SEARCH_QUERY, options, 'mfgVaccine.type', true);
  const date = dayjs().format(DATE_TIME_FORMAT);

  const editItem = useMemo(() => ({
    id: 0,
    lot: '',
    addedDate: date,
    doses: null,
    clinicBarcode: '',
    expDate: null,
    notes: '',
    typeId: null,
    mfgId: undefined,  // Vaccine id
    originalDoses: 0.0,
    dateEntered: date,
    enteredBy: userId
  }), [date, userId]);
  const [
    editValues, handleChange, {
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData(editItem, ['mfgId', 'typeId']);
  const inventoryTypes = useMemo(() => {
    return inventoryTypesData?.inventoryTypes || []
  }, [inventoryTypesData]);

  useEffect(() => {
    if (another) {
      setAnother(false);
      clinicBarcodeRef.current.focus();
    }
  }, [another, setAnother]);

  useMemo(() => {
    if (mfgName) {
      clinicBarcodeRef.current.focus();
    }
  }, [mfgName]);

  useEffect(() => {
    if (editValues.mfgBarcode && data && data.mfgVaccines?.[0]) {
      let name = `${data.mfgVaccines?.[0].manufacturer?.name}`;
      setMfgName(name);
    }
  }, [data, editValues.mfgBarcode]);

  const getCvx = useCallback(() => {
    let result = '-';
    if (data?.mfgVaccines?.[0] && editValues.mfgBarcode) {
      result = `${data.mfgVaccines?.[0].cvx?.shortDescription} (${data.mfgVaccines?.[0].cvx?.cvxCode})`;
    }
    return result;
  }, [data, editValues.mfgBarcode]);

  const getManufacturer = useCallback(() => {
    let result = '-';
    if (data?.mfgVaccines?.[0] && editValues.mfgBarcode) {
      result = `${data.mfgVaccines?.[0].manufacturer?.name}`;
    }
    return result;
  }, [data, editValues.mfgBarcode]);

  const getMfgId = useCallback(() => {
    let result;
    if (data?.mfgVaccines?.[0] && editValues.mfgBarcode) {
      result = data.mfgVaccines?.[0].id;
    }
    return result;
  }, [data, editValues.mfgBarcode]);

  const isBarCodeUnique = useCallback((code) => {
    let result = true;
    if (props.inventories?.length > 0 && !error) {
      let found = find(props.inventories, c => c.clinicBarcode === code);
      if (found) {
        result = false;
      }
    }
    return result;
  }, [error, props]);

  const handleClose = useCallback(() => {
    setBarcode('');
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues, setBarcode]);

  const handleSubmit = useCallback(() => {
    let status = undefined;
    const endDate = dayjs().format(DATE_DB_FORMAT);
    const startDate = dayjs(endDate).subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
    const mfgId = getMfgId();  // Vaccine id

    let inv = {
      ...defaultValues,
      ...editValues
    };

    if (!isBarCodeUnique(barcode)) {
      status = 'inventory.duplicate.message';
    } else {
      if (!barcode) {
        status = 'inventory.required.clinicBarcode.message';
      } else if (!inv.mfgBarcode) {
        status = 'inventory.required.mfgBarcode.message';
      } else if (!invTypeId) {
        status = 'inventory.required.type.message';
      } else if (!inv.expDate) {
        status = 'inventory.required.expDate.message';
      } else if (!inv.doses || inv.doses <= 0) {
        status = 'inventory.required.doses.message';
      } else if (!inv.lot) {
        status = 'inventory.required.lot.message';
      } else if (!mfgId) {
        status = 'inventory.required.mfgId.message';
      }
    }

    if (status) {
      setMessageKey(status);
      setOpenAlert(true);
    } else {
      const addedDate = dayjs(inv.addedDate)?.format(DATE_DB_FORMAT);
      const expDate = dayjs(inv.expDate)?.format(DATE_DB_FORMAT);
      let dateEntered = dayjs(inv.dateEntered)?.format(DATE_DB_FORMAT);

      const currentItem = {
        id: inv.id,
        lot: (inv.lot).trim(),
        addedDate: addedDate,
        doses: toNumber(inv.doses),
        originalDoses: toNumber(inv.doses),
        clinicBarcode: barcode,
        expDate: expDate,
        notes: inv.notes,
        typeId: invTypeId,
        mfgId: mfgId,
        dateEntered: dateEntered,
        enteredBy: userId
      };
      setIsSaving(true);

      inventoryCreate({
        variables: currentItem,
        update: cacheAdd(getInventoryListCacheQueries(startDate, endDate), 'inventory'),
        refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
      });
    }
    setIsSaving(false);
    return status;
  }, [barcode, invTypeId, isBarCodeUnique, inventoryCreate, getMfgId, defaultValues, editValues, userId]);

  const handleAdd = useCallback(() => {
    let status = handleSubmit();
    if (status === undefined) {
      setBarcode('');
      setEditValues(editValues => ({...editValues, mfgBarcode: ''}));
      setEditValues(editValues => ({...editValues, expDate: null}));
      setEditValues(editValues => ({...editValues, doses: null}));
      setEditValues(editValues => ({...editValues, lot: ''}));
      setEditValues(editValues => ({...editValues, notes: ''}));
      setInvTypeId(null);
      resetValues();
      handleClose();
    }
  }, [handleClose, handleSubmit, resetValues, setBarcode, setEditValues]);

  const handleAddAnother = useCallback(() => {
    let status = handleSubmit();
    if (status === undefined) {
      setBarcode('');
      setDefaultValues(defaultValues => ({...defaultValues, clinicBarcode: ''}));
      setAnother(true);
    }
  }, [handleSubmit, setAnother, setBarcode, setDefaultValues]);

  const handleMfgBarcodeChange = (e) => {
    handleSearchDebounced(e.target.value);
    setEditValues(editValues => ({...editValues, mfgBarcode: e.target.value}));
    setIsChanged(true);
  };

  const handleSearch = useCallback((barCode) => {
    if (barCode) {
      vaccineData({variables: {mfgBarcode: barCode}});
    }
  }, [vaccineData]);

  const handleSearchDebounced = useRef(debounce(handleSearch, 1000)).current;

  useEffect(() => {
    if (defaultValues.mfgBarcode && !editValues.mfgBarcode) {
      setEditValues(editValues => ({...editValues, mfgBarcode: defaultValues.mfgBarcode}));
      handleSearch(defaultValues.mfgBarcode);
    }
  }, [defaultValues, editValues, handleSearch, setEditValues]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey}/></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  const handleBarcodeChange = useCallback((event) => {
    let b = event.target.value?.trim();
    setBarcode(b);
  }, [setBarcode]);

  const handleAddedDateChange = (date) => {
    setEditValues(prevState => ({...prevState, addedDate: date}));
    setIsChanged(true);
  };

  const handleExpDateChange = (date) => {
    setEditValues(prevState => ({...prevState, expDate: date}));
    setIsChanged(true);
  };

  return (
    <Form onSubmit={handleAdd}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'inventory.title.label'} color={'textSecondary'} />
        </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
          <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
            {getAlert()}
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="mfgBarcode"
                name="mfgBarcode"
                autoFocus
                inputProps={{style: {fontSize: '0.875rem'}}}
                // InputLabelProps={{ shrink: true }}
                labelKey="inventory.search.mfgBarcode.label"
                onChange={handleMfgBarcodeChange}
                onBlur={handleSearchDebounced.flush}
                value={editValues?.mfgBarcode || defaultValues?.mfgBarcode}
                required
              />
            </Grid>
            <Grid container direction="column" sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TypographyFHG id="inventory.manufacturer.label" color="textSecondary" variant="body1"/>
              <TypographyFHG color="textPrimary" sx={{flex: 'flex', my: 1, mx: 2}} variant="body1">
                {getManufacturer()}
              </TypographyFHG>
            </Grid>
            <Grid container direction="column" sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TypographyFHG id="inventory.cvx.label" color="textSecondary" variant="body1"/>
              <TypographyFHG color="textPrimary" sx={{flex: 'flex', my: 1, mx: 2}} variant="body1">
                {getCvx()}
              </TypographyFHG>
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="clinicBarcode"
                name="clinicBarcode"
                inputRef={clinicBarcodeRef}
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                labelKey="inventory.clinicBarcode.label"
                onChange={handleBarcodeChange}
                value={barcode || ""}
                required
              />
            </Grid>
            <Grid container direction="column" sx={{my: 1, pt: 0.75, width: DATE_PICKER_WIDTH}}>
              <KeyboardDatePickerFHG
                key="addedDate"
                name="addedDate"
                labelKey={'inventory.addedDate.label'}
                onChange={handleAddedDateChange}
                value={editValues?.addedDate || dayjs(defaultValues?.addedDate)}
              />
            </Grid>
            <Grid sx={{mt: 1.5, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
            <Autocomplete
              key="inventoryTypes"
              fullWidth
              getOptionLabel={(option) => option?.name || ''}
              options={inventoryTypes}
              onChange={(event, value) => value ? setInvTypeId(value.inventoryTypeID) : setInvTypeId(event.target.value)}
              renderInput={(params) => (
                <TextField{...params} label={formatMessage(intl, 'inventory.type.label')} placeholder='Select type'
                          size="small" variant="outlined"/>
              )}
            />
            </Grid>
            <Grid container direction="column" sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="lot"
                name="lot"
                labelKey="inventory.lot.label"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={editValues?.lot || defaultValues?.lot}
                required
              />
            </Grid>
            <Grid container direction="column" sx={{mt: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
               <KeyboardDatePickerFHG
                 key="expDate"
                name="expDate"
                inputProps={{style: {fontSize: '0.875rem'}}}
                labelKey="inventory.expDate.label"
                onChange={handleExpDateChange}
                value={editValues?.expDate || dayjs(defaultValues?.expDate)}
              />
            </Grid>
            <Grid container direction="column" sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="doses"
                name="doses"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                labelKey="inventory.doses.label"
                onChange={handleChange}
                value={editValues?.doses || defaultValues?.doses}
                required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="notes"
                name="notes"
                labelKey="inventory.notes.label"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                multiline
                rows="2"
                sx={{fontSize: '.875rem'}}
                onChange={handleChange}
                value={editValues?.notes || defaultValues?.notes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ButtonFHG variant='outlined' size='small' labelKey='save-another.label'
                       color="primary" disabled={isSaving} onClick={handleAddAnother}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
            />
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
            />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       disabled={isSaving} onClick={() => handleClose()}
                       sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

InventoryAdd.propTypes = {
  inventories: PropTypes.array,
  onClose: PropTypes.func,
  onAddAnother: PropTypes.func,
  staticValues: PropTypes.object
}
