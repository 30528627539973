import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Checkbox, Grid, Link, Popover, Tooltip} from '@mui/material';
import {formatMessage} from '../../fhg/utils/Utils';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MaUTable from '@mui/material/Table';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TypographyFHG from '../../fhg/components/Typography';
import TableToolbar from './TableToolbar';
import {useIntl} from 'react-intl';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {BGD_TABLE_HEAD} from '../../Constants';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    )
  }
);

const EnhancedTable = ({
                         columns,
                         data,
                         setData,
                         displayHeader = true,
                         displayFooter = true,
                         updateMyData,
                         skipPageReset,
                         onAdd,
                         onAdjust,
                         onBarcodeClick,
                         onLoan,
                         onTransfer,
                         onTransferLocations,
                         onEdit,
                         onDelete,
                         onRowSelect,
                         onSearch,
                         addButtonKey,
                         adjustButtonKey,
                         loanButtonKey,
                         transferButtonKey,
                         transferLocationsButtonKey,
                         editButtonKey,
                         deleteButtonKey,
                         barcodeColumnIndex,
                         notesColumnIndex,
                         notesTitleKey,
                         isAdmin,
                         historicalSearch,
                         displayInventoryTypes
                       }) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize=25, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          // Header: ({ getToggleAllRowsSelectedProps }) => (
          //   <div>
          //     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          //   </div>
          // ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  );
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [note, setNote] = useState('');
  const intl = useIntl();

  const styles = useMemo(() => {
    return {
      headStyle: {
        backgroundColor: BGD_TABLE_HEAD,
        padding: ' 0 0 0 16px',
        height: '30px !important'
      },
      cellStyle: {
        color: '#FFF',
        fontWeight: 'bold',
        paddingTop: 1
      }
    };
  }, []);

  useMemo(() => {
    const localValue = localStorage.getItem("INVENTORY_SEARCH");
    if (globalFilter === undefined && localValue) {
      setGlobalFilter(localValue);
    }
  }, [globalFilter, setGlobalFilter]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value))
  };

  const addNewHandler = (e) => {
    onAdd(e);
  };

  const adjustHandler = (e) => {
    onAdjust(e);
  };

  const loanHandler = (e) => {
    onLoan(e);
  };

  const transferHandler = (e) => {
    onTransfer(e);
  };

  const transferLocationsHandler = (e) => {
    onTransferLocations(e);
  };
  const editHandler = (e) => {
    onEdit(e);
  };

  const deleteHandler = (e) => {
    onDelete(e);
  };

  const handleRowClick = (e) => {
    onRowSelect(e);
  };

  const handleBarcodeClick = (barcode) => {
    onBarcodeClick(barcode);
  };

  const handleNoteClose = () => {
    setIsNoteOpen(isNoteOpen => !isNoteOpen);
  };

  const handleNoteClick = (value) => {
    setNote(value);
    setIsNoteOpen(isNoteOpen => !isNoteOpen);
  };

  const handleSearch = useCallback((typeId, clinicBarcode, mfgBrand) => {
    if (clinicBarcode || mfgBrand) {
      onSearch?.(clinicBarcode, mfgBrand);
    } else {
      onSearch?.(typeId);
    }
  }, [onSearch]);

  const options = {
      rowStyle: {height: 150},
  };

  // Render the UI for your table
  return (
  <>
    {isNoteOpen && (
      <Popover open={isNoteOpen}
        onClose={handleNoteClose}
        anchorOrigin={{vertical: 'center', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center',}}
        PaperProps={{style: {padding: '8px 16px', width: '400px'}}}
      >
        <TypographyFHG id={notesTitleKey} sx={{borderBottom: '1px solid gray', pt: 1, pr: 1, pb: 0, pl: 1}} variant="h6" />
        <TypographyFHG sx={{py: 1, px: 2}} variant="body1">{note}</TypographyFHG>
      </Popover>
    )}
    <TableContainer>
      {displayHeader && (
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        onAdd={addNewHandler}
        onAdjust={adjustHandler}
        onLoan={loanHandler}
        onTransfer={transferHandler}
        onTransferLocations={transferLocationsHandler}
        onEdit={editHandler}
        onDelete={deleteHandler}
        onSearch={handleSearch}
        addButtonKey={addButtonKey}
        adjustButtonKey={adjustButtonKey}
        loanButtonKey={loanButtonKey}
        transferButtonKey={transferButtonKey}
        transferLocationsButtonKey={transferLocationsButtonKey}
        editButtonKey={editButtonKey}
        deleteButtonKey={deleteButtonKey}
        notesColumnIndex={notesColumnIndex}
        isAdmin={isAdmin}
        historicalSearch={historicalSearch}
        displayInventoryTypes={displayInventoryTypes}
      />)}
      <MaUTable {...getTableProps()} options={options}>
        {displayHeader && (
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()} sx={{...styles.headStyle}}>
              {headerGroup.headers.map(column => (
                <TableCell
                  {...(column.id === 'selection'
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                  size="small"
                >
                  {column.id === 'selection' ? (
                    <>{isAdmin && (<Tooltip title={formatMessage(intl, 'inventory.tooltip')}>
                      <HelpIcon sx={{color: 'white', cursor: "pointer", height: '24px', mt: 0, ml: 1.5}} color="primary" />
                    </Tooltip>)}</>
                  ) : (
                    <Grid container direction="row">
                      <span style={{...styles.cellStyle}}>{column.render('Header')}</span>
                      <TableSortLabel
                        sx={{
                          cursor: "pointer",
                          '&.MuiTableSortLabel-root': {
                            color: 'white',
                          },
                          '&.MuiTableSortLabel-root:hover': {
                            color: 'white',
                          },
                          '&.Mui-active': {
                            color: 'white',
                          },
                          '& .MuiTableSortLabel-icon': {
                            color: 'white !important',
                          }
                        }}
                        active={column.isSorted}
                        // react-table has an unsorted state which is not treated here
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    </Grid>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>)}
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                {row.cells.map(cell => {
                  return cell.column.id === notesColumnIndex && row.values[notesColumnIndex] ?
                    (<TableCell {...cell.getCellProps()} sx={{'&.MuiTableCell-root': {padding: 0}}}>
                      {<Grid sx={{cursor: 'pointer'}} onClick={() => handleNoteClick(row.values[notesColumnIndex])}>{cell.render('Cell')}</Grid>}
                    </TableCell>) :
                    cell.column.id === barcodeColumnIndex ?
                      (<TableCell {...cell.getCellProps()} sx={{'&.MuiTableCell-root': {padding: 0}}}>
                        {<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleBarcodeClick(row.values[barcodeColumnIndex])}>{row.values[barcodeColumnIndex]}</Link>}
                      </TableCell>) :
                    (<TableCell {...cell.getCellProps()} sx={{'&.MuiTableCell-root': {padding: 0}}}>
                        {cell.render('Cell')}
                      </TableCell>)
                })}
              </TableRow>
            )
          })}
        </TableBody>
        {displayFooter && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50,
                { label: 'All', value: data.length },
              ]}
              align="left"
              colSpan={10}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              selectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>)}
      </MaUTable>
    </TableContainer>
  </>
  )
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  displayHeader: PropTypes.bool,
  displayFooter: PropTypes.bool,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  onAdjust: PropTypes.func,
  onBarcodeClick: PropTypes.func,
  onLoan: PropTypes.func,
  onTransfer: PropTypes.func,
  onTransferLocations: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRowSelect: PropTypes.func,
  onSearch: PropTypes.func,
  addButtonKey: PropTypes.string,
  adjustButtonKey: PropTypes.string,
  loanButtonKey: PropTypes.string,
  transferButtonKey: PropTypes.string,
  transferLocationsButtonKey: PropTypes.string,
  editButtonKey: PropTypes.string,
  deleteButtonKey: PropTypes.string,
  barcodeColumnIndex: PropTypes.number,
  notesColumnIndex: PropTypes.number,
  notesTitleKey: PropTypes.string,
  isAdmin: PropTypes.bool
}

export default EnhancedTable
