import React, {useCallback, useMemo, useState} from 'react';
import {Add} from '@mui/icons-material';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CvxAdd from './CvxAdd';
import CvxEdit from './CvxEdit';
import EditDrawer from '../EditDrawer';
import filter from 'lodash/filter';
import {formatMessage} from '../../fhg/utils/Utils';
import {Link, Switch} from '@mui/material';
import {Grid} from '@mui/material';
import sortBy from 'lodash/sortBy';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {CVXS_QUERY} from '../../data/QueriesGL';
import {
  BGD_COLOR,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR,
  VAC_ACTIVE
} from '../../Constants';
import find from "lodash/find";

export default function Cvx() {
  const intl = useIntl();
  const [displayInactive, setDisplayInactive] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [cvxData] = useQueryFHG(CVXS_QUERY, {variables: {}}, 'cvx.type');

  const cvxs = useMemo(() => {
    let data = cvxData?.cvxs || [];
    let filteredRows = displayInactive ? data : filter(data, {vaccineStatus: VAC_ACTIVE});
    return sortBy(filteredRows, (obj) => parseInt(obj.cvxCode, 10))
  }, [cvxData, displayInactive]);

  const handleAdd = useCallback(() => {
    setSelectedId('new');
    setOpenDetailDrawer(true);
  }, []);

  const handleEdit = useCallback((id) => {
    setSelectedId(id);
    const result = find(cvxs, status => status.id === id);
    setSelectedRow(result);
    setOpenDetailDrawer(true);
  }, [cvxs]);

  const columns = useMemo(() => {
    return [
      {
        id: 'cvxCode',
        Header: <TypographyFHG id={'cvx.cvxCode'} sx={{whiteSpace: 'nowrap'}} />,
        accessor: 'cvxCode',
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.id)}>{row.values?.cvxCode}</Link>)
      },
      {
        id: 'shortDescription',
        Header: <TypographyFHG id={'cvx.shortDescription'}/>,
        accessor: 'shortDescription'
      },
      {
        id: 'description',
        Header: <TypographyFHG id={'cvx.full.name'}/>,
        accessor: 'description'
      },
      {
        id: 'vaccineStatus',
        Header: <TypographyFHG id={'cvx.vaccineStatus'}/>,
        accessor: 'cvxStatus.title',
        width: 115
      },
      {
        id: 'notes',
        Header: <TypographyFHG id={'cvx.notes'}/>,
        accessor: 'notes'
      },
    ];
  }, [handleEdit]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleActiveToggle = () => {
    setDisplayInactive(value => !value);
  }

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return !displayInactive ? undefined : {fontWeight: "bold"};
    } else {
      return !displayInactive ? {fontWeight: "bold"} : undefined;
    }
  }, [displayInactive]);

  return (
    <Grid container direction="column" overflow={'visible'} wrap="nowrap">
      <TypographyFHG id="cvx.title" color="textSecondary" sx={{my: 0, mr: 0, ml: 2}} variant="h5" />
      <Grid>
        <TableFHG name="Cvx" columns={columns} data={cvxs}
                  allowSearch emptyTableMessageKey={'cvx.noRecord.message'} stickyHeader
        >
          <Grid container direction="row" justifyContent="flex-start">
            <ButtonFHG id="cvx.add.button" labelKey={'cvx.add.button'} startIcon={(<Add/>)} onClick={handleAdd}
                       sx={{m: 0, fontSize: '0.875rem',
                         '&:hover': {
                           color: PRIMARY_DARK_COLOR,
                       }}}
                       variant="outlined" />
            <Grid container direction="row" sx={{my: 0, mr: 0, ml: 2, pt: 0.75, width: '320px'}}>
              <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
              <Switch color="primary" checked={!displayInactive} onChange={handleActiveToggle} sx={{mt: -0.75, mx: 0, width: '62px'}} />
              <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'slider.active.label', 'Display Active Only', null)}</Grid>
            </Grid>
          </Grid>
        </TableFHG>
      </Grid>

      <EditDrawer
        backgroundColor={BGD_COLOR}
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          {selectedId === 'new' ? <CvxAdd cvxs={cvxData?.cvxs || []} onClose={handleDetailClose} /> : <CvxEdit id={selectedId} cvx={selectedRow} onClose={handleDetailClose} />}
        </Grid>
      </EditDrawer>
    </Grid>
  );
}
