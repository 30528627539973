import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import TypographyFHG from './Typography';

/**
 *
 * @param labelKey {string}
 * @param buttonProps
 * @return {JSX.Element}
 * @constructor
 */
ButtonFHG.propTypes = {
   labelKey: PropTypes.string,
}
export default function ButtonFHG({labelKey, ...buttonProps}) {
   return <Button {...buttonProps}>
      <TypographyFHG variant={'inherit'} id={labelKey} />
   </Button>;
}

