import {useState} from 'react';

export default function useToken() {
  const getToken = () => {
    let tokenString = localStorage.getItem('authentication');

    tokenString = tokenString === 'undefined' ? undefined : tokenString;
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('authentication', userToken);
    // sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
