import React from 'react';
import {Card, CardContent} from '@mui/material';
import * as dayjs from 'dayjs';
import {getPatientAgeString, getPatientName} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import TypographyFHG from '../../fhg/components/Typography';
import {CARD_WIDTH, CARD_WIDTH_SM, DATE_FORMAT_KEYBOARD} from '../../Constants';

export default function PatientCard(props) {
  const {patient, dob, compareDate, small} = props;

  const getPatientDob = () => {
    let result;

    if (patient) {
      if (dob) {
        result = dob;
      } else {
        result = dayjs(patient?.person_DateOfBirth).format(DATE_FORMAT_KEYBOARD);
      }
    }
    return result;
  };

  const ageString = getPatientAgeString(patient?.person_DateOfBirth, compareDate);

  return (
    <Card sx={small ? {border: 'gray solid thin', mt: 1, width: CARD_WIDTH_SM} : {border: 'gray solid thin', mt: 2, width: CARD_WIDTH - 8}}>
      <CardContent>
        <Grid container direction="column">
          <Grid container direction="row">
            <TypographyFHG id="patient.card.name.label" color="textPrimary" sx={{mr: 1}} variant="body1" />
            <TypographyFHG color="textPrimary" sx={{mr: 1}} variant="body1">{getPatientName(patient)}</TypographyFHG>
          </Grid>
          <Grid container direction="row">
            <TypographyFHG id="patient.card.dob.label" color="textPrimary" sx={{mr: 1}} variant="body1" />
            <TypographyFHG color="textPrimary" sx={{mr: 1}} variant="body1">{getPatientDob()}</TypographyFHG>
          </Grid>
          <Grid container direction="row">
            <TypographyFHG id="patient.card.age.label" color="textPrimary" sx={{mr: 1}} variant="body1" />
            <TypographyFHG color="textPrimary" sx={{mr: 1}} variant="body1">{ageString}</TypographyFHG>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

PatientCard.propTypes = {
  patient: PropTypes.object,
  dob: PropTypes.string,
  compareDate: PropTypes.any,
  small: PropTypes.bool
}
