import React from 'react';
import {atom} from 'recoil';
import * as dayjs from 'dayjs';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import _toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import isObjectLike from 'lodash/isObjectLike';
import {DATE_TIME_FORMAT} from '../../Constants';
import { NOT_STARTED, PENDING, ERROR, REJECTED, SUCCESSFUL, ERROR_ARCH, REJ_ARCH, DISMISSED } from '../../Constants';
import { NOT_STARTED_DESC, PENDING_DESC, ERROR_DESC, REJECTED_DESC, SUCCESSFUL_DESC, ERROR_ARCH_DESC, REJ_ARCH_DESC, DISMISSED_DESC } from '../../Constants';

export const getAge = (dob, currentDate) => {
   var age = currentDate.year() - dob.year();
   var m = currentDate.month() - dob.month();
   if (m < 0 || (m === 0 && currentDate.day() < dob.day())) {
      age--;
   }
   return age?.toString();
}

export const getPatientAgeString = (dateOfBirth, compareDate) => {
   let result;

   if (dateOfBirth) {
      const dob = dayjs(dateOfBirth)?.format(DATE_TIME_FORMAT);
      const years = dayjs(compareDate).diff(dob, 'years', false);

      if (years === 0) {
         const months = dayjs(compareDate).diff(dob, 'months', false);
         result = `0 (${months} months)`;
      } else {
         result = `${years} years`;
      }
   }
   return result;
};

export const getPatientName = (patient) => {
   let result;

   if (patient) {
      result = `${patient?.person_LastName}, ${patient?.person_FirstName}`;
   }
   return result;
};

export const getStatus = (status) => {
   switch (status) {
      case NOT_STARTED:
         return NOT_STARTED_DESC;
      case PENDING:
         return PENDING_DESC;
      case ERROR:
         return ERROR_DESC;
      case REJECTED:
         return REJECTED_DESC;
      case SUCCESSFUL:
         return SUCCESSFUL_DESC;
      case ERROR_ARCH:
         return ERROR_ARCH_DESC;
      case REJ_ARCH:
         return REJ_ARCH_DESC;
      case DISMISSED:
         return DISMISSED_DESC;
      default:
         return "";
   }
}

export const getStatusReverse = (status) => {
   switch (status) {
      case 'NOT_STARTED':
         return NOT_STARTED;
      case 'PENDING':
         return PENDING;
      case 'ERROR':
         return ERROR;
      case 'REJECTED':
         return REJECTED;
      case 'SUCCESSFUL':
         return SUCCESSFUL;
      case 'ERROR_ARCH':
         return ERROR_ARCH;
      case 'REJ_ARCH':
         return REJ_ARCH;
      default:
         return "";
   }
}

export const digitLimit = (minimum, maximum) => (inputObj) => {
   const {value} = inputObj;
   if (value <= maximum) {
      if (minimum === undefined || value >= minimum) {
         return inputObj;
      }
   }
};

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({id, defaultMessage: newDefaultMessage}, values) : newDefaultMessage;
   } else {
      return '';
   }
}

export function removeOne(array, index) {
   if (index >= 0 && array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
   return array;
}

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return !isNil(item) && item !== '' && (!isArray(item) || item.length > 0) &&
      (!isObjectLike(item) || Object.keys(item).length > 0);
}

export const getBase64FromUrl = async (url) => {
   const data = await fetch(url);
   const blob = await data.blob();
   return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
         const base64data = reader.result;
         resolve(base64data);
      }
   });
}

export const editChange = (event, value, reason, isComponent = true, newValue, name) => {
   let nextValue;
   let componentName = name;

   if (newValue === undefined) {
      if (value && (reason === 'blur' || reason === 'create-option' || reason === 'select-option')) {
         nextValue = typeof value === 'string' ? value : value.id;
         componentName = name || get(event, 'target.parentElement.dataset.optionname') ||
            get(event, 'target.firstElementChild.dataset.optionname') || event.target.name;
      } else if (value && reason === 'date-picker') {
         nextValue = value;
         componentName = event.target.name;
      } else {
         if (event && event.target) {
            switch (event.target.type) {
               case 'number':
                  nextValue = event.target.valueAsNumber;
                  break;
               case 'checkbox':
                  nextValue = event.target.checked;
                  break;
               case 'date-range':
                  nextValue = event.target.date;
                  break;
               case 'react-select':
                  nextValue = event.target.value;
                  break;
               case 'react-number-format':
                  nextValue = toNumber(event.target.value);
                  break;
               default:
                  const type = get(event, 'target.dataset.type');
                  if (type === 'number') {
                     nextValue = toNumber(event.target.value);
                  } else {
                     nextValue = event.target.value;
                  }
                  break;
            }
            componentName = event.target.name;
         } else {
            console.log('event.target is null');
         }
      }
   }

   if (newValue) {
      return isComponent ? newValue : {componentName, newValue: newValue[name]};
   } else if (isComponent) {
      return {[componentName]: nextValue};
   }
   return {componentName, newValue: nextValue};
};

export const renderOptionsKey = (name) => (option, {inputValue}) => {
   const useOption = typeof option === 'string' ? option : option[name];
   if (useOption === null) {
      return 'Untitled';
   }

   const matches = match(useOption, inputValue);
   const parts = parse(useOption, matches);

   return (
      <div>
         {parts.map((part, index) => {
            if (part.text) {
               return (
                  <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                     {part.text}
                  </span>
               )
            } else {
               return (
                  <span key={index} style={{fontWeight: 400, color: '#A6A6A6'}}>
                     Untitled
                  </span>
               );
            }
         })}
      </div>
   );
};

/**
 * Convert the image into the api call to get a "local" image to avoid cross domain issues.
 * @param image The image to convert.
 * @return {string|undefined|*} The relative image location.
 */
export const convertImageToWrapper = (image) => {
   if (image) {
      if (process.env.NODE_ENV === 'production') {
         //https://(bucketName).s3.us-east-2.amazonaws.com/(key)
         // eslint-disable-next-line
         const regex = /https:\/\/([^.]*)[^\/]*.amazonaws.com\/(.*)/;
         const found = image.match(regex);

         if (found && found.length > 0) {
            const bucketName = found[1];
            const key = found[2];
            return `/image?bucket=${bucketName}&key=${key}`;
         }
         console.log('Image URL could not be parsed', image);
         return image;
      } else {
         return image;
      }
   }
   return undefined;
};

export function toNumber(value, isAllowBlank = true) {
   if (value === null || value === undefined || (isAllowBlank && value === '')) {
      return null;
   } else{
         return _toNumber(value);
   }
}

export function hexToRgb(hex) {
   let result = 'black';
   if (hex) {
      const hexCode = hex.charAt(0) === '#'
        ? hex.substr(1, 6)
        : hex;

      const hexR = parseInt(hexCode.substr(0, 2), 16);
      const hexG = parseInt(hexCode.substr(2, 2), 16);
      const hexB = parseInt(hexCode.substr(4, 2), 16);
      // Gets the average value of the colors
      const contrastRatio = (hexR + hexG + hexB) / (255 * 3);
      result = contrastRatio >= 0.5 ? 'black' : 'white';
   }

   return result;
}

export function getChipBgColor(color) {
   // needs to include the #
  let result = '#ffffff';
  if (color) {
    result = color.slice(0, 1) === '#' ? color : '#' + color;
  }
  return result;
}

export function getColorFromTypeId(typeId, inventoryTypes) {
   let result = '#000000';
   if (inventoryTypes && inventoryTypes.length > 0) {
      let found = inventoryTypes.find(i => i.inventoryTypeID === typeId);
      if (found) {
         result = getChipBgColor(found.colorCode);
      }
   }
   return result;
}

// For now, assume is it either Commercial or VFC.
export function getVCodeConversionList() {
   return [
      {code: 'V01', type: 2, eligible: false}, // Commercial
      {code: 'V01', type: 3, eligible: false}, // Covid
      {code: 'V01', type: 4, eligible: false}, // Ped Covid
      {code: 'V02', type: 1, eligible: true}, // VFC
      {code: 'V02', type: 3, eligible: true}, // Covid
      {code: 'V02', type: 4, eligible: true}, // Ped Covid
      {code: 'V03', type: 1, eligible: true}, // VFC
      {code: 'V03', type: 3, eligible: true}, // VFC
      {code: 'V03', type: 4, eligible: true}, // VFC
      {code: 'V04', type: 1, eligible: true}, // VFC
      {code: 'V05', type: 1, eligible: true}, // VFC
      {code: 'V06', type: 1, eligible: false}, // Unknown
      {code: 'V07', type: 1, eligible: true}, // VFC
      {code: 'V08', type: 1, eligible: true}, // VFC
      {code: 'V09', type: 1, eligible: true}, // VFC
      {code: 'V10', type: 1, eligible: true}, // VFC
      {code: 'V19', type: 3, eligible: false}, // CHIP
      {code: 'V22', type: 1, eligible: true}, // VFC
      {code: 'V23', type: 1, eligible: true}, // VFC
      {code: 'V24', type: 1, eligible: true}, // VFC
      {code: 'V25', type: 1, eligible: true} // VFC
   ];
}
export function getVCodeConversionList2() {
   return [
      {code: 'V01', eligible: false}, // Commercial
      {code: 'V02', eligible: true}, // VFC
      {code: 'V03', eligible: true}, // VFC
      {code: 'V04', eligible: true}, // VFC
      {code: 'V05', eligible: true}, // VFC
      {code: 'V06', eligible: false}, // Unknown
      {code: 'V07', eligible: true}, // VFC
      {code: 'V08', eligible: true}, // VFC
      {code: 'V09', eligible: true}, // VFC
      {code: 'V10', eligible: true}, // VFC
      {code: 'V19', eligible: false}, // CHIP
      {code: 'V22', eligible: true}, // VFC
      {code: 'V23', eligible: true}, // VFC
      {code: 'V24', eligible: true}, // VFC
      {code: 'V25', eligible: true} // VFC
   ];
}

export function getCvxCode(code) {
   return code && code.length === 1 ? '0' + code : code;
}

export const errorState = atom({
   key: 'error',
   default: {
      errorKey: undefined,
      errorMessage: undefined,
      errorInfo: undefined,
      error: undefined,
      values: undefined,
      enableRefresh: true,
   },
});

