import React, {useCallback, useState} from 'react';
import AdminDrawer from './admin/AdminDrawer';
import AdminMain from './admin/AdminMain';
import AdministerIcon from '@mui/icons-material/Colorize';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CaptureIcon from "@mui/icons-material/GetAppOutlined";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ErrorStateSnackbar from '../fhg/components/ErrorStateSnackbar';
import FormControl from "@mui/material/FormControl";
import {Grid} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InventoryIcon from '@mui/icons-material/LibraryBooksOutlined';
import {Link, useMatch} from 'react-router-dom';
// import Login from './client/Login';
// import Logout from './client/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiAppBar from '@mui/material/AppBar';
import PersonIcon from '@mui/icons-material/Person';
import PatientIcon from '@mui/icons-material/AccessibilityNew';
import Select from '@mui/material/Select';
import {styled} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TypographyFHG from "../fhg/components/Typography";
import UserDrawer from './client/UserDrawer';
import UserMain from './client/UserMain';
import {useRecoilState} from 'recoil';
import {userStatus} from '../fhg/hooks/auth/useAuth';
import useTheme from '@mui/material/styles/useTheme';
import {
  ADMIN_ADMINISTER_A_VAX_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_CAPTURE_IMMUNIZE_PATH,
  ADMIN_INVENTORY_PATH,
  DRAWER_WIDTH,
  LOGO,
  USER_ADMINISTER_A_VAX_PATH,
  USER_CAPTURE_IMMUNIZE_PATH,
  USER_DASHBOARD_PATH,
  USER_INVENTORY_PATH,
  ADMIN_PATRECORDS_PATH,
  USER_PATRECORDS_PATH
} from '../Constants';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // marginLeft: 0,
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  width: DRAWER_WIDTH
}));

export default function Root() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isLogoutMenuOpen, setIsLogoutMenuOpen] = useState(false);
  const [{firstName, isAdmin, signOut}] = useRecoilState(userStatus);

  // eslint-disable-next-line no-unused-vars
  // const [localToken, setLocalToken] = useState(undefined);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isAuthenticated, setAuthenticated] = useState(false);

  const getAdministerLink = useCallback(() => {
    return isAdmin ? ADMIN_ADMINISTER_A_VAX_PATH : USER_ADMINISTER_A_VAX_PATH;
  }, [isAdmin]);

  const getCaptureLink = useCallback(() => {
    return isAdmin ? ADMIN_CAPTURE_IMMUNIZE_PATH : USER_CAPTURE_IMMUNIZE_PATH;
  }, [isAdmin]);

  const getInventoryLink = useCallback(() => {
    return isAdmin ? ADMIN_INVENTORY_PATH : USER_INVENTORY_PATH;
  }, [isAdmin]);

  const getPatRecLink = useCallback(() => {
    return isAdmin ? ADMIN_PATRECORDS_PATH : USER_PATRECORDS_PATH;
  }, [isAdmin]);

  const administerMatch = useMatch({path: getAdministerLink(), strict: true, sensitive: true});
  const captureMatch = useMatch({path: getCaptureLink(), strict: true, sensitive: true});
  const inventoryMatch = useMatch({path: getInventoryLink(), strict: true, sensitive: true});
  const patRecordMatch = useMatch({path: getPatRecLink(), strict: true, sensitive: true});

  // const setToken = useCallback(userToken => {
  //   localStorage.setItem('authentication', userToken);
  //   sessionStorage.setItem('authentication', userToken);
  //   setLocalToken(userToken);
  //   if (!isAuthenticated) {
  //     setAuthenticated(true);
  //   }
  // },[isAuthenticated, setLocalToken]);
  //
  // const logout = useCallback(() => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   setAuthenticated(false);
  //   setLocalToken('');
  // }, [setAuthenticated, setLocalToken]);
  //
  // const checkAuth = useCallback(() => {
  //   if (sessionStorage.getItem("authentication")) {
  //     setAuthenticated(true);
  //   }
  // }, [setAuthenticated]);

  const getDrawer = useCallback(() => {
    return isAdmin ? <AdminDrawer handleDrawerClose={handleDrawerClose}/> : <UserDrawer handleDrawerClose={handleDrawerClose}/>;
  }, [isAdmin]);

  const handleClose = () => {
    setIsLogoutMenuOpen(false);
  };

  const handleLogout = () => {
    setIsLogoutMenuOpen(true);
  };

  const handleLogoutChange = () => {

  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <AppBar position="fixed" open={open} sx={{backgroundColor: 'white !important'}}>
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between">
            <Grid sx={{display: 'flex', flexDirection: 'row', pl: 1.5}}>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ m: 'auto 8px', height: '40px', width: '40px', ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Link to={isAdmin ? ADMIN_DASHBOARD_PATH : USER_DASHBOARD_PATH}>
                <img alt='' src={LOGO} style={{cursor: 'pointer', height: '44px', marginRight: 1}} />
              </Link>
              <Hidden xsDown>
                <Grid container direction="row" >
                  <span style={{color: 'black !important', my: 'auto', mx: 1}}>{process.env.REACT_APP_VERSION}</span>
                  <span style={{fontWeight: 'bold', fontSize: '24px', color: 'green !important', my: 'auto', mx: 1}}>{process.env.NODE_ENV}</span>
                </Grid>
              </Hidden>
            </Grid>
            <Grid sx={{display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', my: 'auto', mx: 0}}>
              <Grid container direction="row" sx={{m: 'auto !important'}}>
                <Button component={Link} to={getAdministerLink()} onClick={handleDrawerClose}
                        sx={{color: 'black !important', height: '2rem', mr: 2,}}
                        disabled={administerMatch?.isExact}
                        startIcon={(<AdministerIcon fontSize="large" />)}
                        size="large">
                  <TypographyFHG id='appbar.administer.button' />
                </Button>
                <Button component={Link} to={getInventoryLink()} onClick={handleDrawerClose}
                        sx={{color: 'black !important', height: '2rem', mr: 2,}}
                        disabled={inventoryMatch?.isExact}
                        startIcon={(<InventoryIcon fontSize="large" />)}
                        size="large">
                  <TypographyFHG id='appbar.inventory.button'/>
                </Button>
                <Button component={Link} to={getPatRecLink()} onClick={handleDrawerClose}
                        sx={{color: 'black !important', height: '2rem', mr: 2,}}
                        disabled={patRecordMatch?.isExact}
                        startIcon={(<PatientIcon fontSize="large" />)}
                        size="large">
                  <TypographyFHG id='appbar.patrecords.button'/>
                </Button>
                <Button component={Link} to={getCaptureLink()} onClick={handleDrawerClose}
                        sx={{color: 'black !important', height: '2rem', mr: 2,}}
                        disabled={captureMatch?.isExact}
                        startIcon={(<CaptureIcon fontSize="large" />)}
                        size="large">
                  <TypographyFHG id='appbar.capture.button'/>
                </Button>
              </Grid>
              <FormControl sx={{width: '240px !important'}}>
                <Select
                  open={isLogoutMenuOpen}
                  onClose={handleClose}
                  onOpen={handleLogout}
                  renderValue={() => (
                    <Grid container direction="row" alignItems="center" wrap="nowrap">
                      <PersonIcon color="inherit" sx={{color: `black !important`, pr: 0.5}} />
                        <Typography sx={{my: 0, mx: 1}} variant="body2" color="inherit" size="small">
                          Hi {firstName}
                        </Typography>
                    </Grid>
                  )}
                  disableUnderline={true}
                  displayEmpty={true}
                  size="small"
                  value={''}
                  onChange={handleLogoutChange}
                >
                  <MenuItem value='logout' onClick={signOut}>
                    <Typography id='path.logout'>Logout</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Grid container direction="row" justifyContent="flex-end">
          <>
            <IconButton sx={{height: '36px', my: 0.25, mx: 0.5}} onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </>
        </Grid>
        <Divider />
        {getDrawer()}
      </Drawer>
      <>
        <ErrorStateSnackbar/>
        <Main open={open}>
          <DrawerHeader />
          {isAdmin && <AdminMain />}
          {!isAdmin && <UserMain />}
         </Main>
      </>
    </Box>
  );
}
