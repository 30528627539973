import React, {useCallback, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import * as dayjs from 'dayjs';
import Form from '../../fhg/components/edit/Form';
import {Grid} from '@mui/material';
import InventoryCardBasic from './InventoryCardBasic';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {MenuItem, Select} from '@mui/material';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import TextFieldCustom from '../TextFieldCustom';
import {
  ADJUSTED_INVENTORY_CREATE,
  getAdjustmentRefetchQueries
} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT,
  EDIT_DRAWER_WIDTH,
  ADJUST_REASON_1,
  ADJUST_REASON_2,
  ADJUST_REASON_3,
  ADJUST_REASON_4,
  ADJUST_REASON_5,
  ADJUST_REASON_6,
  ADJUST_REASON_7,
  ADJUST_REASON_8,
  PRIMARY_DARK_COLOR,
  EDIT_DRAWER_ITEM_WIDTH,
  DATE_PICKER_WIDTH
} from '../../Constants';

export default function InventoryAdjust(props) {
  const {inventory} = props;
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [adjustedInventoryCreate] = useMutationFHG(ADJUSTED_INVENTORY_CREATE);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData(undefined, []);

  useMemo(() => {
    if (inventory) {
      setDefaultValues(inventory);
    }
  }, [inventory, setDefaultValues]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleAdjustReasonChange = (e) => {
    setEditValues(editValues => ({...editValues, reason: e.target.value}));
    setIsChanged(true);
  }

  const handleTypeChange = (e) => {
    setEditValues(editValues => ({...editValues, adjustmentType: e.target.value}));
    setIsChanged(true);
  }

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);

        let currentItem = {
          ...defaultValues,
          ...editValues,
        };

        if (!currentItem.adjustmentType) {
          setMessageKey('inventory.required.type.message');
          setOpenAlert(true);
          return;
        }

        if (!currentItem.reason) {
          setMessageKey('inventory.required.reason.message');
          setOpenAlert(true);
          return;
        }

        if (!currentItem.notes) {
          setMessageKey('inventory.required.notes.message');
          setOpenAlert(true);
          return;
        }

        const adjustDate = dayjs(currentItem.adjustDate)?.format(DATE_DB_FORMAT);
        const adjustItem = {
          id: inventory.id,
          vaccineID: inventory.id,
          adjustType: currentItem.adjustmentType,
          adjustDate: adjustDate,
          doses: Number(currentItem.doses),
          reason: currentItem.reason,
          notes: currentItem.notes,
          inventoryId: inventory.id,
          dateEntered: dayjs().format(DATE_DB_FORMAT)
        };

        const dateAdjustedStart = dayjs().startOf('month').format(DATE_DB_FORMAT);
        const dateAdjustedEnd = dayjs().endOf('month').format(DATE_DB_FORMAT);

        await adjustedInventoryCreate({
          variables: adjustItem,
          optimisticResponse: {
            __typename: 'Mutation',
            adjustedInventory: {
              ...adjustItem,
              __typename: 'Adjust'
            }
          },
          refetchQueries: getAdjustmentRefetchQueries(dateAdjustedStart, dateAdjustedEnd, null)
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [inventory, adjustedInventoryCreate, handleClose, isChanged, defaultValues, editValues, setIsChanged]);

  const getDosesProps = useCallback(() => {
    // Adjustment doses must always be a positive number even though the inventory value might be negative.
    // The type of long will add to inventory and short will deduct.
    return {allowNegative: false, decimalScale: 0, style: {fontSize: '0.875rem'}};
  }, []);

  const handleAdjustDateChange = (date) => {
    setEditValues(prevState => ({...prevState, adjustDate: date}));
    setIsChanged(true);
  };

  if (inventory?.mfgBrand) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'inventory.adjustment.title'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
            <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
              {getAlert()}
              <InventoryCardBasic inventory={inventory} />
              <Grid container direction="column" sx={{mt: 1.5, pt: 0.75, width: DATE_PICKER_WIDTH}}>
                <KeyboardDatePickerFHG
                  key="adjustDate1"
                  name="adjustDate"
                  labelKey="inventory.adjustDate.label"
                  onChange={handleAdjustDateChange}
                  value={editValues?.adjustDate || dayjs(defaultValues?.adjustDate)}
                />
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="doses1"
                  name="doses"
                  autoFocus
                  isFormattedNumber
                  InputLabelProps={{ shrink: true }}
                  inputProps={getDosesProps()}
                  labelKey="inventory.adjustment.doses.label"
                  onChange={handleChange}
                  value={editValues.doses}
                  required
                />
              </Grid>
              <Grid container direction="column" sx={{mb: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TypographyFHG id="inventory.typeAdjustment.label" color="primary"
                               sx={{flex: 'flex', mt: 0, mr: 0, mb: 0.25, ml: 0.5}} variant="subtitle2" />
                <Select onChange={handleTypeChange} value={editValues.adjustmentType ? editValues.adjustmentType : ''}
                        sx={{pl: '8px'}} size="small" required variant="outlined">
                  <MenuItem value=""><TypographyFHG id="inventory.select.option" color="textPrimary" variant="body1" /></MenuItem >
                  <MenuItem value="wasted"><TypographyFHG id="inventory.wasted.option" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value="expired"><TypographyFHG id="inventory.expired.option" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value="long"><TypographyFHG id="inventory.long.option" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value="short"><TypographyFHG id="inventory.short.option" color="textPrimary" variant="body1" /></MenuItem>
                </Select>
              </Grid>
              <Grid container direction="column" sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TypographyFHG id="inventory.adjustment.reason.label" color="primary" sx={{mb: 0.25, ml: 0.5}} variant="subtitle2" />
                <Select onChange={handleAdjustReasonChange} value={editValues.reason ? editValues.reason : ''} required sx={{pl: 1}} size="small" variant="outlined">
                  <MenuItem  value=""><TypographyFHG id="inventory.select.option" color="textPrimary" variant="body1" /></MenuItem >
                  <MenuItem value={ADJUST_REASON_1}><TypographyFHG id="inventory.adjustment.reason.option1" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_2}><TypographyFHG id="inventory.adjustment.reason.option2" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_3}><TypographyFHG id="inventory.adjustment.reason.option3" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_4}><TypographyFHG id="inventory.adjustment.reason.option4" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_5}><TypographyFHG id="inventory.adjustment.reason.option5" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_6}><TypographyFHG id="inventory.adjustment.reason.option6" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_7}><TypographyFHG id="inventory.adjustment.reason.option7" color="textPrimary" variant="body1" /></MenuItem>
                  <MenuItem value={ADJUST_REASON_8}><TypographyFHG id="inventory.adjustment.reason.option8" color="textPrimary" variant="body1" /></MenuItem>
                </Select>
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                 <TextFieldCustom
                  key={'notes' }
                  name={'notes'}
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  InputLabelProps={{ shrink: true }}
                  labelTemplate={'inventory.adjustment.{name}.label'}
                  multiline
                  rows="2"
                  onChange={handleChange}
                  value={editValues.notes}
                  required
                />
              </Grid>
          </Grid>
        </Grid>
          <Grid sx={{
            borderTopColor: 'lightgray',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            bottom: 0,
            height: '60px',
            pl: 3,
            width: '100%'
          }}>
            <Grid container direction="row" sx={{mt: 0.5}}>
              <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                              type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                              sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
              />
              <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                         disabled={isSaving} onClick={() => handleClose()}
                         sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
  } else {
    return null;
  }
}

InventoryAdjust.propTypes = {
  inventory: PropTypes.any,
  onClose: PropTypes.func.isRequired
}
