import React, {useCallback, useState} from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import {TextField} from '@mui/material';

const GlobalFilter = ({
                        preGlobalFilteredRows,
                        globalFilter,
                        setGlobalFilter,
                      }) => {
  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState(localStorage.getItem("INVENTORY_SEARCH") ? localStorage.getItem("INVENTORY_SEARCH") : globalFilter);

  const handleSearchChange = useCallback((e) => {
    setSearchValue(e.target.value);
    localStorage.setItem("INVENTORY_SEARCH", e.target.value);
    setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
  }, [setGlobalFilter, setSearchValue]);

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <TextField
      name="search1"
      onChange={handleSearchChange}
      placeholder={`${count} records...`}
      InputProps={{
        startAdornment: (<InputAdornment position="start"><SearchIcon color="primary" size="small" /></InputAdornment>)
      }}
      size="small"
      value={searchValue || ''}
      variant="outlined"
    />
  )
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
}

export default GlobalFilter
