import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import times from 'lodash/times';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Grid} from '@mui/material';
import {LabelsSheet} from './LabelSheet';
import Loading from '../../fhg/components/Loading';
import {MenuItem, Select} from '@mui/material';
import StatusSnackbar from '../../fhg/components/StatusSnackbar';
import TextFieldCustom from '../TextFieldCustom';
import {toNumber} from '../../fhg/utils/Utils';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import {useNavigate} from 'react-router-dom';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {useReactToPrint} from 'react-to-print';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {INVENTORY_BARCODE_MAX_QUERY} from '../../data/QueriesGL';
import {
  ADMIN_DASHBOARD_PATH,
  PRIMARY_DARK_COLOR,
  USER_DASHBOARD_PATH
} from '../../Constants';

export default function PrintBarcodes() {
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const [displayBarcodes, setDisplayBarcodes] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [barcodeArray, setBarcodeArray] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [barcodeData] = useQueryFHG(INVENTORY_BARCODE_MAX_QUERY, {fetchPolicy: "no-cache"}, 'clinicBarcode.type');
  const [{isAdmin}] = useRecoilState(userStatus);
  const LABELS_PER_SHEET = 30;
  const editItem = useMemo(() => ({
    maxValue: 0,
    startingNumber: 0,
    sheets: 1
  }), []);
  const [
    editValues, handleChange, {
      defaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData(editItem, []);

  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error, setOpenSnackbar]);

  const generateNewBarcodes = useCallback((sheets, clinicBarcode) => {
    const start = toNumber(clinicBarcode);
    const qty = sheets * LABELS_PER_SHEET;
    return times(qty, (idx) => {
      return `${start+idx}`;
    });
  }, []);

  useEffect(() => {
    if (barcodeData && barcodeData?.barcode) {
      const maxVal = toNumber(barcodeData.barcode.clinicBarcode);
      const nextVal = maxVal + 1;
      setEditValues({sheets: editItem.sheets, startingNumber: nextVal});
    }
  }, [barcodeData, editItem.sheets, setEditValues]);

  const handleClose = useCallback(() => {
    const path = isAdmin ? ADMIN_DASHBOARD_PATH : USER_DASHBOARD_PATH
    resetValues();
    navigate({pathname: path});
  }, [isAdmin, navigate, resetValues]);

  const handleCloseSnackbar = useCallback(() => {
    setError(undefined);
    setOpenSnackbar(false);
  }, [setOpenSnackbar]);

  const handleSheetsChange = (e) => {
    setEditValues(editValues => ({...editValues, sheets: e.target.value}));
  }

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef]);

  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      const qty = toNumber(editValues.sheets) * LABELS_PER_SHEET;

      setTimeout(() => {
        if (!barcodeArray) {
          const newBarcodes = generateNewBarcodes(qty, editValues.startingNumber);
          setBarcodeArray(newBarcodes);
        }
        resolve();
      }, 2000);
    });
  }, [barcodeArray, generateNewBarcodes, editValues.sheets, editValues.startingNumber]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "BarcodeLabels",
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true
  });

  const handleGenerate = useCallback(() => {
    setLoading(true);
    const qty = toNumber(editValues.sheets) * LABELS_PER_SHEET;
    const newBarcodes = generateNewBarcodes(qty, editValues.startingNumber);
    setBarcodeArray(newBarcodes);
    setLoading(false);
    setDisplayBarcodes(true);
  }, [generateNewBarcodes, editValues.sheets, editValues.startingNumber]);

  const getBarcodeSheets = useCallback(() => {
    let results = {};

    if (barcodeArray && barcodeArray.length > 0) {
      results = (<LabelsSheet ref={componentRef} barcodes={barcodeArray} sheets={editValues.sheets} />);
    }

    return results;
  }, [barcodeArray, componentRef, editValues.sheets]);

  return (
     <Grid container direction="row" overflow="visible" wrap="nowrap">
       {error && openSnackbar ? (
         <StatusSnackbar autoHideDuration={null} allowClose open={openSnackbar} messageKey="barcode.duplicate.message"
                         onClose={handleCloseSnackbar} index={0} total={0} errorState={true} anchor={{vertical: 'top', horizontal: 'center'}}  />
       ) : null}
       <Grid>
           <TypographyFHG id="barcode.title.label" color="textSecondary" sx={{mt: 0, mr: 0, mb: 1, ml: 2}} variant="h5" />
           <Grid container direction="row" sx={{mt: 4, p: 2}}>
             <Grid container item direction="column" sx={{mr: 3, width: '240px'}}>
               <TextFieldCustom
                 key="startingNumber"
                 name="startingNumber"
                 sx={{mt: 2, mx: 0, mb: 0, width: '220px'}}
                 defaultValue={defaultValues.startingNumber}
                 labelTemplate="barcode.startingNumber.label"
                 onChange={handleChange}
                 value={editValues.startingNumber}
                 required
               />
               <Grid container direction="column" sx={{width: '220px'}}>
                 <TypographyFHG id="barcode.sheets.label" color="primary" sx={{flex: 'flex', my: 1, mx: 2}} variant="subtitle2" />
                 <Select sx={{pl: 1}} inputProps={{size: "small"}}
                         required variant="outlined"
                         onChange={handleSheetsChange} value={editValues.sheets ? editValues.sheets : '1'} >
                   <MenuItem  value="1"><TypographyFHG id="barcode.select.option1" color="textPrimary" variant="body1" /></MenuItem >
                   <MenuItem value="2"><TypographyFHG id="barcode.select.option2" color="textPrimary" variant="body1" /></MenuItem>
                   <MenuItem value="3"><TypographyFHG id="barcode.select.option3" color="textPrimary" variant="body1" /></MenuItem>
                 </Select>
               </Grid>
               <Grid container item direction="row" justifyContent="space-between" sx={{borderTop: 'solid 1px darkgray', mt: 3, py: 1}}>
                 <ButtonFHG sx={{mt: 1, mr: 0, mb: 0, ml: 0, '&:hover': {color: PRIMARY_DARK_COLOR}}} variant="outlined" color="primary"
                            disabled={displayBarcodes} onClick={handleGenerate} labelKey="generate.button" />
                 <ButtonFHG sx={{mt: 1, mr: 0, mb: 0, ml: 0, '&:hover': {color: PRIMARY_DARK_COLOR}}} variant="outlined" color="primary"
                            disabled={!displayBarcodes} onClick={handlePrint} labelKey="print.button" />
                 <ButtonFHG sx={{mt: 1, mr: 0, mb: 0, ml: 0, '&:hover': {color: PRIMARY_DARK_COLOR}}} variant="outlined" labelKey="close.button"
                            onClick={() => handleClose()}/>
               </Grid>
             </Grid>
             <Grid container item direction="column" sx={{width: 150}}>
               <Loading isLoading={loading}/>
               {displayBarcodes && getBarcodeSheets()}
             </Grid>
           </Grid>
        </Grid>
     </Grid>
   );
}
