import assign from 'lodash/assign';
import get from 'lodash/get';
import values from 'lodash/values';
import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import {removeOne} from './Utils';

/**
 * Update the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to update. (e.g. {query, variables, queryPath})
 * @param id of the item to update.
 * @param mutationPathProp Property name for the property to update coming back from the mutation.
 * @return {function: void} The function for update.
 */
export const cacheUpdate = (queryList, id, mutationPathProp) => {
   const useQueryList = castArray(queryList);

   if (id !== undefined) {
      return (proxy, {data}) => {
         for (const queryItem of useQueryList) {
            const {query, variables, queryPath = mutationPathProp, mutationPath = mutationPathProp} = queryItem;
            const resultData = get(data, mutationPath);
            if (resultData) {
               try {
                  const cachedData = proxy.readQuery({query, variables});
                  const itemIndex = findIndex(cachedData[queryPath], {id});
                  let arr;

                  if (itemIndex >= 0) {
                     arr = [...cachedData[queryPath]];
                     const updatedItem = assign({}, arr[itemIndex], resultData);
                     arr[itemIndex] = updatedItem;
                  } else {
                     arr = [...(cachedData[queryPath] || []), resultData];
                  }
                  proxy.writeQuery({query, variables, data: {...cachedData, [queryPath]: arr}});
               } catch (e) {
                  debugger;
                  console.log('Failed to update cache.', e);
               }
            }
         }
      }
   } else {
      return cacheAdd(useQueryList, mutationPathProp);
   }
};

/**
 * Add the new item to the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to add the result item. (e.g. {query, variables, queryPath})
 * @param mutationPath Property name resulting object being updated.
 * @return {function: void} The function for the update.
 */
export const cacheAdd = (queryList, mutationPath) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = mutationPath} = queryItem;

         const resultData = get(data, mutationPath);
         // Read the data from our cache for this query.
         const cachedData = proxy.readQuery({query, variables});
         // Write our data back to the cache with the new comment in it
         const newArray = [...(cachedData[queryPath] || []), resultData];
         const newData = {...cachedData, [queryPath]: newArray};
         proxy.writeQuery({query, variables, data: newData});
      }
   };
}

/**
 * Delete the item add the id from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at id. (e.g. {query, variables, queryPath})
 * @param id The id of the item to delete in the cache.
 * @param path Property name resulting object being updated.
 * @return {function: void} Function for update.
 */
export const cacheDelete = (queryList, id, path) => {
   const useQueryList = castArray(queryList);

   return (proxy) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = path} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         const itemIndex = findIndex(cachedData[queryPath], {id});
         if (itemIndex >= 0) {
            const modifiedList = removeOne([...cachedData[queryPath]], itemIndex);
            proxy.writeQuery({
               query,
               variables,
               data: {...cachedData, [queryPath]: modifiedList.length > 0 ? modifiedList : null}
            });
         }
      }
   };
}

/**
 * Create a cell in Excel spreadsheet.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 * @param font The font style for the cell.
 * @param hasBottomBorder Indicates if the cell has a bottom border.
 * @return {Cell} The Excel spreadsheet cell.
 */
const createCell = (worksheet, text, location, font, hasBottomBorder) => {
   const currentCell = worksheet.getCell(location);
   currentCell.value = text;
   currentCell.font = font;

   if (hasBottomBorder) {
      currentCell.border = {
         bottom: {style: 'thin'},
      };
   }
   return currentCell;
};

/**
 * Create a label for the Excel spreadsheet. Label is bold and can be aligned right.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 * @param alignRight Indicates if the text should be aligned right.
 */
export const createLabel = (worksheet, text, location, alignRight = false) => {
   const LABEL_FONT = {
      name: 'Arial',
      size: 10,
      bold: true,
   };
   const currentCell = createCell(worksheet, text, location, LABEL_FONT);

   if (alignRight) {
      currentCell.alignment = {horizontal: 'right'};
   }
};

/**
 * Create a notes area for the Excel spreadsheet. A note is any large text area.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 * @param wrap Indicates if the cell should wrap.
 */
export const createNotes = (worksheet, text, location, wrap = false) => {
   const NOTE_FONT = {
      name: 'Arial',
      size: 10,
   };
   const currentCell = createCell(worksheet, text, location, NOTE_FONT);

   if (wrap) {
      currentCell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'left'};
   }
};

/**
 * A value cell in an Excel spreadsheet. The value will be underlined.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param text The text for the cell.
 * @param location The location of the cell (e.g. 'A1').
 */
export const createValue = (worksheet, text, location) => {
   const VALUE_FONT = {
      name: 'Arial',
      size: 12,
   }
   createCell(worksheet, text, location, VALUE_FONT, true);
};

/**
 * A table for an Excel spreadsheet.
 *
 * @param worksheet The worksheet of the spreadsheet.
 * @param columns The columns for the table.
 * @param data The data properties must match the columns in order. For example the first column will be the first
 *        property in the object
 * @param location The location of the cell (e.g. 'A1').
 * @param showRowStripes Indicates if the stripes should be used in the table.
 * @param style
 */
export const createTable = (worksheet, columns, data, location, showRowStripes=false, style={}) => {
   const rows = map(data, values);

   worksheet.addTable({
      name: 'MyTable',
      ref: location,
      headerRow: true,
      totalsRow: true,
      style: {
         theme: 'TableStyleLight15',
         showRowStripes,
         ...style,
      },
      columns,
      rows,
   });
};
