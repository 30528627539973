import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Typography from './Typography';

/**
 * Button Component to show progress.
 *
 * Reviewed:
 */
ProgressButton.propTypes = {
   isProgress: PropTypes.bool.isRequired, //Indicates if the progress should be showing.
   labelKey: PropTypes.string,            // Localization key for the button label.
   typographyProps: PropTypes.any,        // The properties for the typography component.
   ...Button.propTypes,
};

/**
 *
 * @param isProgress
 * @param labelKey
 * @param children
 * @param typographyProps
 * @param buttonProperties {ButtonProps}
 * @return {JSX.Element}
 * @constructor
 */
export default function ProgressButton({isProgress = false, labelKey, children, typographyProps, ...buttonProperties}) {
   return (
      <Button {...buttonProperties}>
         {labelKey && (
            <Typography variant={'inherit'} id={labelKey} {...typographyProps}/>
         )}
         {children}
         {isProgress && <CircularProgress size={15} thickness={2.5} sx={{ml: 0.5, color: 'white'}} />}
      </Button>
   );
}

