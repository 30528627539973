import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import CheckIcon from '@mui/icons-material/Done';
import {Chip, FormControlLabel, IconButton, MenuItem, Select} from '@mui/material';
import dayjs from 'dayjs';
import {Grid} from '@mui/material';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import {formatMessage, getChipBgColor, hexToRgb, toNumber} from '../../fhg/utils/Utils';
import InventoryCardBasic from '../inventory/InventoryCardBasic';
import isNil from 'lodash/isNil';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import {lighten} from '@mui/material/styles';
import orderBy from 'lodash/orderBy';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  BORROW_CREATE,
  BORROW_MULTIPLE_CREATE,
  BARCODE_SEARCH_QUERY,
  getBorrowsRefetchQueries,
  getInventoryListRefetchQueries,
  INVENTORY_CREATE,
  INVENTORY_SEARCH_CLINIC_BARCODE_QUERY,
  INVENTORY_TYPES_QUERY,
  LOAN_REASONS_QUERY
} from '../../data/QueriesGL';
import {
  DATE_DB_FORMAT, DATE_PICKER_WIDTH,
  EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH,
  INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT,
  PRIMARY_DARK_COLOR, PRIVATE, VFC
} from '../../Constants';

export default function BorrowAdd(props) {
  const {onClose} = props;
  const intl = useIntl();
  const [{userId}] = useRecoilState(userStatus);
  const [barcode, setBarcode] = useState('');
  const [barcodeNew, setBarcodeNew] = useState('');
  const clinicBarcodeRef = useRef(null);
  const [creatingBorrow, setCreatingBorrow] = useState(false);
  const [createdBorrow, setCreatedBorrow] = useState(false);
  const [creatingInventory, setCreatingInventory] = useState(false);
  const [createdInventory, setCreatedInventory] = useState(false);
  const [defaultDate] = useState(dayjs());
  const [filteredInvTypes, setFilteredInvTypes] = useState([]);
  const [fromInventoryId, setFromInventoryId] = useState(undefined);
  const [fromTypeId, setFromTypeId] = useState(undefined);
  const [fromInventory, setFromInventory] = useState({});
  const [toInventoryId, setToInventoryId] = useState(undefined);
  const [toTypeId, setToTypeId] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loanDate, setLoanDate] = useState(defaultDate);
  const [messageKey, setMessageKey] = useState('');
  const [notes, setNotes] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [reasonId, setReasonId] = useState('');
  const [entireBarcode, setEntireBarcode] = useState(false);
  const [searchingBarcode, setSearchingBarcode] = useState(false);
  const [searchedBarcode, setSearchedBarcode] = useState(false);
  const [multipleStatus, setMultipleStatus] = useState('');
  const [searchingInventory, setSearchingInventory] = useState(false);
  const [loanReasonData] = useQueryFHG(LOAN_REASONS_QUERY, {}, 'loan.reasons.type');
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, {}, 'inventoryType.type');
  const [borrowCreate, {data: borrowCreateData, error: borrowCreateError}] = useMutationFHG(BORROW_CREATE);
  const [borrowCreateMultiple, {data: borrowCreateMultipleData, error: borrowCreateMultipleError}] = useMutationFHG(BORROW_MULTIPLE_CREATE);
  const [inventoryCreate, {data: invCreateData, error: invCreateError}] = useMutationFHG(INVENTORY_CREATE);
  const [getInventory, {data: fromInventoryData, error: fromInventoryError}] = useLazyQueryFHG(INVENTORY_SEARCH_CLINIC_BARCODE_QUERY, {}, 'inventory.type', true);
  const [searchBarcode, {data: barcodeData, error: barcodeError}] = useLazyQueryFHG(BARCODE_SEARCH_QUERY, {}, 'clinicBarcode.type');
  const loanReasons = useMemo(() => orderBy(loanReasonData?.loanReasons, [row => row.id], ['asc']), [loanReasonData]);
  const inventoryTypes = useMemo(() => inventoryTypesData?.inventoryTypes || [], [inventoryTypesData]);

  const getFilteredInventoryTypes = useCallback((fromType) => {
    let filtered = [];
    if (inventoryTypes && inventoryTypes.length > 0 && fromType) {
      // remove the type we are borrowing from
      let inventoryTypesFiltered = inventoryTypes.filter(x => x.id !== fromType);
      filtered = orderBy(inventoryTypesFiltered, [row => row.name.toLowerCase()], ['asc']);
    }
    return filtered;
  }, [inventoryTypes]);

  useEffect(() => {
    const types = getFilteredInventoryTypes(fromTypeId);
    setFilteredInvTypes(types);
  }, [fromTypeId, getFilteredInventoryTypes]);

  // LoanTo and BorrowFrom are a bit confusing, but we decided to stick with the existing convention from the original system to avoid DB changes.
  useEffect(() => {
    if (barcode && searchingInventory && !fromInventoryError && fromInventoryData && fromInventoryData.clinicVaccine.length > 0) {
      setFromInventoryId(fromInventoryData.clinicVaccine?.[0].id);
      setFromTypeId(fromInventoryData.clinicVaccine?.[0].typeId);
      setFromInventory(fromInventoryData.clinicVaccine[0]);
      setSearchingInventory(false);
      setSearchingBarcode(true);
    }
  }, [barcode, fromInventoryData, fromInventoryError, searchingInventory, setFromTypeId, setFromInventoryId, setFromInventory, setSearchingBarcode, setSearchingInventory]);

  // execute barcode search to find all values like the typed in barcode value
  useEffect(() => {
    if (searchingBarcode && barcode) {
      let param = {
        fetchPolicy: 'no-cache',
        variables: {clinicBarcode: barcode, dts: dayjs().format("MMDDYYYYhhmmsszzz")}
      };
      setSearchingBarcode(false);
      searchBarcode(param);
    }
  }, [barcode, searchBarcode, searchingBarcode, setSearchingBarcode]);

  const getNewBarcodeValue = useCallback((bcData) => {
    // By convention a new barcode number will be generated with -Bx at the end.
    // First find the list of barcodes with the same starting number since this vial could already have been borrowed against.
    const orderedOrig = orderBy(bcData, [row => row.clinicBarcode], ['desc']);
    // If this vial has already been borrowed against there will be a dash in the barcode.
    const bc = orderedOrig[0]?.clinicBarcode;
    let arr = [];
    let barcodeLen = 0;
    let barcodeStem;
    let barcodeSuffix;
    let newBc;
    let dashPos = bc.indexOf('-');
    if (dashPos >= 0 ) {
      barcodeLen = dashPos;
      barcodeStem = bc.substring(0, barcodeLen);
      orderedOrig.forEach(x => {
        // one of the items does not have a dash
        if (x?.clinicBarcode?.length > dashPos + 2) {
          barcodeSuffix = toNumber(x?.clinicBarcode?.substring(dashPos + 2)); // Number part after the B
          arr.push(barcodeSuffix);
        }
      });

      arr.sort((a,b)=>b-a);  // sort reverse
      const maxBc = arr[0] + 1;
      newBc = `${barcodeStem}-B${maxBc}`;
    } else {
      // No priors exist, this will be the first one.
      newBc = `${bc}-B1`;
    }
    return newBc;
  }, []);

  useEffect(() => {
    const data = barcodeData && barcodeData.barcodes ? barcodeData.barcodes : [];
    if (data.length > 0 && !searchedBarcode && !barcodeError) {
      const newBc = getNewBarcodeValue(data);
      setBarcodeNew(newBc);
      setSearchedBarcode(true);
    }
  }, [barcodeData, barcodeError, getNewBarcodeValue, setBarcodeNew, searchedBarcode, setSearchedBarcode]);

  useEffect(() => {
    if (!createdInventory && invCreateData && !invCreateError) {
      setToInventoryId(invCreateData.inventory.id);
      setCreatedInventory(true);
    }
  }, [createdInventory, invCreateData, invCreateError, setCreatedInventory, setToInventoryId]);

  useEffect(() => {
    if (!createdBorrow && borrowCreateData && !borrowCreateError) {
      setCreatedBorrow(true);
    }
  }, [barcodeNew, borrowCreateData, borrowCreateError, createdBorrow, invCreateData, setCreatedBorrow]);

  useEffect(() => {
    if (!createdBorrow && borrowCreateMultipleData && !borrowCreateMultipleError) {
      setMultipleStatus('SUCCESS');
      setCreatedBorrow(true);
    }
  }, [borrowCreateMultipleData, borrowCreateMultipleError, createdBorrow, setCreatedBorrow]);

  useEffect(() => {
    if (createdBorrow && borrowCreateData && !borrowCreateError) {
      setMessageKey('borrow.success.message');
      setOpenAlert(true);
    } else if (entireBarcode && createdBorrow && multipleStatus === 'SUCCESS') {
      setMessageKey('borrow.multiple.success.message');
      setOpenAlert(true);
    }
  }, [borrowCreateData, borrowCreateError, multipleStatus, createdBorrow, entireBarcode]);

   const resetValues = useCallback(() => {
    setBarcode('');
    setBarcodeNew('');
    setSearchingBarcode(false);
    setCreatingBorrow(false);
    setCreatedBorrow(false);
    setCreatingInventory(false);
    setCreatedInventory(false);
    setFromInventoryId(undefined);
    setFromInventory({});
    setFromTypeId(undefined);
    setToInventoryId(undefined);
    setToTypeId(null);
    setReasonId(null);
    setNotes('');
    setIsChanged(false);
    setIsSaving(false);
  }, [setCreatingInventory, setCreatedInventory, setFromInventoryId, setFromInventory,
    setFromTypeId, setToInventoryId, setToTypeId, setReasonId, setNotes, setIsChanged]);

  const handleInventoryTypeChange = useCallback((e) => {
    const {value} = e.target;
    setToTypeId(value);
    setIsChanged(true);
  }, [setToTypeId, setIsChanged]);

  const isVFC = useCallback(() => {
    let result = null;
    const fromType = find(inventoryTypes, item => item.id === fromTypeId);
    if (fromType) {
      result = fromType?.name.includes('VFC') ? VFC : PRIVATE ;
    }
    return result;
  }, [inventoryTypes, fromTypeId]);

  const getFilteredReasons = useCallback(() => {
    let result = [];
    const isVfc = isVFC();
    if (loanReasons && isVfc === VFC) {
      result = loanReasons.filter(x => x.id < 8);
    } if (loanReasons && isVfc === PRIVATE) {
      result = loanReasons.filter(x => x.id >= 8);
    } else {
      result = loanReasons
    }
    return result;
  },[isVFC, loanReasons]);

  const getReasonOptions = useCallback(() => {
    let reasons = getFilteredReasons();
    return reasons && (reasons.map(reason => {
      return(
        <MenuItem key={`reason-${reason.id}`} style={{fontSize: '.75rem', whiteSpace: "unset", wordBreak: "break-all"}} value={reason.id}>{reason.reasonName}</MenuItem>);
    }));
  }, [getFilteredReasons]);

  const getToInventoryTypes = useCallback(() => {
    return filteredInvTypes && (filteredInvTypes.map((option, i) => {
      return(
        <MenuItem key={i} value={`${option.inventoryTypeID}`}>
          <Chip label={option.name} size='small' style={{
            fontSize: '0.675rem',
            margin: 'auto',
            backgroundColor: `${getChipBgColor(option.colorCode)}`,
            color: hexToRgb(option.colorCode),
            width: '130px'
          }} />
        </MenuItem>);
    }));
  }, [filteredInvTypes]);

  const handleClose = useCallback(() => {
    resetValues();
    if (onClose) {
      onClose();
    }
  }, [onClose, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        if (!toTypeId) {
          setMessageKey('borrow.type.required.message');
          setOpenAlert(true);
          return;
        }

        //Other reason - notes are required
        if ((reasonId === 7 || reasonId === 13) && !notes) {
          setMessageKey('borrow.notes.required.message');
          setOpenAlert(true);
          return;
        }

        setIsSaving(true);
        const date = dayjs(loanDate).format(DATE_DB_FORMAT);
        const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
        const endDate = dayjs().format(DATE_DB_FORMAT);

        if (entireBarcode && !creatingInventory && !createdInventory) {
          const multipleItem = {
            id: 0,
            loanDate: date,
            fromInvId: fromInventoryId,
            toTypeId: toNumber(toTypeId),
            borrowNotes: notes,
            reasonId: reasonId,
            enteredBy: userId,
            dateEntered: date
          };
          borrowCreateMultiple({
            variables: multipleItem,
            refetchQueries: getBorrowsRefetchQueries()
          });
          setCreatingInventory(true);
          setIsChanged(false);
        } else {
          // Create a copy of the "from" inventory only with the new "to" inventory type.
          if (barcodeNew && fromInventory && !invCreateData && !creatingInventory && !createdInventory) {
            const currentItem = {
              id: 0,
              clinicBarcode: barcodeNew,
              doses: 1,
              originalDoses: 1,
              notes: notes,
              expDate: fromInventory.expDate,
              lot: fromInventory.lot,
              mfgId: fromInventory.mfgId,
              addedDate: date,
              typeId: toNumber(toTypeId),
              dateEntered: date,
              enteredBy: userId
            };

            inventoryCreate({
              variables: currentItem,
              refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
            });
            setCreatingInventory(true);
            setIsSaving(false);
            return;
          }

          // Has the new inventory been created?
          if (!toInventoryId) {
            setMessageKey('borrow.barcode.error.message');
            setOpenAlert(true);
            setIsSaving(false);
            return;
          }

          // Use the new inventoryId to create a loan record.
          if (!creatingBorrow && !createdBorrow && createdInventory) {
            setCreatingBorrow(true);

            let borrowItem = {
              id: 0,
              loanDate: date,
              doses: 1,
              notes: notes,
              inventoryId: fromInventoryId,
              loanToInventoryId: toInventoryId,
              reasonId: reasonId,
              paid: 'false',
              dateEntered: date,
              dateLastUpdated: date,
              enteredBy: userId,
              lastUpdatedBy: userId
            };

            await borrowCreate({
              variables: borrowItem,
              refetchQueries: getBorrowsRefetchQueries()
            });

            setIsChanged(false);
          }
        }
      } catch (e) {
        console.log(e?.message);
      }
    }
  }, [barcodeNew, borrowCreate, borrowCreateMultiple, creatingBorrow, createdBorrow, creatingInventory,
    createdInventory, entireBarcode, fromInventoryId, inventoryCreate, isChanged, fromInventory, invCreateData,
    loanDate, notes, reasonId, setCreatingBorrow, setIsChanged, toInventoryId, toTypeId, userId]);

  useEffect(() => {
    if (!creatingBorrow && !createdBorrow && createdInventory && toInventoryId) {
      setTimeout(() => handleSubmit().then(r => {}), 10);
    }
  }, [creatingBorrow, createdBorrow, createdInventory, handleSubmit, toInventoryId]);

  const handleEntireBarcodeChange = (e) => {
    setEntireBarcode(e.target.checked);
    setIsChanged(true);
  };

  const handleClinicBarcodeChange = (e) => {
    setBarcode(e.target.value);
    setIsChanged(true);
  };

  const handleLoanDateChange = (date) => {
    setLoanDate(date);
    setIsChanged(true);
  };

  const handleNotesChange = useCallback((e) => {
    setNotes(e.target.value);
    setIsChanged(true);
  }, [setNotes, setIsChanged]);

  const handleReasonChange = useCallback((e) => {
    setReasonId(e.target.value);
    setIsChanged(true);
  }, [setReasonId, setIsChanged]);

  const handleCloseFlyout = useCallback(() => {
    setOpenAlert(false);
    handleClose()
  }, [setOpenAlert, handleClose]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      if (messageKey === 'borrow.success.message') {
        result = <Alert severity="success" onClose={handleCloseFlyout}>
          {formatMessage(intl, messageKey, 'Borrow record created.', [barcodeNew])}
        </Alert>;
      } else if (messageKey === 'borrow.multiple.success.message') {
        result = <Alert severity="success" onClose={handleCloseFlyout}>
          {formatMessage(intl, messageKey, 'Borrow record created.', [barcodeNew])}
        </Alert>;
      } else {
        result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
      }
    }
    return result;
  }, [barcodeNew, intl, messageKey, openAlert, handleAlertClose, handleCloseFlyout]);

  const handleSearch = useCallback(() => {
    setMessageKey('');
    setOpenAlert(false);
    const fromInvOptions = {
      fetchPolicy: "no-cache",
      variables: {clinicBarcode: barcode}
    };
    getInventory(fromInvOptions);
    setSearchingInventory(true);
    setSearchedBarcode(false);
  }, [barcode, getInventory, setSearchingInventory, setSearchedBarcode]);

  const cantSave = useCallback(() => {
    return [barcode, toTypeId, reasonId].some( x => isNil(x));
  }, [barcode, toTypeId, reasonId]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'borrow.add.title'} color={'textSecondary'} />
        </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
          <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
            {getAlert()}
            <Grid container direction={'row'} >
              <TextFieldCustom
                key={'clinicBarcode'}
                name="clinicBarcode"
                autoFocus
                fullWidth={false}
                labelKey="borrow.clinicBarcode.label"
                InputLabelProps={{style: {color: lighten(PRIMARY_DARK_COLOR, .5)}}}
                inputRef={clinicBarcodeRef}
                onChange={handleClinicBarcodeChange}
                required
                size="small"
                sx={{mt: 0.875, mr: 0.25, width: '136px'}}
                value={barcode}
              />
              <IconButton aria-label="select" key={`barcode-select-01`} size="small" onClick={handleSearch}>
                <Grid key={`barcode-check-01`} sx={{
                  backgroundColor: lighten(PRIMARY_DARK_COLOR, 0.25),
                  color: '#FFF',
                  mt: -0.25,
                  pt: 0.75,
                  height: '35px',
                  width: '32px'
                }}><CheckIcon /></Grid>
              </IconButton>
              <FormGroup sx={{ml: 3}} row>
                <FormControlLabel
                  control={
                    <CheckboxFHG
                      key={'entireBarcode'}
                      name="entireBarcode"
                      color={'default'}
                      checked={entireBarcode}
                      size="small"
                      onChange={handleEntireBarcodeChange}
                    />
                  }
                  sx={{mt: -2}}
                  label={<TypographyFHG id="borrow.entireBarcode.label" color="textSecondary" sx={{mt:2, ml: -2}} variant="body2" />}
                />
              </FormGroup>
            </Grid>
            <InventoryCardBasic inventory={fromInventoryData?.clinicVaccine?.[0]} />
            <Grid container direction="column" sx={{mb: 1}}>
              <FormControl size={'small'}>
                <TypographyFHG id="inventory.loan.typeTo.label" color="primary" variant="subtitle2" />
                <Select onChange={handleInventoryTypeChange}
                        data-testid="borrow-add__inventory.types"
                        size="small"
                        sx={{fontSize: '.75rem !important', width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}
                        value={toTypeId}
                        variant="outlined"
                >
                  {getToInventoryTypes()}
                </Select>
              </FormControl>
            </Grid>
            <Grid container direction="column" sx={{mb: 0.5, pt: 0.75, width: DATE_PICKER_WIDTH}}>
              <KeyboardDatePickerFHG
                key={'loanDate-key'}
                name="loanDate"
                labelKey="borrow.loanDate.label"
                onChange={handleLoanDateChange}
                value={loanDate}
              />
            </Grid>
            <Grid container direction="column" sx={{mb: 1}}>
              <FormControl size={'small'}>
                <TypographyFHG id="inventory.loan.reason.label" color="primary" variant="subtitle2" />
                <Select MenuProps={{style: {height: '400px'}}}
                        data-testid="borrow-add__loan-reasons"
                        sx={{
                          fontSize: '.75rem !important',
                          py: 0.5, px: 1,
                          textOverflow: 'ellipse !important',
                          width: `${EDIT_DRAWER_ITEM_WIDTH}px`
                        }}
                        onChange={handleReasonChange} value={reasonId ? reasonId : ''} required variant="outlined" size="small">
                  {getReasonOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{mt: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key={'notes'}
                name={'notes'}
                labelKey="borrow.notes.label"
                multiline
                rows="2"
                inputProps={{style: {fontSize: '0.875rem'}}}
                onChange={handleNotesChange}
                value={notes}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}} >
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={cantSave()}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
            />
            <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                       onClick={() => handleClose()} sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}/>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

BorrowAdd.propTypes = {
  onClose: PropTypes.func
}
