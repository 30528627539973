import {useEffect} from 'react';
import useLazyQueryFHG from '../hooks/data/useLazyQueryFHG';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {userStatus} from '../hooks/auth/useAuth';
import {USER_BY_COGNITO_SUB_QUERY} from '../../data/QueriesGL';
import {ADMIN_ROLE_NAME, SUPERVISOR_ROLE_NAME} from '../../Constants';

export default function AuthenticatedUser({authState, onAuthenticated, children}) {
   const setUserStatus = useSetRecoilState(userStatus);
   const [userStatusData] = useRecoilState(userStatus);
   const [loadData] = useLazyQueryFHG(USER_BY_COGNITO_SUB_QUERY, {}, 'user.type');

   useEffect(() => {
      if (authState) {
         const groups = authState?.user?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? [];
         const sub = authState?.user?.attributes?.sub;
         const isAdmin = groups.includes(ADMIN_ROLE_NAME);
         const isSupervisor = groups.includes(SUPERVISOR_ROLE_NAME);

         setUserStatus({
            username: authState?.user?.username,
            groups,
            isAdmin, isSupervisor,
            signOut: authState?.signOut,
            sub});

         // eslint-disable-next-line
         onAuthenticated?.(authState);

         if (userStatusData?.username !== authState?.user?.username) {
            (async () => {
               const result = await loadData({variables: {cognitoSub: sub}});
               const user = result?.data?.users?.[0];

               if (user) {
                  setUserStatus((userState) => ({
                     ...userState,
                     firstName: user?.firstName || authState?.user?.username,
                     roleId: user?.roleId || null
                  }));
               } else {
                  setUserStatus((userState) => ({
                     ...userState,
                     firstName: authState?.user?.username, roleId: null
                  }));
               }
            })();         }
      }
   }, [authState, loadData, onAuthenticated, setUserStatus, userStatusData?.username]);

   if (authState) {
      return children;
   } else {
      return null;
   }
}
