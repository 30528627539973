import React, {useCallback, useMemo, useState} from 'react';
import {assign} from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Card, CardContent, TextField} from '@mui/material';
import * as dayjs from 'dayjs';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import {formatMessage, getPatientAgeString, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryCardBasic from '../inventory/InventoryCardBasic';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import orderBy from 'lodash/orderBy';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import useEffect from '../../fhg/hooks/useEffect';
import {useIntl} from 'react-intl';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import usePromptFHG from '../../fhg/hooks/usePromptFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {
  getPatientRecordsRefetchQueries,
  PATIENT_RECORDS_UPDATE,
  PATIENT_RECORD_QUERY,
  INSURANCE_CATEGORIES_QUERY
} from '../../data/QueriesGL';
import {
  CARD_WIDTH,
  DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD, DATE_PICKER_WIDTH,
  EDIT_DRAWER_ITEM_WIDTH,
  EDIT_DRAWER_WIDTH,
  PRIMARY_DARK_COLOR
} from '../../Constants';

export default function PatientRecordEdit(props) {
  const intl = useIntl();
  const {id: patientRecordId, startDate, endDate} = props;
  const [isSaving, setIsSaving] = useState(false);
  const [options, setOptions] = useState({
    variables: {
      id: patientRecordId,
      dts: dayjs().format("MMDDYYYYhhmmsszzz"),
    },
    fetchPolicy: "no-cache"
  });
  const [patientRecordData] = useQueryFHG(PATIENT_RECORD_QUERY, options);
  const [patientRecordUpdate] = useMutationFHG(PATIENT_RECORDS_UPDATE);
  const [insuranceData] = useQueryFHG(INSURANCE_CATEGORIES_QUERY, undefined, 'insurance.type');
  const insurance = useMemo(() => orderBy(insuranceData?.insurance, [row => row.name?.toLowerCase()], ['asc']), [insuranceData]);

  const editItem = useMemo(() => ({
    id: 0,
    dateGiven: dayjs(),
    dosesGiven: 0,
    insuranceId: 0,
    inventoryId: 0,
    notes: ''
  }), []);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues,
      getValue
    }
  ] = useEditData(undefined, []);
  let patientRecord = useMemo(() => patientRecordData?.patient[0] || editItem, [patientRecordData, editItem]);

  useEffect(() => {
    if (patientRecordData?.patient) {
      setDefaultValues(assign({}, patientRecord, {id: patientRecordId}));
    }
  }, [patientRecordId, patientRecord, patientRecordData, setDefaultValues]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        const currentItem = {
          ...defaultValues,
          ...editValues,
        };

        const doses = (currentItem.dosesGiven.constructor === String) ? toNumber(currentItem.dosesGiven) : currentItem.dosesGiven;
        const variables = {
          id: patientRecordId,
          administerID: patientRecordId,
          dateGiven: dayjs(currentItem.dateGiven).format(DATE_DB_FORMAT),
          dosesGiven: doses,
          notes: currentItem.notes,
          inventoryId: currentItem.inventoryId,
          insuranceId: currentItem.insuranceId
        };

        setIsSaving(true);
        const start = dayjs(startDate).format(DATE_DB_FORMAT);
        const end = dayjs(endDate).format(DATE_DB_FORMAT);

        await patientRecordUpdate({
          variables: variables,
          refetchQueries: getPatientRecordsRefetchQueries(start, end, currentItem.inventoryId),
        }).then(() => {
          const options = {
            variables: {
              id: patientRecordId,
              dts: dayjs().format("MMDDYYYYhhmmsszzz")
            },
            fetchPolicy: "no-cache"
          }

          setOptions(options);
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [patientRecordId, patientRecordUpdate, handleClose, isChanged, defaultValues, editValues, setIsChanged, startDate, endDate]);

  const getInsurance = useCallback(() => {
    let result = '';
    if (insurance?.length > 0) {
      const insuranceId = getValue('insuranceId') || null;
      result = find(insurance, i => i.id === insuranceId);
    }
    return result;
  }, [getValue, insurance]);

  const handleInsuranceChange = (event, value) => {
    setEditValues(editValues => ({...editValues, insuranceId: value?.id}));
    setIsChanged(true);
  };

  usePromptFHG({when: isChanged});

  const handleDateChange = (date) => {
    setEditValues(prevState => ({...prevState, dateGiven: date}));
    setIsChanged(true);
  };

  if (defaultValues.id > 0) {
    return (
      <Form onSubmit={handleSubmit}>
        <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
          <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
            <TypographyFHG variant={'h5'} id={'patientRecords.title.label'} color={'textSecondary'} />
          </Grid>
          <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
            <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
              <Card sx={{
                border: 'thin solid #707070',
                my: 2,
                width: CARD_WIDTH
              }}>
                <CardContent>
                  <Grid container direction="column">
                    <Grid container direction="row">
                      <TypographyFHG id="patient.card.name.label" color="textPrimary" sx={{mr: 1}} variant="body1" />
                      <TypographyFHG color="textPrimary" sx={{mr: 1}} variant="body1">{patientRecord?.patientName}</TypographyFHG>
                    </Grid>
                    <Grid container direction="row">
                      <TypographyFHG id="patient.card.dob.label" color="textPrimary" sx={{mr: 1}} variant="body1" />
                      <TypographyFHG color="textPrimary" sx={{mr: 1}} variant="body1">{patientRecord && patientRecord.patientDob ? dayjs(patientRecord.patientDob).format(DATE_FORMAT_KEYBOARD) : ''}</TypographyFHG>
                    </Grid>
                    <Grid container direction="row">
                      <TypographyFHG id="patient.card.age.label" color="textPrimary" sx={{mr: 1}} variant="body1" />
                      <TypographyFHG color="textPrimary" sx={{mr: 1}} variant="body1">{getPatientAgeString(patientRecord?.patientDob, dayjs())}</TypographyFHG>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <InventoryCardBasic inventory={defaultValues} />
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="dosesGiven"
                  name="dosesGiven"
                  autoFocus
                  inputProps={{style: {fontSize: '1rem'}}}
                  labelKey="patientRecords.dosesGiven.label"
                  onChange={handleChange}
                  value={editValues.dosesGiven ? editValues.dosesGiven : defaultValues.dosesGiven}
                  required
                />
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <Autocomplete
                  key="insuranceList"
                  fullWidth
                  getOptionLabel={(option) => option?.name || ''}
                  options={insurance}
                  onChange={handleInsuranceChange}
                  value={getInsurance()}
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  sx={{mt: 1.5}}
                  renderInput={(params) => (
                    <TextField {...params} label={formatMessage(intl, 'import.insurance.label')} placeholder='Select insurance' size="small" variant="outlined" required />
                  )}
                  required
                />
              </Grid>
              <Grid container direction="column" sx={{mt: 1, mb: 1, pt: 0.75, width: DATE_PICKER_WIDTH}}>
                <KeyboardDatePickerFHG
                  key="dateGiven"
                  name="dateGiven"
                  labelKey="patientRecords.dateGiven.label"
                  size="small"
                  onChange={handleDateChange}
                  value={editValues?.dateGiven || dayjs(defaultValues?.dateGiven)}
                />
              </Grid>
              <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
                <TextFieldCustom
                  key="notes"
                  name="notes"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{style: {fontSize: '0.875rem'}}}
                  labelKey="patientRecords.notes.label"
                  multiline
                  rows={3}
                  onChange={handleChange}
                  value={editValues.notes ? editValues.notes : defaultValues.notes}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{
            borderTopColor: 'lightgray',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            bottom: 0,
            height: '60px',
            pl: 3,
            width: '100%'
          }}>
            <Grid container direction="row" sx={{mt: 0.5}}>
              <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                              type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                              sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
              <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                         disabled={isSaving} onClick={() => handleClose()}
                         sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
  } else {
    return null;
  }
}

PatientRecordEdit.propTypes = {
  id: PropTypes.any,
  onClose: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any
}
