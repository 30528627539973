import React, {useCallback, useEffect, useMemo, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import BorrowAdd from './BorrowAdd';
import BorrowEdit from './BorrowEdit';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip, Dialog, Divider, IconButton, Link, Switch, Tooltip} from '@mui/material';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/ArrowDownwardOutlined';
import EditDrawer from '../EditDrawer';
import filter from 'lodash/filter';
import find from 'lodash/find';
import {formatMessage, getChipBgColor, getVCodeConversionList2, hexToRgb} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import InventoryPatients from '../inventory/InventoryPatients';
import {lighten} from '@mui/material/styles';
import Loading from '../../fhg/components/Loading';
import NotesIcon from '@mui/icons-material/AssignmentOutlined';
import orderBy from 'lodash/orderBy';
import PaybackAdd from './PaybackAdd';
import PaybackIcon from '@mui/icons-material/SyncOutlined';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import UnpayableIcon from '@mui/icons-material/CancelPresentation';
import {useIntl} from 'react-intl';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import useTheme from '@mui/material/styles/useTheme';
import {
  BORROWS_QUERY,
  BORROW_DELETE,
  getBorrowsRefetchQueries,
  getInventoryListRefetchQueries,
  getPaybacksRefetchQueries,
  INVENTORY_DELETE,
  INVENTORY_TYPES_QUERY,
  LOAN_REASONS_QUERY,
  LOAN_UPDATE,
  PAYBACK_DELETE,
} from '../../data/QueriesGL';
import {
  CARD_SM_FONT, DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD, EDIT_DRAWER_WIDTH, ERROR_COLOR,
  INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT, PRIMARY_DARK_COLOR,
  PRIVATE, VFC
} from '../../Constants';

export default function Borrows() {
  const [{isAdmin, userId}] = useRecoilState(userStatus);
  const intl = useIntl();
  const theme = useTheme();
  const [displayUnpaid, setDisplayUnpaid] = useState(false);
  const [drawerAction, setDrawerAction] = useState('');
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [loan, setLoan] = useState(undefined);
  const [selectedId, setSelectedId] = useState(undefined);
  const [borrowDataset, setBorrowDataset] = useState([]);
  const [borrowData] = useQueryFHG(BORROWS_QUERY, {variables: {}}, 'borrows.type');
  const [borrowDelete, {data: borrowDeleteData}] = useMutationFHG(BORROW_DELETE);
  const [borrowUpdate] = useMutationFHG(LOAN_UPDATE);
  const [paybackDelete, {data: paybackDeleteData}] = useMutationFHG(PAYBACK_DELETE);
  const [inventoryDelete] = useMutationFHG(INVENTORY_DELETE);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const [loanReasonsData] = useQueryFHG(LOAN_REASONS_QUERY, undefined, 'loan.reasons.type');
  const inventoryTypes = useMemo(() => inventoryTypesData?.inventoryTypes || [], [inventoryTypesData]);

  useMemo(() => {
    const recs = borrowData?.borrows ? orderBy(borrowData?.borrows, [row => row.loanDate], ['asc']) : [];
    const filteredRows = displayUnpaid ? filter(recs, {paid: 'false'}) : recs;
    const borrowArr = filteredRows.map(x => {
      return assign({}, x, {comboBrand: `${x?.lfiMfgBrand} (${x?.lfiMfgGeneric})`});
    });
    setBorrowDataset(borrowArr);
    if (recs) {
      setLoading(false);
    }
  }, [borrowData, displayUnpaid]);

  useEffect(() => {
    if (paybackDeleteData && paybackDeleteData.payback) {
      setOpenAlert(true);
    }
  }, [paybackDeleteData]);

  const borrowFromCommercialReasons = useMemo(() => {
    const reasons =  loanReasonsData && loanReasonsData.loanReasons ? loanReasonsData.loanReasons : [];
    let result = [];
    if (reasons) {
      result = reasons.filter(x => x.id >= 8);
    }
    return result;
  }, [loanReasonsData]);

  const borrowFromVfcReasons = useMemo(() => {
    const reasons =  loanReasonsData && loanReasonsData.loanReasons ? loanReasonsData.loanReasons : [];
    let result = [];
    if (reasons) {
      result = reasons.filter(x => x.id < 8);
    }
    return result;
  }, [loanReasonsData]);

  const handleAddBorrow = useCallback(() => {
    setDrawerAction('borrow');
    setOpenDetailDrawer(true)
  }, [setDrawerAction, setOpenDetailDrawer]);

  const handleAddPayback = useCallback((loanObj) => {
    setLoan(loanObj);
    setDrawerAction('payback');
    setOpenDetailDrawer(true)
  }, [setDrawerAction, setOpenDetailDrawer, setLoan]);

  const handleBarcodeClick = useCallback((inventoryId) => {
    setSelectedId(inventoryId);
    setDrawerAction('patients');
    setOpenDetailDrawer(true)
  }, []);

  const handleIdClick = useCallback((inventoryId) => {
    const loanObj = find(borrowDataset, x => x.id === inventoryId);
    setSelectedId(inventoryId);
    setLoan(loanObj);
    setDrawerAction('edit');
    setOpenDetailDrawer(true)
  }, [borrowDataset]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
    setDrawerAction('');
    setLoading(false);
  }, [setOpenAlert, setLoading]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert && borrowDeleteData && borrowDeleteData.borrow === 1) {
      result = <Dialog open={true} onClose={handleAlertClose}><Alert severity="success" onClose={handleAlertClose}>
        Successfully deleted borrow record.</Alert></Dialog>;
    } else if (openAlert && paybackDeleteData && paybackDeleteData.payback === 1) {
      result =
        <Dialog open={true} onClose={handleAlertClose}><Alert severity="success" onClose={handleAlertClose}>
          Successfully deleted payback record.</Alert></Dialog>;
    } else if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>Unknown error</TypographyFHG></Alert>;
    }

    return result;
  }, [borrowDeleteData, handleAlertClose, openAlert, paybackDeleteData]);

  const getFormattedDate = useCallback((date) => {
    let result;
    if (date) {
      result = dayjs(date).format(DATE_FORMAT_KEYBOARD);
    }
    return result;
  }, []);

  const getEligibility = useCallback((finClass, wasAdministered) => {
    let result = '';
    if (wasAdministered) {
      const vCodeList = getVCodeConversionList2();
      const eligibleItem = find(vCodeList, item => item.code === finClass) || [];
      if (eligibleItem) {
        result = eligibleItem.eligible ? 'VFC' : 'Comm';
      }
    }
    return result;
  }, []);

  const getInventory = useCallback((row, prefix) => {
    let result;
    if(row) {
      const inv = row.original;
      result = {
        inventoryId: inv[prefix + 'InventoryId'],
        clinicBarcode: inv[prefix + 'ClinicBarcode'],
        colorCode: inv[prefix + 'ColorCode'],
        cvxCode: inv[prefix + 'CvxCode'],
        cvxName: inv[prefix + 'CvxName'],
        doses: inv[prefix + 'Doses'],
        expDate: inv[prefix + 'ExpDate'],
        lot: inv[prefix + 'Lot'],
        manufacturerCode: inv[prefix + 'ManufacturerCode'],
        mfgBarcode: inv[prefix + 'MfgBarcode'],
        mfgBrand: inv[prefix + 'MfgBrand'],
        mfgGeneric: inv[prefix + 'MfgGeneric'],
        type: inv[prefix + 'Type'],
        typeId: inv[prefix + 'TypeId'],
      };
    }
    return result;
  }, []);

  const getTypeChip = useCallback((row, prefix) => {
    const inv = getInventory(row, prefix);
    const invTypeId = inv && inv.typeId ? inv.typeId : null;
    let invType
    let result = (<span style={{margin: 0}}></span>);
    if (invTypeId) {
      invType = find(inventoryTypes, item => item.id === invTypeId);
      if (invType) {
        result = (
          <span style={{margin: 0}}>
            <Chip sx={{fontSize: CARD_SM_FONT, m: 0, width: '130px'}} size='small' label={invType.name} style={{
              margin: 'auto',
              backgroundColor: `${getChipBgColor(invType.colorCode)}`,
              color: hexToRgb(invType.colorCode)
            }}/>
          </span>
        );
      }
    }

    return result;
  }, [getInventory, inventoryTypes]);

  const getBarcode = useCallback((row, prefix) => {
    const inv = getInventory(row, prefix);
    const bc = inv && inv.clinicBarcode ? inv.clinicBarcode : null;
    let result = (<span style={{margin: 0}}></span>);
    if (bc) {
      result = (<span style={{margin: 0}}>{bc}</span>);
    }

    return result;
  }, [getInventory]);

  const getPatientDob = useCallback((row, prefix) => {
    let result = '';
    if (row) {
      const dob = row[prefix + '_person_DateOfBirth'] || '';
      if (dob) {
        result = dayjs(dob).format(DATE_FORMAT_KEYBOARD);
      }
    }
    return result;
  }, []);

  const getPatientName = useCallback((row, prefix) => {
    let result = '';
    if (row) {
      const fn = row[prefix + '_person_FirstName'] || '';
      const ln = row[prefix + '_person_LastName'] || '';
      result = (fn + ' ' + ln).trim();
    }
    return result;
  }, []);

  const getStockType = useCallback((type) => {
    let result = undefined;
    if (type === PRIVATE) {
      result = 'Private';
    } else if (type === VFC) {
      result = 'VFC';
    }
    return result;
  }, []);

  const getSliderLabelStyle = useCallback((placement) => {
    if (placement === 'start') {
      return displayUnpaid ? undefined : {fontWeight: "bold"};
    } else {
      return displayUnpaid ? {fontWeight: "bold"} : undefined;
    }
  }, [displayUnpaid]);

  const handleDeleteBorrow = useCallback((id, inventoryId) => {
    const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
    const endDate = dayjs().format(DATE_DB_FORMAT);

    // Delete the borrow & inventory records.
    // Delete is allowed for an admin and only if the payback has not been paid!
    if (id) {
      setOpenAlert(true);
      inventoryDelete({
        variables: {inventoryId: inventoryId},
        refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
      });

      borrowDelete({
        variables: {id: parseInt(id)},
        refetchQueries: getBorrowsRefetchQueries()
      });
    }
  }, [borrowDelete, inventoryDelete]);

  const handleDeletePayback = useCallback((borrowObj) => {
    const startDate = dayjs().subtract(INVENTORY_DATE_NUMBER, INVENTORY_DATE_UNIT).format(DATE_DB_FORMAT);
    const endDate = dayjs().format(DATE_DB_FORMAT);
    const inventoryId = borrowObj?.ptiInventoryId;
    const paybackId = borrowObj?.paybackId;

    // Delete the payback & inventory records.
    // Delete is allowed only for an admin
    if (paybackId) {
      inventoryDelete({
        variables: {inventoryId: inventoryId},
        refetchQueries: getInventoryListRefetchQueries(startDate, endDate)
      });

      paybackDelete({
        variables: {id: parseInt(paybackId)},
        refetchQueries: getPaybacksRefetchQueries(startDate, endDate, borrowObj.id)
      });

      const borrowItem = {
        id: borrowObj.id,
        loanId: borrowObj.id,
        paid: 'false',
        dateLastUpdated: endDate,
        lastUpdatedBy: userId
      };
      borrowUpdate({
        variables: borrowItem,
        refetchQueries: getBorrowsRefetchQueries()
      });

    }
  }, [borrowUpdate, inventoryDelete, paybackDelete, userId]);

  const handlePaidToggle = useCallback(() => {
    setDisplayUnpaid(value => !value);
  }, []);

  const borrowColumns = useMemo(() => {
    return [
      {
        id: 'borrow-data',
        Header: 'Borrow Data',
        columns: [
          {
            id: 'loanId',
            accessor: 'loanID',
            Header: "Id",
            Cell: ({row}) => (
              <Tooltip title="Edit Borrow">
                <Link sx={{cursor: 'pointer', py: 0, px: 1}} underline="always" variant="inherit" onClick={() => handleIdClick(row?.original.loanID)}>{row?.original.loanID}</Link>
              </Tooltip>)
          },
          {
            id: 'loanDate',
            Header: <TypographyFHG id={'borrows.loanDate.column'} />,
            accessor: 'loanDate',
            weighting: 1,
            width: 70,
            Cell: ({value}) => (
              <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
            )
          },
          {
            id: 'comboBrand',
            Header: <TypographyFHG id={'borrows.from.brand.column'} sx={{whiteSpace: 'nowrap'}} variant="body2" />,
            accessor: 'comboBrand',
          },
          {
            id: 'cvx',
            Header: <TypographyFHG id={'borrows.from.cvx.column'} />,
            accessor: 'lfiCvxCode',
          }
        ]
      },
      {
        id: 'borrow-from',
        Header: 'Borrow From',
        columns: [
          {
            id: 'lfi-type',
            Header: <TypographyFHG id={'borrows.from.type.column'} />,
            accessor: 'lfi',
            Cell: ({row}) => (getTypeChip(row, 'lfi'))
          },
        ]
      },
      {
        id: 'borrow-to',
        Header: 'Borrow To Inventory',
        columns: [
          {
            id: 'lti',
            Header: <TypographyFHG id={'borrows.to.barcode.column'} />,
            accessor: 'ltiClinicBarcode',
            Cell: ({row}) => (<Link sx={{cursor: 'pointer', py: 0, px: 1}} underline="always" variant="inherit" onClick={() => handleBarcodeClick(row?.original.ltiInventoryId)}>{row?.original.ltiClinicBarcode}</Link>)
          },
          {
            id: 'lti-type',
            Header: <TypographyFHG id={'borrows.to.type.column'} />,
            accessor: 'lti',
            Cell: ({row}) => (getTypeChip(row, 'lti'))
          },
        ]
      },
      {
        id: 'ltiPerson',
        accessor: 'loanID',
        Header: "Patient",
        Cell: ({row}) => (getPatientName(row?.original, 'lie'))
      },
      {
        id: 'notes',
        Header: <TypographyFHG id={'borrows.notes.column'} />,
        accessor: 'notes',
        align: 'center',
        Cell: ({value}) => (value ? <div style={{
          cursor: 'pointer',
          my: 0, mx: 'auto',
          pt: 0.35, px: 0, mb: 0,
          height: 30,
          width: 30
        }}><NotesIcon/></div> : null)
      },
      {
        id: 'b-delete-group',
        Header: '',
        columns: [
          {
            id: 'delete',
            accessor: 'loanID',
            Header: "Delete",
            disableGroupBy: true,
            weighting: 1,
            width: 70,
            Cell: ({row}) => (
              isAdmin && row?.original.id && !row.original.paybackId ?
                <ConfirmButton
                  id={row?.original.id}
                  buttonLabelKey=""
                  buttonTypographyProps={{variant: "body1"}}
                  sx={{cursor: 'pointer', py: 0, px: 1}}
                  onConfirm={() => handleDeleteBorrow(row?.original.id, row?.original.ltiInventoryId)}
                  values={{
                    type: `borrow record along with its generated inventory record ${row?.original.ltiClinicBarcode}`,
                    name: ''
                  }}
                  titleValues={{
                    type: 'borrow record',
                    name: ''
                  }}
                  size='large'
                  submitStyle={{
                    backgroundColor: lighten(ERROR_COLOR, 0.15),
                    '&:hover': {
                      backgroundColor: lighten(ERROR_COLOR, 0.2)
                    }
                  }}
                  startIcon={<DeleteIcon />}
                /> : null
            )
          },
        ]
      },
      {
        id: 'payback',
        Header: <TypographyFHG id={'borrows.paid.column'}/>,
        accessor: '',
        align: 'center',
        width: 70,
        Cell: ({row}) => (row.original.paid === 'unpayable' ?
          <div style={{borderRadius: 0, textAlign: 'center', width: '100%'}}>
            <Tooltip title="Unpayable">
              <UnpayableIcon color="error" />
            </Tooltip>
          </div>
          : !row.original.paybackId ?
          <div style={{borderRadius: 0, textAlign: 'center', width: '100%'}}>
            <Tooltip title="Add Payback">
              <IconButton aria-label="Add Payback" onClick={() => handleAddPayback(row.original)} style={{ display: 'block', margin: 'auto' }}>
                <PaybackIcon />
              </IconButton>
            </Tooltip>
          </div> : null)
      },
      {
        id: 'payback-from',
        Header: 'Payback From Inventory',
        columns: [
          {
            id: 'paybackDate',
            Header: <TypographyFHG id={'borrows.paybackDate.column'}/>,
            accessor: 'paybackDate',
            weighting: 1,
            width: 70,
            Cell: ({value}) => (
              <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
            )
          },
          {
            id: 'pfi-type',
            Header: <TypographyFHG id={'borrows.from.type.column'}/>,
            accessor: 'pfiType',
            Cell: ({row}) => (getTypeChip(row, 'pfi'))
          }
        ]
      },
      {
        id: 'payback-to',
        Header: 'Payback To Inventory',
        columns: [
          {
            id: 'ptiClinicBarcode',
            Header: <TypographyFHG id={'borrows.to.barcode.column'}/>,
            accessor: 'ptiClinicBarcode',
            Cell: ({row}) => (<Link sx={{cursor: 'pointer', py: 0, px: 1}} underline="always" variant="inherit" onClick={() => handleBarcodeClick(row?.original.ptiInventoryId)}>{getBarcode(row, 'pti')}</Link>)
          },
          {
            id: 'pti-type',
            Header: <TypographyFHG id={'borrows.to.type.column'}/>,
            accessor: 'ptiType',
            Cell: ({row}) => (getTypeChip(row, 'pti'))
          },
          {
            id: 'ptiPerson',
            accessor: 'loanID',
            Header: "Patient",
            Cell: ({row}) => (getPatientName(row?.original, 'pie'))
          },
         ]
      },
      {
        id: 'paybackNotes',
        Header: <TypographyFHG id={'borrows.notes.column'}/>,
        accessor: 'paybackNotes',
        align: 'center',
        Cell: ({value}) => (value ? <NotesIcon sx={{cursor: 'pointer', my: 0, ml: 1}} /> : null)
      },
      {
        id: 'p-delete-group',
        Header: '',
        columns: [
          {
            id: 'p-delete',
            accessor: 'loanID',
            Header: "Delete",
            disableGroupBy: true,
            weighting: 1,
            width: 70,
            Cell: ({row}) => (
              isAdmin  && row.original.paybackId ?
                <ConfirmButton
                  id={row?.original.paybackId}
                  buttonLabelKey=""
                  buttonTypographyProps={{variant:"body1"}}
                  sx={{cursor: 'pointer', py: 0, px: 1}}
                  onConfirm={() => handleDeletePayback(row?.original)}
                  values={{
                    type: `payback record along with its generated inventory record ${row?.original.ptiClinicBarcode}`,
                    name: ''
                  }}
                  titleValues={{
                    type: 'payback record',
                    name: ''
                  }}
                  size='large'
                  submitStyle={{
                    backgroundColor: lighten(ERROR_COLOR, 0.15),
                    '&:hover': {
                      backgroundColor: lighten(ERROR_COLOR, 0.2),
                    }
                  }}
                  startIcon={<DeleteIcon />}
                /> : null
            )
          },
        ]
      }
    ];
  }, [getBarcode, getPatientName, getTypeChip, handleAddPayback, handleBarcodeClick, handleDeleteBorrow, handleDeletePayback, handleIdClick, isAdmin]);

  const getFlyoutContent = useCallback(() => {
    let content;

    switch (drawerAction) {
      case 'edit':
        content = content = <BorrowEdit borrow={loan} onClose={handleDetailClose} />;
        break;
      case 'payback':
        content = content = <PaybackAdd borrow={loan} onClose={handleDetailClose} />;
        break;
      case 'patients':
        content = (<InventoryPatients id={selectedId} onClose={handleDetailClose} />);
        break;
      default:
        content = <BorrowAdd onClose={handleDetailClose} />;
    }
    return content;
  }, [drawerAction, handleDetailClose, loan, selectedId]);

  const downloadData = async () => {
    let headerTableData = [];
    let mainTableData = [];
    let footerTable1Data = [];

    headerTableData.push([{text: 'VACCINE BORROWING REPORT', style: 'tableDataBlack', colSpan: 13},
      {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}]);
    headerTableData.push([
      {text: 'BORROWED DOSE', style: 'subHeader', colSpan: 8},
      {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
      {text: 'REPAYMENT DOSE', style: 'subHeader', colSpan: 5},
      {text:''}, {text:''}, {text:''}, {text:''}
    ]);
    headerTableData.push([
      {text: 'VACCINE\nBORROWED', style: 'loanHeader'},
      {text: 'LOT # OF\nBORROWED\nDOSE', style: 'loanHeader'},
      {text: 'STOCK TYPE\nVFC, CHIP, 317, PRIVATE', style: 'loanHeader'},
      {text: 'DATE\nBORROWED', style: 'loanHeader'},
      {text: 'PATIENT IDENTIFIER: PT NAME, MEDICAL RECORD # OR KSWEBIZ# AND DOB', style: 'loanHeader', colSpan: 3},
      {text:''}, {text:''},
      {text: 'PATIENT\nELIGIBILITY', style: 'loanHeader'},
      {text: 'REASON\n(SEE TABLE)', style: 'loanHeader'},
      {text: 'LOT # OF\nRETURNED\nDOSES', style: 'loanHeader'},
      {text: 'DATE\nRETURNED', style: 'loanHeader'},
      {text: 'PATIENT IDENTIFIER\nAND DOB', style: 'loanHeader'},
      {text: 'PATIENT\nELIGIBILITY', style: 'loanHeader'},
    ]);

    if (borrowDataset && borrowDataset.length > 0) {
      let totalRecords = borrowDataset.length;

      for (var j = 0; j < totalRecords; j++) {
        // Don't print if there is no borrow patient (the dose has not been administered).
        const b_patient = getPatientName(borrowDataset[j], 'lie');
        const p_patient = getPatientName(borrowDataset[j], 'pie');
        if (b_patient){
          mainTableData.push([
            {text: borrowDataset[j].lfiMfgBrand, style: 'tableData1', rowSpan: 2},
            {text: borrowDataset[j].lfiLot, style: 'tableData1', rowSpan: 2},
            {text: getStockType(borrowDataset[j].lfiTypeId), style: 'tableDataCenter', rowSpan: 2},
            {text: getFormattedDate(borrowDataset[j].loanDate), style: 'tableDataCenter', rowSpan: 2},
            {text: getPatientName(borrowDataset[j], 'lie'), style: 'tableData1', colSpan: 3},
            {text:''}, {text:''},
            {text: getEligibility(borrowDataset[j].lie_financial_Class, !!b_patient), style: 'tableDataCenter', rowSpan: 2},
            {text: borrowDataset[j].reasonId, style: 'tableDataCenter', rowSpan: 2},
            {text: borrowDataset[j].pfiLot, style: 'tableData1', rowSpan: 2},
            {text: getFormattedDate(borrowDataset[j].paybackDate), style: 'tableDataCenter', rowSpan: 2},
            {text: getPatientName(borrowDataset[j], 'pie'), style: 'tableData1'},
            {text: getEligibility(borrowDataset[j].pie_financial_Class, !!p_patient), style: 'tableDataCenter', rowSpan: 2},
          ]);
          mainTableData.push([
            {text: '', style: 'tableData1'},
            {text: '', style: 'tableData1'},
            {text: '', style: 'tableData1'},
            {text: '', style: 'tableData1'},
            {text: getPatientDob(borrowDataset[j], 'lie'), style: 'tableData1', colSpan: 3},
            {text:''}, {text:''},
            {text: '', style: 'tableData1'},
            {text: '', style: 'tableData1'},
            {text: '', style: 'tableData1'},
            {text: '', style: 'tableData1'},
            {text: getPatientDob(borrowDataset[j], 'pie'), style: 'tableData1'},
            {text: '', style: 'tableData1'},
          ]);
        }
      }
    }

    if (borrowFromVfcReasons && borrowFromCommercialReasons) {
      let reasonCount = borrowFromVfcReasons?.length
      footerTable1Data.push([
        {text: 'Reason for Borrowing VFC Dose', style: 'tableHeader1', colSpan: 8},
        {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
        {text: 'Code', style: 'tableHeader1', colSpan: 2}, {text:''},
        {text: '', style: 'tableHeader1'},
        {text: 'Reason for Borrowing CHIP/Private Dose', style: 'tableHeader1', colSpan: 8},
        {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
        {text: 'Code', style: 'tableHeader1', colSpan: 2}, {text:''}
      ]);
      for (let i = 0; i < reasonCount; i++) {
        if (i < borrowFromVfcReasons?.length-1) {
          footerTable1Data.push([
            {text: borrowFromVfcReasons[i].reasonName, style: 'tableDataReason', colSpan: 8},
            {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
            {text: borrowFromVfcReasons[i].id, style: 'tableDataReasonCenter', colSpan: 2}, {text:''},
            {text: '', style: 'tableDataGray'},
            {text: borrowFromCommercialReasons[i].reasonName, style: 'tableDataReason', colSpan: 8},
            {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
            {text: borrowFromCommercialReasons[i].id, style: 'tableDataReasonCenter', colSpan: 2}, {text:''}
          ]);
        } else {
          footerTable1Data.push([
            {text: borrowFromVfcReasons[i].reasonName, style: 'tableDataReason', colSpan: 8},
            {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
            {text: borrowFromVfcReasons[i].id, style: 'tableDataReasonCenter', colSpan: 2}, {text:''},
            {text: '', style: 'tableDataGray'},
            {text: '', style: 'tableDataReason', colSpan: 8},
            {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
            {text: '', style: 'tableDataReason', colSpan: 2}, {text:''}
          ]);
        }
      }
    }

    footerTable1Data.push([
      {
        text: 'I hereby certify, subject to penalty under the False Claims Act (31 U.S.C 3730) and other applicable Federal and state law, that VFC vaccine dose borrowing and replacement reported on this form has been accurately reported and conducted in conformance with VFC provisions for such borrowing and further certifies that all VFC borrowed doses borrowed during the noted time period have been fully reported on this form.',
        style: 'footerData1', colSpan: 21,
        border: [false, false, false, false]
      },
      {text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},
      {text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''},{text:''}
    ]);
    footerTable1Data.push([
      {text: 'FACILITY NAME', style: 'footerData1', colSpan: 11},
      {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
      {text: 'PIN', style: 'footerData1', colSpan: 10},
      {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
    ]);
    footerTable1Data.push([
      {text: 'PRINTED NAME', style: 'footerData2a', colSpan: 8}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
      {text: 'SIGNATURE', style: 'footerData2a', colSpan: 9}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''}, {text:''},
      {text: 'DATE', style: 'footerData2b', colSpan: 4}, {text:''}, {text:''}, {text:''}
    ]);
    footerTable1Data.push([
      {text: 'Rev 2017-05-11', style: 'footerData3', colSpan: 21, border: [false, false, false, false]}
    ]);

    let docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [30, 97, 30, 250],
      header: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            {
              alignment: 'left',
              style: 'headerTable',
              width: '*',
              table: {
                 widths: [90, 52, 60, 56, 72, 6, 6, 48, 46, 50, 56, 76, 48],  // 684
                body: headerTableData
              },
            }
          ],
          margin: [30, 16, 30, 0],
        };
      },
      content: [
        {
          style: 'table',
          table: {
            borderCols: [0, 0, 0, 0 ,0 ,0, 0, 0 ,0 ,1, 0, 0, 0],
            margin: [30, 0],
            widths: [90, 52, 60, 56, 72, 6, 6, 48, 46, 50, 56, 76, 48],  // 684
            body: mainTableData
          }
        }
      ],
      footer: function(currentPage, pageCount, pageSize) {
        return {
          columns: [
            {
              alignment: 'left',
              style: 'footerTable1',
              width: '*',
              table: {
                margin: [30, 0],
                widths: [40, 40, 30, 30, 30, 30, 30, 30, 20, 12, 8, 40, 40, 30, 30, 30, 30, 30, 30, 20, 12],
                body: footerTable1Data
              },
            }
          ],
          margin: [30, 10]
        }
      },
      styles: {
        headerTable: {
          alignment: 'center',
          color: 'white',
          fillColor: 'black',
          margin: [0, 4, 0, 0],
        },
        subHeader: {
          bold: true,
          fontSize: 10,
          color: 'black',
          fillColor: 'white',
          alignment: 'center',
          margin: 0.25,
        },
        loanHeader: {
          bold: true,
          fontSize: 8.5,
          color: 'black',
          fillColor: 'white',
          alignment: 'left',
          margin: [0, 2, 0, 0]
        },
        tableHeader1: {
          bold: true,
          fillColor: '#dedede',
          fontSize: 9,
          color: 'black',
          alignment: 'center',
          margin: [0, 0, 0, 0],
          padding: 4
        },
        table: {
          alignment: 'left',
          margin: 0
        },
        reasonTable: {
          alignment: 'left',
          fontSize: 8,
          margin: 0,
        },
        tableDataCenter: {
          alignment: 'center',
          fontSize: 9,
          margin: [0, 2]
        },
        tableData1: {
          fontSize: 9,
          margin: [0, 2]
        },
        tableDataReason: {
          fontSize: 7.5,
          margin: [0, 2, 0, 0]
        },
        tableDataReasonCenter: {
          alignment: 'center',
          fontSize: 8,
          margin: [0, 2, 0, 0]
        },
        tableDataBar: {
          fontSize: 9,
          margin: [1, 2, 0, 0]
        },
        tableDataGray: {
          fillColor: '#dedede',
          fontSize: 9,
          margin: [0, 2, 0, 0]
        },
        tableDataBlack: {
          alignment: 'center',
          color: 'white',
          fillColor: 'black',
          fontSize: 12,
          height: 10,
          margin: [0, 2, 0, 0]
        },
        footerTable1: {
          alignment: 'left',
          fontSize: 8,
          margin: [0, -4, 10, 10],
        },
        footerData1: {
          alignment: 'left',
          fontSize: 8,
          margin: [0, 0, 0, 4],
          padding: 2
        },
        footerData2a: {
          fontSize: 8,
          margin: [0, -1, 0, 4],
          padding: 2
        },
        footerData2b: {
          fontSize: 8,
          margin: [0, -1, 0, 4],
          padding: 2
        },
        footerData3: {
          alignment: 'right',
          fontSize: 8,
          margin: [0, 4, 0, 0],
        }
      },
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).download('VaccineBorrowingReport.pdf');
  }

  return (
    <>
      <Loading isLoading={loading}/>
      <Grid container direction={'column'}>
        <Grid container direction="row">
          <TypographyFHG id={'borrows.title'} color="textSecondary" sx={{mt: 0, mr: 0, mb: -0.5, ml: 2}} variant="h5" />
        </Grid>
        {getAlert()}
        <Grid sx={{minHeight: 0, overflowY: 'auto', mb: 1}}>
          <TableFHG name="borrows" columns={borrowColumns} data={borrowDataset}
                    allowSearch emptyTableMessageKey={'borrows.noRecord.message'} stickyHeader>
            <Grid container direction="row" justifyContent="flex-start">
              <Grid container direction="row" sx={{mt: 1.5, width: '240px'}}>
                <ButtonFHG id="borrow.add.button" labelKey="borrow.add.button" startIcon={(<AddIcon />)}
                           sx={{mr: 1, fontSize: '0.875rem', height: '2.5rem', '&:hover': {color: PRIMARY_DARK_COLOR}}}
                           onClick={handleAddBorrow} color="primary" size="small" variant="outlined" />
                <ButtonFHG color="primary" variant="outlined"
                           sx={{m: 0, fontSize: '0.875rem', height: '2.5rem',
                             '&:hover': {
                               color: PRIMARY_DARK_COLOR,
                             }}}
                           disabled={(borrowDataset && borrowDataset?.length === 0)}
                           labelKey="borrow.report.button" startIcon={(<DownloadIcon />)}
                           onClick={downloadData} size="small" />
              </Grid>
              <Grid container direction="row" sx={{mt: 1.5, ml: 1, pt: 0.75, width: '320px'}}>
                <Grid sx={getSliderLabelStyle('start')}>{formatMessage(intl, 'slider.all', 'Display All', null)}</Grid>
                <Switch color="primary" checked={displayUnpaid} onChange={handlePaidToggle} sx={{my: -1, mx: 0, width: '62px'}} />
                <Grid sx={getSliderLabelStyle('end')}>{formatMessage(intl, 'borrows.slider.unpaid', 'Display Unpaid Only', null)}</Grid>
              </Grid>
              <Divider color={theme.palette.divider} flexItem orientation='vertical' />
              <Grid sx={{pt: 0}}>
                <TypographyFHG id="borrow.add.message" color="textSecondary" sx={{fontSize: "0.825rem", fontWeight: "bold", my: 1, mr: 1, ml: 2}} />
                <TypographyFHG id="borrow.add.message2" color="textSecondary" sx={{fontSize: "0.825rem", fontWeight: "bold", my: 1, mr: 1, ml: 2, textWrap: "wrap"}} />
              </Grid>
            </Grid>
          </TableFHG>
        </Grid>
        <EditDrawer
          ModalProps={{BackdropProps: {style: {height: '100%'}}}}
          open={openDetailDrawer}
          onClose={handleDetailClose}
        >
          <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
            {getFlyoutContent()}
          </Grid>
        </EditDrawer>
      </Grid>
    </>
  );
}
