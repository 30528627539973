import React, {useCallback, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import * as dayjs from 'dayjs';
import {Grid} from '@mui/material';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import orderBy from 'lodash/orderBy';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {CVX_STATUS_QUERY, CVX_CREATE, getCvxRefetchQueries} from '../../data/QueriesGL';
import {DATE_TIME_FORMAT, EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from '../../Constants';

export default function CvxAdd(props) {
  const date = dayjs().format(DATE_TIME_FORMAT);
  const [{userId}] = useRecoilState(userStatus);
  const variables = {};
  const [inputValue, setInputValue] = useState('')
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [cvxStatusData] = useQueryFHG(CVX_STATUS_QUERY, variables, 'cvxStatus.type');
  const [cvxCreate] = useMutationFHG(CVX_CREATE);
  const editItem = useMemo(() => ({
    id: 0,
    cvxId: 0,
    shortDescription: '',
    description: '',
    notes: '',
    vaccineStatus: 2,  // Active
    dateCvxLastUpdated: date,
    dateEntered: date,
    dateLastUpdated: date,
    enteredBy: userId
  }), [date, userId]);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData(editItem, ['vaccineStatus']);
  const statuses = useMemo(() => orderBy(cvxStatusData?.cvxStatus, row => row?.title?.toLowerCase()), [cvxStatusData]);

  useMemo(() => {
    if (statuses) {
      setDefaultValues(assign({}, editItem));
    }
  }, [editItem, statuses, setDefaultValues]);

  const getCvxStatus = useCallback(() => {
    let result = null;
    if (statuses?.length > 0) {
      if (editValues?.vaccineStatus) {
        result = find(statuses, status => status.id === editValues.vaccineStatus);
      } else {
        result = find(statuses, status => status.id === defaultValues?.vaccineStatus);
      }
    }
    return result;
  }, [defaultValues, editValues, statuses]);

  const isCvxCodeInUse = useCallback((code) => {
    let result = false;
    if (props.cvxs?.length > 0) {
      result = find(props.cvxs, c => c.cvxCode === code);
    }
    return result;
  }, [props]);

  const handleClose = useCallback(() => {
    resetValues();
    setInputValue('');
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        let currentItem = {
          ...defaultValues,
          ...editValues
        };

        if (isCvxCodeInUse(currentItem.cvxCode)) {
          setMessageKey('cvx.inuse.message');
          setOpenAlert(true);
          return;
        }

        setIsSaving(true);

        await cvxCreate ({
          variables: currentItem,
          refetchQueries: getCvxRefetchQueries()
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [cvxCreate, defaultValues, editValues, handleClose, isChanged, isCvxCodeInUse, setIsChanged]);

  const handleCvxStatusChange = (event, value) => {
    setEditValues(editValues => ({...editValues, vaccineStatus: value?.id}));
    setIsChanged(true);
  }

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'cvx.add.title'} color={'textSecondary'} />
        </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 2, pl: 3}}>
          <Grid sx={{minHeight: 0, overflowY: 'auto'}}>
            {getAlert()}
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="cvxCode"
                name="cvxCode"
                labelKey="cvx.cvxCode"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={editValues.cvxCode ? editValues.cvxCode : defaultValues.cvxCode}
                required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="shortDescription"
                name="shortDescription"
                labelKey="cvx.shortDescription"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={editValues.shortDescription ? editValues.shortDescription : defaultValues.shortDescription}
                required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="description"
                name="description"
                labelKey="cvx.full.name"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={editValues.description ? editValues.description : defaultValues.description}
                multiline
                rows="3"
              />
            </Grid>
            <Grid sx={{my: 1, width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <Autocomplete
                key="cvxStatus"
                fullWidth={false}
                getOptionLabel={(option) => option?.title}
                options={statuses}
                onChange={handleCvxStatusChange}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue)
                }}
                isOptionEqualToValue={(option, value) => option?.title === value?.title}
                inputValue={inputValue || ''}
                value={getCvxStatus()}
                renderInput={(params) => (
                  <TextField{...params} label='Status' placeholder='Select status' size="small" variant="outlined" />
                )}
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="notes"
                name="notes"
                labelKey="cvx.notes"
                inputProps={{style: {fontSize: '0.875rem'}}}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={editValues.notes ? editValues.notes : defaultValues.notes}
                multiline
                rows="4"
                sx={{fontSize: '.875rem', mt: 1}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{
          borderTopColor: 'lightgray',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          bottom: 0,
          height: '60px',
          pl: 3,
          width: '100%'
        }}>
          <Grid container direction="row" sx={{mt: 0.5}}>
            <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                            type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                            sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
            />
              <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                         disabled={isSaving}
                         onClick={handleClose}
                         sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}}
              />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

CvxAdd.propTypes = {
  cvxs: PropTypes.array,
  onClose: PropTypes.func
}
