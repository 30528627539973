import React from 'react';
import AdministerVax from '../../components/vaccine/AdministerVax';
import Borrows from '../../components/borrow/Borrows';
import CaptureImmunizations from '../../components/vaccine/CaptureImmunizations';
import Dashboard from '../../components/dashboard/Dashboard';
import DispensedReport from '../../components/report/DispensedReport';
import EnrollmentReport from '../../components/report/EnrollmentReport';
import HistoricalReport from '../../components/report/HistoricalReport';
import Inventory from '../../components/inventory/Inventory';
import InventoryActionReport from '../../components/report/InventoryActionReport';
import InventoryReportExpanded from '../../components/report/InventoryReportExpanded';
import Logout from './Logout';
import NegativeInventoryReport from '../../components/report/NegativeInventoryReport';
import PatientRecords from '../../components/patientRecords/PatientRecords';
import PrintBarcodes from '../../components/barcodes/PrintBarcodes';
import ReportsLanding from '../../components/report/ReportsLanding';
import {Routes, Route, Link} from 'react-router-dom';
import SendToStateResults from '../../components/report/SendToStateResults';
import StateStatusReport from '../../components/report/StateStatusReport';
import TransferredReport from '../../components/report/TransferredReport';
import Transfers from '../../components/transfers/Transfers';
import Users from '../../components/users/Users';
import {
  DEFAULT_PATH, LOGOUT_PATH,
  USER_ADMINISTER_A_VAX_PATH,
  USER_DASHBOARD_PATH,
  USER_CAPTURE_IMMUNIZE_PATH,
  USER_INVENTORY_PATH,
  USER_PRINT_BARCODE_PATH,
  USER_BORROWS_PATH,
  USER_TRANSFERS_PATH,
  USER_USERS_PATH,
  USER_TRANSFER_REPORT_PATH,
  USER_PATRECORDS_PATH,
  USER_REPORTS_PATH,
  USER_EXPANDED_INVENTORY_REPORT_PATH,
  USER_DISPENSED_REPORT_PATH,
  USER_NEGATIVE_INV_REPORT_PATH,
  USER_ACTION_REPORT_PATH,
  USER_ENROLLMENT_REPORT_PATH,
  USER_STATUS_REPORT_PATH,
  USER_SEND_TO_STATE_PATH,
  USER_SEARCH_REPORT_PATH
} from '../../Constants';

/**
 * Main component accessible if the user has been authenticated as a user.
 *
 * Reviewed:
 */

export default function UserMain() {
  function DashboardLink() {
    return <Link to={USER_DASHBOARD_PATH}>Dashboard</Link>
  }

  function LogoutLink() {
    return <Logout />
  }

  return (
    <Routes>
      <Route exact path={USER_DASHBOARD_PATH} element={<Dashboard />} />>
      <Route exact path={USER_ADMINISTER_A_VAX_PATH} element={<AdministerVax />} />
      <Route exact path={USER_BORROWS_PATH} element={<Borrows />} />
      <Route exact path={USER_CAPTURE_IMMUNIZE_PATH} element={<CaptureImmunizations />} />
      <Route exact path={USER_PATRECORDS_PATH} element={<PatientRecords/>} />
      <Route exact path={USER_INVENTORY_PATH} element={<Inventory />} />
      <Route exact path={USER_TRANSFERS_PATH} element={<Transfers />} />
      <Route exact path={USER_PRINT_BARCODE_PATH} element={<PrintBarcodes />} />
      <Route exact path={USER_USERS_PATH} element={<Users />} />
      <Route exact path={USER_REPORTS_PATH} element={<ReportsLanding />} />
      <Route exact path={USER_EXPANDED_INVENTORY_REPORT_PATH} element={<InventoryReportExpanded title={'Inventory By Date'}/>} />
      <Route exact path={USER_TRANSFER_REPORT_PATH} element={<TransferredReport />} />
      <Route exact path={USER_ACTION_REPORT_PATH} element={<InventoryActionReport />} />
      <Route exact path={USER_ENROLLMENT_REPORT_PATH} element={<EnrollmentReport />} />
      <Route exact path={USER_STATUS_REPORT_PATH} element={<SendToStateResults />} />
      <Route exact path={USER_SEND_TO_STATE_PATH} element={<StateStatusReport />} />
      <Route exact path={USER_NEGATIVE_INV_REPORT_PATH} element={<NegativeInventoryReport />} />
      <Route exact path={USER_DISPENSED_REPORT_PATH} element={<DispensedReport />} />
      <Route exact path={USER_SEARCH_REPORT_PATH} element={<HistoricalReport />} />
      <Route exact path={DEFAULT_PATH} element={<DashboardLink />} />
      <Route exact path={LOGOUT_PATH} element={<LogoutLink />} />
    </Routes>
  );
}
