import React, {useCallback, useEffect, useMemo, useState} from 'react';
import assign from 'lodash/assign';
import Box from '@mui/material/Box';
import {cacheUpdate} from '../../fhg/utils/DataUtil';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip, MenuItem, Select} from '@mui/material';
import * as dayjs from 'dayjs';
import {getChipBgColor, hexToRgb, toNumber} from '../../fhg/utils/Utils';
import {Grid} from '@mui/material';
import Form from '../../fhg/components/edit/Form';
import KeyboardDatePickerFHG from '../../fhg/components/KeyboardDatePickerFHG';
import ProgressButton from '../../fhg/components/ProgressButton';
import PropTypes from 'prop-types';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useEditData from '../../fhg/components/edit/useEditData';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import {
  ADJUSTED_INVENTORY_UPDATE,
  getWastedReportCacheQueries,
  getAdjustmentRefetchQueries,
  INVENTORY_TYPES_QUERY
} from '../../data/QueriesGL';
import {
  ADJUST_REASON_1, ADJUST_REASON_2, ADJUST_REASON_3, ADJUST_REASON_4,
  ADJUST_REASON_5, ADJUST_REASON_6, ADJUST_REASON_7, ADJUST_REASON_8,
  DATE_DB_FORMAT,
  DATE_FORMAT_KEYBOARD, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR
} from '../../Constants';

export default function AdjustedEdit(props) {
  const [{userId}] = useRecoilState(userStatus);
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [adjustedInventoryUpdate] = useMutationFHG(ADJUSTED_INVENTORY_UPDATE);
  const adjust = useMemo(() => props.adjustRow || {}, [props]);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      setEditValues,
      resetValues
    }
  ] = useEditData({}, []);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, undefined, 'inventoryType.type');
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);

  const getColor = useCallback((typeId) => {
    let result = 'FFFFFF';
    if (inventoryTypes && inventoryTypes.length > 0) {
      let type = inventoryTypes.find(i => i.id === typeId);
      if (type) {
        result = type.colorCode;
      }
    }
    return result;
  }, [inventoryTypes]);

  useEffect(() => {
    if (adjust) {
      setDefaultValues(assign({}, adjust));
    }
  }, [adjust, setDefaultValues]);

  const getBrand = useCallback(() => {
    return adjust && adjust.mfgBrand ? adjust.mfgBrand : '';
  }, [adjust]);

  const getInventoryType = useCallback(() => {
    let result = '';
    if (adjust) {
      let color = getColor(adjust.typeId);
      return (<Chip size='small' label={adjust.typeName}
                    style={{margin: 'auto', backgroundColor: `${getChipBgColor(color)}`, color: `${hexToRgb(color)}`}} />);
    }
    return result;
  }, [adjust, getColor]);

  const getLot = useCallback(() => {
    return adjust && adjust.lot ? adjust.lot : '';
  }, [adjust]);

  const getExpDate = useCallback(() => {
    return adjust && adjust.expDate ? dayjs(adjust.expDate).format(DATE_FORMAT_KEYBOARD) : '';
  }, [adjust]);

  const getDoses = useCallback(() => {
    return adjust && adjust.doses ? adjust.doses : '';
  }, [adjust]);

  const getAdjustType = useCallback(() => {
    return adjust && adjust.adjustType ? adjust.adjustType : '';
  }, [adjust]);

  const getAdjustReason = useCallback(() => {
    return adjust && adjust.reason ? adjust.reason : '';
  }, [adjust]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);

        let currentItem = {
          ...defaultValues,
          ...editValues,
          lastUpdatedBy: userId,
          dateLastUpdated: dayjs().format(DATE_DB_FORMAT),
          '__typename': 'AdjustedInventory'
        };

        if (!currentItem.adjustDate) {
          setMessageKey('wasted.adjustment.required.date.message');
          setOpenAlert(true);
          return;
        }

        let adjustItem = {
          adjustId: currentItem.id,
          adjustedInventoryId: currentItem.inventoryId,
          adjustDate: currentItem.adjustDate.format(DATE_DB_FORMAT),
          reason: currentItem.reason,
          notes: currentItem.notes,
          lastUpdatedBy: currentItem.lastUpdatedBy,
          dateLastUpdated: currentItem.dateLastUpdated
        };
        let type = props.typeId === '0' ? null : toNumber(props.typeId);

        await adjustedInventoryUpdate ({
          variables: adjustItem,
          optimisticResponse: {
            __typename: 'Mutation',
            adjustID: adjustItem.adjustId,
            adjustedInventory: adjustItem
          },
          update: cacheUpdate(getWastedReportCacheQueries(props.dateAdjustedStart, props.dateAdjustedEnd, type), adjustItem.adjustId, 'adjustedInventory'),
          refetchQueries: getAdjustmentRefetchQueries(props.dateAdjustedStart, props.dateAdjustedEnd, type)
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [adjustedInventoryUpdate, defaultValues, editValues, props, handleClose, isChanged, setIsChanged, userId]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const handleAdjustReasonChange = (e) => {
    setEditValues(editValues => ({...editValues, reason: e.target.value}));
    setIsChanged(true);
  }

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  const handleDateChange = (date) => {
    setEditValues(prevState => ({...prevState, adjustDate: date}));
  };

  if (props.adjustRow) {
  return (
    <Form onSubmit={handleSubmit} sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: `calc(100% - 17px)`}}>
      <Grid sx={{height: '52px', mt: 3, ml: 2, top: 0}}>
        <TypographyFHG variant="h5" id="wasted.adjustment.title" color="textSecondary" gutterBottom />
      </Grid>
      {getAlert()}
      <Grid sx={{display: 'flex', flexDirection: 'column', minHeight: 0, mx: '16px', paddingBottom: '8px', width: `calc(100% - 40px)`}}>
        <Grid sx={{overflowY: 'auto', mb: 5}}>
        <Grid container direction="column" sx={{mt: 2, mx: 0, mb: 1}}>
          <KeyboardDatePickerFHG
            key={'adjustDate1'}
            name={'adjustDate'}
            labelKey={'wasted.adjustment.date.label'}
            onChange={handleDateChange}
            value={editValues.adjustDate || dayjs(defaultValues.adjustDate)}
          />
        </Grid>
        <Grid container direction="column" sx={{mt: 1, mb: 2}}>
          <TypographyFHG id="inventory.adjustment.reason.label" color="primary" variant="subtitle2"
                         sx={{mt: 0.5, mr: 0, mb: 0, ml: 1}} />
          <Select sx={{pl: 1}} onChange={handleAdjustReasonChange} value={editValues.reason ? editValues.reason : getAdjustReason()} required variant="outlined">
            <MenuItem  value=""><TypographyFHG id="inventory.select.option" color="textPrimary" variant="body1" /></MenuItem >
            <MenuItem value={ADJUST_REASON_1}><TypographyFHG id="inventory.adjustment.reason.option1" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_2}><TypographyFHG id="inventory.adjustment.reason.option2" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_3}><TypographyFHG id="inventory.adjustment.reason.option3" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_4}><TypographyFHG id="inventory.adjustment.reason.option4" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_5}><TypographyFHG id="inventory.adjustment.reason.option5" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_6}><TypographyFHG id="inventory.adjustment.reason.option6" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_7}><TypographyFHG id="inventory.adjustment.reason.option7" color="textPrimary" variant="body1" /></MenuItem>
            <MenuItem value={ADJUST_REASON_8}><TypographyFHG id="inventory.adjustment.reason.option8" color="textPrimary" variant="body1" /></MenuItem>
          </Select>
        </Grid>
        <TextFieldCustom
          key={'notes' + defaultValues.id}
          name={'notes'}
          InputLabelProps={{ shrink: true }}
          labelTemplate={'inventory.adjustment.{name}.label'}
          onChange={handleChange}
          value={editValues?.notes || defaultValues?.notes}
          multiline
          rows="2"
        />
        <Grid container direction={'column'} sx={{borderColor: 'lightgray', backgroundColor: 'white', alignItems: 'flex-start', mt: 1, p: 1, width: EDIT_DRAWER_WIDTH}}>
          <Grid item sx={{my: 0, mx: 2, textAlign: 'left'}}>
            <TypographyFHG variant={'body1'} id={'wasted.adjustment.mfgBrand.label'} color={'textSecondary'}>
              <TypographyFHG sx={{my: 0.75, mx: 2}} color="textPrimary" variant="body1">{getBrand()}</TypographyFHG>
            </TypographyFHG>
          </Grid>
          <Grid item sx={{my: 0, mx: 2, textAlign: 'left'}}>
            <TypographyFHG variant={'body1'} id={'wasted.adjustment.type.label'} color={'textSecondary'}>
              <TypographyFHG sx={{my: 0.75, mx: 2}} color="textPrimary" variant="body1">{getInventoryType()}</TypographyFHG>
            </TypographyFHG>
          </Grid>
          <Grid item sx={{my: 0, mx: 2, textAlign: 'left'}}>
            <TypographyFHG variant={'body1'} id={'wasted.adjustment.lot.label'} color={'textSecondary'}>
              <TypographyFHG sx={{my: 0.75, mx: 2}} color="textPrimary" variant="body1">
                {getLot()}
              </TypographyFHG>
            </TypographyFHG>
          </Grid>
          <Grid item sx={{my: 0, mx: 2, textAlign: 'left'}}>
            <TypographyFHG variant={'body1'} id={'wasted.adjustment.expDate.label'} color={'textSecondary'}>
              <TypographyFHG sx={{my: 0.75, mx: 2}} color="textPrimary" variant="body1">
                {getExpDate()}
              </TypographyFHG>
            </TypographyFHG>
          </Grid>
          <Grid item sx={{my: 0, mx: 2, textAlign: 'left'}}>
            <TypographyFHG variant={'body1'} id={'wasted.adjustment.doses.label'} color={'textSecondary'}>
              <TypographyFHG sx={{my: 0.75, mx: 2}} color="textPrimary" variant="body1">
                {getDoses()}
              </TypographyFHG>
            </TypographyFHG>
          </Grid>
          <Grid item sx={{my: 0, mx: 2, textAlign: 'left'}}>
            <TypographyFHG variant={'body1'} id={'wasted.adjustment.adjustType.label'} color={'textSecondary'}>
              <TypographyFHG sx={{my: 0.75, mx: 2}} color="textPrimary" variant="body1">
                {getAdjustType()}
              </TypographyFHG>
            </TypographyFHG>
          </Grid>
        </Grid>
      </Grid>
     </Grid>
     <Box sx={{
      borderTopColor: 'lightgray',
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      bottom: 0,
      height: '60px',
       pl: 3,
       width: '100%'
    }}>
       <Grid container direction="row" sx={{mt: 0.5}}>
         <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                        sx={{
                          '&:hover': {
                            color: PRIMARY_DARK_COLOR,
                          }}}
                        type={'submit'} size='small' labelKey='save.label' disabled={isSaving}/>
         <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                   sx={{
                     '&:hover': {
                       color: PRIMARY_DARK_COLOR,
                     }}}
                   disabled={isSaving} onClick={() => handleClose()}/>
      </Grid>
    </Box>
  </Form>
  );
  } else {
    return null;
  }
}

AdjustedEdit.propTypes = {
  adjustRow: PropTypes.any,
  onClose: PropTypes.func,
  dateAdjustedStart: PropTypes.string,
  dateAdjustedEnd: PropTypes.string,
  typeId: PropTypes.string
}
