import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import * as dayjs from 'dayjs';
import {Grid} from '@mui/material';
import find from 'lodash/find';
import Form from '../../fhg/components/edit/Form';
import ProgressButton from '../../fhg/components/ProgressButton';
import TextFieldCustom from '../TextFieldCustom';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import {useRecoilState} from 'recoil';
import {userStatus} from '../../fhg/hooks/auth/useAuth';
import useEditData from '../../fhg/components/edit/useEditData';
import {
  CLINIC_CREATE,
  getClinicRefetchQueries,
} from '../../data/QueriesGL';
import {DATE_TIME_FORMAT, EDIT_DRAWER_ITEM_WIDTH, EDIT_DRAWER_WIDTH, PRIMARY_DARK_COLOR} from '../../Constants';

export default function ClinicAdd(props) {
  const date = dayjs().format(DATE_TIME_FORMAT);
  const [{userId}] = useRecoilState(userStatus);
  const [isSaving, setIsSaving] = useState(false);
  const [messageKey, setMessageKey] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [clinicCreate] = useMutationFHG(CLINIC_CREATE);
  const editItem = useMemo(() => ({
    id: 0,
    name: '',
    phone: '',
    dateEntered: date,
    dateLastUpdated: date,
    enteredBy: userId
  }), [date, userId]);
  const [
    editValues, handleChange, {
      isChanged = false,
      setIsChanged,
      defaultValues,
      resetValues
    }
  ] = useEditData(editItem, []);

  const isClinicNameInUse = useCallback((name) => {
    let result = false;
    if (props.clinics?.length > 0) {
      result = find(props.clinics, c => c.name === name);
    }
    return result;
  }, [props]);

  const handleClose = useCallback(() => {
    resetValues();
    if (props.onClose) {
      props.onClose();
    }
  }, [props, resetValues]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        let currentItem = {
          ...defaultValues,
          ...editValues,
          clinicID: 0
        };

        if (isClinicNameInUse(currentItem.name)) {
          setMessageKey('clinic.inuse.message');
          setOpenAlert(true);
          return;
        }

        setIsSaving(true);

        await clinicCreate ({
          variables: currentItem,
          refetchQueries: getClinicRefetchQueries()
        });

        setIsChanged(false);
        handleClose();
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [clinicCreate, defaultValues, editValues, handleClose, isChanged, isClinicNameInUse, setIsChanged]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG id={messageKey} /></Alert>;
    }
    return result;
  }, [messageKey, openAlert, handleAlertClose]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid sx={{display: 'flex', flexDirection: 'column', height: '100vh', width: `${EDIT_DRAWER_WIDTH}px`}}>
        <Grid sx={{height: '64px', pt: 3, pl: 2, top: 0, position: 'sticky'}}>
          <TypographyFHG variant={'h5'} id={'clinic.add.title'} color={'textSecondary'} />
        </Grid>
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 0, mt: 1}}>
          <Grid sx={{overflowY: 'auto', ml: 3}}>
            {getAlert()}
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="name"
                name="name"
                autoFocus
                labelKey="clinic.name"
                inputProps={{fontSize: '0.875rem'}}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={editValues.name ? editValues.name : defaultValues.name}
                required
              />
            </Grid>
            <Grid sx={{width: `${EDIT_DRAWER_ITEM_WIDTH}px`}}>
              <TextFieldCustom
                key="phone"
                name="phone"
                inputProps={{fontSize: '0.875rem'}}
                InputLabelProps={{ shrink: true }}
                labelKey="clinic.phone"
                onChange={handleChange}
                value={editValues.phone ? editValues.phone : defaultValues.phone}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      <Grid sx={{
        borderTopColor: 'lightgray',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        bottom: 0,
        height: '60px',
        pl: 3,
        width: '100%'
      }}>
        <Grid container direction="row" sx={{mt: 0.5}}>
          <ProgressButton isProgress={isSaving} variant='outlined' color='primary'
                          type={'submit'} size='small' labelKey='save.label' disabled={isSaving}
                          sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
          <ButtonFHG variant='outlined' size={'small'} labelKey={'cancel.button'}
                     disabled={isSaving} onClick={handleClose}
                     sx={{mt: 1, mr: 1, '&:hover': {color: PRIMARY_DARK_COLOR}}} />
        </Grid>
      </Grid>
      </Grid>
    </Form>
  );
}

ClinicAdd.propTypes = {
  clinics: PropTypes.array,
  onClose: PropTypes.func
}
