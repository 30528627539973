import React from 'react';
import AdministerVax from '../../components/vaccine/AdministerVax';
import Borrows from '../../components/borrow/Borrows';
import CaptureImmunizations from '../../components/vaccine/CaptureImmunizations';
import Clinics from '../../components/clinics/Clinics';
import Cvx from '../../components/cvx/Cvx';
import Dashboard from '../../components/dashboard/Dashboard';
import DispensedReport from '../../components/report/DispensedReport';
import EnrollmentReport from '../../components/report/EnrollmentReport';
import HistoricalReport from '../../components/report/HistoricalReport';
import Inventory from '../../components/inventory/Inventory';
import InventoryActionReport from '../../components/report/InventoryActionReport';
import InventoryReportExpanded from '../../components/report/InventoryReportExpanded';
import InventoryTypes from '../../components/inventory/InventoryTypes';
import Logout from '../client/Logout';
import Manufacturers from '../../components/manufacturers/Manufacturers';
import MfgVaccines from '../../components/vaccine/MfgVaccines';
import NegativeInventoryReport from '../../components/report/NegativeInventoryReport';
import PatientRecords from '../../components/patientRecords/PatientRecords';
import PrintBarcodes from '../../components/barcodes/PrintBarcodes';
import ReportsLanding from '../../components/report/ReportsLanding';
import {Routes, Route} from 'react-router-dom';
import SendToStateResults from '../../components/report/SendToStateResults';
import StateStatusReport from '../../components/report/StateStatusReport';
import TransferredReport from '../../components/report/TransferredReport';
import Transfers from '../../components/transfers/Transfers';
import Users from '../../components/users/Users';
import {
  ADMIN_ADMINISTER_A_VAX_PATH,
  ADMIN_CLINICS_PATH,
  ADMIN_CVX_PATH,
  ADMIN_CAPTURE_IMMUNIZE_PATH,
  ADMIN_MANUFACTURERS_PATH,
  ADMIN_MFGVACCINES_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_INVENTORY_PATH,
  ADMIN_INVENTORYTYPES_PATH,
  ADMIN_PRINT_BARCODE_PATH,
  ADMIN_BORROWS_PATH,
  ADMIN_TRANSFERS_PATH,
  ADMIN_REPORTS_PATH,
  ADMIN_USERS_PATH,
  DISPENSED_REPORT_PATH,
  TRANSFER_REPORT_PATH,
  ENROLLMENT_REPORT_PATH,
  NEGATIVE_INV_REPORT_PATH,
  STATUS_REPORT_PATH,
  ADMIN_PATRECORDS_PATH,
  SEND_TO_STATE_PATH,
  ACTION_REPORT_PATH,
  EXPANDED_INVENTORY_REPORT_PATH,
  SEARCH_REPORT_PATH, DEFAULT_PATH, LOGOUT_PATH
} from '../../Constants';

/**
 * Main component accessible only if the user has been authenticated as an admin. Contains the routing for the admin
 * paths.
 *
 * Reviewed:
 */
export default function AdminMain() {
  function LogoutLink() {
    return <Logout />
  }
  return (
    <Routes>
      <Route exact path={ADMIN_ADMINISTER_A_VAX_PATH} element={<AdministerVax/>} />
      <Route exact path={ADMIN_CLINICS_PATH} element={<Clinics />} />
      <Route exact path={ADMIN_CVX_PATH} element={<Cvx />} />
      <Route exact path={ADMIN_DASHBOARD_PATH} element={<Dashboard />} />
      <Route exact path={ADMIN_CAPTURE_IMMUNIZE_PATH} element={<CaptureImmunizations />} />
      <Route exact path={ADMIN_PATRECORDS_PATH} element={<PatientRecords />} />
      <Route exact path={ADMIN_INVENTORY_PATH} element={<Inventory />} />
      <Route exact path={ADMIN_INVENTORYTYPES_PATH} element={<InventoryTypes />} />
      <Route exact path={ADMIN_MANUFACTURERS_PATH} element={<Manufacturers />} />
      <Route exact path={ADMIN_MFGVACCINES_PATH} element={<MfgVaccines />} />
      <Route exact path={ADMIN_BORROWS_PATH} element={<Borrows />} />
      <Route exact path={ADMIN_TRANSFERS_PATH} element={<Transfers />} />
      <Route exact path={ADMIN_PRINT_BARCODE_PATH} element={<PrintBarcodes />} />
      <Route exact path={ADMIN_USERS_PATH} element={<Users />} />
      <Route exact path={ADMIN_REPORTS_PATH} element={<ReportsLanding />} />
      <Route exact path={EXPANDED_INVENTORY_REPORT_PATH} element={<InventoryReportExpanded title={'Inventory By Date'}/>} />
      <Route exact path={TRANSFER_REPORT_PATH} element={<TransferredReport />} />
      <Route exact path={ACTION_REPORT_PATH} element={<InventoryActionReport />} />
      <Route exact path={ENROLLMENT_REPORT_PATH} element={<EnrollmentReport />} />
      <Route exact path={DISPENSED_REPORT_PATH} element={<DispensedReport />} />
      <Route exact path={NEGATIVE_INV_REPORT_PATH} element={<NegativeInventoryReport />} />
      <Route exact path={STATUS_REPORT_PATH} element={<SendToStateResults />} />
      <Route exact path={SEND_TO_STATE_PATH} element={<StateStatusReport />} />
      <Route exact path={SEARCH_REPORT_PATH} element={<HistoricalReport />} />
      <Route exact path={LOGOUT_PATH} element={<LogoutLink />} />
      <Route path={DEFAULT_PATH} element={<Dashboard />} />
    </Routes>
  );
}
