import React, {useCallback, useMemo} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {Grid} from "@mui/material";
import InventoryCard from '../inventory/InventoryCard';
import orderBy from 'lodash/orderBy';
import TypographyFHG from '../../fhg/components/Typography';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {DASHBOARD_EXPIRING_QUERY} from '../../data/QueriesGL';
import {BGD_COLOR, BGD_TABLE_HEAD, CARD_WIDTH, TABLE_HEIGHT} from '../../Constants';

export default function ExpiringTable() {
  const [dashboardExpireData] = useQueryFHG(DASHBOARD_EXPIRING_QUERY, {}, 'dashboard.expiring.type');

  const getHeaderCard = useCallback(() => {
    return (<TypographyFHG id={'dashboard.expiring.column'} sx={{fontSize: '1.125rem', fontWeight: 'bold'}} />);
  }, []);

  const getDisplayCard = useCallback((params) => {
    return (<InventoryCard inventory={params.row} expiring showAvailable showBarcodeLink />);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'displayType',
        flex: 1,
        renderHeader: getHeaderCard,
        headerAlign: 'left',
        headerName: 'Expiring Soon',
        minWidth: CARD_WIDTH + 10,
        renderCell: getDisplayCard,
        sortable: false
      }
    ];
  }, [getDisplayCard, getHeaderCard]);

  const rows = useMemo(() => {
    return dashboardExpireData?.expireRecords ? orderBy(dashboardExpireData?.expireRecords, [row => row.expDate], ['asc']) : [];
  }, [dashboardExpireData]);

  return (
    <Grid sx={{ height: TABLE_HEIGHT}}>
      <DataGrid columns={columns} rows={rows}
                columnHeaderHeight={48}
                density={'compact'}
                rowBufferPx={4}
                disableColumnMenu
                disableColumnSelector
                pageSize={100}
                rowHeight={260}
                sx={{
                  '.MuiDataGrid-columnHeader': {
                    backgroundColor: `${BGD_TABLE_HEAD} !important`,
                    color: `${BGD_COLOR} !important`,
                    fontWeight: 'bold !important',
                  },
                }}
      />
    </Grid>
  );
}
