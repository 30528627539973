import React from 'react';
import PropTypes from 'prop-types';
import AdministerIcon from '@mui/icons-material/Colorize';
import BarcodeIcon from '@mui/icons-material/CalendarViewDay';
import BorrowIcon from '@mui/icons-material/SwapHorizOutlined';
import Button from '@mui/material/Button';
import CaptureIcon from '@mui/icons-material/GetAppOutlined';
import ClinicsIcon from '@mui/icons-material/StoreOutlined';
import CxvIcon from '@mui/icons-material/CallSplitOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {Divider} from '@mui/material';
import {Grid} from '@mui/material';
import InventoryIcon from '@mui/icons-material/LibraryBooksOutlined';
import InventoryTypesIcon from '@mui/icons-material/PaletteOutlined';
import {Link, useMatch} from 'react-router-dom';
import ManufactureIcon from '@mui/icons-material/HomeWorkOutlined';
import PatientIcon from '@mui/icons-material/AccessibilityNew';
import ReportIcon from '@mui/icons-material/AssessmentOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import TransferIcon from '@mui/icons-material/SwapHorizOutlined';
import TypographyFHG from '../../fhg/components/Typography';
import UserIcon from '@mui/icons-material/SupervisorAccount';
import VaccineIcon from '@mui/icons-material/DoneOutlineOutlined';
import {
  ADMIN_INVENTORYTYPES_PATH,
  ADMIN_CLINICS_PATH,
  ADMIN_CVX_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_MANUFACTURERS_PATH,
  ADMIN_MFGVACCINES_PATH,
  ADMIN_USERS_PATH,
  ADMIN_ADMINISTER_A_VAX_PATH,
  ADMIN_CAPTURE_IMMUNIZE_PATH,
  ADMIN_INVENTORY_PATH,
  ADMIN_BORROWS_PATH,
  ADMIN_PRINT_BARCODE_PATH,
  ADMIN_REPORTS_PATH,
  ADMIN_TRANSFERS_PATH,
  ADMIN_PATRECORDS_PATH,
  SEND_TO_STATE_PATH
} from '../../Constants';

export default function AdminDrawer(props) {
  const inventoryTypesMatch = useMatch({path: ADMIN_INVENTORYTYPES_PATH, strict: true, sensitive: true});
  const clinicsMatch = useMatch({path: ADMIN_CLINICS_PATH, strict: true, sensitive: true});
  const cvsMatch = useMatch({path: ADMIN_CVX_PATH, strict: true, sensitive: true});
  const dashboardMatch = useMatch({path: ADMIN_DASHBOARD_PATH, strict: true, sensitive: true});
  const manufacturersMatch = useMatch({path: ADMIN_MANUFACTURERS_PATH, strict: true, sensitive: true});
  const vaccinesMatch = useMatch({path: ADMIN_MFGVACCINES_PATH, strict: true, sensitive: true});
  const usersMatch = useMatch({path: ADMIN_USERS_PATH, strict: true, sensitive: true});
  const inventoryMatch = useMatch({path: ADMIN_INVENTORY_PATH, strict: true, sensitive: true});
  const borrowMatch = useMatch({path: ADMIN_BORROWS_PATH, strict: true, sensitive: true});
  const transfersMatch = useMatch({path: ADMIN_TRANSFERS_PATH, strict: true, sensitive: true});
  const administerMatch = useMatch({path: ADMIN_ADMINISTER_A_VAX_PATH, strict: true, sensitive: true});
  const captureMatch = useMatch({path: ADMIN_CAPTURE_IMMUNIZE_PATH, strict: true, sensitive: true});
  const barcodeMatch = useMatch({path: ADMIN_PRINT_BARCODE_PATH, strict: true, sensitive: true});
  const reportsMatch = useMatch({path: ADMIN_REPORTS_PATH, strict: true, sensitive: true});
  const prRecordsMatch = useMatch({path: ADMIN_PATRECORDS_PATH, strict: true, sensitive: true});
  const toStateMatch = useMatch({path: SEND_TO_STATE_PATH, strict: true, sensitive: true});
  const {handleDrawerClose} = props;

  return (
    <Grid container direction={'column'} isScrollable sx={{py: 4, px: 2}}>
      <Grid container direction={'column'} sx={{display: 'flex', alignItems: 'flex-start', pl: 3}}>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_DASHBOARD_PATH} onClick={handleDrawerClose}
                  disabled={dashboardMatch?.isExact}
                  startIcon={(<DashboardIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.dashboard.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_ADMINISTER_A_VAX_PATH} onClick={handleDrawerClose}
                  disabled={administerMatch?.isExact}
                  startIcon={(<AdministerIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.administer.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_INVENTORY_PATH} onClick={handleDrawerClose}
                  disabled={inventoryMatch?.isExact}
                  startIcon={(<InventoryIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.inventory.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_PATRECORDS_PATH} onClick={handleDrawerClose}
                  disabled={prRecordsMatch?.isExact}
                  startIcon={(<PatientIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.patrecords.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_CAPTURE_IMMUNIZE_PATH} onClick={handleDrawerClose}
                  disabled={captureMatch?.isExact}
                  startIcon={(<CaptureIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.capture.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{my: 0.75, mx: 0, pl: 1, width: '200px'}}>
          <Divider sx={{width: '100% !important'}} />
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_BORROWS_PATH} onClick={handleDrawerClose}
                  disabled={borrowMatch?.isExact}
                  startIcon={(<BorrowIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.borrows.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_CLINICS_PATH} onClick={handleDrawerClose}
                  disabled={clinicsMatch?.isExact}
                  startIcon={(<ClinicsIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.clinics.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_CVX_PATH} onClick={handleDrawerClose}
                  disabled={cvsMatch?.isExact}
                  startIcon={(<CxvIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.cvx.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_INVENTORYTYPES_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={inventoryTypesMatch?.isExact}
                  startIcon={(<InventoryTypesIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.inventoryType.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_MANUFACTURERS_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={manufacturersMatch?.isExact}
                  startIcon={(<ManufactureIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.manufacturers.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_PRINT_BARCODE_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={barcodeMatch?.isExact}
                  startIcon={(<BarcodeIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.barcode.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_REPORTS_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={reportsMatch?.isExact}
                  startIcon={(<ReportIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.reports.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={SEND_TO_STATE_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={toStateMatch?.isExact}
                  startIcon={(<SendIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.tostate.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_TRANSFERS_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={transfersMatch?.isExact}
                  startIcon={(<TransferIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.transfers.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_USERS_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={usersMatch?.isExact}
                  startIcon={(<UserIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.users.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
        <Grid item sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', my: 0, mx: 0, width: '200px', whiteSpace: 'nowrap'}}>
          <Button component={Link} to={ADMIN_MFGVACCINES_PATH} onClick={handleDrawerClose}
                  sx={{display: 'flex', height: '2rem', my: 0.5, mx: 0, width: '200px'}}
                  disabled={vaccinesMatch?.isExact}
                  startIcon={(<VaccineIcon fontSize="large" />)}
                  size={'large'}>
            <TypographyFHG id='appbar.vaccines.nav' sx={{justifyContent: 'left !important', textAlign: 'left', width: '200px'}} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

AdminDrawer.propTypes = {
  handleDrawerClose: PropTypes.func
};
