import React, {useCallback, useMemo} from 'react';
import {Card, CardContent} from '@mui/material';
import * as dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import TypographyFHG from '../../fhg/components/Typography';
import {DASHBOARD_STATUS_QUERY} from '../../data/QueriesGL';
import {DATE_TIME_FORMAT} from '../../Constants';

export default function SendToStateResults() {
  const [dashboardStatusData] = useQueryFHG(DASHBOARD_STATUS_QUERY, {fetchPolicy: "no-cache"}, 'send.status.type');

  const rows = useMemo(() => {
    return dashboardStatusData?.results ? dashboardStatusData.results : [];
  }, [dashboardStatusData]);


  const getLastReported = useCallback((row) => {
    return row?.last_reported_date ? dayjs(row.last_reported_date).format(DATE_TIME_FORMAT) : 'Unknown';
  }, []);

  return (
    <>
      <TypographyFHG id="reports.status.title" color="textSecondary" sx={{my: 0, mr: 1, ml: 2, pt: 1.25}} variant="h5" gutterBottom/>
      <Card sx={{mt: 2, p: 2}}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TypographyFHG id="dashboard.status.last_reported" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.successful" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.rejected" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.seven_day_success" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.seven_day_not_started" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.seven_day_pending" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.seven_day_other" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
                <TableCell>
                  <TypographyFHG id="dashboard.status.seven_day_failure" sx={{fontWeight: 'bold'}} variant="body1" color="textPrimary"/>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TypographyFHG variant="body1" color="textPrimary">{getLastReported()}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].successful : ''}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].rejected : ''}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].seven_day_success : ''}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].seven_day_not_started : ''}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].seven_day_pending : ''}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].seven_day_other : ''}</TypographyFHG>
                </TableCell>
                <TableCell>
                  <TypographyFHG variant="body1"
                                 color="textPrimary">{rows.length > 0 ? rows[0].seven_day_failure : ''}</TypographyFHG>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
