import React, {useCallback} from 'react';
import {Card, CardContent} from '@mui/material';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import TypographyFHG from '../../fhg/components/Typography';
import {CARD_WIDTH_SM} from '../../Constants';

export default function VaccineCard(props) {
  const {inventory, small} = props;

  const getBrand = useCallback(() => {
    let result;
    if (inventory?.mfgBrand) {
      result = `${inventory?.mfgBrand} (${inventory?.mfgGeneric})`;
    }
    return result;
  }, [inventory]);

  const getLot = useCallback(() => {
    let result;
    if (inventory?.lot) {
      result = `[Lot: ${inventory?.lot}]`;
    }
    return result;
  }, [inventory]);

  const getCvx = useCallback(() => {
    let result;
    if (inventory?.cvxCode) {
      result = `(${inventory?.cvxCode})`;
    }
    return result;
  }, [inventory]);

  const getDoses = useCallback(() => {
    return `${inventory?.doses ? inventory?.doses : 0}`;
  }, [inventory]);

  const getManufacturer = useCallback(() => {
    let result;
    if (inventory?.manufacturerName) {
      result = `${inventory?.manufacturerName}`;
    }
    return result;
  }, [inventory]);

  return (
    <Card sx={small ? {border: 'gray solid thin', width: CARD_WIDTH_SM} : {border: 'gray solid thin', mt: 1, width: CARD_WIDTH_SM - 12}}>
      <CardContent>
        <Grid container direction="row">
          <TypographyFHG color="textPrimary" sx={{fontWeight: 'bold', my: 0, mr: 1, ml: 0}} variant="body1">{getBrand()}</TypographyFHG>
        </Grid>
        <Grid container direction="row">
          <TypographyFHG color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2">{getLot()}</TypographyFHG>
        </Grid>
        <Grid container direction="row">
          <TypographyFHG id="inventory.card.mvx.label" color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2" />
          <TypographyFHG color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2">{getManufacturer()}</TypographyFHG>
        </Grid>
        <Grid container direction="row">
          <TypographyFHG id="inventory.card.cvx.label" color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2" />
          <TypographyFHG color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2">{getCvx()}</TypographyFHG>
        </Grid>
        <Grid container direction="row">
          <TypographyFHG id="inventory.card.doses.label" color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2" />
          <TypographyFHG color="textPrimary" sx={{my: 0, mr: 1, ml: 0}} variant="body2">{getDoses()}</TypographyFHG>
        </Grid>
      </CardContent>
    </Card>
  );
}

VaccineCard.propTypes = {
  inventory: PropTypes.object,
  small: PropTypes.bool
}
