import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import AdjustIcon from '@mui/icons-material/SystemUpdateAlt';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import {Chip, InputLabel, MenuItem, Select, Toolbar} from '@mui/material';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {getChipBgColor, hexToRgb} from '../../fhg/utils/Utils';
import GlobalFilter from './GlobalFilter';
import {Grid} from '@mui/material';
import {lighten} from '@mui/material/styles';
import LoanIcon from '@mui/icons-material/SwapHoriz';
import SearchIcon from "@mui/icons-material/Search";
import TextFieldCustom from '../TextFieldCustom';
import TransferIcon from '@mui/icons-material/LowPriority';
import TransferLocationsIcon from '@mui/icons-material/LocationOnOutlined';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import useTheme from '@mui/material/styles/useTheme';
import {INVENTORY_TYPES_QUERY} from '../../data/QueriesGL';
import {CARD_SM_FONT, ERROR_COLOR, PRIMARY_DARK_COLOR} from '../../Constants';

const TableToolbar = props => {
  const [clinicBarcode, setClinicBarcode] = useState('');
  const [mfgBrand, setMfgBrand] = useState('');
  const [typeId, setTypeId] = useState(0);
  const [inventoryTypesData] = useQueryFHG(INVENTORY_TYPES_QUERY, {}, 'inventoryType.type');
  const inventoryTypes = useMemo(() => {return inventoryTypesData?.inventoryTypes || []}, [inventoryTypesData]);
  const theme = useTheme();
  const {
    numSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    onAdd,
    onAdjust,
    onLoan,
    onTransfer,
    onTransferLocations,
    onEdit,
    onDelete,
    addButtonKey,
    adjustButtonKey,
    loanButtonKey,
    transferButtonKey,
    transferLocationsButtonKey,
    editButtonKey,
    deleteButtonKey,
    isAdmin,
    historicalSearch,
    displayInventoryTypes
  } = props;

  const handleAdd = () => {
    onAdd();
  };

  const handleAdjust = () => {
    onAdjust();
  };

  const handleLoan = () => {
    onLoan();
  };

  const handleTransfer = () => {
    onTransfer();
  };

  const handleTransferLocations = () => {
    onTransferLocations();
  };

  const handleEdit = () => {
    onEdit();
  }

  const handleDelete = () => {
    onDelete();
  }

  const handleInventoryTypeChange = (value) => {
    setTypeId(value);
  }

  const handleSearch = useCallback(() => {
    props.onSearch(typeId, clinicBarcode, mfgBrand);
  }, [props, typeId, clinicBarcode, mfgBrand]);

  const handleClinicBarcodeChange = useCallback((event) => {
    setClinicBarcode(event.target.value);
  }, [setClinicBarcode]);

  const handleMfgBrandChange = useCallback((event) => {
    setMfgBrand(event.target.value);
  }, [setMfgBrand]);

  return (
    <Toolbar sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pl: 2, pr: 1}}>
      <Grid container direction="row">
        <Grid container direction={'row'} justifyContent="flex-start">
          {historicalSearch && (
            <Grid item container direction="row" justifyContent="flex-start">
              <TextFieldCustom
                key="clinicBarcode"
                name="clinicBarcode"
                inputProps={{style: {fontSize: '0.875rem'}}}
                size="small"
                sx={{mr: 1, width: '144px'}}
                labelKey={'search.clinicBarcode.label'}
                onChange={handleClinicBarcodeChange}
                value={clinicBarcode}
              />
              <span style={{margin: '24px 0.875px 0', width: '32px'}}>OR</span>
              <TextFieldCustom
                key="mfgBrand"
                name="mfgBrand"
                inputProps={{style: {fontSize: '0.875rem'}}}
                size="small"
                sx={{mr: 1, width: '236px'}}
                defaultValue=""
                labelKey={'search.mfgBrand.label'}
                onChange={handleMfgBrandChange}
                value={mfgBrand}
              />
              <ButtonFHG id="patientRecords.search.button" labelKey={'patientRecords.search.button'}
                         sx={{
                           mt: 2,
                           mx: 0.75,
                           mb: 1,
                           '&:hover': {
                             color: PRIMARY_DARK_COLOR,
                           },
                           fontSize: '0.75rem'
                         }}
                         startIcon={(<SearchIcon />)} onClick={handleSearch} variant="outlined" />
            </Grid>)}
          {addButtonKey && isAdmin && (
            <Grid item sx={{mt: 1}}>
              <ButtonFHG id={addButtonKey} labelKey={addButtonKey}
                         sx={{
                           mt: 2,
                           mx: 0.75,
                           mb: 1,
                            '&:hover': {
                              color: PRIMARY_DARK_COLOR,
                            },
                            fontSize: '0.75rem'
                         }}
                         startIcon={(<AddIcon />)} onClick={handleAdd} variant="outlined" />
            </Grid>)}
          {displayInventoryTypes && (
          <Grid container direction="column" item sx={{mt: 0.25, mr: 2, ml: 1, width: '160px'}}>
            <InputLabel id="typeDropDown" sx={{fontSize: '.875rem', mt: 0, mr: 0, ml: 0.5}}>Inventory Type</InputLabel>
            <Select
              sx={{
                '& .MuiSelect-select': {p: 0.75}
              }}
              value={typeId}
              onChange={e => {
                handleInventoryTypeChange(e.target.value || undefined)
              }}
              labelId={'typeDropDown'}
            >
              <MenuItem key={0} value={'0'}>
                <Chip size='small' label={'ALL'} sx={{
                  fontSize: CARD_SM_FONT,
                  width: '130px',
                  m: 0,
                  backgroundColor: `blue`,
                  color: theme.palette.common.white
                }}/>
              </MenuItem>
              {inventoryTypes.map((option, i) => (
                <MenuItem key={i} value={option.inventoryTypeID}>
                  <Chip size='small' label={option.name} sx={{
                    fontSize: CARD_SM_FONT,
                    width: '130px',
                    m: 0,
                    backgroundColor: `${getChipBgColor(option.colorCode)}`,
                    color: `${hexToRgb(option.colorCode)}`
                  }}/>
                </MenuItem>
              ))}
            </Select>
          </Grid>)}
          {!historicalSearch && (
          <Grid item sx={{mt: 1}}>
            <ButtonFHG id="patientRecords.search.button" labelKey={'patientRecords.search.button'}
                       sx={{
                         mt: 2,
                         mx: 0.75,
                         mb: 1,
                         '&:hover': {
                           color: PRIMARY_DARK_COLOR,
                         },
                         fontSize: '0.75rem'
                       }}
                       startIcon={(<SearchIcon />)} onClick={handleSearch} variant="outlined" />
          </Grid>
          )}
          {numSelected === 1 && isAdmin && (
            <>
              {editButtonKey && (
                  <ButtonFHG id={editButtonKey} sx={{
                    mt: 2,
                    mx: 0.75,
                    mb: 1,
                    ml: 2,
                    '&:hover': {
                      color: PRIMARY_DARK_COLOR,
                    },
                    fontSize: '0.75rem'
                  }} labelKey={editButtonKey} startIcon={(<EditIcon />)} onClick={handleEdit} size="small" variant="outlined" />
               )}

              {adjustButtonKey && (
                  <ButtonFHG id={adjustButtonKey} sx={{
                    mt: 2,
                    mx: 0.75,
                    mb: 1,
                    '&:hover': {
                      color: PRIMARY_DARK_COLOR,
                    },
                    fontSize: '0.75rem'
                  }} labelKey={adjustButtonKey} startIcon={(<AdjustIcon />)} onClick={handleAdjust} size="small" variant="outlined" />
                )}
              {loanButtonKey && (
                  <ButtonFHG id={loanButtonKey} sx={{
                    mt: 2,
                    mx: 0.75,
                    mb: 1,
                    '&:hover': {
                      color: PRIMARY_DARK_COLOR,
                    },
                    fontSize: '0.75rem'
                  }} labelKey={loanButtonKey} startIcon={(<LoanIcon />)} onClick={handleLoan}/>
              )}
              {transferButtonKey && (
                  <ButtonFHG id={transferButtonKey} sx={{
                    mt: 2,
                    mx: 0.75,
                    mb: 1,
                    '&:hover': {
                      color: PRIMARY_DARK_COLOR,
                    },
                    fontSize: '0.75rem'
                  }} labelKey={transferButtonKey} startIcon={(<TransferIcon />)} onClick={handleTransfer} size="small" variant="outlined" />
              )}
              {transferLocationsButtonKey && (
                  <ButtonFHG id={transferLocationsButtonKey} sx={{
                    mt: 2,
                    mx: 0.75,
                    mb: 1,
                    '&:hover': {
                      color: PRIMARY_DARK_COLOR,
                    },
                    fontSize: '0.75rem'
                  }} labelKey={transferLocationsButtonKey} startIcon={(<TransferLocationsIcon />)} onClick={handleTransferLocations} size="small" variant="outlined" />
              )}
              {deleteButtonKey && (
                  <ConfirmButton
                    id={deleteButtonKey}
                    buttonLabelKey="inventory.delete.button"
                    buttonTypographyProps={{variant:"body1"}}
                    sx={{
                      mt: 2,
                      mx: 0.75,
                      mb: 1,
                      '&:hover': {
                        color: PRIMARY_DARK_COLOR,
                      },
                      fontSize: '0.75rem',
                      '.MuiTypography-root': {fontSize: '0.75rem'}
                    }}
                    onConfirm={handleDelete}
                    values={{
                      type: 'inventory record',
                      name: ''
                    }}
                    submitStyle={{
                      backgroundColor: lighten(ERROR_COLOR, 0.15),
                      '&:hover': {
                        backgroundColor: lighten(ERROR_COLOR, 0.2),
                      },
                      fontSize: '0.75rem'
                    }}
                    startIcon={<DeleteIcon />}
                   size="small" variant="outlined" />
              )}
            </>
          )}
        </Grid>
      </Grid>
      {!historicalSearch && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  setGlobalFilter: PropTypes.func,
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.string,
  onAdd: PropTypes.func,
  onAdjust: PropTypes.func,
  onDelete: PropTypes.func,
  onLoan: PropTypes.func,
  onTransfer: PropTypes.func,
  onTransferLocations: PropTypes.func,
  onSearch: PropTypes.func,
  addButtonKey: PropTypes.string,
  deleteButtonKey: PropTypes.string,
  loanButtonKey:  PropTypes.string,
  transferButtonKey:  PropTypes.string,
  transferLocationsButtonKey:  PropTypes.string,
  isAdmin: PropTypes.bool
}

export default TableToolbar
