import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Add} from '@mui/icons-material';
import ButtonFHG from '../../fhg/components/ButtonFHG';
import ClinicAdd from './ClinicAdd';
import ClinicEdit from './ClinicEdit';
import * as dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditDrawer from '../EditDrawer';
import {lighten} from '@mui/material/styles';
import {Link} from '@mui/material';
import {Grid} from '@mui/material';
import TableFHG from '../../fhg/components/table/TableFHG';
import TypographyFHG from '../../fhg/components/Typography';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {CLINIC_DELETE, CLINICS_QUERY, getClinicRefetchQueries} from '../../data/QueriesGL';
import {
  DATE_FORMAT_KEYBOARD,
  EDIT_DRAWER_WIDTH, ERROR_COLOR,
  PRIMARY_DARK_COLOR
} from '../../Constants';
import ConfirmButton from "../../fhg/components/ConfirmButton";

export default function Clinics() {
  const [clinicId, setClinicId] = useState(undefined);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  const [deleteClinic, setDeleteClinic] = useState(false);
  const [clinicDeleted, setClinicDeleted] = useState(false);
  const [clinicsData] = useQueryFHG(CLINICS_QUERY, {variables: {}}, 'clinic.type');
  const [clinicDelete, {data: clinicDeleteData, error: clinicDeleteError}] = useMutationFHG(CLINIC_DELETE);

  const clinics = useMemo(() => clinicsData?.clinics || [], [clinicsData]);
  const styles = useMemo(() => {
    return {
      deleteColorStyle: {
        color: 'primary.contrastText',
        backgroundColor: ERROR_COLOR,
        '&:hover': {
          backgroundColor: lighten(ERROR_COLOR, 0.3),
        }
      },
    };
  }, []);

  useEffect(() => {
    if (clinicDeleteData && clinicDeleteError === undefined) {
      setClinicDeleted(true);
    }
  }, [clinicDeleteData, clinicDeleteError, setClinicDeleted]);

  useEffect(() => {
    async function deleteThisClinc() {
      return await clinicDelete({
        variables: {id: clinicId},
        refetchQueries: getClinicRefetchQueries()
      });
    }
    if (deleteClinic && !clinicDeleted) {
      deleteThisClinc().then(r => {});
    }
  }, [clinicId, clinicDelete, deleteClinic, clinicDeleted]);

  const handleAdd = useCallback(() => {
    setSelectedId('new');
    setOpenDetailDrawer(true);
  }, []);

  const handleEdit = useCallback((id) => {
    setSelectedId(id);
    setOpenDetailDrawer(true);
  }, []);

  const handleDeleteClick = useCallback((id) => {
    if (id) {
      setDeleteClinic(true);
      setClinicDeleted(false);
      setClinicId(id);
    }
  }, [setDeleteClinic, setClinicDeleted, setClinicId]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        Header: <TypographyFHG id={'clinics.name'}/>,
        accessor: 'name',
        Cell: ({row}) => (<Link sx={{cursor: 'pointer'}} underline="always" variant="inherit" onClick={() => handleEdit(row?.original.id)}>{row.values?.name}</Link>)
      },
      {
        id: 'phone',
        Header: <TypographyFHG id={'clinics.phone'}/>,
        accessor: 'phone'
      },
      {
        id: 'dateEntered',
        Header: <TypographyFHG id={'clinics.dateEntered'}/>,
        accessor: 'dateEntered',
        Cell: ({value}) => (
          <span>{value ? dayjs(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 'delete',
        Header: 'Delete',
        headerAlign: 'left',
        accessor: '',
        Cell: ({row}) => (
          <ConfirmButton
            id={'clinics.delete.button'}
            buttonLabelKey=""
            confirmButtonLabelKey="delete.button"
            buttonTypographyProps={{variant:"body1"}}
            sx={{
              backgroundColor: "inherit",
              cursor: "pointer",
              my: 0.25, mx: 0,
              width: "32px"
            }}
            style={{cursor: 'pointer'}}
            onConfirm={() => handleDeleteClick(row.original.id)}
            message={`Are you sure you want to delete: ${row.original.name}`}
            values={{
              type: 'clinic record',
              name: row.values?.name
            }}
            size='small'
            submitStyle={styles.deleteColorStyle}
            startIcon={<DeleteIcon color="action" style={{width: '132px'}} />}
          />
        )
      }
    ];
  }, [handleDeleteClick, handleEdit, styles]);

  const handleDetailClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  return (
    <Grid container direction="column" overflow={'visible'} wrap="nowrap">
      <TypographyFHG id="clinics.title" color="textSecondary" sx={{mt: 0, mr: 0, mb: 0.5, ml: 2}} variant="h5" />
      <Grid item>
        <TableFHG name="Clinics" columns={columns} data={clinics}
                  allowSearch emptyTableMessageKey={'clinics.noRecord.message'}
        >
          <Grid container item direction="row" justifyContent="flex-start">
            <ButtonFHG id="clinics.add.button" labelKey={'clinics.add.button'} startIcon={(<Add/>)} onClick={handleAdd}
                       sx={{m: 0, fontSize: '0.875rem',
                         '&:hover': {
                           color: PRIMARY_DARK_COLOR,
                         }}}
                       variant="outlined" />
          </Grid>
        </TableFHG>
      </Grid>

      <EditDrawer
        ModalProps={{BackdropProps: {style: {height: '100%'}}}}
        open={openDetailDrawer}
        onClose={handleDetailClose}
      >
        <Grid container direction="column" sx={{height: '100vh', width: EDIT_DRAWER_WIDTH}}>
          {selectedId === 'new' ? <ClinicAdd clinics={clinics} onClose={handleDetailClose} /> : <ClinicEdit clinics={clinics} id={selectedId} onClose={handleDetailClose} />}
        </Grid>
      </EditDrawer>
    </Grid>
  );
}
